import React, { useState, useEffect } from "react";
import { ChromePicker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';
import convert from 'color-convert';
import './ColorPicker.scss';

const ColorPicker = (props) => {
    const { onChange, colorHex } = props;
    // const { t } = useTranslation();
    const [currentType, setCurrentType] = useState(props.type || 'rgb');
    const [currentColor, setCurrentColor] = useState({
        rgb: {
            r: 255,
            g: 255,
            b: 255,
        },
        hex: "#ffffff"
    });
    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        if (colorHex) {
            handleColorChange('hex', props.colorHex);
        }
    }, [colorHex]);

    const handleColorChange = (type, value) => {
        if (type === 'hex') {
            if (value[0] === '#') value = value.substring(1);
            const rgb = convert.hex.rgb(value);
            const newColorObject = {
                ...currentColor,
                [type]: `#${value}`,
                rgb: {
                    r: rgb[0],
                    g: rgb[1],
                    b: rgb[2],
                }
            }

            setCurrentColor(newColorObject);
            onChange(newColorObject.hex);
        } else {
            const r = type === 'r' ? +value : currentColor.rgb.r;
            const g = type === 'g' ? +value : currentColor.rgb.g;
            const b = type === 'b' ? +value : currentColor.rgb.b;
            const hex = convert.rgb.hex(r, g, b);

            const newColorObject = {
                ...currentColor,
                hex: `#${hex}`,
                rgb: {
                    ...currentColor.rgb,
                    [type]: value
                }
            };

            setCurrentColor(newColorObject);
            onChange(newColorObject.hex);
        }
    }

    return (
        <div className="color-picker-component">
            <div className="color-picker-type">
                <select name="picker-type" id="picker-type" value={currentType} onChange={e => setCurrentType(e.target.value)}>
                    <option value="rgb">RGB Color Code</option>
                    <option value="hex">Hex Color Code</option>
                </select>
            </div>

            <div className="color-picker-value">
                {
                    currentType === 'rgb' ?
                        <div className="color-picker-rgb">
                            <span>R</span>
                            <input
                                type="number"
                                maxLength="3"
                                min="0"
                                max="255"
                                value={currentColor.rgb?.r}
                                onChange={e => handleColorChange("r", e.target.value)} />

                            <span>G</span>
                            <input
                                type="number"
                                maxLength="3"
                                min="0"
                                max="255"
                                value={currentColor.rgb?.g}
                                onChange={e => handleColorChange("g", e.target.value)} />

                            <span>B</span>
                            <input
                                type="number"
                                maxLength="3"
                                min="0"
                                max="255"
                                value={currentColor.rgb?.b}
                                onChange={e => handleColorChange("b", e.target.value)} />
                        </div> :

                        <div className="color-picker-hex">
                            <span>#</span>
                            <input
                                type="text"
                                maxLength="6"
                                value={currentColor.hex.slice(1, currentColor.hex.length)}
                                onChange={e => handleColorChange("hex", e.target.value)} />
                        </div>
                }

                <div className="color-picker-popup">
                    <div className="selected-color" onClick={() => setShowColorPicker(!showColorPicker)} style={{
                        border: "1px solid #bbb",
                        height: '100%',
                        width: 25,
                        padding: 2,
                        marginLeft: 5
                    }}>
                        <div style={{ backgroundColor: currentType === 'rgb' ? `rgb(${currentColor.rgb.r}, ${currentColor.rgb.g}, ${currentColor.rgb.b})` : currentColor.hex, height: '100%', width: '100%' }}></div>
                    </div>

                    {
                        showColorPicker &&
                        <div style={{ position: 'absolute', zIndex: 100 }}>
                            <OutsideClickHandler onOutsideClick={() => setShowColorPicker(false)}>
                                <ChromePicker
                                    color={currentColor}
                                    onChangeComplete={(color, event) => handleColorChange('hex', color.hex)} />
                            </OutsideClickHandler>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default ColorPicker;