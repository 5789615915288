import React, { useState, useEffect } from 'react'
import LicenseKeyComponent from '../components/LicenseKeyComponent'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { updateLicense } from "redux/actions/licenseActions";
import { updateSettings } from "redux/actions/settingsActions";
import { updateDashboard } from "redux/actions/dashboardActions";
import { updateLicenseKeyService } from 'services/settings';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { parseLicenseData } from 'services/helper';
import { getAllConfigurations } from 'services/configurations';

import _ from 'lodash';

const LicenseKey = (props) => {
    const {  settings, dashboard, updateLicense, updateDashboard, updateSettings } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [updadingLicense, setUpdadingLicense] = useState(false);
    const [newLicenseKey, setNewLicenseKey] = useState("");

    const { t } = useTranslation();

    const changeLicense = async () => {
        setUpdadingLicense(true);

        try {
            const formData = {
                keyText: newLicenseKey
            }

            await updateLicenseKeyService(formData);
            await getConfigurations(settings, dashboard);

            const licenseParsed = await parseLicenseData(formData);
            updateLicense(licenseParsed);
            setNewLicenseKey("");

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_license_update_success')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while updating license: ${err}`);
        }

        setUpdadingLicense(false);
    }

    const getConfigurations = async (settings, dashboard) => {
        try {
            const { settingsData, dashboardData } = await getAllConfigurations(settings, dashboard);
            updateDashboard(dashboardData);
            updateSettings(settingsData);
        } catch (err) {
            console.log(`Error while fetching configurations: ${err}`)
        }
    }

    return (
        <LicenseKeyComponent
            {...props}
            newLicenseKey={newLicenseKey}
            setNewLicenseKey={setNewLicenseKey}
            changeLicense={changeLicense}
            isLoading={isLoading}
            updadingLicense={updadingLicense}
        />
    )
}


const mapStateToProps = (store) => ({
    license: store.licenseReducer.license,
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateLicense, updateDashboard, updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LicenseKey);