import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { getSMTPCredentialsService, submitSMTPService, getEmailOptionsService, submitEmailTextService, getSMTPReceiversService, submitReceiversService } from 'services/email';
import { getRiskOfficersService } from 'services/riskOfficer';
import EmailSettingsComponent from '../components/EmailSettingsComponent'
import _ from 'lodash'; import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateRiskOfficerService } from 'services/riskOfficer';

const EmailSettingsContainer = (props) => {
    const { settings } = props;

    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [smtpConfig, setSmtpConfig] = useState({});
    const [receiversConfig, setReceiversConfig] = useState([]);
    const [receiversShowGroup, setReceiversShowGroup] = useState(false);
    const [emailTextConfig, setEmailTextConfig] = useState({});

    const [riskOfficerList, setRiskOfficerList] = useState([]);

    useEffect(() => {
        getCredentials();
        getReceivers();
        getEmailOptions();
        getRiskOfficers();
    }, []);

    const getCredentials = async () => {
        try {
            setLoadingData(true);
            const res = await getSMTPCredentialsService();
            setSmtpConfig(res);
        }
        catch (e) {
            console.log('error get credencials', e);
        }
        finally {
            setLoadingData(false);
        }
    }

    const getReceivers = async () => {
        try {
            setLoadingData(true);
            const res = await getSMTPReceiversService();
            setReceiversConfig(res);
            setReceiversShowGroup(settings?.coordinatorConfig?.useGroups ? true : false);
        }
        catch (e) {
            console.log('error get credencials', e);
        }
        finally {
            setLoadingData(false);
        }
    }

    const getEmailOptions = async () => {
        try {
            setLoadingData(true);
            const res = await getEmailOptionsService();

            let grouped = {};
            res.forEach((item) => {
                const language = item.languageId;
                if (!grouped[language]) grouped[language] = {};
                grouped[language][item.emailType] = item.body;
            })
            setEmailTextConfig(grouped);

        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoadingData(false);
        }
    }

    const getRiskOfficers = async () => {
        setLoadingData(true);

        try {
            const riskOfficers = await getRiskOfficersService();
            setRiskOfficerList([riskOfficers]);
        } catch (err) {
            console.log(`Error while fetching risk officers: ${err}`)
        }

        setLoadingData(false);
    }

    const submitSMTP = async (values) => {
        try {
            setLoading(true);
            const res = await submitSMTPService(values);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_configuration_saved')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        }
        catch (e) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={''} />, {
                progressClassName: "toastr-progress-bar danger"
            });
            console.log('error sending SMTP', e);
        }
        finally {
            setLoading(false);
        }
    }

    const submitReceivers = async (newReceivers) => {
        try {
            setLoading(true);
            const res = await submitReceiversService(newReceivers);
            await getReceivers();
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_configuration_saved')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        }
        catch (e) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={''} />, {
                progressClassName: "toastr-progress-bar danger"
            });
            console.log('error sending receivers', e);
        }
        finally {
            setLoading(false);
        }
    }

    const submitEmailText = async (values) => {
        try {
            setLoading(true);
            let payload = [];
            _.keys(values).forEach((key) => {
                const languageId = parseInt(key.split('_')[0]);
                const emailType = key.split('_')[1];
                const body = values[key];
                payload.push({ languageId, emailType, body });
            })
            await submitEmailTextService(payload);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_configuration_saved')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        }
        catch (e) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={''} />, {
                progressClassName: "toastr-progress-bar danger"
            });
            console.log('error sending email text', e);
        }
        finally {
            setLoading(false);
        }
    }

    const updateRiskOfficer = async (riskOfficer) => {
        setLoading(true);

        try {
            await updateRiskOfficerService(riskOfficer);
            setRiskOfficerList([riskOfficer]);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_riskofficer_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while updating risk officer: ${err?.message || err}`);
        }

        setLoading(false);
    }

    return (
        <EmailSettingsComponent
            loading={loading}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            submitSMTP={submitSMTP}
            smtpConfig={smtpConfig}
            submitReceivers={submitReceivers}
            receiversConfig={receiversConfig}
            submitEmailText={submitEmailText}
            emailTextConfig={emailTextConfig}
            receiversShowGroup={receiversShowGroup}
            riskOfficerList={riskOfficerList}
            updateRiskOfficer={updateRiskOfficer}
        />
    )
}

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(EmailSettingsContainer);