import React from "react";
import { useTranslation } from "react-i18next";
import Wizard from 'components/Scanning/StartScan/components/Wizard/containers/Wizard';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import './WizardModalComponent.scss';

const WizardModalComponent = (props) => {
    const { isOpen, setIsOpen, id, settings } = props;
    const { t } = useTranslation();
    const mainColor = settings?.theme?.mainColor;

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal wizard-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>Scan Wizard</h3>
                <p style={{ color: mainColor }}>This is a read only visualization of a configuration already set</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <Wizard
                    id={id}
                    readOnly={true}
                />
            </div>
        </Modal>
    )
};

export default WizardModalComponent;