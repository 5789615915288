import React from "react";
import { FaChevronRight } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import "./SendButton.scss";

const SendButton = (props) => {
    const { disabled, onClick, isLoading } = props;

    return (
        <div className={`send-button-component ${disabled ? "disabled" : ""}`} onClick={onClick}>
            {
                isLoading ?
                    <ClipLoader
                        size={17}
                        color={"#fff"}
                        loading={true}
                    /> :
                    <FaChevronRight />
            }
        </div>
    )
};

export default SendButton;