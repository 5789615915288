import React from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import Button from 'components/common/Button/Button';
import Skeleton from "react-loading-skeleton";
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import _ from 'lodash';
import './ChangeMultipleFileStatusModalComponent.scss';

const ChangeMultipleFileStatusModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        settings,
        changeValidationStatus,
        isLoading,
        fileRows,
        fetchingFileData,
        updateFileAssignedUser,
        setSelectUserModalOpen,
        selectUserModalOpen,
        editingRow,
        setEditingRow
    } = props;

    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                className="kpmg-modal change-multiple-file-status-modal"
                overlayClassName="kpmg-modal-overlay"
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}>

                <div className="modal-header">
                    <h3>{t('change_multiple_file_status_modal_title')}</h3>
                    <p style={{ color: mainColor }}>{t('change_multiple_file_status_modal_subtitle')}</p>

                    <div className="close-icon" onClick={() => setIsOpen(false)}>
                        <FaTimes />
                    </div>
                </div>

                <div className="modal-body">
                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="infotext-en">{t('change_multiple_file_status_modal_file_list')}</label>
                        <div className="files-list">
                            {
                                fetchingFileData ?
                                    _.times(4, i => (
                                        <div key={i} className="file-row white">
                                            <div className="filename">
                                                <Skeleton width={100} height={10} />
                                            </div>
                                            <div className="coordinator">
                                                <Skeleton width={50} height={10} />
                                            </div>
                                        </div>
                                    )) :
                                    fileRows.map((file, i) => (
                                        <div key={i} className="file-row">
                                            <div className="filename">{file.fileName}</div>
                                            <div className="coordinator">
                                                <span>{file?.assignedUser?.displayName || '-'}</span>
                                                <div className="coordinator-edit" onClick={() => {
                                                    setEditingRow(file);
                                                    setSelectUserModalOpen(true);
                                                }}>
                                                    <BsPencilSquare style={{ fontSize: 14 }} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>

                    <div className="action-buttons">
                        <Button
                            style={{ width: 100 }}
                            loading={isLoading}
                            onClick={() => changeValidationStatus()}
                            disabled={fetchingFileData}>
                            {t('settings_tab4_popup_infotext_button2')}
                        </Button>
                    </div>
                </div>
            </Modal>

            <SelectUserModal
                isOpen={selectUserModalOpen}
                setIsOpen={setSelectUserModalOpen}
                selectedItems={[editingRow]}
                getCoordinatorNameCallback={updateFileAssignedUser}
                title={t('file_validation_modal_title')}
                description={t('file_validation_modal_content')}
            />
        </div>
    )
};

export default ChangeMultipleFileStatusModalComponent;