import React from "react";
import { connect } from "react-redux";
import "./NavTabs.scss";

const NavTabs = (props) => {
    const {
        children,
        tabs,
        selectedTab,
        setSelectedTab,
        settings
    } = props;

    const secondaryColor = settings?.theme?.secondaryColor;

    return (
        <div className="navbar-tabs-wrapper">
            <nav className="navbar">
                {
                    tabs.map((tab, i) => (
                        tab ?
                            <div
                                key={i}
                                className={`navbar-item ${selectedTab === i ? "active" : ""}`}
                                onClick={() => setSelectedTab(i)}>
                                <div style={{ borderBottomColor: selectedTab === i ? secondaryColor : 'white' }} className="text">{tab}</div>
                            </div> :
                            null
                    ))
                }
                <div className="fill"></div>
            </nav>

            <div className="tab-wrapper">
                {children}
            </div>
        </div>
    );
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(NavTabs);