import React from 'react'
import { FaCheck, FaExclamationTriangle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa'
import "./Toastr.scss"

const getToastrType = type => {
    switch (type) {
        case 'success':
            return <FaCheck />;
        case 'danger':
            return <FaTimesCircle />;
        case 'warning':
            return <FaExclamationTriangle />;
        case 'info':
            return <FaInfoCircle />;
        default:
            return <FaCheck />;
    }
}

const ToastrContent = props => (
    <div className={`toastr-content-wrapper ${props.type}`}>
        <div className="toastr-icon">
            {getToastrType(props.type)}
        </div>
        <div className="toastr-content">
            <h4>{props.title}</h4>
            <p>{props.message}</p>
        </div>
    </div >
);

export default ToastrContent