import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddItem from 'components/common/AddItem/AddItem';
import Button from 'components/common/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineClose } from 'react-icons/ai';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import _ from 'lodash';
import ToastrContent from "components/common/Toastr/Toastr";
import QuestionsManagementSkeleton from "components/common/QuestionsManagementSkeleton/QuestionsManagementSkeleton";
import { toast } from "react-toastify";
import './EucCheckManagementComponent.scss';

const EmptyRow = () => {
    return (
        <tr className="empty">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    )
}

const EucCheckManagementComponent = (props) => {
    const {
        error,
        isLoading,
        questions,
        submitQuestions,
        requestSending,
        allowedAlias,
        setAllowedAlias,
        allowedAutomaticValidations,
        setAllowedAutomaticValidations,
        allowedFileExplorer,
        setAllowedFileExplorer,
    } = props;

    const { t, i18n } = useTranslation();
    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    const [initialValues, setInitialValues] = useState({});
    const [initialYup, setInitialYup] = useState({});

    useEffect(() => {
        let newInitialValues = {
            dictionary: {
                isEuc: {
                    en: "",
                    de: ""
                },
                notEuc: {
                    en: "",
                    de: ""
                },
                bottomMessageEuc: {
                    en: "",
                    de: ""
                },
                bottomMessageNotEuc: {
                    en: "",
                    de: ""
                },
            }
        };

        // Old fields are used to perform GET operations. They are deleted when sending the request to back-end
        if (translationsLoaded) {
            if (questions.length) {
                questions.forEach((question, i) => {
                    newInitialValues[i] = {
                        order: i + 1,
                        text: {
                            en: t(question.text, { lng: 'en' }),
                            de: t(question.text, { lng: 'de' })
                        },
                        type: 'select',
                        behaviour: i === 0 ? 'show' : 'hide',
                        infoText: {
                            en: t(question.infoText, { lng: 'en' }),
                            de: t(question.infoText, { lng: 'de' })
                        },
                        requirements: [],
                        options: [],
                        oldFields: {
                            ...question
                        }
                    };

                    question.options.forEach((questionOption, j) => {
                        const questionThatRequireThisAnswer = questions.map((thisQuestion, i) => {
                            const requirementList = thisQuestion.requirements.filter(requirement => {
                                return requirement.trigger === questionOption.value;
                            });

                            const questionIds = requirementList.map(requirement => requirement.requiredId)[0];
                            const questionOrder = questionIds ? thisQuestion.order : null;
                            const isValueEuc = thisQuestion.valueEuc.includes(questionOption.value);

                            if (isValueEuc) return 101;

                            return questionOrder;
                        }).filter(questionValue => questionValue !== null)[0];

                        newInitialValues[i].options.push({
                            order: j + 1,
                            value: {
                                en: t(questionOption.value, { lng: 'en' }),
                                de: t(questionOption.value, { lng: 'de' })
                            },
                            requiredByQuestionOrder: questionThatRequireThisAnswer ? parseInt(questionThatRequireThisAnswer) : 100,
                            oldFields: {
                                ...questionOption
                            }
                        });
                    })
                });
            }

            newInitialValues['dictionary'] = {
                isEuc: {
                    en: t("screenEucCheck_layout_chain_text_9", { lng: 'en' }),
                    de: t("screenEucCheck_layout_chain_text_9", { lng: 'de' }),
                },
                notEuc: {
                    en: t("screenEucCheck_layout_chain_text_10", { lng: 'en' }),
                    de: t("screenEucCheck_layout_chain_text_10", { lng: 'de' }),
                },
                bottomMessageEuc: {
                    en: t("screenEucCheck_layout_chain_text_7", { lng: 'en' }),
                    de: t("screenEucCheck_layout_chain_text_7", { lng: 'de' }),
                },
                bottomMessageNotEuc: {
                    en: t("screenEucCheck_layout_chain_text_13", { lng: 'en' }),
                    de: t("screenEucCheck_layout_chain_text_13", { lng: 'de' }),
                },
            }

            setInitialValues(newInitialValues);
            generateYupSchema('initialize', null, null, newInitialValues)
        }
    }, [questions, translationsLoaded]);

    const formik = useFormik({
        initialValues,
        validationSchema: initialYup,
        enableReinitialize: true,
        onSubmit: values => {
            submitQuestions(values);
        },
    });

    const generateYupSchema = (operationType, questionIndex, optionIndex, questions = formik.values) => {
        let yupValidationSchemaTemp = {}

        const questionsWithoutDictionary = _.omit(questions, ['dictionary']);
        const questionsWithoutNull = _.omitBy(questionsWithoutDictionary, _.isNull);
        let questionKeys = _.keys(questionsWithoutNull).map(key => parseInt(key));

        if (operationType) {
            if (operationType === 'addQuestion') {
                const lastQuestionNumber = questionKeys.length ? _.last(questionKeys) + 1 : 0;
                questionKeys.push(lastQuestionNumber);
            }

            if (operationType === 'removeQuestion') {
                const questionsWithoutRemoved = questionKeys.filter(value => value !== parseInt(questionIndex));
                questionKeys = questionsWithoutRemoved;
            }
        }

        questionKeys.forEach(questionKey => {
            const questionOptionsWithoutNull = questions[questionKey]?.options?.filter(option => option !== null);
            let questionOptions = questionOptionsWithoutNull || ['option', 'option'];

            if (operationType === 'addOption' && questionKey === parseInt(questionIndex)) questionOptions.push('option');
            if (operationType === 'removeOption' && questionKey === parseInt(questionIndex)) questionOptions.pop();

            const questionOptionsYup = questionOptions.map(questionOption => {
                return Yup.object().shape({
                    value: Yup.object().shape({
                        en: Yup.string().required(),
                        de: Yup.string().required(),
                    }),
                    requiredByQuestionOrder: Yup.number().moreThan(0).required()
                }).nullable();
            });

            let questionYupObject = {
                text: Yup.object().shape({
                    en: Yup.string().required(),
                    de: Yup.string().required(),
                }),
            }

            if (questionOptionsYup.length) questionYupObject.options = Yup.array().of(...questionOptionsYup).strict();

            yupValidationSchemaTemp[questionKey] = Yup.object().shape(questionYupObject)
        });

        setInitialYup(Yup.object().shape({
            ...yupValidationSchemaTemp,
            dictionary: Yup.object().shape({
                isEuc: Yup.object().shape({
                    en: Yup.string().required(),
                    de: Yup.string().required()
                }),
                notEuc: Yup.object().shape({
                    en: Yup.string().required(),
                    de: Yup.string().required()
                }),
                bottomMessageEuc: Yup.object().shape({
                    en: Yup.string().required(),
                    de: Yup.string().required()
                }),
            }),
        }))
    }

    const addQuestion = async () => {
        const formikQuestionKeys = _.keys(_.omit(formik.values, ['dictionary']));
        const newIndex = formikQuestionKeys.length ? parseInt(_.last(formikQuestionKeys)) + 1 : 0;

        formik.setFieldValue(newIndex, {
            order: newIndex + 1,
            text: {
                en: '',
                de: ''
            },
            infoText: {
                en: '',
                de: ''
            },
            type: "select",
            behaviour: "hide",
            requirements: [],
            options: [
                {
                    value: {
                        en: '',
                        de: ''
                    },
                    order: 1,
                    requiredByQuestionOrder: 0
                },
                {
                    value: {
                        en: '',
                        de: ''
                    },
                    order: 2,
                    requiredByQuestionOrder: 0
                }
            ]
        })

        generateYupSchema('addQuestion');
    }

    const removeQuestion = (questionIndex) => {
        formik.setFieldValue(questionIndex, null);

        const questionOrder = formik.values[questionIndex].order;

        const valuesWithoutDictionary = _.omit(formik.values, ['dictionary']);
        const valuesWithoutRemovedQuestions = _.omitBy(valuesWithoutDictionary, _.isNull);
        const formikQuestionKeys = _.keys(valuesWithoutRemovedQuestions);

        formikQuestionKeys.forEach(formikKey => {
            formik.values[formikKey].options.forEach((option, i) => {
                if (option?.requiredByQuestionOrder === questionOrder) {
                    formik.setFieldValue(`${formikKey}.options[${i}].requiredByQuestionOrder`, 0);
                }
            })
        });

        generateYupSchema('removeQuestion', questionIndex);
    }

    const addOption = (questionIndex) => {
        const questionData = formik.values[questionIndex];
        const questionOptions = questionData.options;

        formik.setFieldValue(`${questionIndex}.options`, [
            ...questionOptions,
            {
                value: {
                    en: '',
                    de: ''
                },
                order: questionOptions.length + 1,
                requiredByQuestionOrder: 0
            }
        ])

        generateYupSchema('addOption', questionIndex);
    }

    const removeOption = (questionIndex, optionIndex) => {
        formik.setFieldValue(`${questionIndex}.options[${optionIndex}]`, null);
        generateYupSchema('removeOption', questionIndex);
    }

    const sortQuestion = (sortDirection, questionKey) => {
        const questionIndex = _.indexOf(formikQuestionKeys, questionKey);
        let formikValuesCopy = _.cloneDeep(formik.values); // Used to update yup

        if (sortDirection === 'up') {
            const previousQuestionKey = formikQuestionKeys[questionIndex - 1];
            let thisQuestionData = _.cloneDeep(formik.values[questionKey]);
            let previousQuestionData = _.cloneDeep(formik.values[previousQuestionKey]);

            thisQuestionData.order = previousQuestionData.order;
            previousQuestionData.order = formik.values[questionKey].order;

            previousQuestionData.options.forEach((option, i) => {
                if (option?.requiredByQuestionOrder <= previousQuestionData.order) {
                    previousQuestionData.options[i].requiredByQuestionOrder = 0;
                }
            });

            formik.setFieldValue(previousQuestionKey, thisQuestionData);
            formik.setFieldValue(questionKey, previousQuestionData);

            formikValuesCopy[previousQuestionKey] = thisQuestionData;
            formikValuesCopy[questionKey] = previousQuestionData;
        }

        if (sortDirection === 'down') {
            const nextQuestionKey = formikQuestionKeys[questionIndex + 1];
            let thisQuestionData = _.cloneDeep(formik.values[questionKey]);
            let nextQuestionData = _.cloneDeep(formik.values[nextQuestionKey]);

            thisQuestionData.order = nextQuestionData.order;
            nextQuestionData.order = formik.values[questionKey].order;

            thisQuestionData.options.forEach((option, i) => {
                if (option?.requiredByQuestionOrder <= thisQuestionData.order) {
                    thisQuestionData.options[i].requiredByQuestionOrder = 0;
                }
            })

            formik.setFieldValue(nextQuestionKey, thisQuestionData);
            formik.setFieldValue(questionKey, nextQuestionData);

            formikValuesCopy[nextQuestionKey] = thisQuestionData;
            formikValuesCopy[questionKey] = nextQuestionData;
        }

        generateYupSchema(null, null, null, formikValuesCopy);
    }

    const submitForm = (e) => {
        e.preventDefault();
        formik.handleSubmit();

        if (!_.isEmpty(formik.errors)) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_euc_check_error')} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
    }

    const valuesWithoutDictionary = _.omit(formik.values, ['dictionary']);
    const valuesWithoutRemovedQuestions = _.omitBy(valuesWithoutDictionary, _.isNull);
    const formikQuestionKeys = _.keys(valuesWithoutRemovedQuestions);
    const { errors, touched } = formik;

    return (
        <div className="euc-check2-wrapper">
            {
                isLoading || !translationsLoaded ?
                    <QuestionsManagementSkeleton /> :
                    error ?
                        <div className="error">
                            {error}
                            <button onClick={() => window.location.reload()}>{t('screenEucCheck_check_chain_text_3')}</button>
                        </div> :
                        <div className="euc-check2-content">
                            <form onSubmit={submitForm}>
                                <table className="kpmg-table">
                                    <thead>
                                        <tr className="section-header">
                                            <th colSpan={6}>{t('settings_tab5_layout_chain_text_1')}</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_2')}</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_3')}</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_4')} (Deustch)</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_4')} (English)</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_5')}</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="table-head">
                                                    <span>{t('settings_tab5_layout_chain_text_6')}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <EmptyRow />

                                        {
                                            translationsLoaded && formikQuestionKeys.map((questionKey, i) => {
                                                const thisQuestion = formik.values[questionKey];
                                                const optionsNotNull = thisQuestion.options.filter(question => question !== null);
                                                const infoTextRowClass = optionsNotNull.length % 2 ? "primary-dark" : "primary-medium";

                                                return (
                                                    <React.Fragment key={i}>
                                                        {
                                                            thisQuestion &&
                                                            <tr>
                                                                <td className="primary-dark">{i + 1}.</td>
                                                                <td className="primary-dark"><strong>{t('settings_tab5_tip_chain_text_1')}</strong></td>
                                                                <td className="input-cell">
                                                                    <input
                                                                        className={touched[questionKey]?.text?.de && errors[questionKey]?.text?.de ? 'error' : ''}
                                                                        id={`${questionKey}.text.de`}
                                                                        name={`${questionKey}.text.de`}
                                                                        type="text"
                                                                        value={thisQuestion?.text?.de}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </td>
                                                                <td className="input-cell">
                                                                    <input
                                                                        className={touched[questionKey]?.text?.en && errors[questionKey]?.text?.en ? 'error' : ''}
                                                                        id={`${questionKey}.text.en`}
                                                                        name={`${questionKey}.text.en`}
                                                                        type="text"
                                                                        value={thisQuestion?.text?.en}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </td>
                                                                <td></td>
                                                                <td>
                                                                    {
                                                                        formikQuestionKeys.length > 1 &&
                                                                        <div className="remove-row" onClick={() => removeQuestion(questionKey)}>
                                                                            <AiOutlineClose />
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            thisQuestion?.options.map((option, j) => {
                                                                // This is used to keep yup state, because filtering null values may bug
                                                                // the array.of option (yup issue)
                                                                const nullsBeforeThisQuestion = thisQuestion.options.filter((question, i) => i <= j && question === null).length;
                                                                const currentIndex = j - nullsBeforeThisQuestion;

                                                                const rowClass = currentIndex % 2 ? "primary-dark" : "primary-medium";
                                                                const questionsAfterThis = formikQuestionKeys.length - (i + 1);

                                                                const questionsSetByOtherOptions = thisQuestion?.options
                                                                    .filter(otherOption => otherOption?.order !== option?.order)
                                                                    .map(otherOption => {
                                                                        return otherOption?.requiredByQuestionOrder;
                                                                    });

                                                                let optionsAvailable = _.times(questionsAfterThis, (k) => {
                                                                    return {
                                                                        questionId: i + (k + 2),
                                                                        questionValue: `${t('settings_tab5_layout_chain_text_10')}: Position ${i + (k + 2)}`
                                                                    };
                                                                });

                                                                optionsAvailable = optionsAvailable.filter(optionAvailable => {
                                                                    return !questionsSetByOtherOptions.includes(optionAvailable.questionId)
                                                                })

                                                                optionsAvailable = [
                                                                    ...optionsAvailable,
                                                                    {
                                                                        questionId: 100,
                                                                        questionValue: t('settings_tab5_layout_chain_text_12')
                                                                    },
                                                                    {
                                                                        questionId: 101,
                                                                        questionValue: t('settings_tab5_layout_chain_text_11')
                                                                    }
                                                                ];

                                                                return (
                                                                    option &&
                                                                    <tr key={j}>
                                                                        <td style={{ width: 80 }} className={rowClass}>{i + 1}.{currentIndex + 1}</td>
                                                                        <td style={{ width: 90 }} className={rowClass}>{t('settings_tab5_tip_chain_text_6')} {currentIndex + 1}</td>
                                                                        <td className="input-cell">
                                                                            <input
                                                                                className={
                                                                                    touched[questionKey]?.options && touched[questionKey].options[j]?.value?.de &&
                                                                                        errors[questionKey]?.options && errors[questionKey].options[j]?.value?.de ?
                                                                                        'error' :
                                                                                        ''
                                                                                }
                                                                                id={`${questionKey}.options[${j}].value.de`}
                                                                                name={`${questionKey}.options[${j}].value.de`}
                                                                                type="text"
                                                                                value={thisQuestion?.options[j]?.value?.de || ""}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </td>
                                                                        <td className="input-cell">
                                                                            <input
                                                                                className={
                                                                                    touched[questionKey]?.options && touched[questionKey].options[j]?.value?.en &&
                                                                                        errors[questionKey]?.options && errors[questionKey].options[j]?.value?.en ?
                                                                                        'error' :
                                                                                        ''
                                                                                }
                                                                                id={`${questionKey}.options[${j}].value.en`}
                                                                                name={`${questionKey}.options[${j}].value.en`}
                                                                                type="text"
                                                                                value={thisQuestion?.options[j]?.value?.en || ""}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: 170 }} className="input-cell">
                                                                            <select
                                                                                className={
                                                                                    touched[questionKey]?.options && touched[questionKey].options[j]?.requiredByQuestionOrder &&
                                                                                        errors[questionKey]?.options && errors[questionKey].options[j]?.requiredByQuestionOrder ?
                                                                                        'error' :
                                                                                        ''
                                                                                }
                                                                                name={`${questionKey}.options[${j}].requiredByQuestionOrder`}
                                                                                id={`${questionKey}.options[${j}].requiredByQuestionOrder`}
                                                                                value={thisQuestion?.options[j]?.requiredByQuestionOrder || 0}
                                                                                onChange={(e) => formik.setFieldValue(`${questionKey}.options[${j}].requiredByQuestionOrder`, parseInt(e.target.value))}
                                                                                onBlur={formik.handleBlur}>
                                                                                <option value={0}>-- Select an option --</option>
                                                                                {
                                                                                    optionsAvailable.map((option, k) => (
                                                                                        <option value={option.questionId} key={k}>{option.questionValue}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </td>
                                                                        <td style={{ width: 85 }}>
                                                                            <div className="remove-row" onClick={() => removeOption(questionKey, j)}>
                                                                                <AiOutlineClose />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                            <td style={{ width: 80 }} className={infoTextRowClass}>{i + 1}.{optionsNotNull.length + 1}</td>
                                                            <td style={{ width: 90 }} className={infoTextRowClass}>Info text</td>
                                                            <td className="input-cell">
                                                                <input
                                                                    className={touched[questionKey]?.infoText?.de && errors[questionKey]?.infoText?.de ? 'error' : ''}
                                                                    id={`${questionKey}.infoText.de`}
                                                                    name={`${questionKey}.infoText.de`}
                                                                    type="text"
                                                                    value={thisQuestion?.infoText?.de}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </td>
                                                            <td className="input-cell">
                                                                <input
                                                                    className={touched[questionKey]?.infoText?.en && errors[questionKey]?.infoText?.en ? 'error' : ''}
                                                                    id={`${questionKey}.infoText.en`}
                                                                    name={`${questionKey}.infoText.en`}
                                                                    type="text"
                                                                    value={thisQuestion?.infoText?.en}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        {
                                                            thisQuestion &&
                                                            <tr className="empty">
                                                                <td>
                                                                    <div className="sort-arrows">
                                                                        {
                                                                            questionKey !== _.first(formikQuestionKeys) &&
                                                                            <div className="sort-arrow" onClick={() => sortQuestion('up', questionKey)}><FiArrowUp style={{ fontSize: 18 }} /></div>
                                                                        }
                                                                        {
                                                                            questionKey !== _.last(formikQuestionKeys) &&
                                                                            <div className="sort-arrow" onClick={() => sortQuestion('down', questionKey)}><FiArrowDown style={{ fontSize: 18 }} /></div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td colSpan={5} style={{ textAlign: 'right' }}>
                                                                    <div style={{ width: 170, textAlign: 'left', marginLeft: 'auto' }}>
                                                                        <AddItem
                                                                            text={t('settings_tab5_layout_chain_text_7')}
                                                                            onClick={() => addOption(thisQuestion.order - 1)} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            thisQuestion && formikQuestionKeys.length !== i + 1 &&
                                                            <EmptyRow />
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                        <tr className="empty">
                                            <td colSpan={6} style={{ textAlign: 'right' }}>
                                                <div style={{ width: 170, textAlign: 'left', marginLeft: 'auto' }}>
                                                    <AddItem
                                                        text={t('settings_tab5_layout_chain_text_9')}
                                                        onClick={() => addQuestion()} />
                                                </div>
                                            </td>
                                        </tr>

                                        <EmptyRow />
                                        {/* todo */}
                                        <tr className="empty">
                                            <td colSpan={6}>
                                                <div className="allow-alias">
                                                    <input
                                                        type="checkbox"
                                                        id="allowFileExplorer"
                                                        name="allowFileExplorer"
                                                        checked={allowedFileExplorer}
                                                        onChange={() => setAllowedFileExplorer(!allowedFileExplorer)}
                                                    />
                                                    <label htmlFor="allowFileExplorer">{t('settings_tab5_allow_file_browser')}</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="empty">
                                            <td colSpan={6}>
                                                <div className="allow-alias">
                                                    <input
                                                        type="checkbox"
                                                        id="allowAlias"
                                                        name="allowAlias"
                                                        checked={allowedAlias}
                                                        onChange={() => setAllowedAlias(!allowedAlias)}
                                                    />
                                                    <label htmlFor="allowAlias">{t('settings_tab5_allow_alias')}</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="empty">
                                            <td colSpan={6}>
                                                <div className="allow-alias">
                                                    <input
                                                        type="checkbox"
                                                        id="allowAutomaticValidations"
                                                        name="allowAutomaticValidations"
                                                        checked={allowedAutomaticValidations}
                                                        onChange={() => setAllowedAutomaticValidations(!allowedAutomaticValidations)}
                                                    />
                                                    <label htmlFor="allowAutomaticValidations">{t('settings_tab5_allow_automatic_validations')}</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <EmptyRow />

                                        <tr className="empty">
                                            <td colSpan={6}><hr style={{ opacity: 0.5 }} /></td>
                                        </tr>
                                        <EmptyRow />

                                        <tr>
                                            <td rowSpan="4" className="primary-dark">{t('settings_tab5_result_chain_text_1')}</td>
                                            <td className="primary-dark" style={{ wordBreak: 'keep-all' }}>{t('settings_tab5_result_chain_text_2')}</td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.isEuc?.de ? 'error' : ''}
                                                    id={'dictionary.isEuc.de'}
                                                    name={'dictionary.isEuc.de'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.isEuc?.de || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.isEuc?.en ? 'error' : ''}
                                                    id={'dictionary.isEuc.en'}
                                                    name={'dictionary.isEuc.en'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.isEuc?.en || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="primary-medium" style={{ wordBreak: 'keep-all' }}>{t('settings_tab5_result_chain_text_3')}</td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.notEuc?.de ? 'error' : ''}
                                                    id={'dictionary.notEuc.de'}
                                                    name={'dictionary.notEuc.de'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.notEuc?.de || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.notEuc?.en ? 'error' : ''}
                                                    id={'dictionary.notEuc.en'}
                                                    name={'dictionary.notEuc.en'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.notEuc?.en || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="primary-dark" style={{ wordBreak: 'keep-all' }}>{t('settings_tab5_result_chain_text_4')}</td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.bottomMessageEuc?.de ? 'error' : ''}
                                                    id={'dictionary.bottomMessageEuc.de'}
                                                    name={'dictionary.bottomMessageEuc.de'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.bottomMessageEuc?.de || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.bottomMessageEuc?.en ? 'error' : ''}
                                                    id={'dictionary.bottomMessageEuc.en'}
                                                    name={'dictionary.bottomMessageEuc.en'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.bottomMessageEuc?.en || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="primary-medium" style={{ wordBreak: 'keep-all' }}>{t('settings_tab5_result_chain_text_5')}</td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.bottomMessageNotEuc?.de ? 'error' : ''}
                                                    id={'dictionary.bottomMessageNotEuc.de'}
                                                    name={'dictionary.bottomMessageNotEuc.de'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.bottomMessageNotEuc?.de || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                            <td className="input-cell">
                                                <input
                                                    className={errors['dictionary'] && errors['dictionary']?.bottomMessageNotEuc?.en ? 'error' : ''}
                                                    id={'dictionary.bottomMessageNotEuc.en'}
                                                    name={'dictionary.bottomMessageNotEuc.en'}
                                                    type="text"
                                                    value={formik?.values?.dictionary?.bottomMessageNotEuc?.en || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="actions flex center">
                                    <div>{t('settings_tab5_button_layout_chain_text')}:</div>
                                    <Button type="button" onClick={() => formik.resetForm()}>{t('settings_tab5_button1_title')}</Button>
                                    <Button loading={requestSending} type="submit">{t('settings_tab5_button2_title')}</Button>
                                </div>
                            </form>
                        </div>
            }
        </div>
    )
};

export default EucCheckManagementComponent;