import customFetch from './fetch';
import _ from 'lodash';

export async function getInventoryQuestionsService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/questions`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventorySectionAnswersService(inventoryFormSectionId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/sections/${inventoryFormSectionId}`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventorySectionQuestionsService(configSectionId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/section/${configSectionId}/questions`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventoryAllSectionsAnswersService(inventoryId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/${inventoryId}/sections`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function updateInventoryQuestionsService(questions) {
    try {
        const body = JSON.stringify(questions);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/questions`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function sendInventoryQuestionnaireService(questions, isDraft) {
    try {
        let url = `${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform`;
        if (isDraft) url += `/save`;

        const body = JSON.stringify(questions);
        const response = await customFetch(url, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function updateInventoryQuestionnaireService(sectionData) {
    try {
        let url = `${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/section/edit`;
        const body = JSON.stringify(sectionData);
        const response = await customFetch(url, "POST", body);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventoryDataService(filters, userOnly = false, pagination, isCount) {
    let filtersString = `${userOnly ? '/me' : ''}?`;

    if (filters) {
        _.keys(filters).forEach(filterKey => {
            if (Array.isArray(filters[filterKey])) {
                filters[filterKey].forEach(filterValue => {
                    filtersString += `${filterKey}=${filterValue}&`
                })
            } else {
                if (!_.isNil(filters[filterKey]) && filters[filterKey] !== "none" && filters[filterKey] !== "") {
                    filtersString += `${filterKey}=${filters[filterKey]}&`
                }
            }
        })
    }

    if (pagination) {
        filtersString += `limit=${pagination.limit}&`;

        if (pagination.currentIndex) {
            filtersString += `skip=${pagination.limit * (pagination.currentIndex - 1)}`;
        }
    }

    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform${isCount ? "/count" : ""}${filtersString}`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function fileUploadService(files) {
    try {
        let formData = new FormData();
        files.forEach(fileObject => {
            formData.append('file', fileObject.file);
        })

        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/file/upload`, "POST", formData, true)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function changeInventoryStatusService(formData) {
    try {
        const body = JSON.stringify(formData);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/status`, "PUT", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteInventoryService(inventoryId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/${inventoryId}/delete`, "POST")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function rejectInventoryService(inventoryId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/reject/${inventoryId}`, "POST")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function requestInventoryService(formData) {
    try {
        const body = JSON.stringify(formData);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/request`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventoryVersionsService(fileId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/${fileId}/versions`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventoryManagementService(filters, pagination, isCount) {
    let filtersString = "?";

    if (filters) {
        _.keys(filters).forEach(filterKey => {
            if (Array.isArray(filters[filterKey])) {
                let filterData = filters[filterKey];
                if (typeof filterData === "object") filterData = filters[filterKey].map(selectItem => selectItem.value);

                filterData.forEach(filterValue => {
                    filtersString += `${filterKey}=${filterValue}&`
                })
            } else {
                if (!_.isNil(filters[filterKey]) && filters[filterKey] !== "none" && filters[filterKey] !== "") {
                    filtersString += `${filterKey}=${filters[filterKey]}&`
                }
            }
        })
    }

    if (pagination) {
        filtersString += `limit=${pagination.limit}&`;

        if (pagination.currentIndex) {
            filtersString += `skip=${pagination.limit * (pagination.currentIndex - 1)}`;
        }
    }

    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/management${isCount ? "/count" : ""}${filtersString}`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getInventoriesByIdService(filters) {
    try {
        const body = JSON.stringify(filters);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/export`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function forwardInventoryService(userSamAccount, inventoryId) {
    try {
        const body = JSON.stringify({ username: userSamAccount });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/inventoryform/${inventoryId}/forward`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}