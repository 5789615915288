import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ManageKeywordListModal from 'components/modals/ManageKeywordListModal/containers/ManageKeywordListModal';
import EucKeywordsModal from 'components/modals/EucKeywordsModal/containers/EucKeywordsModal';
import InfoTextModal from 'components/modals/InfoTextModal/InfoTextModal';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import { BsPencilSquare } from 'react-icons/bs';
import Button from 'components/common/Button/Button';
import ClipLoader from "react-spinners/ClipLoader";
import { BiTrash } from 'react-icons/bi';
import AddItem from 'components/common/AddItem/AddItem';

const MetadataComponent = (props) => {
    const {
        isFetchingMetadataList,
        deleteMetadata,
        deletingMetadataIds,
        getMetadataList,
        editMetadata,
        submitMetadata,
        discardMetadataListChanges,
        isSubmitting,
        allMetadataList
    } = props;

    const [infoTextModalOpen, setInfoTextModalOpen] = useState(false);
    const [keywordsModalOpen, setKeywordsModalOpen] = useState(false);
    const [topicModalOpen, setTopicModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { t, i18n } = useTranslation();
    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    const openInfoTextModal = (item) => {
        setSelectedItem(item);
        setInfoTextModalOpen(true);
    }

    const openKeywordModal = (item) => {
        setSelectedItem(item);
        setKeywordsModalOpen(true);
    }

    const submitInfoCallback = ({ infoTextDe, infoTextEn }) => {
        const infoText = {
            en: infoTextEn,
            de: infoTextDe,
        };

        const metadataId = selectedItem.metadataId;
        const selectedItemIndex = allMetadataList.findIndex(topic => topic.metadataId === metadataId);
        editMetadata(selectedItemIndex, 'infoText', infoText);
    }

    return (
        <>
            {
                isFetchingMetadataList || !translationsLoaded ?
                    <TableSkeletonLoading
                        rows={15}
                        columns={8}
                        style={{ marginTop: 20 }}
                    /> :
                    <>
                        <table className="kpmg-table stripped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_5_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_6_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_6_b')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_7_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_8_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_13_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_9_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_10_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_11_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_12_a')}</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {
                                    allMetadataList.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ textAlign: 'left', wordBreak: 'unset' }}>{item.name}</td>
                                                <td className='cursor-pointer number-row' onClick={() => openInfoTextModal(item)}>
                                                    <BsPencilSquare style={{ fontSize: 14 }} />
                                                </td>
                                                <td className='cursor-pointer number-row' onClick={() => openKeywordModal(item)}>
                                                    <BsPencilSquare style={{ fontSize: 14 }} />
                                                </td>
                                                <td className="input-cell">
                                                    <input type="number" value={item.threshold} onChange={(e) => editMetadata(i, 'threshold', e.target.value)} />
                                                </td>
                                                <td className="input-cell">
                                                    <input type="number" value={item.weight} onChange={(e) => editMetadata(i, 'weight', e.target.value)} />
                                                </td>
                                                <td className="input-cell number-row">
                                                    <select
                                                        value={item.valueType}
                                                        onChange={(e) => editMetadata(i, 'valueType', e.target.value)}>
                                                        <option value="Relative">{t('settings_tab4_indicator_type_file_relative')}</option>
                                                        <option value="Absolute">{t('settings_tab4_indicator_type_file_absolute')}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editMetadata(i, 'kpiType', 'Complexity')}>
                                                        {item.kpiType === "Complexity" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editMetadata(i, 'kpiType', 'Criticality')}>
                                                        {item.kpiType === "Criticality" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editMetadata(i, 'kpiType', 'NonAssigned')}>
                                                        {item.kpiType === "NonAssigned" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-action" onClick={() => !deletingMetadataIds.includes(item.metadataId) && deleteMetadata(item.metadataId)}>
                                                        {
                                                            deletingMetadataIds.includes(item.metadataId) ?
                                                                <ClipLoader
                                                                    size={14}
                                                                    color={"#525662"}
                                                                    loading={true}
                                                                /> :
                                                                <BiTrash />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="empty">
                                    <td></td>
                                    <td colSpan={8}>
                                        <AddItem text={t('settings_tab4_add_metadata')} onClick={() => setTopicModalOpen(true)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="actions">
                            <div>{t('settings_tab4_button_layout_chain_text_a')}:</div>
                            <Button onClick={() => discardMetadataListChanges()}>
                                {t('settings_tab4_button1_title_a')}
                            </Button>
                            <Button loading={isSubmitting} onClick={submitMetadata}>
                                {t('settings_tab4_button2_title_a')}
                            </Button>
                        </div>
                    </>
            }

            <ManageKeywordListModal
                isOpen={topicModalOpen}
                setIsOpen={setTopicModalOpen}
                getKeywordList={getMetadataList}
                isMetadata={true}
            />

            <EucKeywordsModal
                isOpen={keywordsModalOpen}
                setIsOpen={setKeywordsModalOpen}
                keywordListItem={selectedItem}
                keywordListItemId={selectedItem.metadataId}
                isMetadata={true}
            />

            <InfoTextModal
                isOpen={infoTextModalOpen}
                setIsOpen={setInfoTextModalOpen}
                item={selectedItem}
                submitInfoCallback={submitInfoCallback}
            />
        </>
    )
};

export default MetadataComponent;