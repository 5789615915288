import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import Skeleton from "react-loading-skeleton";
import './StepOneComponent.scss';

const StepOneComponentLoading = () => {
    return (
        <div className="step-one-loading">
            <Skeleton width={250} style={{ marginBottom: 20 }} />

            <Skeleton width={'100%'} style={{ marginBottom: 5 }} count={3} />

            <Skeleton width={'100%'} height={30} style={{ marginTop: 20 }} />
        </div>
    )
}

const StepOneComponent = props => {
    const { isFetching, configurationList, selectConfiguration } = props;
    const [selectedConfiguration, setSelectedConfiguration] = useState("empty");
    const { t } = useTranslation();

    return (
        <div className="step-one-wrapper">
            {
                isFetching ?
                    <StepOneComponentLoading /> :
                    <>
                        <p className="title">{t('scan_tab1_wizard_step1_layout_chain_text_3')}</p>

                        <p>{t('scan_tab1_wizard_step1_layout_chain_text_4')}</p>

                        <div className="select-field">
                            <div className="title">
                                <span>{t('scan_tab1_wizard_step1_layout_chain_text_5')}</span>
                            </div>

                            <div className="field">
                                <select
                                    name="select-configuration"
                                    id="select-configuration"
                                    onChange={e => setSelectedConfiguration(e.target.value)}
                                >
                                    <option value="empty">{t('scan_tab1_wizard_step1_select')}</option>
                                    {
                                        configurationList.map((configuration, i) => (
                                            <option value={configuration.configRunId} key={i}>{configuration.configName}</option>
                                        ))
                                    }
                                </select>

                                <Button onClick={() => selectConfiguration(selectedConfiguration)}>{t('scan_tab1_wizard_step1_button_1')}</Button>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default StepOneComponent;