import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserListModalComponent from '../components/UserListModalComponent';
import { searchUsersService } from 'services/user';

const UserListModal = (props) => {
    const { searchTerm, setSearchTerm, selectUserCallback, isOpen, buttonLoading = false } = props;
    const [isFetching, setIsFetching] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setUsers([]);
            setSelectedUser(null);
        } else {
            setSearchTerm("");
        }
    }, [isOpen]);

    useEffect(() => {
        if (searchTerm) searchUsers(searchTerm);
    }, [searchTerm]);

    const searchUsers = async (searchTerm) => {
        setIsFetching(true);

        try {
            const usersFound = await searchUsersService(searchTerm);
            setUsers(usersFound);
        } catch (err) {
            console.log(`Error while fetching search users request: ${err}`)
        }

        setIsFetching(false);
    }

    return (
        <UserListModalComponent
            {...props}
            isFetching={isFetching}
            users={users}
            selectUserCallback={selectUserCallback}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            buttonLoading={buttonLoading}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(UserListModal);