import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "redux/actions/settingsActions";
import { updateInterfaceConfigService } from 'services/settings';
import DesignComponent from '../components/DesignComponent';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { urlToBase64 } from 'services/helper';

const Design = (props) => {
    const { settings, settings: { theme }, updateSettings } = props;
    const [resetInterface, setResetInterface] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();


    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    const updateInterfaceConfig = async ({ mainColor, secondaryColor, backgroundFile, logoFile }) => {
        setIsLoading(true);

        let theme = settings.theme;

        if (mainColor) theme.mainColor = mainColor;
        if (secondaryColor) theme.secondaryColor = secondaryColor;
        if (backgroundFile) theme.backgroundFile = backgroundFile;
        if (logoFile) theme.logoFile = logoFile;

        if (theme?.backgroundFile?.includes("static")) theme.backgroundFile = await urlToBase64(theme.backgroundFile);
        if (theme?.logoFile?.includes("static")) theme.logoFile = await urlToBase64(theme.logoFile);

        try {
            await updateInterfaceConfigService(theme);

            updateSettings({
                ...settings,
                theme
            });

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_user_interface_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log("Error while updating interface", err);
        }

        setIsLoading(false);
    }

    const resetInterfaceConfig = () => {
        setResetInterface(true);
    }

    return <DesignComponent
        theme={theme}
        isLoading={isLoading}
        updateInterfaceConfig={updateInterfaceConfig}
        resetInterfaceConfig={resetInterfaceConfig}
        resetInterface={resetInterface}
        setResetInterface={setResetInterface} />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Design);