import React from 'react';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from 'components/common/PrivateRoute/PrivateRoute';

// Guidance
import EucCheck from './components/Guidance/EucCheck/containers/EucCheck';
import EucCheckQuestions from './components/Guidance/EucCheckQuestions/containers/EucCheckQuestions';
// import InventoryQuestions from './components/Guidance/InventoryQuestions/containers/InventoryQuestions';
import MeineValidierungen from './components/Guidance/MeineValidierungen/containers/MeineValidierungen';
import EucRegularienContainer from './components/Guidance/EucRegularien/containers/EucRegularienContainer';
import AnsprechartnerContainer from './components/Guidance/Ansprechartner/containers/AnsprechartnerContainer';

// Scanning
import StartScan from './components/Scanning/StartScan/containers/StartScan';
import Konfigurationen from './components/Scanning/Konfigurationen/containers/Konfigurationen';
import UbersichtScans from './components/Scanning/UbersichtScans/containers/UbersichtScans';

// Analyzing
import Dashboard from './components/Analyzing/Dashboard/containers/Dashboard';
import Expertenmodus from './components/Analyzing/Expertenmodus/containers/Expertenmodus';

// Validation
import Validierungen from './components/Validation/Validierungen/containers/Validierungen';
// import Inventarisierungen from './components/Validation/Inventarisierungen/containers/Inventarisierungen';

// Management
// import Inventory from './components/Managing/Inventory/containers/Inventory';
// import ManageInventoryQuestionnaire from './components/Managing/ManageInventoryQuestionnaire/containers/ManageInventoryQuestionnaire';

// Settings
import UserInterface from './components/Settings/UserInterface/containers/UserInterface';
import Berechtigungen from './components/Settings/Berechtigungen/containers/Berechtigungen';
import OrgEinheiten from './components/Settings/OrgEinheiten/containers/OrgEinheiten';
import KalibrierungScan from './components/Settings/KalibrierungScan/containers/KalibrierungScan';
import Questionnaires from './components/Settings/Questionnaires/containers/Questionnaires';
import EmailSettings from './components/Settings/EmailSettings/containers/EmailSettings';
import LicenseKey from './components/Settings/LicenseKey/containers/LicenseKey';

import { ToastContainer } from "react-toastify";
import { FaTimes } from 'react-icons/fa';

import './App.scss';
import "react-toastify/dist/ReactToastify.css";

const CloseButton = () => <FaTimes className="icon toastr-close" />;

function App() {
	return (
		<Provider store={store}>
			<Router>
				<div className="toastr-div">
					<ToastContainer
						toastClassName="toastr-wrapper"
						autoClose={5000}
						hideProgressBar={false}
						pauseOnHover
						closeButton={<CloseButton />} />
				</div>
				<div className="App">
					<Switch>
						{/* Settings Routes */}
						<PrivateRoute permission={['EUCADMIN']} component={LicenseKey} path="/settings/license-key" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={EmailSettings} path="/settings/email-settings" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={Questionnaires} path="/settings/questionnaires" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={KalibrierungScan} path="/settings/kalibrierung-scan" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={OrgEinheiten} path="/settings/org-einheiten" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={Berechtigungen} path="/settings/berechtigungen" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" component={UserInterface} path="/settings/user-interface" />
						<PrivateRoute permission={['EUCADMIN']} licensePermission="Settings" path="/settings" redirectRoute="/settings/user-interface" />

						{/* Management Routes */}
						{/* <PrivateRoute permission={['EUCINVENTORYMANAGEMENT']} licensePermission="Management" component={ManageInventoryQuestionnaire} path="/management/manage-inventory" /> */}
						{/* <PrivateRoute permission={['EUCINVENTORYMANAGEMENT']} licensePermission="Management" component={Inventory} path="/management/inventory" /> */}
						<PrivateRoute permission={['EUCINVENTORYMANAGEMENT']} licensePermission="Management" path="/management" redirectRoute="/management/inventory" />

						{/* Validation Routes */}
						{/* <PrivateRoute permission={['EUCDASHBOARDWRITE']} licensePermission="Validation" component={Inventarisierungen} path="/validation/inventarisierungen" /> */}
						<PrivateRoute permission={['EUCDASHBOARDWRITE']} licensePermission="Validation" component={Validierungen} path="/validation/validierungen" />
						<PrivateRoute permission={['EUCDASHBOARDWRITE']} licensePermission="Validation" path="/validation" redirectRoute="/validation/validierungen" />

						{/* Analyzing Routes */}
						<PrivateRoute permission={['EUCDASHBOARDREAD', 'EUCDASHBOARDWRITE']} licensePermission="Analyse" component={Expertenmodus} path="/analyzing/expertenmodus" />
						<PrivateRoute permission={['EUCDASHBOARDREAD', 'EUCDASHBOARDWRITE']} licensePermission="Analyse" component={Dashboard} path="/analyzing/dashboard/:currentPath" />
						<PrivateRoute permission={['EUCDASHBOARDREAD', 'EUCDASHBOARDWRITE']} licensePermission="Analyse" path="/analyzing" redirectRoute="/analyzing/dashboard/graphen" />

						{/* Scanning Routes */}
						<PrivateRoute permission={['EUCSCAN']} licensePermission="Discovery" component={UbersichtScans} path="/scanning/ubersicht-scans" />
						<PrivateRoute permission={['EUCSCAN']} licensePermission="Discovery" component={Konfigurationen} path="/scanning/konfigurationen" />
						<PrivateRoute permission={['EUCSCAN']} licensePermission="Discovery" component={StartScan} path="/scanning/start-scan/:id?" />
						<PrivateRoute permission={['EUCSCAN']} licensePermission="Discovery" path="/scanning" redirectRoute="/scanning/start-scan" />

						{/* Guidance Routes */}
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={AnsprechartnerContainer} path="/guidance/ansprechartner" />
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={EucRegularienContainer} path="/guidance/euc-regularien" />
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={MeineValidierungen} path="/guidance/meine-validierungen" />
						{/* <PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={InventoryQuestions} path="/guidance/inventory-check-questions" /> */}
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={EucCheckQuestions} path="/guidance/euc-check-questions" />
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" component={EucCheck} path="/guidance/euc-check" />
						<PrivateRoute permission={['EUCGUIDANCE']} licensePermission="Info-Portal" path="/guidance" redirectRoute="/guidance/euc-check" />

						<Route path="/" exact={true}>
							<Redirect to="/guidance/euc-check" />
						</Route>
					</Switch>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
