import { UPDATE_SETTINGS, UPDATE_SETTINGS_CONFIG_GRAPH, UPDATE_SETTINGS_CONFIG_CRAWLER } from "../types/settingsTypes";

const savedSettings = localStorage.getItem("kpmg-settings");
const savedSettingsJson = JSON.parse(savedSettings);

const initialState = {
    settings: savedSettingsJson?.language && savedSettingsJson?.theme ? savedSettingsJson : {
        language: 'en',
        theme: {
            // mainColor: '#005EB8',
            // secondaryColor: '#00338D',
            mainColor: '#FFC540',
            secondaryColor: '#FFC540',
            backgroundFile: require("assets/images/main-bg.png").default,
            logoFile: require("assets/images/logo.png").default,
        },
        validationConfig: {},
        configGraph: {},
        configCrawler: {},
        coordinatorConfig: {}
    }
};

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            localStorage.setItem("kpmg-settings", JSON.stringify(action.settings));

            return {
                ...state,
                settings: action.settings
            };

        case UPDATE_SETTINGS_CONFIG_GRAPH:
            const newSettingsWithConfigGraph = {
                ...state.settings,
                configGraph: action.configGraph
            }

            localStorage.setItem("kpmg-settings", JSON.stringify(newSettingsWithConfigGraph));

            return {
                ...state,
                settings: newSettingsWithConfigGraph
            };

        case UPDATE_SETTINGS_CONFIG_CRAWLER:
            const newSettingsWithConfigCrawler = {
                ...state.settings,
                configCrawler: action.configCrawler
            }

            localStorage.setItem("kpmg-settings", JSON.stringify(newSettingsWithConfigCrawler));

            return {
                ...state,
                settings: newSettingsWithConfigCrawler
            };

        default:
            return state;
    }
};
