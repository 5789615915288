import React, { useState } from "react";
import Layout from 'layouts/layout';
import NavTabs from 'components/common/NavTabs/NavTabs';
import Design from 'components/Settings/Design/containers/Design';
import Language from 'components/Settings/Language/containers/Language';
import { useTranslation } from "react-i18next";
import './UserInterfaceComponent.scss';

const UserInterfaceComponent = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation();

    const tabs = [t('settings_tab11'), t('settings_tab7')];

    return (
        <Layout>
            <div className="user-interface-wrapper">
                <NavTabs
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabs={tabs}
                >
                    {
                        selectedTab === 0 &&
                        <Design />
                    }

                    {
                        selectedTab === 1 &&
                        <Language />
                    }
                </NavTabs>
            </div>
        </Layout>
    )
};

export default UserInterfaceComponent;