import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { adjustColorBrightness } from 'services/helper';
import './ArrowButton.scss';

const ArrowButton = (props) => {
    const { onClick, buttonTitle, buttonText, settings: { theme: { secondaryColor } }, previewTheme, style } = props;
    const [arrowHeight, setArrowHeight] = useState(0);
    const buttonRef = useRef(null);

    useEffect(() => {
        let timeoutId = null;

        const resizeListener = () => {
            clearTimeout(timeoutId);
            document.getElementById("root").style.width = "99%";
            timeoutId = setTimeout(() => {
                document.getElementById("root").style.width = "100%";
                const height = buttonRef?.current?.clientHeight;
                setArrowHeight(height / 2);
            }, 0);
        };

        resizeListener();

        window.addEventListener('resize', resizeListener);
        window.addEventListener('i18next.languageChanged', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
            window.removeEventListener('i18next.languageChanged', resizeListener);
        }
    }, [])

    const usedSecondaryColor = previewTheme ? previewTheme.secondaryColor : secondaryColor;

    return (
        <div
            ref={buttonRef}
            className="arrow-button"
            onClick={onClick}
            style={{
                background: `linear-gradient(to bottom, ${adjustColorBrightness(usedSecondaryColor, 0.4)} 0, ${adjustColorBrightness(usedSecondaryColor, 0)} 50%, ${adjustColorBrightness(usedSecondaryColor, -0.2)} 100%)`,
                ...style
            }}
        >
            <div className="arrow-button__title" style={{ borderColor: adjustColorBrightness(usedSecondaryColor, -0.1) }}>{buttonTitle}</div>
            <div className="arrow-button__text">{buttonText}</div>
            <div className="arrow" style={{ borderTopWidth: arrowHeight, borderBottomWidth: arrowHeight }}></div>
        </div>
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default connect(mapStateToProps)(ArrowButton);