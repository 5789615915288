import React from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import './EucRegularienComponent.scss';

const EucRegularienComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="euc-regularien">
                <div className="euc-regularien-content">
                    <h2>{t('screenEucRegularien_layout_chain_text_1')}</h2>
                    <p>{t('screenEucRegularien_layout_chain_text_2')}</p>
                    <a href={t('screenEucRegularien_link_chain_text_1')} rel="noopener noreferrer" target="_blank">
                        {t('screenEucRegularien_layout_chain_text_3')}
                    </a>
                </div>
            </div>
        </Layout>
    )
};

export default EucRegularienComponent;