import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FiDownload } from 'react-icons/fi';
import { AiOutlineDiff } from 'react-icons/ai'
import { RiLayoutGridLine } from 'react-icons/ri';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import TableSortIcon from 'components/common/TableSortIcon/TableSortIcon';
import TablePagination from 'components/common/TablePagination/TablePagination';
import DuplicateModal from 'components/modals/DuplicateModal/containers/DuplicateModal';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import ChartPointModal from 'components/modals/ChartPointModal/containers/ChartPointModal';
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import InventoryUserPicker from 'components/modals/InventoryUserPicker/containers/InventoryUserPicker';
import DownloadCsvModal from 'components/modals/DownloadCsvModal/containers/DownloadCsvModal';
import { formatFileSize } from 'services/helper';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import moment from 'moment';
import Number from 'components/common/Number/Number';
import './ListenansichtComponent.scss';

const ListenansichtComponent = (props) => {
    const {
        settings,
        listData,
        isLoading,
        pagination,
        changePageData,
        applyLocalFilters,
        localFilters,
        setLocalFilters,
        globalFilters,
        changedTableLoading,
        setChangedTableLoading,
        listDataTotalCount,
        toggleChartCallback,
        allDataCount,
        loadingAllDataCount,
        loadingListDataCount
    } = props;

    const mainColor = settings?.theme?.mainColor;
    const { t } = useTranslation();

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false);
    const [isInventoryValidationModalOpen, setIsInventoryValidationModalOpen] = useState(false);
    const [isChartPointModalOpen, setIsChartPointModalOpen] = useState(false);

    const [selectedPoint, setSelectedPoint] = useState(null);

    const [duplicateOpen, setDuplicateOpen] = useState(false);
    const [selectedDupItem, setSelectedDupItem] = useState({});

    const [isDownloadCsvModalOpen, setIsDownloadCsvModalOpen] = useState(false);

    const debouncedText = useCallback(_.debounce((newLocalFilters, globalFilters) => applyLocalFilters(newLocalFilters, globalFilters), 1000), []);

    const changeFilter = (key, value, debounceIt) => {
        let newLocalFilters = _.cloneDeep(localFilters);

        if (Array.isArray(key)) {
            key.forEach((thisKey, i) => {
                _.set(newLocalFilters, thisKey, value[i]);
            });
        } else {
            _.set(newLocalFilters, key, value);
        }

        if (debounceIt) {
            debouncedText(newLocalFilters, globalFilters)
        } else {
            applyLocalFilters(newLocalFilters, globalFilters);
        }

        setLocalFilters(newLocalFilters);
    }

    const sortTable = (column) => {
        let sortOrder = 'asc';

        if (currentSortColumn === column) {
            if (currentSortOrder === 'asc') sortOrder = 'desc';
            if (currentSortOrder === 'desc') {
                sortOrder = null;
                column = null;
            };
        }

        const filterReversed = sortOrder === 'asc' ? false : (sortOrder === 'desc' ? true : null);
        changeFilter(["order", "reverse"], [column, filterReversed]);

        setCurrentSortColumn(column);
        setCurrentSortOrder(sortOrder);
    }

    const openDuplicateModal = (e, item) => {
        e.stopPropagation();
        e.preventDefault();

        setSelectedDupItem(item);
        setDuplicateOpen(true);
    }

    const openPointModal = (listItem) => {
        setIsChartPointModalOpen(true);
        setSelectedPoint(listItem);
    }

    return (
        <div className="listenansicht-wrapper">
            {
                isLoading ?
                    <TableSkeletonLoading
                        columns={5}
                        rows={10}
                    /> :
                    <div>
                        <table className="kpmg-table stripped hoverable">
                            <thead>
                                <tr className="row-header">
                                    <th colSpan={8}>
                                        <div className={`section-header-item active`}>
                                            <span style={{ borderColor: mainColor }}>
                                                {t('analysis_tab1_listenansicht_title')}
                                            </span>
                                            <div className="chart-type" style={{ color: mainColor }} onClick={toggleChartCallback}>
                                                <RiLayoutGridLine style={{ fontSize: 18 }} />
                                            </div>

                                            <div className="list-data-count">
                                                <span className="title">{t('analysis_tab1_graphen_files_displayed')}:</span>
                                                <span className="count" style={{ color: mainColor }}>
                                                    {
                                                        loadingListDataCount ?
                                                            "..." :
                                                            <Number value={listDataTotalCount || 0} />
                                                    }
                                                    &nbsp;
                                                    {t('analysis_tab1_graphen_of')}
                                                    &nbsp;
                                                    {
                                                        loadingAllDataCount ?
                                                            "..." :
                                                            <Number value={allDataCount || 0} />
                                                    }
                                                    &nbsp;
                                                    {t('analysis_tab1_graphen_results')}
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th colSpan={2}>
                                        <div className="download-csv" onClick={() => setIsDownloadCsvModalOpen(true)}>
                                            <FiDownload style={{ fontSize: 18 }} />
                                            <span>{t('analysis_tab1_listenansicht_download_csv')}</span>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th width={85}>{t('analysis_tab1_listenansicht_table_header_1')}</th>
                                    <th width={65}>{t('analysis_tab1_listenansicht_table_issues')}</th>
                                    <th width={65}>
                                        <div className="table-head" onClick={() => sortTable("FileId")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_9')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="FileId"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th width={65}>{t('analysis_tab1_listenansicht_table_header_10')}</th>
                                    <th style={{ minWidth: 100 }}>{t('analysis_tab1_listenansicht_table_header_2')}</th>
                                    <th width={100}>
                                        <div className="table-head" onClick={() => sortTable("FileSize")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_4')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="FileSize"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th width={100}>
                                        <div className="table-head" onClick={() => sortTable("LastModified")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_8')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="LastModified"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th width={50}>
                                        <div className="table-head" onClick={() => sortTable("Complexity")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_5')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="Complexity"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th width={50}>
                                        <div className="table-head" onClick={() => sortTable("Criticality")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_6')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="Criticality"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th width={50}>
                                        <div className="table-head" onClick={() => sortTable("NrDuplicates")}>
                                            <span>{t('analysis_tab1_listenansicht_table_header_7')}</span>
                                            <div className="sort-icon">
                                                <TableSortIcon
                                                    columnName="NrDuplicates"
                                                    sortColumn={currentSortColumn}
                                                    sortOrder={currentSortOrder}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="row-filters">
                                    <td>
                                        <select
                                            onChange={e => changeFilter("status", e.target.value === 'none' ? [] : [e.target.value])}
                                            value={localFilters?.status[0]}
                                            name="statusSelect"
                                            id="statusSelect">
                                            <option value="none">-- Status --</option>
                                            <option value="no-validation">{t('analysis_tab1_graphen_legend_3')}</option>
                                            <option value="in-validation">{t('analysis_tab1_graphen_legend_5')}</option>
                                            <option value="euc">{t('analysis_tab1_graphen_legend_2')}</option>
                                            <option value="non-euc">{t('analysis_tab1_graphen_legend_1')}</option>
                                            {/* <option value="validated">{t('analysis_tab1_graphen_legend_6')}</option>
                                            <option value="in-inventory-process">{t('analysis_tab1_graphen_legend_7')}</option>
                                            <option value="in-inventory-closed">{t('analysis_tab1_graphen_legend_8')}</option> */}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            onChange={e => changeFilter("hasError", e.target.value === 'none' ? [] : [e.target.value])}
                                            value={localFilters?.hasError?.[0]}
                                            name="hasErrorSelect"
                                            id="hasErrorSelect">
                                            <option value="none">-- {t('analysis_tab1_listenansicht_table_issues')} --</option>
                                            <option value="0">{t('analysis_tab1_listenansicht_table_ok')}</option>
                                            <option value="1">{t('analysis_tab1_listenansicht_table_issue')}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={localFilters.fileId}
                                            onChange={e => changeFilter("fileId", e.target.value, true)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={localFilters.fileExtension}
                                            onChange={e => changeFilter("fileExtension", e.target.value, true)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={localFilters.nameSearch}
                                            onChange={e => changeFilter("nameSearch", e.target.value, true)}
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <select
                                            className="filter-select"
                                            onChange={e => changeFilter("complexity.min", parseInt(e.target.value))}
                                            value={localFilters.complexity.min}
                                            name="complexitySelect"
                                            id="complexitySelect">
                                            {
                                                _.times(10, (i) => (
                                                    <option key={i} value={i * 10}>&gt; {i * 10}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            className="filter-select"
                                            onChange={e => changeFilter("criticality.min", parseInt(e.target.value))}
                                            value={localFilters.criticality.min}
                                            name="criticalitySelect"
                                            id="criticalitySelect">
                                            {
                                                _.times(10, (i) => (
                                                    <option key={i} value={i * 10}>&gt; {i * 10}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            onChange={e => changeFilter("duplicates.showOnlyDuplicates", JSON.parse(e.target.value))}
                                            value={localFilters?.status[0]}
                                            name="duplicatesSelect"
                                            id="duplicatesSelect">
                                            <option value="false">{t('analysis_tab1_listenansicht_filter_dup_show_all')}</option>
                                            <option value="true">{t('analysis_tab1_listenansicht_filter_dup_show_duplicates')}</option>
                                        </select>
                                    </td>
                                </tr>
                                {
                                    changedTableLoading ?
                                        _.times(pagination.limit, (i) => (
                                            <tr key={i}>
                                                {
                                                    _.times(9, (j) => (
                                                        <td key={j} style={{ backgroundColor: "white" }}>
                                                            <Skeleton width={60} height={10} />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        )) :
                                        listData.length ?
                                            listData.map((listItem, i) => (
                                                <tr className="cursor-pointer" key={i} onClick={() => openPointModal(listItem)}>
                                                    <td className='number-row'>
                                                        <img src={listItem.statusIcon} alt="status-icon" />
                                                    </td>
                                                    <td>{listItem?.hasError ?
                                                        <div className="issues-row error">
                                                            <img src={require('assets/icons/table/WarningCircle.svg').default} alt="" />
                                                            {t('analysis_tab1_listenansicht_table_issue')}
                                                        </div> :
                                                        <div className="issues-row success">
                                                            <img src={require('assets/icons/table/CheckCircle.svg').default} alt="" />
                                                            {t('analysis_tab1_listenansicht_table_ok')}
                                                        </div>
                                                    }</td>
                                                    <td>{listItem.id.toLocaleString('de-DE') || "-"}</td>
                                                    <td>{listItem.extension || "-"}</td>
                                                    <td>{listItem.fileName || '-'}</td>
                                                    <td>{listItem.fileSize ? `${formatFileSize(listItem.fileSize)} KB` : '-'}</td>
                                                    <td>{listItem.lastModified ? moment(listItem.lastModified).format("DD/MM/YYYY") : '-'}</td>
                                                    <td className='number-row'>{listItem.complexity}</td>
                                                    <td className='number-row'>{listItem.criticality}</td>
                                                    <td>
                                                        {
                                                            listItem.nrDuplicates > 0 ?
                                                                <div className="details-button" onClick={(e) => openDuplicateModal(e, listItem)}>
                                                                    <AiOutlineDiff style={{ fontSize: 14 }} />
                                                                    <span>{listItem.nrDuplicates}</span>
                                                                </div> :
                                                                <div></div>
                                                        }
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={9}>{t('analysis_tab1_listenansicht_empty')}</td>
                                            </tr>
                                }
                            </tbody>
                        </table>

                        {
                            listData.length ?
                                <TablePagination
                                    pagination={pagination}
                                    dataCount={listDataTotalCount}
                                    changePageDataCallback={changePageData}
                                    isLoading={loadingListDataCount}
                                /> :
                                null
                        }
                    </div>
            }

            <DuplicateModal
                isOpen={duplicateOpen}
                setIsOpen={setDuplicateOpen}
                itemId={selectedDupItem.fileSnapshotId}
                estimRow={selectedDupItem.nrDuplicates}
                openPointModal={openPointModal}
            />

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                setSelectedItem={setSelectedPoint}
                selectedItem={selectedPoint}
                refreshScreen={() => applyLocalFilters(localFilters, globalFilters)}
            />

            <ChartPointModal
                isOpen={isChartPointModalOpen}
                setIsOpen={setIsChartPointModalOpen}
                setIsFileOverviewModalOpen={setIsFileOverviewModalOpen}
                setIsFileValidationModalOpen={setIsSelectUserModalOpen}
                setIsInventoryValidationModalOpen={setIsInventoryValidationModalOpen}
                selectedPoint={selectedPoint}
                setIsGraphLoading={setChangedTableLoading}
                getChartDataWithFilters={() => applyLocalFilters(localFilters, globalFilters)}
            />

            <SelectUserModal
                isOpen={isSelectUserModalOpen}
                setIsOpen={setIsSelectUserModalOpen}
                selectedItems={[selectedPoint]}
                setIsGraphLoading={setChangedTableLoading}
                getChartDataWithFilters={() => applyLocalFilters(localFilters, globalFilters)}
                title={t('file_validation_modal_title')}
                description={t('file_validation_modal_content')}
            />

            {
                isInventoryValidationModalOpen &&
                <InventoryUserPicker
                    isOpen={isInventoryValidationModalOpen}
                    setIsOpen={setIsInventoryValidationModalOpen}
                    selectedItems={[selectedPoint]}
                    reloadTab={() => applyLocalFilters(localFilters, globalFilters)}
                />
            }

            <DownloadCsvModal
                isOpen={isDownloadCsvModalOpen}
                setIsOpen={setIsDownloadCsvModalOpen}
                globalFilters={globalFilters}
                localFilters={localFilters}
            />
        </div>
    )
};

export default ListenansichtComponent;