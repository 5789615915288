import customFetch from './fetch';

export async function getTopicsService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic`, "GET");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function setTopicsService(topics) {
    try {
        const body = JSON.stringify(topics);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic`, "POST", body);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteTopicService(topicId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic/${topicId}`, "DELETE");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getTopicKeywordsService(topicId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic/${topicId}/keywords`, "GET");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function setTopicKeywordsService(keywords) {
    try {
        const body = JSON.stringify(keywords);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic/keywords`, "POST", body);
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteTopicKeywordService(keywordId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/topic/keyword/${keywordId}`, "DELETE");
        return response;
    } catch (err) {
        throw err;
    }
}