import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withResizeDetector } from 'react-resize-detector';
import Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from "react-i18next";
import { fileStatusList, getPointData } from 'services/helper';
import './ScatterChartComponent.scss';

Boost(Highcharts);

const ScatterChartComponent = (props) => {
    const {
        chartData,
        currentZoomCallback,
        settings,
        setIsChartPointModalOpen,
        setSelectedPoint,
        criticalityValue,
        setCriticalityValue,
        complexityValue,
        setComplexityValue,
        axisVisible,
        xAxisMinValue = 0,
        xAxisMaxValue = 100,
        yAxisMinValue = 0,
        yAxisMaxValue = 100,
        width,
        height,
        setPointsList,
        setIsChartPointListModalOpen,
        chartDuplicateData
    } = props;
    const { t } = useTranslation();

    const duplicateDataRef = useRef(null);
    const chartRef = useRef(null);

    const draggableRectXRef = useRef(null);
    const draggableLineXRef = useRef(null);
    const draggableLabelOverlayXRef = useRef(null);
    const labelTextXRef = useRef(null);

    const draggableRectYRef = useRef(null);
    const draggableLineYRef = useRef(null);
    const draggableLabelOverlayYRef = useRef(null);
    const labelTextYRef = useRef(null);

    const oldMouseRef = useRef(null);
    const newMouseRef = useRef(null);

    useEffect(() => {
        if (chartRef?.current) {
            chartRef.current.update({
                chart: {
                    width: width,
                    height: height
                }
            });

            drawChartAxis(chartRef.current, complexityValue || 1000, criticalityValue || 1000);
        }
    }, [width, height]);

    useEffect(() => {
        const isAxisVisible = JSON.parse(axisVisible);

        if (complexityValue) {
            const valueX = chartRef.current.xAxis[0].toPixels(complexityValue);
            const roundedValue = chartRef.current.xAxis[0].toValue(valueX).toFixed(0);

            draggableRectXRef.current.attr({ x: valueX - 3, visibility: isAxisVisible ? 'visible' : 'hidden' })
            draggableLineXRef.current.attr({ x: valueX, visibility: isAxisVisible ? 'visible' : 'hidden' })
            draggableLabelOverlayXRef.current.attr({ x: valueX - 20, visibility: isAxisVisible ? 'visible' : 'hidden' })
            labelTextXRef.current.attr({
                text: `
                        <span>${t('analysis_tab1_graphen_filters_complexity')}:</span>
                        <span class="highlight-value" style="color: ${mainColor}">${roundedValue}</span>
                    `,
                x: valueX - 10,
                y: chartRef.current.plotTop + 60 + 15,
                transform: `rotate(270, ${valueX - 10}, ${chartRef.current.plotTop + 60 + 15})`,
                "text-anchor": "middle",
                "dominant-baseline": "central",
                visibility: isAxisVisible ? 'visible' : 'hidden'
            })
        }

        if (criticalityValue) {
            const valueY = chartRef.current.yAxis[0].toPixels(criticalityValue);
            const roundedValue = chartRef.current.yAxis[0].toValue(valueY).toFixed(0);

            draggableRectYRef.current.attr({ y: valueY - 3, visibility: isAxisVisible ? 'visible' : 'hidden' })
            draggableLineYRef.current.attr({ y: valueY, visibility: isAxisVisible ? 'visible' : 'hidden' })
            draggableLabelOverlayYRef.current.attr({ y: valueY, visibility: isAxisVisible ? 'visible' : 'hidden' })
            labelTextYRef.current.attr({
                text: `
                        <span>${t('analysis_tab1_graphen_filters_criticality')}:</span>
                        <span class="highlight-value" style="color: ${mainColor}">${roundedValue}</span>
                    `,
                y: valueY + 15,
                visibility: isAxisVisible ? 'visible' : 'hidden'
            })
        }
    }, [criticalityValue, complexityValue, axisVisible]);

    useEffect(() => {
        // Workaround to support highcharts event click caching issue
        duplicateDataRef.current = chartDuplicateData;
    }, [chartDuplicateData])

    // useEffect(() => {
    //     drawChartAxis(chartRef.current, complexityValue, criticalityValue);
    // }, [t]);

    // Chart configuration
    const mainColor = settings?.theme?.mainColor;

    const chartLegend = fileStatusList.map(fileStatus => {
        let seriesAttributes = {
            name: t(fileStatus.label),
            type: 'scatter',
            marker: {
                symbol: `url(${fileStatus.icon})`
            },
            cursor: 'pointer',
            point: {
                events: {
                    click: function () {
                        if (this.statusId === "duplicate") {
                            const duplicates = duplicateDataRef.current[`${this.complexity}-${this.criticality}`];
                            const duplicatesWithData = duplicates.map(duplicate => {
                                const thisStatus = fileStatusList.filter(status => status.id === duplicate?.statusId)[0];

                                return {
                                    ...getPointData(duplicate),
                                    statusIcon: thisStatus.icon
                                };
                            });

                            setPointsList(duplicatesWithData);
                            setIsChartPointListModalOpen(true);
                        } else {
                            setIsChartPointModalOpen(true);
                            setSelectedPoint(getPointData(this));
                        }
                    },
                }
            },
            boostThreshold: 1000
        }

        if (fileStatus.id === "duplicate") {
            let dataWithMarkers = [];

            if (chartData[fileStatus.id]) {
                dataWithMarkers = chartData[fileStatus.id].map(chartPoint => {
                    return {
                        ...chartPoint,
                        marker: {
                            radius: chartPoint.numberOfDuplicates > 99 ? 7 : 6,
                            symbol: 'circle',
                            fillColor: "rgba(233,233,233,1)",
                            lineColor: '#525662',
                            lineWidth: 1
                        },
                        dataLabels: {
                            enabled: true,
                            align: 'center',
                            format: `${chartPoint.numberOfDuplicates}`,
                            crop: false,
                            overflow: 'allow',
                            y: window?.navigator?.platform?.includes("Mac") ? -1 : 0,
                            x: 0,
                            style: {
                                fontSize: chartPoint.numberOfDuplicates > 99 ? 7 : 8,
                                fontWeight: 'normal'
                            },
                            verticalAlign: 'middle'
                        }
                    }
                })
            }

            seriesAttributes.data = dataWithMarkers;
        } else {
            seriesAttributes.data = chartData[fileStatus.id] || [];
        }

        return seriesAttributes
    });

    const options = {
        chart: {
            animation: false,
            zoomType: 'xy',
            // height: '100%',
            panning: {
                enabled: true,
                type: "xy"
            },
            panKey: 'shift',
            events: {
                selection: function (event) {
                    setTimeout(() => {
                        const currentZoomValues = event.target.axes[0].getExtremes();
                        const zoom = (currentZoomValues.max - currentZoomValues.min) / (xAxisMaxValue - 0);
                        currentZoomCallback(zoom);
                    });
                },
                load() {
                    var chart = this;
                    // drawChartAxis(chart, xAxisMaxValue, yAxisMaxValue);
                }
            },
            spacingLeft: 5,
            spacingBottom: 5,
        },
        credits: {
            enabled: false
        },
        boost: {
            enabled: true,
            useGPUTranslations: true,
            usePreAllocated: true
        },
        xAxis: {
            min: xAxisMinValue,
            max: xAxisMaxValue,
            gridLineWidth: 1,
            crosshair: true
        },
        yAxis: {
            // Renders faster when we don't have to compute min and max
            min: yAxisMinValue,
            max: yAxisMaxValue,
            minPadding: 0,
            maxPadding: 0,
            gridLineWidth: 1,
            crosshair: true,
            title: {
                text: null
            },
            labels: {
                x: -7
            }
        },
        title: {
            text: ''
        },
        legend: {
            enabled: true,
            itemDistance: 15,
            itemMarginBottom: 5,
            itemStyle: {
                fontSize: "11px",
                fontWeight: "normal",
            },
            padding: 5
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            series: {
                stickyTracking: false,
                states: {
                    inactive: {
                        animation: {
                            duration: 0
                        }
                    },
                    hover: {
                        animation: {
                            duration: 0
                        }
                    },
                    normal: {
                        animation: {
                            duration: 0
                        }
                    },
                    select: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                turboThreshold: 100000
            }
        },
        series: chartLegend
    }

    const drawChartAxis = (chart, axisXValue, axisYValue) => {
        const initialX = chart.xAxis[0].toPixels(axisXValue);
        const initialY = chart.yAxis[0].toPixels(axisYValue);

        if (draggableRectXRef.current) draggableRectXRef.current.destroy();
        if (draggableLineXRef.current) draggableLineXRef.current.destroy();
        if (draggableLabelOverlayXRef.current) draggableLabelOverlayXRef.current.destroy();
        if (labelTextXRef.current) labelTextXRef.current.destroy();

        if (draggableRectYRef.current) draggableRectYRef.current.destroy();
        if (draggableLineYRef.current) draggableLineYRef.current.destroy();
        if (draggableLabelOverlayYRef.current) draggableLabelOverlayYRef.current.destroy();
        if (labelTextYRef.current) labelTextYRef.current.destroy();

        // Line X
        draggableRectXRef.current = chart.renderer.rect(initialX - 3, chart.plotTop, 5, chart.plotHeight)
            .attr({ fill: 'rgba(0, 0, 0, 0)', zIndex: 5, class: "draggable-axis x-axis" })
            .add();

        draggableLineXRef.current = chart.renderer.rect(initialX, chart.plotTop, 1, chart.plotHeight)
            .attr({ fill: '#525662', zIndex: 3 })
            .add();

        draggableLabelOverlayXRef.current = chart.renderer.rect(initialX - 20, chart.plotTop + 15, 20, 120)
            .attr({ fill: '#525662', zIndex: 3 })
            .add();

        labelTextXRef.current = chart.renderer.text(`
            <span>${t('analysis_tab1_graphen_filters_complexity')}:</span>
            <span class="highlight-value" style="color: ${mainColor}">${Math.round(axisXValue)}</span>
        `,
            initialX - 5, chart.plotTop + 120 + 15 - 5)
            .attr({
                zIndex: 4,
                x: initialX - 10,
                y: chartRef.current.plotTop + 60 + 15,
                transform: `rotate(270, ${initialX - 10}, ${chartRef.current.plotTop + 60 + 15})`,
                "text-anchor": "middle",
                "dominant-baseline": "central",
            })
            .css({ fontSize: '13px', color: '#fff', zIndex: 4 })
            .add();

        // Line Y
        draggableRectYRef.current = chart.renderer.rect(chart.plotLeft, initialY - 3, chart.plotWidth, 5)
            .attr({ fill: 'rgba(0, 0, 0, 0)', zIndex: 5, class: "draggable-axis y-axis" })
            .add();

        draggableLineYRef.current = chart.renderer.rect(chart.plotLeft, initialY, chart.plotWidth, 1)
            .attr({ fill: '#525662', zIndex: 3 })
            .add();

        draggableLabelOverlayYRef.current = chart.renderer.rect(chart.plotLeft + chart.plotWidth - 120 - 15, initialY, 120, 20)
            .attr({ fill: '#525662', zIndex: 3 })
            .add();

        labelTextYRef.current = chart.renderer.text(`
            <span>${t('analysis_tab1_graphen_filters_criticality')}:</span>
            <span class="highlight-value" style="color: ${mainColor}">${Math.round(axisYValue)}</span>
        `,
            chart.plotLeft + chart.plotWidth - 120 - 15 + 5, initialY + 15)
            .attr({ zIndex: 4 })
            .css({ fontSize: '13px', color: '#fff', zIndex: 4 })
            .add();

        oldMouseRef.current = chart.container.onmousemove;
        newMouseRef.current = function (e) {
            if (draggableRectXRef.current.drag || draggableRectYRef.current.drag) {
                e.preventDefault();
                e.stopPropagation();

                chart.pointer.normalize(e);

                const extremesX = {
                    left: chart.plotLeft,
                    right: chart.plotLeft + chart.plotWidth
                };

                const extremesY = {
                    top: chart.plotTop,
                    bottom: chart.plotTop + chart.plotHeight
                };

                if (draggableRectXRef.current.drag && (e.chartX >= extremesX.left && e.chartX <= extremesX.right)) {
                    const roundedValue = chart.xAxis[0].toValue(e.chartX).toFixed(0);

                    draggableRectXRef.current.attr({ x: e.chartX - 3 })
                    draggableLineXRef.current.attr({ x: e.chartX })
                    draggableLabelOverlayXRef.current.attr({ x: e.chartX - 20 })
                    labelTextXRef.current.attr({
                        text: `
                                <span>${t('analysis_tab1_graphen_filters_complexity')}:</span>
                                <span class="highlight-value" style="color: ${mainColor}">${roundedValue}</span>
                            `,
                        x: e.chartX - 10,
                        y: chartRef.current.plotTop + 60 + 15,
                        transform: `rotate(270, ${e.chartX - 10}, ${chartRef.current.plotTop + 60 + 15})`,
                    })
                }

                if (draggableRectYRef.current.drag && (e.chartY >= extremesY.top && e.chartY <= extremesY.bottom)) {
                    const roundedValue = chart.yAxis[0].toValue(e.chartY).toFixed(0);

                    draggableRectYRef.current.attr({ y: e.chartY - 3 })
                    draggableLineYRef.current.attr({ y: e.chartY })
                    draggableLabelOverlayYRef.current.attr({ y: e.chartY })
                    labelTextYRef.current.attr({
                        text: `
                                <span>${t('analysis_tab1_graphen_filters_criticality')}:</span>
                                <span class="highlight-value" style="color: ${mainColor}">${roundedValue}</span>
                            `,
                        y: e.chartY + 15,
                    })
                }
            }
        }

        draggableRectXRef.current.element.onmousedown = function () {
            chart.container.onmousemove = newMouseRef.current;
            draggableRectXRef.current.drag = true;
        }

        draggableRectXRef.current.element.onmouseup = function (e) {
            chart.container.onmousemove = oldMouseRef.current;

            chart.pointer.normalize(e);

            const pointValue = chartRef.current.xAxis[0].toValue(e.chartX).toFixed(0);
            draggableRectXRef.current.drag = false;
            setComplexityValue(pointValue);
        }

        draggableRectYRef.current.element.onmousedown = function () {
            chart.container.onmousemove = newMouseRef.current;
            draggableRectYRef.current.drag = true;
        }

        draggableRectYRef.current.element.onmouseup = function (e) {
            chart.container.onmousemove = oldMouseRef.current;

            chart.pointer.normalize(e);

            const pointValue = chart.yAxis[0].toValue(e.chartY).toFixed(0);
            draggableRectYRef.current.drag = false;
            setCriticalityValue(pointValue);
        }
    }

    const afterRender = chart => {
        chartRef.current = chart;
    }

    return (
        <div className="scatter-chart-component">
            <HighchartsReact
                containerProps={{ style: { height: "100%" } }}
                allowChartUpdate={true}
                highcharts={Highcharts}
                options={options}
                callback={afterRender}
            />
        </div>
    )
}

const ScatterChartComponentWithDetector = withResizeDetector(ScatterChartComponent);

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default connect(mapStateToProps)(ScatterChartComponentWithDetector);