import React from 'react';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';

const TableSkeletonLoading = props => {
    const { hideHeader, rows, columns, style } = props;

    return (
        <div style={style} className="table-skeleton-loading-component">
            {
                !hideHeader &&
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    {
                        _.times(columns, (i) => (
                            <div style={{ width: '100%' }} key={i}>
                                <Skeleton width="80%" />
                            </div>
                        ))
                    }
                </div>
            }

            {
                _.times(rows, (j) => (
                    <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        {
                            _.times(columns, (i) => (
                                <div style={{ width: '100%' }} key={i}>
                                    <Skeleton width="80%" />
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default TableSkeletonLoading