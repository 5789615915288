import React from "react";
import Layout from 'layouts/layout';
import NavTabs from 'components/common/NavTabs/NavTabs';
import EucCheckSuccess from '../../EucCheckSuccess/EucCheckSuccess'
import Questionnaire from 'components/Questionnaire/containers/Questionnaire';
import { useTranslation } from "react-i18next";
import './EucCheckQuestionsComponent.scss';

const EucCheckQuestionsComponent = (props) => {
    const {
        formSent,
        filename,
        alias,
        successStatus,
        sendQuestionsData,
        isLoading,
        previewTheme,
        history,
        oldFormData,
        isLoadingOldFormData
    } = props;

    const { t } = useTranslation();

    return (
        <Layout previewTheme={previewTheme}>
            <div className="euc-check-questions-wrapper">
                <div className="euc-check-questions-content">
                    <h2>{t('screenEucCheck_layout_chain_text_2')}</h2>
                    <p style={{ marginBottom: 20 }}>{t('screenEucCheck_layout_chain_text_3')}</p>

                    <NavTabs
                        selectedTab={0}
                        setSelectedTab={null}
                        tabs={[t('screenEucCheck_layout_chain_text_4')]}
                    >
                        <div className="euc-check-questions-tab">
                            {
                                formSent ?
                                    <EucCheckSuccess
                                        filename={filename}
                                        alias={alias}
                                        statusText={successStatus === "euc" ? t('screenEucCheck_layout_chain_text_9') : t('screenEucCheck_layout_chain_text_10')}
                                        bottomText={
                                            successStatus === "euc" ?
                                                t('screenEucCheck_layout_chain_text_7') :
                                                t('screenEucCheck_layout_chain_text_13')
                                        }
                                        clickAction={() => history.push('/guidance/euc-check')}
                                    /> :
                                    <Questionnaire
                                        submitForm={sendQuestionsData}
                                        isSubmitting={isLoading}
                                        oldFormData={oldFormData}
                                        isLoadingOldFormData={isLoadingOldFormData}
                                    />
                            }
                        </div>
                    </NavTabs>
                </div>
            </div>
        </Layout>
    )
};

export default EucCheckQuestionsComponent;
