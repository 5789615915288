import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import InventoryUserPickerComponent from '../components/InventoryUserPickerComponent';
import { requestInventoryService } from 'services/inventory';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const InventoryUserPicker = (props) => {
    const { selectedItems, setIsOpen, reloadTab } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [businessOfficerModalOpen, setBusinessOfficerModalOpen] = useState(false);
    const [riskOfficerModalOpen, setRiskOfficerModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const submitRequest = async officerNames => {
        setIsLoading(true);

        try {
            const assignations = [
                {
                    configSectionOrder: 1,
                    username: officerNames.businessOfficer
                },
                {
                    configSectionOrder: 2,
                    username: officerNames.riskOfficer
                },
            ];

            const formDatasPromises = selectedItems.map(selectedItem => {
                const formData = {
                    fileId: selectedItem.id,
                    assignations
                };

                return requestInventoryService(formData);
            })

            await Promise.all(formDatasPromises);

            closeModal();
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_inventory_request')} />, {
                progressClassName: "toastr-progress-bar success"
            });
            await reloadTab();
        } catch (err) {
            console.log(`Error while creating inventory form: ${err}`);
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={err} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }

        setIsLoading(false);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setBusinessOfficerModalOpen(false);
        setRiskOfficerModalOpen(false);
        setIsOpen(false);
    }

    return (
        <InventoryUserPickerComponent
            {...props}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            businessOfficerModalOpen={businessOfficerModalOpen}
            setBusinessOfficerModalOpen={setBusinessOfficerModalOpen}
            riskOfficerModalOpen={riskOfficerModalOpen}
            setRiskOfficerModalOpen={setRiskOfficerModalOpen}
            submitRequest={submitRequest}
            closeModal={closeModal}
            isLoading={isLoading}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(InventoryUserPicker);