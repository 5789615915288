import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StepFiveComponent from '../components/StepFiveComponent';
import { getIndicatorsService } from "services/settings";
import { getTopicsService } from "services/topic";
import { getMetadataService } from "services/metadata";

const StepFive = props => {
    const { changeStep, settings, submitStepData, data, readOnly } = props;
    const [isFetching, setIsFetching] = useState(true);
    const [defaultIndicators, setDefaultIndicators] = useState([]);

    useEffect(() => {
        getIndicators();
    }, [])

    const getIndicators = async () => {
        try {
            const indicators = await getIndicatorsService();

            const topics = await getTopicsService();
            const topicsParsed = topics.map(topicItem => {
                return {
                    ...topicItem,
                    type: "topic-item"
                }
            })

            const metadata = await getMetadataService();
            const metadataParsed = metadata.map(metadataItem => {
                return {
                    ...metadataItem,
                    type: "metadata-item"
                }
            })

            const allItems = [...topicsParsed, ...indicators, ...metadataParsed];
            const allItemsCriticality = allItems.filter(item => item.kpiType === 'Criticality')

            setDefaultIndicators(allItemsCriticality);
        } catch (err) {
            console.log("Error indicators GET:", err);
        }

        setIsFetching(false);
    }

    return (
        <StepFiveComponent
            data={data}
            isFetching={isFetching}
            settings={settings}
            changeStep={changeStep}
            defaultIndicators={defaultIndicators}
            submitStepData={submitStepData}
            readOnly={readOnly}
        />
    )
}

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default connect(mapStateToProps)(StepFive);