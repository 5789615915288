import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from 'components/common/Button/Button';
import SendButton from 'components/common/SendButton/SendButton';
import { ImSearch } from 'react-icons/im';
import UserListModal from 'components/modals/UserListModal/containers/UserListModal';
import Skeleton from "react-loading-skeleton";
import ModalLayout from 'layouts/modal-layout/modal-layout';
import './SelectUserModalComponent.scss';

const SelectUserModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        user,
        selectedItems,
        submitValidation,
        getCoordinatorNameCallback,
        selectedOption,
        setSelectedOption,
        selectedUser,
        setSelectedUser,
        isLoading,
        thisCoordinator,
        isLoadingComponent,
        keepOpenOnCallback,
        hideYourselfOption,
        title,
        description,
        inputSearch,
        setInputSearch
    } = props;

    const [usersModalOpen, setUsersModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();

    const selectOption = optionValue => {
        setSelectedUser(null);
        setSelectedOption(optionValue);

        if (optionValue === 0) {
            setUsersModalOpen(true);
            setSearchTerm(`search=${selectedItems[0]?.user}`);
        }

        if (optionValue === 1) {
            setSelectedUser(thisCoordinator || null);
        }

        if (optionValue === 2) {
            setSelectedUser(user);
        }

        if (optionValue === 3) {
            setUsersModalOpen(true);
            setSearchTerm(`search=${inputSearch}`);
        }
    }

    const selectUser = user => {
        setInputSearch(user?.displayName);
        setSelectedUser(user);
        setUsersModalOpen(false);
    }

    const getCoordinatorName = () => {
        getCoordinatorNameCallback(selectedUser);
        if (!keepOpenOnCallback) setIsOpen(false);
    }

    return (
        <ModalLayout
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalCustomClass="select-user-modal"
            modalTitle={title}
            modalDescription={isLoadingComponent ? null : description}
        >
            {
                isLoadingComponent ?
                    <SelectUserModalComponentSkeleton /> :
                    <div className="select-user-modal-wrapper">
                        <div className="modal-actions">
                            <div className="inputs-wrapper">
                                <div className="input-row">
                                    <input
                                        type="radio"
                                        name="recipient"
                                        id="input-1"
                                        checked={selectedOption === 0}
                                        onChange={(e) => selectOption(0)}
                                    />
                                    <label htmlFor="input-1">{t('file_validation_modal_option_1')}</label>
                                </div>

                                <div className="input-row">
                                    <input
                                        type="radio"
                                        name="recipient"
                                        id="input-2"
                                        checked={selectedOption === 1}
                                        disabled={!thisCoordinator}
                                        onChange={(e) => selectOption(1)}
                                    />
                                    <label htmlFor="input-2">{t('file_validation_modal_option_2')} {thisCoordinator?.name ? `(${thisCoordinator.name})` : null}</label>
                                </div>

                                {
                                    !hideYourselfOption &&
                                    <div className="input-row">
                                        <input
                                            type="radio"
                                            name="recipient"
                                            id="input-3"
                                            checked={selectedOption === 2}
                                            onChange={(e) => selectOption(2)}
                                        />
                                        <label htmlFor="input-3">{t('file_validation_modal_option_3')}</label>
                                    </div>
                                }

                                <div className="input-row">
                                    <input
                                        type="radio"
                                        name="recipient"
                                        id="input-4"
                                        checked={selectedOption === 3}
                                        onChange={(e) => setSelectedOption(3)}
                                    />
                                    <input
                                        className="input-text"
                                        type="text"
                                        disabled={selectedOption !== 3}
                                        placeholder={t('file_validation_modal_option_4_placeholder')}
                                        value={inputSearch}
                                        onChange={e => setInputSearch(e.target.value)}
                                    />
                                    <Button className="small light" disabled={selectedOption !== 3} onClick={() => selectOption(3)}>
                                        <div className="flex center">
                                            <ImSearch />
                                        </div>
                                    </Button>
                                </div>
                            </div>

                            <SendButton
                                onClick={() => getCoordinatorNameCallback ? getCoordinatorName() : submitValidation()}
                                disabled={!selectedUser}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
            }

            <UserListModal
                isOpen={usersModalOpen}
                setIsOpen={setUsersModalOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectUserCallback={selectUser}
            />
        </ModalLayout>
    )
};

const SelectUserModalComponentSkeleton = () => {
    return (
        <div style={{ backgroundColor: '#fff', padding: 15 }}>
            <div>
                <div style={{ marginBottom: 20 }}>
                    <Skeleton count={3} width={"100%"} height={10} style={{ marginBottom: 5 }} />
                </div>

                <div>
                    <div><Skeleton width={100} height={10} style={{ marginBottom: 5 }} /></div>
                    <div><Skeleton width={100} height={10} style={{ marginBottom: 5 }} /></div>
                    <div><Skeleton width={100} height={10} style={{ marginBottom: 5 }} /></div>
                    <div><Skeleton width={150} height={10} style={{ marginBottom: 5 }} /></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(SelectUserModalComponent);