import React from "react";
import { connect } from "react-redux";
import { ImInfo } from 'react-icons/im';
import ReactTooltip from "react-tooltip";

import './InfoComponent.scss';

const InfoComponent = (props) => {
    const { tooltipValue, style = {}, settings } = props;

    const secondaryColor = settings?.theme?.secondaryColor;

    return (
        <div className="info-tooltip" style={style}>
            <ReactTooltip
                globalEventOff="click"
                html={true}
                className="help-tooltip"
                effect="solid"
                delayShow={0}
                delayHide={0}
                overridePosition={(position) => {
                    return {
                        top: position.top + 8,
                        left: position.left
                    }
                }} />

            <div className="info-icon" style={{ color: secondaryColor }} data-tip={tooltipValue} data-event="click">
                <ImInfo />
            </div>
        </div>
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default connect(mapStateToProps)(InfoComponent);