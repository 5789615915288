import React from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import './AnsprechartnerComponent.scss';

const AnsprechartnerComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="ansprechartner">
                <div className="ansprechartner-content">
                    <h2>{t('screenAnsprechartner_layout_chain_text_1')}</h2>

                    <p>{t('screenAnsprechartner_layout_chain_text_2')}</p>
                    <a href={`mailto:${t('screenAnsprechartner_mailto_chain_text_1')}`} rel="noopener noreferrer" target="_blank">
                        {t('screenAnsprechartner_layout_chain_text_3')}
                    </a>

                    <div className="section">
                        <p>{t('screenAnsprechartner_layout_chain_text_4')}</p>
                        <a href={t('screenAnsprechartner_link_chain_text_1')} rel="noopener noreferrer" target="_blank">
                            {t('screenAnsprechartner_layout_chain_text_5')}
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default AnsprechartnerComponent;