import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "redux/actions/settingsActions";
import BerechtigungenComponent from '../components/BerechtigungenComponent';
import { usersService } from 'services/user';
import { setConfigValidationService } from 'services/settings';

const Berechtigungen = (props) => {
    const { settings, updateSettings } = props;

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allowedModalPermission, setAllowedModalPermission] = useState(null);

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getUsersWithPermissions();
    }, []);

    useEffect(() => {
        setAllowedModalPermission(settings?.validationConfig?.unpriviledgedScanView === 1);
    }, [settings]);

    const getUsersWithPermissions = async () => {
        setIsLoading(true);

        try {
            const usersData = await usersService();
            setUsers(usersData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }

    const setConfigValidation = async (newModalPermission) => {
        const newValidationConfig = {
            ...settings.validationConfig,
            unpriviledgedScanView: newModalPermission ? 1 : 0,
        };

        try {
            setAllowedModalPermission(newModalPermission);

            await setConfigValidationService(newValidationConfig);

            updateSettings({
                ...settings,
                validationConfig: newValidationConfig
            })
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    return <BerechtigungenComponent
        users={users}
        isLoading={isLoading}
        allowedModalPermission={allowedModalPermission}
        setConfigValidation={setConfigValidation}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Berechtigungen);