import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from 'components/common/Button/Button';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import './InfoTextModal.scss';

const InfoTextModal = (props) => {
    const { isOpen, setIsOpen, type = "", submitInfoCallback, settings, item } = props;
    const [infoTextEn, setInfoTextEn] = useState("");
    const [infoTextDe, setInfoTextDe] = useState("");
    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    useEffect(() => {
        setInfoTextEn(item?.infoText?.en);
        setInfoTextDe(item?.infoText?.de);
    }, [item])

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal info-text-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{t('settings_tab4_popup_infotext_layout_chain_text1')}</h3>
                {/* <p style={{ color: mainColor }}>Type to change the {item.indicatorId ? "indicator" : "topic"} {item.name} infotext</p> */}
                <p style={{ color: mainColor }}>{t('settings_tab4_popup_infotext_layout_chain_text2')}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <div className="input-wrapper" style={{ marginBottom: 20 }}>
                    <label htmlFor="infotext-en">{t('settings_tab4_popup_infotext_layout_chain_text3')}</label>
                    <input
                        id="infotext-en"
                        name="infotext-en"
                        type="text"
                        placeholder={t('settings_tab4_popup_infotext_layout_chain_text4')}
                        value={infoTextEn}
                        onChange={(e) => setInfoTextEn(e.target.value)} />
                </div>

                <div className="input-wrapper">
                    <label htmlFor="infotext-de">{t('settings_tab4_popup_infotext_layout_chain_text5')}</label>
                    <input
                        id="infotext-de"
                        name="infotext-de"
                        type="text"
                        placeholder={t('settings_tab4_popup_infotext_layout_chain_text6')}
                        value={infoTextDe}
                        onChange={(e) => setInfoTextDe(e.target.value)} />
                </div>

                <div className="action-buttons">
                    <Button onClick={() => {
                        setInfoTextEn("");
                        setInfoTextDe("");
                    }}>
                        {t('settings_tab4_popup_infotext_button1')}
                    </Button>
                    <Button onClick={() => {
                        submitInfoCallback({ infoTextDe, infoTextEn });
                        setIsOpen(false);
                    }}>{t('settings_tab4_popup_infotext_button2')}</Button>
                </div>
            </div>
        </Modal>
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(InfoTextModal);