import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateDashboardListField } from "redux/actions/dashboardActions";
import ListenansichtComponent from '../components/ListenansichtComponent';
import { getGraphDataService } from 'services/analysis';
import { parseFileSnapshot } from 'services/helper';

const Listenansicht = (props) => {
    const { settings, globalFilters, toggleChartCallback, updateDashboardListField, dashboard } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [changedTableLoading, setChangedTableLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [listDataTotalCount, setListDataTotalCount] = useState(null);

    const [pagination, setPagination] = useState({
        limit: settings?.configGraph?.pointLimit > 100 ? 100 : settings?.configGraph?.pointLimit,
        selectValue: 100,
        currentIndex: 1,
    });

    const [localFilters, setLocalFilters] = useState({
        status: [],
        hasError: [],
        complexity: {
            min: 0,
            max: 100
        },
        criticality: {
            min: 0,
            max: 100
        },
        order: null,
        reverse: null
    });

    const [allDataCount, setAllDataCount] = useState(0);

    const [loadingAllDataCount, setLoadingAllDataCount] = useState(false);
    const [loadingListDataCount, setLoadingListDataCount] = useState(false);

    useEffect(() => {
        if (!globalFilters && !dashboard?.list?.data.length) {
            const paginationDefault = {
                currentIndex: 1,
                selectValue: 100,
                limit: settings?.configGraph?.pointLimit > 100 ? 100 : settings?.configGraph?.pointLimit
            };

            setIsLoading(true);
            getListData(null, null, paginationDefault);
        }

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    useEffect(() => {
        const pointLimit = settings?.configGraph?.pointLimit;

        if (dashboard?.list?.data.length) {
            setListData(dashboard.list.data);
            setListDataTotalCount(dashboard.list.dataCount);
            setAllDataCount(dashboard.list.allDataCount);
            setPagination(dashboard.list.pagination);
        } else {
            if (globalFilters) {
                const paginationDefault = {
                    currentIndex: 1,
                    selectValue: 100,
                    limit: pointLimit > 100 ? 100 : pointLimit
                };
                setIsLoading(true);
                getListData(globalFilters, localFilters, paginationDefault);
            }
        }
    }, [globalFilters])

    useEffect(() => {
        setLocalFilters(dashboard?.list?.filters);
    }, [dashboard.list.filters])

    const getListData = async (globalFilters, thisLocalFilters, newPagination, changePage = false) => {
        try {
            if (!changePage) {
                getListDataCount(globalFilters, thisLocalFilters, newPagination);
                getAllDataCount(globalFilters);
            }

            const newListData = await getGraphDataService(globalFilters, thisLocalFilters, newPagination);

            const newListDataParsed = newListData.map(data => {
                return {
                    ...parseFileSnapshot(data),
                    nrDuplicates: data.nrDuplicates,
                    fileSnapshotId: data.fileSnapshotId,
                }
            });

            setListData(newListDataParsed);

            updateDashboardListField("data", newListDataParsed);
            updateDashboardListField("filters", thisLocalFilters || localFilters);
            updateDashboardListField("pagination", newPagination);
        } catch (err) {
            console.log("Error fetching graph data", err);
        }

        setIsLoading(false);
        setChangedTableLoading(false);
    }

    const getAllDataCount = async (globalFilters) => {
        setLoadingAllDataCount(true);

        const globalFiltersDataCount = await getGraphDataService(globalFilters, null, null, true);
        setAllDataCount(globalFiltersDataCount.count);

        updateDashboardListField("allDataCount", globalFiltersDataCount.count);
        setLoadingAllDataCount(false);
    }

    const getListDataCount = async (globalFilters, thisLocalFilters, newPagination) => {
        setLoadingListDataCount(true);

        const newListDataTotalCount = await getGraphDataService(globalFilters, thisLocalFilters, newPagination, true);

        updateDashboardListField("dataCount", newListDataTotalCount.count);
        setListDataTotalCount(newListDataTotalCount.count);

        setLoadingListDataCount(false);
    }

    const changePageData = (newPagination, limit) => {
        const pointLimit = settings?.configGraph?.pointLimit;

        setChangedTableLoading(true);

        if (limit) {
            newPagination.limit = limit > pointLimit ? pointLimit : limit;
            newPagination.selectValue = limit;
            newPagination.currentIndex = 1;
        }

        setPagination(newPagination);
        getListData(globalFilters, localFilters, newPagination, true);
    }

    const applyLocalFilters = (newLocalFilters, globalFilters) => {
        setChangedTableLoading(true);

        const newPagination = { ...pagination, currentIndex: 1 };

        setPagination(newPagination);
        getListData(globalFilters, newLocalFilters, newPagination);
    }

    return <ListenansichtComponent
        settings={settings}
        listData={listData}
        isLoading={isLoading}
        pagination={pagination}
        changePageData={changePageData}
        applyLocalFilters={applyLocalFilters}
        setLocalFilters={setLocalFilters}
        localFilters={localFilters}
        globalFilters={globalFilters}
        changedTableLoading={changedTableLoading}
        setChangedTableLoading={setChangedTableLoading}
        listDataTotalCount={listDataTotalCount}
        toggleChartCallback={toggleChartCallback}
        allDataCount={allDataCount}
        loadingAllDataCount={loadingAllDataCount}
        loadingListDataCount={loadingListDataCount}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateDashboardListField }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Listenansicht);