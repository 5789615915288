import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ChangeMultipleFileStatusModalComponent from '../components/ChangeMultipleFileStatusModalComponent';
import { getSMTPReceiversService } from 'services/email';
import { searchUsersService } from 'services/user';
import { connect } from "react-redux";
import { setValidationFormRequest } from 'services/excel';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';
const mime = require('mime-types');

const ChangeMultipleFileStatus = (props) => {
    const {
        settings,
        isOpen,
        setIsOpen,
        reloadTab,
        selectedRows,
        setSelectedCheckboxes
    } = props;

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [fileRows, setFileRows] = useState([]);
    const [fetchingFileData, setFetchingFileData] = useState(false);
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
    const [editingRow, setEditingRow] = useState({});

    useEffect(() => {
        if (isOpen) {
            mapFilesCoordinators(selectedRows);
        } else {
            setFileRows([])
        }
    }, [isOpen]);

    const getCoordinators = async () => {
        try {
            const coordinators = await getSMTPReceiversService();
            return coordinators;
        } catch (err) {
            console.log(err);
        }
    }

    const mapFilesCoordinators = async (oldFileRows) => {
        setFetchingFileData(true);

        try {
            const coordinators = await getCoordinators();
            const useGroups = settings?.coordinatorConfig?.useGroups ? true : false;

            const newFileRowsPromises = oldFileRows.map(async fileRow => {
                let coordinatorData;

                if (useGroups) {
                    const groupId = fileRow.orgGroupId;
                    coordinatorData = coordinators.filter(coordinator => coordinator.orgDepartmentId === groupId)[0];
                } else {
                    const departmentId = fileRow.orgDepartmentId;
                    coordinatorData = coordinators.filter(coordinator => coordinator.orgDepartmentId === departmentId)[0];
                }

                if (coordinatorData?.samAccountName) {
                    fileRow.assignedUser = coordinatorData;
                } else {
                    if (fileRow.lastModifiedBy) {
                        const users = await searchUsersService(`search=${fileRow.lastModifiedBy}`);

                        if (users.length && users.length > 1) {
                            fileRow.assignedUser = users[0];
                        } else {
                            fileRow.assignedUser = null;
                        }
                    }
                }

                return fileRow;
            });

            const newFileRows = await Promise.all(newFileRowsPromises);
            setFileRows(newFileRows);
        } catch (err) {
            console.log(err);
        }

        setFetchingFileData(false);
    }

    const updateFileAssignedUser = (newUser) => {
        let newFileRows = _.cloneDeep(fileRows);

        newFileRows = newFileRows.map(fileRow => {
            if (fileRow.id === editingRow.id) {
                fileRow.assignedUser = newUser;
            }

            return fileRow;
        });

        setFileRows(newFileRows);
    }

    const changeValidationStatus = async () => {
        setIsLoading(true);

        try {
            const assignedUsers = fileRows.map(fileRow => fileRow.assignedUser);

            if (assignedUsers.includes(null)) {
                toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('change_multiple_file_status_modal_error')} />, {
                    progressClassName: "toastr-progress-bar danger"
                });
            } else {
                const formDatasPromises = fileRows.map(async fileRow => {
                    const path = fileRow.path.replace(`\\${fileRow.fileName}`, "");

                    const formData = {
                        fileId: fileRow.id,
                        type: mime.lookup(fileRow.fileName) || "unknown",
                        directory: path,
                        alias: fileRow.alias,
                        samAccount: fileRow?.assignedUser?.samAccountName,
                    }

                    await setValidationFormRequest(formData);

                    return formData;
                })

                const formDatas = await Promise.all(formDatasPromises);
                reloadTab(formDatas);
                setSelectedCheckboxes([]);
                setIsOpen(false);

                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_file_validation_success')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            }
        } catch (err) {
            console.log(`Error while changing status: ${err}`)
        }

        setIsLoading(false);
    }

    return (
        <ChangeMultipleFileStatusModalComponent
            {...props}
            changeValidationStatus={changeValidationStatus}
            isLoading={isLoading}
            fileRows={fileRows}
            fetchingFileData={fetchingFileData}
            updateFileAssignedUser={updateFileAssignedUser}
            setSelectUserModalOpen={setSelectUserModalOpen}
            selectUserModalOpen={selectUserModalOpen}
            editingRow={editingRow}
            setEditingRow={setEditingRow}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user
});

export default connect(mapStateToProps)(ChangeMultipleFileStatus);