import React, { useState, useEffect } from 'react'
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import NavTabs from 'components/common/NavTabs/NavTabs';
import _ from 'lodash';
import SMTP from './Smtp';
import Receivers from './Receivers';
import EmailText from './EmailText';
// import RiskOfficers from './RiskOfficers';
import './EmailSettings.scss';

const EmailSettingsComponent = (props) => {
    const { t } = useTranslation();
    const {
        selectedTab,
        setSelectedTab,
        loading,
        submitSMTP,
        smtpConfig,
        submitReceivers,
        receiversConfig,
        submitEmailText,
        emailTextConfig,
        receiversShowGroup,
        riskOfficerList,
        updateRiskOfficer
    } = props;

    return (
        <Layout>
            <div className="email-settings-component">
                <NavTabs
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabs={[t('settings_tab6_tab1_title'), t('settings_tab6_tab2_title'), t('settings_tab6_tab3_title')]}
                >
                    {
                        selectedTab === 0 &&
                        <SMTP loading={loading} submitSMTP={submitSMTP} smtpConfig={smtpConfig} />
                    }

                    {
                        selectedTab === 1 &&
                        <Receivers
                            loading={loading}
                            submitReceivers={submitReceivers}
                            receiversConfig={receiversConfig}
                            receiversShowGroup={receiversShowGroup}
                        />
                    }

                    {
                        selectedTab === 2 &&
                        <EmailText
                            loading={loading}
                            submitEmailText={submitEmailText}
                            emailTextConfig={emailTextConfig}
                        />
                    }

                    {/* {
                        selectedTab === 3 &&
                        <RiskOfficers
                            loading={loading}
                            riskOfficerList={riskOfficerList}
                            updateRiskOfficer={updateRiskOfficer}
                        />
                    } */}
                </NavTabs>
            </div>
        </Layout>
    )
}
export default EmailSettingsComponent;