import React from "react";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { connect } from "react-redux";
import './modal-layout.scss';

const ModalLayout = (props) => {
    const {
        children,
        isOpen,
        setIsOpen,
        modalTitle,
        modalSubtitle,
        modalDescription,
        modalFooter,
        style = {},
        modalCustomClass,
        settings
    } = props;

    const mainColor = settings?.theme?.mainColor;

    return (
        <Modal
            isOpen={isOpen}
            className={`modal-layout ${modalCustomClass || ''}`}
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}
            style={{
                content: style
            }}>
            <div className="modal-header">
                <div className="modal-header-content">
                    <h3>{modalTitle}</h3>
                    {
                        modalSubtitle &&
                        <p style={{ color: mainColor }}>{modalSubtitle}</p>
                    }
                </div>

                <div className="modal-header-close" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                {
                    modalDescription &&
                    <p className="modal-description">{modalDescription}</p>
                }

                <div className="content">
                    {children}
                </div>
            </div>

            {
                modalFooter &&
                <div className="modal-footer">{modalFooter}</div>
            }
        </Modal>
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(ModalLayout);