import React, { useState, useEffect } from 'react'
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import moment from 'moment';
import Button from 'components/common/Button/Button';
import './LicenseKeyComponent.scss';

const LicenseKeyComponent = (props) => {
    const { newLicenseKey, setNewLicenseKey, isLoading, license, changeLicense, updadingLicense } = props;
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="license-key-component">
                {
                    isLoading ?
                        <div>
                            <Skeleton
                                width={'100%'}
                                height={10}
                                style={{ marginBottom: 10 }}
                                count={3}
                            />
                            <Skeleton
                                width={'60%'}
                                height={10}
                                style={{ marginBottom: 40 }}
                            />

                            <Skeleton
                                width={'100%'}
                                height={10}
                                style={{ marginBottom: 10 }}
                                count={2}
                            />
                            <Skeleton
                                width={'40%'}
                                height={10}
                            />
                        </div> :
                        <div className="license-key-content">
                            <div className="field">
                                <div className="title">{t('settings_tab8_issuer')}:</div>
                                <div className="value">{license.iss || "-"}</div>
                            </div>
                            <div className="field">
                                <div className="title">{t('settings_tab8_resources')}:</div>
                                <div className="value">{license?.resources?.join(", ") || "-"}</div>
                            </div>
                            <div className="field">
                                <div className="title">{t('settings_tab8_issued_at')}:</div>
                                <div className="value">{license.iat ? moment(license.iat * 1000).format("DD/MM/YYYY HH:mm:ss") : "-"}</div>
                            </div>
                            <div className="field">
                                <div className="title">{t('settings_tab8_expires_in')}:</div>
                                <div className="value">{license.exp ? moment(license.exp * 1000).format("DD/MM/YYYY HH:mm:ss") : "-"}</div>
                            </div>
                            <div className="field">
                                <div className="title">{t('settings_tab8_status')}:</div>
                                <div className="value">{license?.statusMessage || "-"}</div>
                            </div>

                            <hr />

                            <div className="new-license">
                                <div className="new-license-title">{t('settings_tab8_new_license_title')}</div>
                                <textarea
                                    value={newLicenseKey}
                                    onChange={e => setNewLicenseKey(e.target.value)}
                                    cols="30"
                                    rows="10">
                                </textarea>

                                <Button style={{ width: 170 }} loading={updadingLicense} onClick={() => changeLicense()}>
                                    {t('settings_tab8_update_license')}
                                </Button>
                            </div>
                        </div>
                }
            </div>
        </Layout>
    )
}
export default LicenseKeyComponent;