import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OrgEinheitenComponent from '../components/OrgEinheitenComponent';
import { getOrganizationService, setOrganizationService } from 'services/settings';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'
import _ from 'lodash';

const OrgEinheiten = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingOrganizations, setIsFetchingOrganizations] = useState(true);
    const [organizationData, setOrganizationData] = useState([]);
    const [organizationDataOriginal, setOrganizationDataOriginal] = useState([]);

    const [selectedTenantIndex, setSelectedTenantIndex] = useState(null);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
    const [selectedDepartmentIndex, setSelectedDepartmentIndex] = useState(null);

    const [itemsToDelete, setItemsToDelete] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getOrganization();
    }, []);


    const getOrganization = async () => {
        try {
            const response = await getOrganizationService();
            setOrganizationData(response);
            setOrganizationDataOriginal(response); // Used to reset state
        } catch (err) {
            console.log(JSON.stringify(err));
        }

        setIsFetchingOrganizations(false);
    }

    const setNewOrganizationItem = (type, organizationName, selectedTabsIndexes) => {
        const { selectedTenantIndex, selectedGroupIndex } = selectedTabsIndexes;

        let newOrganizationData = _.cloneDeep(organizationData);

        if (type === 'tenant') {
            newOrganizationData.organization.push({
                name: organizationName,
                groups: []
            });
        }

        if (type === 'group') {
            newOrganizationData.organization[selectedTenantIndex].groups.push({
                name: organizationName,
                departments: []
            });
        }

        if (type === 'department') {
            newOrganizationData.organization[selectedTenantIndex].groups[selectedGroupIndex].departments.push({
                name: organizationName
            });
        }

        setOrganizationData(newOrganizationData);
    }

    const deleteOrganizationItem = async (event, tabsIndexes, itemType) => {
        event.stopPropagation();
        event.preventDefault();

        let newOrganizationData = _.cloneDeep(organizationData);

        if (itemType === 'tenant') {
            if (await confirm({
                title: t('orgeinheiten_delete_confirm_title'),
                confirmation: t('orgeinheiten_delete_tenant_confirm_title')
            })) {
                const { tenantIndex } = tabsIndexes;
                const tenant = newOrganizationData.organization[tenantIndex];

                if (tenant.tenantId) {
                    setItemsToDelete([
                        ...itemsToDelete,
                        {
                            id: tenant.tenantId,
                            type: itemType
                        }
                    ]);
                }

                newOrganizationData.organization.splice(tenantIndex, 1);

                if (tenantIndex === selectedTenantIndex) {
                    setSelectedTenantIndex(null);
                    setSelectedGroupIndex(null);
                    setSelectedDepartmentIndex(null);
                }
            }
        }

        if (itemType === 'group') {
            if (await confirm({
                title: t('orgeinheiten_delete_confirm_title'),
                confirmation: t('orgeinheiten_delete_group_confirm_title')
            })) {
                const { tenantIndex, groupIndex } = tabsIndexes;

                const group = newOrganizationData.organization[tenantIndex].groups[groupIndex];

                if (group.groupId) {
                    setItemsToDelete([
                        ...itemsToDelete,
                        {
                            id: group.groupId,
                            type: itemType
                        }
                    ]);
                }

                newOrganizationData.organization[tenantIndex].groups.splice(groupIndex, 1);

                if (groupIndex === selectedGroupIndex) {
                    setSelectedGroupIndex(null);
                    setSelectedDepartmentIndex(null);
                }
            }
        }

        if (itemType === 'department') {
            if (await confirm({
                title: t('orgeinheiten_delete_confirm_title'),
                confirmation: t('orgeinheiten_delete_department_confirm_title')
            })) {
                const { tenantIndex, groupIndex, departmentIndex } = tabsIndexes;

                const department = newOrganizationData.organization[tenantIndex].groups[groupIndex].departments[departmentIndex];

                if (department.departmentId) {
                    setItemsToDelete([
                        ...itemsToDelete,
                        {
                            id: department.departmentId,
                            type: itemType
                        }
                    ]);
                }

                newOrganizationData.organization[tenantIndex].groups[groupIndex].departments.splice(departmentIndex, 1);

                if (departmentIndex === selectedDepartmentIndex) {
                    setSelectedDepartmentIndex(null);
                }
            }
        }

        setOrganizationData(newOrganizationData);
    }

    const submitOrganizationData = async () => {
        setIsLoading(true);

        const newOrganizationData = {
            ...organizationData,
            deletes: itemsToDelete
        }

        try {
            const response = await setOrganizationService(newOrganizationData);
            await getOrganization();
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_org_einheiten_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(JSON.stringify(err));
        }

        setIsLoading(false);
    }

    const resetOrganizationData = () => {
        setSelectedTenantIndex(null);
        setSelectedGroupIndex(null);
        setSelectedDepartmentIndex(null);
        setOrganizationData(organizationDataOriginal);
    }

    return <OrgEinheitenComponent
        selectedTenantIndex={selectedTenantIndex}
        setSelectedTenantIndex={setSelectedTenantIndex}
        selectedGroupIndex={selectedGroupIndex}
        setSelectedGroupIndex={setSelectedGroupIndex}
        selectedDepartmentIndex={selectedDepartmentIndex}
        setSelectedDepartmentIndex={setSelectedDepartmentIndex}
        organizationData={organizationData}
        setNewOrganizationItem={setNewOrganizationItem}
        submitOrganizationData={submitOrganizationData}
        resetOrganizationData={resetOrganizationData}
        deleteOrganizationItem={deleteOrganizationItem}
        isLoading={isLoading}
        isFetchingOrganizations={isFetchingOrganizations} />;
};

export default OrgEinheiten;
