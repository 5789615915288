import React, { useLayoutEffect, useState, useRef } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import Loading from 'components/common/Loading/Loading';
import './ExpertenmodusComponent.scss';

const ExpertenmodusComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="expertenmodus-wrapper">
                <div className="expertenmodus-content">

                </div>
            </div>
        </Layout>
    )
};

export default ExpertenmodusComponent;