import React from "react";
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const TableSortIcon = props => {
    const { columnName, sortColumn, sortOrder } = props;

    return (
        sortColumn !== columnName ?
            <FaSort /> :
            sortOrder === 'asc' ?
                <FaSortUp /> :
                <FaSortDown />
    )
}

export default TableSortIcon;