import React, { useState, useEffect } from "react";
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";

const QuestionnaireComponentLoading = ({ isAllowedAlias }) => {
    return (
        <div>
            {
                _.times(isAllowedAlias ? 2 : 1, (i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                        <Skeleton width={100} height={20} style={{ marginBottom: 5 }} />
                        <Skeleton width={'100%'} height={30} />
                    </div>
                ))
            }
        </div>
    )
}

export default QuestionnaireComponentLoading;