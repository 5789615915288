import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDirectoryRootService, getDirectoriesService } from 'services/scanning';
import FileTreeModalComponent from '../components/FileTreeModalComponent';
import _ from 'lodash';

const FileTreeModal = (props) => {
    const { isOpen, setIsOpen, selectFileCallback, settings } = props;
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingRoot, setIsFetchingRoot] = useState(false);

    const [currentPath, setCurrentPath] = useState([]);
    const [rootFolders, setRootFolders] = useState([]);
    const [currentChildren, setCurrentChildren] = useState([]);
    const [currentChildrenFiles, setCurrentChildrenFiles] = useState([]);

    const [selectedTreeIndex, setSelectedTreeIndex] = useState(null);
    const [allowedAccess, setAllowedAccess] = useState(true);
    const [allowedAccessMessage, setAllowedAccessMessage] = useState(null);

    useEffect(() => {
        getFoldersTreeData();
    }, []);

    const getFoldersTreeData = async () => {
        setIsFetchingRoot(true);
        setIsFetching(true);

        try {
            const responseRoot = await getDirectoryRootService();
            setRootFolders(responseRoot);
            setCurrentChildren(responseRoot);
            setCurrentPath([{ name: "root", fullName: "root" }]);
        } catch (err) {
            console.log(err);
        }

        setIsFetching(false);
        setIsFetchingRoot(false);
    }

    const getDirectories = async (directoryInfo, ignorePathSet) => {
        setIsFetching(true);

        try {
            setSelectedTreeIndex(null);
            const response = await getDirectoriesService(directoryInfo.fullName);

            setCurrentChildren(response.hasAccess ? response.childFolders : []);
            setCurrentChildrenFiles(response.hasAccess ? response.childFiles : []);

            if (!ignorePathSet) setCurrentPath([...currentPath, directoryInfo]);

            setAllowedAccess(response.hasAccess);
            setAllowedAccessMessage(response.errorMessage);
        } catch (err) {
            console.log(err);
        }

        setIsFetching(false);
    }

    const selectFolderInPath = index => {
        if (index === 0) {
            getFoldersTreeData();
            setCurrentChildrenFiles([]);
            setAllowedAccess(true);
        } else {
            const newPathItems = currentPath.slice(0, index + 1);
            setCurrentPath(newPathItems);
            getDirectories(_.last(newPathItems), true);
        }
    }

    return (
        <FileTreeModalComponent
            isFetchingRoot={isFetchingRoot}
            isFetching={isFetching}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            settings={settings}
            currentPath={currentPath}
            currentChildren={currentChildren}
            currentChildrenFiles={currentChildrenFiles}
            getDirectories={getDirectories}
            selectFolderInPath={selectFolderInPath}
            selectFileCallback={selectFileCallback}
            setSelectedTreeIndex={setSelectedTreeIndex}
            selectedTreeIndex={selectedTreeIndex}
            allowedAccess={allowedAccess}
            allowedAccessMessage={allowedAccessMessage}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(FileTreeModal);