import React, { useState, useEffect } from "react";
import QuestionnairesComponent from '../components/QuestionnairesComponent';

const Questionnaires = (props) => {
    return (
        <QuestionnairesComponent
            {...props}
        />
    )
};

export default Questionnaires;