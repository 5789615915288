import customFetch from './fetch';

export async function getDirectoriesScanService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/directory`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getDirectoryRootService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/folderbrowser/root`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getDirectoriesService(path) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ path })
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/folderbrowser/eval`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFilterFilesService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/filter-files`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getScanModesService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/scan-modes`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function myConfigurationsService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/my-configs`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getScanProgressService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/progress`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getConfigurationService(configId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/version/${configId}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setConfigScanService(configScanData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(configScanData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function setConfigScanNamedService(configScanData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(configScanData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/default`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function runConfigScanService(configScanId) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(configScanId)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configscan/run-config`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function getDbCacheConfigService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/files/cache`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteDbCacheConfigService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/files/cache`, "DELETE")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function exportScanByFileId(fileIds = []) {
    try {
        const body = JSON.stringify({
            exportFileId: fileIds
        });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/snapshots/export`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function stopScanService(runId) {
    try {
        const body = JSON.stringify({
        });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/progress/run/${runId}/pause`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function abortScanService(runId) {
    try {
        const body = JSON.stringify({
        });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/progress/run/${runId}/failure`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}