import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ModalLayout from 'layouts/modal-layout/modal-layout';
import Button from 'components/common/Button/Button';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import './UserListModalComponent.scss';

const UserListModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        isFetching,
        users,
        selectUserCallback,
        selectedUser,
        setSelectedUser,
        buttonLoading,
        title,
        description
    } = props;

    const { t } = useTranslation();

    return (
        <ModalLayout
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalCustomClass="user-list-modal"
            modalTitle={title || t('user_list_modal_title')}
            modalDescription={description || t('user_list_modal_description')}
        >
            <div className="user-list-modal-wrapper">
                <div className="table-wrapper">
                    {
                        isFetching ?
                            <TableSkeletonLoading
                                columns={4}
                                rows={10}
                            /> :
                            users.length ?
                                <table className="kpmg-table stripped">
                                    <thead>
                                        <tr>
                                            <th>{t('user_list_table_header_1')}</th>
                                            <th>{t('user_list_table_header_2')}</th>
                                            <th>{t('user_list_table_header_3')}</th>
                                            <th>{t('user_list_table_header_4')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map((user, i) => (
                                                <tr className={user.samAccountName === selectedUser?.samAccountName ? "selected" : ""} key={i} onClick={() => setSelectedUser(user)}>
                                                    <td>{user.samAccountName || "-"}</td>
                                                    <td>{user.displayName || "-"}</td>
                                                    <td>{user.emailAddress || "-"}</td>
                                                    <td>{user.department || "-"}</td>
                                                </tr>

                                            ))
                                        }
                                    </tbody>
                                </table> :
                                <p>{t('user_list_table_empty')}</p>
                    }


                    <div className="action-button">
                        <Button
                            style={{ width: 140 }}
                            loading={buttonLoading}
                            disabled={!selectedUser}
                            onClick={() => selectUserCallback(selectedUser)}>
                            {t('user_list_table_action')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
};

export default UserListModalComponent;