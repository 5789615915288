import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from 'components/common/Button/Button';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './ManageOrganizationModal.scss';

const ManageOrganizationModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        type = "",
        addNewItemCallback,
        organizationData,
        selectedTenantIndex,
        selectedGroupIndex,
        settings
    } = props;
    const [organizationName, setOrganizationName] = useState('');
    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    useEffect(() => {
        setOrganizationName('');
        formik.resetForm();
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            organizationName
        },
        validationSchema: Yup.object().shape({
            organizationName: Yup.string()
                .required(t('formik_required_field'))
                .test(
                    'myCustomTest',
                    null,
                    (thisField) => {
                        let message = '';

                        if (type === 'tenant') {
                            const allTenants = organizationData.organization.map(tenant => tenant?.name?.toLowerCase());

                            if (!allTenants.includes(thisField.toLowerCase())) {
                                return true;
                            } else {
                                message = t('settings_tab3_popup_tenant_error_duplicated');
                            }
                        }

                        if (type === 'group') {
                            const allGroups = organizationData.organization[selectedTenantIndex].groups.map(group => group?.name?.toLowerCase());

                            if (!allGroups.includes(thisField.toLowerCase())) {
                                return true;
                            } else {
                                message = t('settings_tab3_popup_group_error_duplicated');
                            }
                        }

                        if (type === 'department') {
                            const allDepartments = organizationData.organization[selectedTenantIndex].groups[selectedGroupIndex].departments.map(department => {
                                return department?.name?.toLowerCase()
                            });

                            if (!allDepartments.includes(thisField.toLowerCase())) {
                                return true;
                            } else {
                                message = t('settings_tab3_popup_department_error_duplicated');
                            }
                        }

                        return new Yup.ValidationError(
                            message,
                            null,
                            'organizationName'
                        );
                    }
                )
        }),
        enableReinitialize: true,
        onSubmit: values => {
            addNewItemCallback(values.organizationName);
            setIsOpen(false);
        },
    });

    const { errors, touched } = formik;

    let title = '';
    let subtitle = '';
    let inputLabel = '';
    let inputPlaceholder = '';

    if (type === 'tenant') {
        title = t('settings_tab3_popup_tentant_layout_chain_text1');
        subtitle = t('settings_tab3_popup_tentant_layout_chain_text2');
        inputLabel = t('settings_tab3_popup_tentant_layout_chain_text3');
        inputPlaceholder = t('settings_tab3_popup_tentant_layout_chain_text4');
    }

    if (type === 'group') {
        title = t('settings_tab3_popup_group_layout_chain_text1');
        subtitle = t('settings_tab3_popup_group_layout_chain_text2');
        inputLabel = t('settings_tab3_popup_group_layout_chain_text3');
        inputPlaceholder = t('settings_tab3_popup_group_layout_chain_text4');
    }

    if (type === 'department') {
        title = t('settings_tab3_popup_department_layout_chain_text1');
        subtitle = t('settings_tab3_popup_department_layout_chain_text2');
        inputLabel = t('settings_tab3_popup_department_layout_chain_text3');
        inputPlaceholder = t('settings_tab3_popup_department_layout_chain_text4');
    }

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal manage-organization-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{title}</h3>
                <p style={{ color: mainColor }}>{subtitle}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-wrapper">
                        <label htmlFor="organizationName">{inputLabel}</label>
                        <input
                            className={errors.organizationName && touched.organizationName ? 'error' : ''}
                            id="organizationName"
                            name="organizationName"
                            type="text"
                            placeholder={inputPlaceholder}
                            value={formik.values.organizationName || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {
                            errors.organizationName && touched.organizationName &&
                            <div className="error-message">
                                {errors.organizationName}
                            </div>
                        }
                    </div>

                    <div className="action-buttons">
                        <Button onClick={() => formik.resetForm()}>{t('settings_tab3_popup_button1')}</Button>
                        <Button type="submit">{t('settings_tab3_popup_button2')}</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(ManageOrganizationModal);