const parseResponse = async (response) => {
    let fetchResponse = "";
    const headersContentType = response?.headers?.get("Content-Type");

    if (headersContentType?.includes("application/json")) {
        try {
            fetchResponse = await response.json();
        } catch (err) {
            fetchResponse = response;
        }
    } else {
        try {
            fetchResponse = await response.text();
        } catch (err) {
            fetchResponse = response;
        }
    }

    return fetchResponse;
}

export default async function customFetch(url, method = "GET", bodyReq = null, fileUpload = false) {
    if (!url) return { error: "Invalid url" };

    try {
        let request = {
            headers: {},
            method,
            credentials: 'include',
        };

        if (!fileUpload) request.headers["Content-Type"] = "application/json";

        if (method === 'POST' || method === 'PUT') request.body = bodyReq;

        const response = await fetch(url, request);


        if (response.ok) {
            const fetchResponse = await parseResponse(response);
            return fetchResponse;
        } else {
            const responseError = await parseResponse(response);
            throw responseError;
        }
    } catch (err) {
        throw err;
    }
}