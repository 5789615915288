import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "redux/actions/settingsActions";
import InventoryQuestionsManagementComponent from '../components/InventoryQuestionsManagementComponent';
import { getInventoryQuestionsService, updateInventoryQuestionsService } from 'services/inventory';
import { setConfigValidationService } from 'services/settings';
import { isOrderHigher } from 'services/helper';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { setQuestionAsLast } from 'services/inventoryQuestionsHelper';

const riskQuestion = {
    behaviour: "show",
    fieldSize: null,
    finished: [],
    infoText: {
        en: "Info Protection requirement classification (business owner)",
        de: "Info Schutzbedarf Einstufung (Business Owner)"
    },
    options: [
        {
            value: {
                en: "1 - low",
                de: "1 - gering"
            },
            order: "1.1"
        },
        {
            value: {
                en: "2 - mittel",
                de: "2 - medium"
            },
            order: "1.2"
        },
        {
            value: {
                en: "3 - hoch",
                de: "3 - high"
            },
            order: "1.3"
        },
        {
            value: {
                en: "4 - kritisch",
                de: "4 - critical"
            },
            order: "1.4"
        },
    ],
    order: "1",
    parentOrder: null,
    required: true,
    requirements: [],
    text: { en: "Protection needs - Classification (Business Officer)", de: "Schutzbedarf - Einstufung (Business Owner)" },
    type: "select",
    identifier: "protection-requirement"
}

const technologyQuestion = {
    behaviour: "show",
    fieldSize: null,
    finished: [],
    infoText: {
        en: "Info Technology",
        de: "Info Technologie(n)"
    },
    options: [
        {
            value: {
                en: "MS Excel",
                de: "MS Excel"
            },
            order: "2.1"
        },
        {
            value: {
                en: "MS Access",
                de: "MS Access"
            },
            order: "2.2"
        },
        {
            value: {
                en: "Other",
                de: "Other"
            },
            order: "2.3"
        }
    ],
    order: "2",
    parentOrder: null,
    required: true,
    requirements: [],
    text: { en: "Technology", de: "Technologie(n)" },
    type: "checkbox",
    identifier: "technology"
}

const InventoryQuestionsManagement = (props) => {
    const { settings, updateSettings } = props;

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [requestSending, setRequestSending] = useState(false);
    const [questions, setQuestions] = useState([]);

    const [allowedAutomaticValidations, setAllowedAutomaticValidations] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        getInventoryQuestions();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    useEffect(() => {
        setAllowedAutomaticValidations(settings?.validationConfig?.inventoryAutoClose === 1);
    }, [settings]);

    const setConfigValidation = async () => {
        const newValidationConfig = {
            ...settings.validationConfig,
            inventoryAutoClose: allowedAutomaticValidations ? 1 : 0,
        };

        try {
            await setConfigValidationService(newValidationConfig);
            updateSettings({
                ...settings,
                validationConfig: newValidationConfig
            })
        } catch (err) {
            setError(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const getInventoryQuestions = async () => {
        try {
            let response = await getInventoryQuestionsService();

            if (response.length) {
                const firstSectionOrderIndex = response.findIndex(section => section.order === 1);
                const sectionQuestions = response[firstSectionOrderIndex].questions;

                const includesRiskQuestion = sectionQuestions.filter(question => question.identifier === "protection-requirement")[0];
                if (!includesRiskQuestion) {
                    response[firstSectionOrderIndex].questions = [
                        ...sectionQuestions,
                        setQuestionAsLast(riskQuestion, sectionQuestions)
                    ]
                }

                const includesTecnologyRiskQuestion = sectionQuestions.filter(question => question.identifier === "technology")[0];
                if (!includesTecnologyRiskQuestion) {
                    response[firstSectionOrderIndex].questions = [
                        ...sectionQuestions,
                        setQuestionAsLast(technologyQuestion, sectionQuestions)
                    ]
                }

                setQuestions(response);
            } else {
                setQuestions([
                    {
                        order: 1,
                        sectionName: "EUC Information",
                        questions: [riskQuestion]
                    },
                    {
                        order: 2,
                        sectionName: "Risk evaluation",
                        questions: []
                    }
                ]);
            }
        } catch (err) {
            setError(`Error ${err.status}: ${err.statusText}`);
        }

        setIsLoading(false);
    }

    const submitQuestions = async (allQuestions) => {
        setRequestSending(true);
        try {
            allQuestions.forEach((questionGroup) => {
                if (questionGroup?.sectionName === "Risk evaluation" && questionGroup?.questions?.length <= 0) throw new Error(t("toastr_inventory_questions_missing"));
            })
            const sectionsWithQuestionsSorted = allQuestions.map(section => {
                const questionsSorted = section.questions.sort((x, y) => isOrderHigher(y.order, x.order));
                section.questions = questionsSorted;
                return section;
            });

            await setConfigValidation();
            await updateInventoryQuestionsService(sectionsWithQuestionsSorted);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_inventory_questions_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={err.message} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }

        setRequestSending(false);
    }

    return <InventoryQuestionsManagementComponent
        error={error}
        isLoading={isLoading}
        questions={questions}
        submitQuestions={submitQuestions}
        requestSending={requestSending}
        allowedAutomaticValidations={allowedAutomaticValidations}
        setAllowedAutomaticValidations={setAllowedAutomaticValidations}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryQuestionsManagement);