import React, { useEffect, useState, useRef } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import AddItem from 'components/common/AddItem/AddItem';
import Button from 'components/common/Button/Button';
import ManageOrganizationModal from 'components/modals/ManageOrganizationModal/ManageOrganizationModal';
import { AiOutlineClose } from 'react-icons/ai';
import { FaSquareFull } from 'react-icons/fa';
import _ from 'lodash';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import './OrgEinheitenComponent.scss';

const OrgEinheitenComponent = (props) => {
    const {
        organizationData,
        resetOrganizationData,
        setNewOrganizationItem,
        submitOrganizationData,
        selectedTenantIndex,
        setSelectedTenantIndex,
        selectedGroupIndex,
        setSelectedGroupIndex,
        selectedDepartmentIndex,
        setSelectedDepartmentIndex,
        deleteOrganizationItem,
        isLoading,
        isFetchingOrganizations
    } = props;
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);

    useEffect(() => {
        if (organizationData?.organization?.length && selectedTenantIndex === null) {
            setSelectedTenantIndex(0);
            if (organizationData?.organization[0]?.groups?.length) setSelectedGroupIndex(0);
        }
    }, [organizationData])

    const changeTenantIndex = (tenantIndex) => {
        setSelectedTenantIndex(tenantIndex);

        if (organizationData.organization[tenantIndex].groups.length) {
            setSelectedGroupIndex(0);
        } else {
            setSelectedGroupIndex(null);
        }
    }

    const changeGroupIndex = (groupIndex) => {
        setSelectedGroupIndex(groupIndex);
    }

    const addNewItemModal = type => {
        setModalType(type);
        setIsModalOpen(true);
    }

    const addNewItemCallback = organizationName => {
        setNewOrganizationItem(modalType, organizationName, { selectedTenantIndex, selectedGroupIndex, selectedDepartmentIndex });
    }

    return (
        <Layout>
            <div className="org-einheiten-wrapper">
                <div className="org-einheiten-content">
                    <div className="section-content">
                        <div className="group">
                            <div className="group-title">{t('settings_tab3_layout_chain_text_1')}</div>
                            <div className="group-content">
                                {
                                    isFetchingOrganizations ?
                                        <SkeletonTheme color="#dadada" highlightColor="#cacaca">
                                            <Skeleton width={250} height={15} count={3} style={{ marginBottom: 15 }} />
                                        </SkeletonTheme> :
                                        organizationData.organization?.length ?
                                            _.map(organizationData.organization, (tenant, i) => (
                                                <div className={i === selectedTenantIndex ? "group-item active" : "group-item"} key={i} onClick={() => changeTenantIndex(i)}>
                                                    <div className="icon"><FaSquareFull /></div>
                                                    <div className="name">{tenant.name}</div>
                                                    <div className="remove-item" onClick={(e) => deleteOrganizationItem(e, { tenantIndex: i }, 'tenant')}>
                                                        <AiOutlineClose />
                                                    </div>
                                                </div>
                                            )) :
                                            <span>{t('settings_tab3_no_element_chain_text')}</span>
                                }
                            </div>

                            <AddItem
                                style={{ marginLeft: 10, marginTop: 10 }}
                                text={t('settings_tab3_layout_chain_text_4')}
                                onClick={() => addNewItemModal("tenant")} />
                        </div>
                        <div className="group">
                            <div className="group-title">{t('settings_tab3_layout_chain_text_2')}</div>
                            <div className="group-content">
                                {
                                    isFetchingOrganizations ?
                                        <SkeletonTheme color="#dadada" highlightColor="#cacaca">
                                            <Skeleton width={250} height={15} count={3} style={{ marginBottom: 15 }} />
                                        </SkeletonTheme> :
                                        selectedTenantIndex !== null && organizationData.organization[selectedTenantIndex]?.groups?.length ?
                                            _.map(organizationData.organization[selectedTenantIndex].groups, (group, i) => (
                                                <div className={i === selectedGroupIndex ? "group-item active" : "group-item"} onClick={() => changeGroupIndex(i)} key={i}>
                                                    <div className="icon"><FaSquareFull /></div>
                                                    <div className="name">{group.name}</div>
                                                    <div className="remove-item" onClick={(e) => deleteOrganizationItem(e, { tenantIndex: selectedTenantIndex, groupIndex: i }, 'group')}>
                                                        <AiOutlineClose />
                                                    </div>
                                                </div>
                                            )) :
                                            <span>{t('settings_tab3_no_element_chain_text')}</span>
                                }
                            </div>
                            <AddItem
                                disabled={selectedTenantIndex === null}
                                style={{ marginLeft: 10, marginTop: 10 }}
                                text={t('settings_tab3_layout_chain_text_5')}
                                onClick={() => addNewItemModal("group")} />
                        </div>
                        <div className="group">
                            <div className="group-title">{t('settings_tab3_layout_chain_text_3')}</div>
                            <div className="group-content">
                                {
                                    isFetchingOrganizations ?
                                        <SkeletonTheme color="#dadada" highlightColor="#cacaca">
                                            <Skeleton width={250} height={15} count={3} style={{ marginBottom: 15 }} />
                                        </SkeletonTheme> :
                                        selectedTenantIndex !== null && selectedGroupIndex !== null && organizationData?.organization[selectedTenantIndex]?.groups[selectedGroupIndex]?.departments?.length ?
                                            _.map(organizationData?.organization[selectedTenantIndex]?.groups[selectedGroupIndex]?.departments, (department, i) => (
                                                <div className="group-item" key={i}>
                                                    <div className="icon"><FaSquareFull /></div>
                                                    <div className="name">{department.name}</div>
                                                    <div className="remove-item" onClick={(e) => deleteOrganizationItem(e, { tenantIndex: selectedTenantIndex, groupIndex: selectedGroupIndex, departmentIndex: i }, 'department')}>
                                                        <AiOutlineClose />
                                                    </div>
                                                </div>
                                            )) :
                                            <span>{t('settings_tab3_no_element_chain_text')}</span>
                                }
                            </div>

                            <AddItem
                                disabled={selectedGroupIndex === null}
                                style={{ marginLeft: 10, marginTop: 10 }}
                                text={t('settings_tab3_layout_chain_text_6')}
                                onClick={() => addNewItemModal("department")} />
                        </div>
                    </div>

                    <div className="actions flex center">
                        <div>{t('settings_tab3_layout_chain_text_7')}:</div>
                        <Button onClick={() => resetOrganizationData()}>{t('settings_tab3_button1_title')}</Button>
                        <Button loading={isLoading} onClick={() => submitOrganizationData()}>{t('settings_tab3_button2_title')}</Button>
                    </div>
                </div>
            </div>

            <ManageOrganizationModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                type={modalType}
                addNewItemCallback={addNewItemCallback}
                organizationData={organizationData}
                selectedTenantIndex={selectedTenantIndex}
                selectedGroupIndex={selectedGroupIndex}
            />
        </Layout>
    )
};

export default OrgEinheitenComponent;