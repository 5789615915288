import customFetch from './fetch';

export async function getBlacklistService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/blacklist`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function addBlacklistService({ fullName }) {
    try {
        const body = JSON.stringify({ fullName });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/blacklist`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteBlacklistService({ blacklistId }) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/blacklist/${blacklistId}`, "DELETE");
        return response;
    } catch (err) {
        throw err;
    }
}
