import customFetch from './fetch';

export async function getMetadataService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function setMetadataService(data) {
    try {
        const body = JSON.stringify(data);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteMetadataService(metadataId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata/${metadataId}`, "DELETE");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getMetadataKeywordsService(metadataId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata/${metadataId}/keywords`, "GET");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getMetadataFieldsOptionsService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata/fields`, "GET");
        return response;
    } catch (err) {
        throw err;
    }
}

export async function setMetadataKeywordsService(keywords) {
    try {
        const body = JSON.stringify(keywords);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata/keywords`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function deleteMetadataKeywordService(keywordId) {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/metadata/keyword/${keywordId}`, "DELETE")
        return response;
    } catch (err) {
        throw err;
    }
}