import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ManageKeywordListModal from 'components/modals/ManageKeywordListModal/containers/ManageKeywordListModal';
import EucKeywordsModal from 'components/modals/EucKeywordsModal/containers/EucKeywordsModal';
import InfoTextModal from 'components/modals/InfoTextModal/InfoTextModal';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import { BsPencilSquare } from 'react-icons/bs';
import Button from 'components/common/Button/Button';
import AddItem from 'components/common/AddItem/AddItem';
import ClipLoader from "react-spinners/ClipLoader";
import { BiTrash } from 'react-icons/bi';
import Input from 'components/common/Input/Input';
import { FiDownload, FiUpload } from 'react-icons/fi';
import InfoComponent from "components/common/InfoComponent/InfoComponent";

const KeywordsComponent = (props) => {
    const {
        isFetchingTopics,
        isSubmitting,
        editTopic,
        submitTopics,
        discardTopicsChanges,
        deletingTopicIds,
        deleteTopic,
        allTopics,
        getTopics,
        uploadExcel,
        exportTemplate,
        exportKeywords
    } = props;

    const [infoTextModalOpen, setInfoTextModalOpen] = useState(false);
    const [keywordsModalOpen, setKeywordsModalOpen] = useState(false);
    const [topicModalOpen, setTopicModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { t, i18n } = useTranslation();
    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    const openInfoTextModal = (item) => {
        setSelectedItem(item);
        setInfoTextModalOpen(true);
    }

    const openKeywordModal = (item) => {
        setSelectedItem(item);
        setKeywordsModalOpen(true);
    }

    const submitInfoCallback = ({ infoTextDe, infoTextEn }) => {
        const infoText = {
            en: infoTextEn,
            de: infoTextDe,
        };

        const topicId = selectedItem.topicId;
        const selectedItemIndex = allTopics.findIndex(topic => topic.topicId === topicId);
        editTopic(selectedItemIndex, 'infoText', infoText);
    }

    return (
        <>
            {
                isFetchingTopics || !translationsLoaded ?
                    <TableSkeletonLoading
                        rows={15}
                        columns={8}
                        style={{ marginTop: 20 }}
                    /> :
                    <>
                        <table className="kpmg-table stripped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_5_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_6_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_6_b')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_7_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_8_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_13_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap', minWidth: 75 }}>{t('settings_tab4_layout_chain_text_9_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap', minWidth: 75 }}>{t('settings_tab4_layout_chain_text_10_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap', minWidth: 75 }}>{t('settings_tab4_layout_chain_text_11_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_12_a')}</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="empty">
                                    <td colSpan={2}></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={6}>
                                        <div className="csv-actions">
                                            <Input type="file" name="upload-csv" onChange={uploadExcel} />
                                            <label htmlFor="upload-csv" className="download-csv" onClick={() => { }}>
                                                <FiUpload style={{ fontSize: 18 }} />
                                                <span>{t('settings_tab4_import')}</span>
                                            </label>

                                            <div className="download-csv" onClick={() => exportTemplate()}>
                                                <FiDownload style={{ fontSize: 18 }} />
                                                <span>{t('settings_tab4_export_template')}</span>
                                            </div>

                                            <div className="download-csv" onClick={() => exportKeywords()}>
                                                <FiDownload style={{ fontSize: 18 }} />
                                                <span>{t('settings_tab4_export')}</span>
                                            </div>

                                            <div className="download-csv">
                                                <InfoComponent tooltipValue={t("settings_tab4_import_help")} style={{ marginLeft: 0 }} />
                                                {/* <img alt="help" src={require('assets/icons/fe_question.svg').default} style={{ width: 18, objectFit: 'contain' }} />
                                                <span>Help</span> */}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    allTopics.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ textAlign: 'left', wordBreak: 'unset' }}>{item.name}</td>
                                                <td className='cursor-pointer number-row' onClick={() => openInfoTextModal(item)}>
                                                    <BsPencilSquare style={{ fontSize: 14 }} />
                                                </td>
                                                <td className='cursor-pointer number-row' onClick={() => openKeywordModal(item)}>
                                                    <BsPencilSquare style={{ fontSize: 14 }} />
                                                </td>
                                                <td className="input-cell">
                                                    <input type="number" value={item.threshold} onChange={(e) => editTopic(i, 'threshold', e.target.value)} />
                                                </td>
                                                <td className="input-cell">
                                                    <input type="number" value={item.weight} onChange={(e) => editTopic(i, 'weight', e.target.value)} />
                                                </td>
                                                <td className="input-cell number-row">
                                                    <select
                                                        value={item.valueType}
                                                        onChange={(e) => editTopic(i, 'valueType', e.target.value)}>
                                                        <option value="Relative">{t('settings_tab4_indicator_type_file_relative')}</option>
                                                        <option value="Absolute">{t('settings_tab4_indicator_type_file_absolute')}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editTopic(i, 'kpiType', 'Complexity')}>
                                                        {item.kpiType === "Complexity" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editTopic(i, 'kpiType', 'Criticality')}>
                                                        {item.kpiType === "Criticality" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="radio-button cursor-pointer" onClick={() => editTopic(i, 'kpiType', 'NonAssigned')}>
                                                        {item.kpiType === "NonAssigned" && <div className="fill"></div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-action" onClick={() => !deletingTopicIds.includes(item.topicId) && deleteTopic(item.topicId)}>
                                                        {
                                                            deletingTopicIds.includes(item.topicId) ?
                                                                <ClipLoader
                                                                    size={14}
                                                                    color={"#525662"}
                                                                    loading={true}
                                                                /> :
                                                                <BiTrash />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="empty">
                                    <td></td>
                                    <td colSpan={8}>
                                        <AddItem text={t('settings_tab4_layout_chain_text_13_b')}
                                            onClick={() => setTopicModalOpen(true)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="actions">
                            <div>{t('settings_tab4_button_layout_chain_text_a')}:</div>
                            <Button onClick={() => discardTopicsChanges()}>
                                {t('settings_tab4_button1_title_a')}
                            </Button>
                            <Button loading={isSubmitting} onClick={submitTopics}>
                                {t('settings_tab4_button2_title_a')}
                            </Button>
                        </div>
                    </>
            }

            <ManageKeywordListModal
                isOpen={topicModalOpen}
                setIsOpen={setTopicModalOpen}
                getKeywordList={getTopics}
            />

            <EucKeywordsModal
                isOpen={keywordsModalOpen}
                setIsOpen={setKeywordsModalOpen}
                keywordListItem={selectedItem}
                keywordListItemId={selectedItem.topicId}
            />

            <InfoTextModal
                isOpen={infoTextModalOpen}
                setIsOpen={setInfoTextModalOpen}
                item={selectedItem}
                submitInfoCallback={submitInfoCallback}
            />
        </>
    )
};

export default KeywordsComponent;