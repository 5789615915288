import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AddBlacklistModalComponent from '../components/AddBlacklistModalComponent';
import { addBlacklistService } from 'services/blacklist';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const AddBlacklistModal = (props) => {
    const { setIsOpen, getKeywordList } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const submitKeywordList = async ({ name }) => {
        setIsLoading(true);

        try {
            await addBlacklistService({ fullName: name })

            await getKeywordList();
            setIsOpen(false);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_blacklist_created')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while creating blacklist: ${err}`);
        }

        setIsLoading(false);
    }

    return (
        <AddBlacklistModalComponent
            {...props}
            submitKeywordList={submitKeywordList}
            isLoading={isLoading}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(AddBlacklistModal);