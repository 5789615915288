import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import WizardComponent from '../components/WizardComponent';
import { setConfigScanService, setConfigScanNamedService, runConfigScanService, getConfigurationService } from 'services/scanning';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions';
import _ from 'lodash';

const Wizard = (props) => {
    const { history, settings, id, allConfigurations, readOnly } = props;

    const [currentStep, setCurrentStep] = useState(1);
    const [wizardData, setWizardData] = useState({
        indicatorConfig: [],
        topicConfig: [],
        metadataConfig: [],
        ouConfig: [],
        filterSettingConfig: {
            fileExtensionsFilter: [],
            fileSizeFilter: "",
            fileLastModifiedDateFilter: ""
        },
        scanModeConfig: []
    });
    const [oldWizardData, setOldWizardData] = useState({});
    const [sendingWizardData, setSendingWizardData] = useState(false);
    const [sendingRunScan, setSendingRunScan] = useState(false);
    const [wizardResponse, setWizardResponse] = useState(null);
    const [filterFiles, setFilterFiles] = useState([]); // Response from step 3 used to check if user changed file extensions at step three
    const [wizardConfigDataChanged, setWizardConfigDataChanged] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            getConfiguration(id);
        }
    }, [id]);

    const getConfiguration = async (id) => {
        try {
            const response = await getConfigurationService(id);
            const formattedData = _.cloneDeep(response);
            formattedData.topicConfig = response.topicConfig.map((item) => ({ ...item, type: "topic-item" }));
            formattedData.indicatorConfig = response.indicatorConfig;
            formattedData.metadataConfig = response.metadataConfig.map((item) => ({ ...item, type: "metadata-item" }));
            setWizardData(formattedData);
            setOldWizardData(formattedData);
            setCurrentStep(2);
        } catch (err) {
            console.log("Error get configuration:", err);
        }
    }

    const submitStepData = (data, stepNumber) => {
        let newWizardData = _.cloneDeep(wizardData);

        if (stepNumber === 2) {
            const stepData = data.map(fileTreeItem => {
                const paths = fileTreeItem.files.map(file => file.path);

                return {
                    orgDepartmentId: fileTreeItem.departmentId,
                    directoryPath: paths
                }
            });

            newWizardData.ouConfig = stepData;
            setWizardData(newWizardData);
        }

        if (stepNumber === 3) {
            newWizardData.scanModeConfig = data.scanModeConfig;
            newWizardData.filterSettingConfig = data.filterSettingConfig;
            setFilterFiles(data.filterFiles);
            setWizardData(newWizardData);
        }

        if (stepNumber === 4 || stepNumber === 5) {
            const getDataByType = (dataType, idKey) => {
                return _.keys(data)
                    .filter(rowIdentifier => rowIdentifier.includes(dataType))
                    .map(rowIdentifier => {
                        const rowIndentifierId = rowIdentifier.split("-")[0];

                        return {
                            [idKey]: parseInt(rowIndentifierId),
                            weight: parseInt(data[rowIdentifier].weight),
                            threshold: parseInt(data[rowIdentifier].threshold),
                            kpiType: data[rowIdentifier].kpiType,
                            valueType: data[rowIdentifier].valueType
                        }
                    });
            }

            const newIndicators = getDataByType("indicator", "indicatorId");
            const newTopics = getDataByType("topic", "topicId");
            const newMetadata = getDataByType("metadata", "metadataId");

            const getChangedDataByType = (idKey, configName, newDataRows) => {
                const changedIds = newDataRows.map(row => row[idKey]);
                const unChangedRows = newWizardData[configName].filter(indicator => !changedIds.includes(indicator[idKey]));

                return [
                    ...unChangedRows,
                    ...newDataRows
                ];
            }

            newWizardData.indicatorConfig = getChangedDataByType("indicatorId", "indicatorConfig", newIndicators);
            newWizardData.topicConfig = getChangedDataByType("topicId", "topicConfig", newTopics);
            newWizardData.metadataConfig = getChangedDataByType("metadataId", "metadataConfig", newMetadata);

            if (stepNumber === 5 && id) {
                checkIfWizardDataChanged(newWizardData, oldWizardData);
            }

            setWizardData(newWizardData);
        }

        if (stepNumber === 6) {
            submitWizardData(data);
        }
    }

    const submitWizardData = async (data) => {
        setSendingWizardData(true);

        let finalWizardData = {};
        let response;

        const saveNewConfiguration = async () => {
            finalWizardData = {
                configName: data.name,
                config: wizardData
            }

            response = await setConfigScanNamedService(finalWizardData);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_configuration_saved')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        }

        try {
            if (data.name) {
                const configWithSameName = allConfigurations.filter(config => config.configName === data.name)[0];

                if (configWithSameName) {
                    if (await confirm({
                        title: t('config_overwrite_confirm_title'),
                        confirmation: t('config_overwrite_confirm_message')
                    })) {
                        await saveNewConfiguration();
                    }
                } else {
                    await saveNewConfiguration();
                }
            } else {
                finalWizardData = wizardData;

                response = await setConfigScanService(finalWizardData);

                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_scan_scheduled')} />, {
                    progressClassName: "toastr-progress-bar success"
                });

                history.push("/scanning/konfigurationen")
            }

            if (response) setWizardResponse(response);
        } catch (err) {
            console.log("Error sending scanning data:", err);
        }

        setSendingWizardData(false);
    }

    const runScan = async () => {
        setSendingRunScan(true);

        let configId;

        if (wizardResponse) {
            configId = wizardResponse.configRunId;
        } else {
            if (id) configId = id;
        }

        try {
            await runConfigScanService({ ConfigRunId: parseInt(configId) });

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_wizard_scan_configured')} />, {
                progressClassName: "toastr-progress-bar success"
            });

            history.push("/scanning/konfigurationen");
        } catch (err) {
            console.log("Error run scan:", err);
        }

        setSendingRunScan(false);
    }

    const selectConfiguration = (configId) => {
        if (configId === "empty") {
            history.push(`/scanning/start-scan`);

            setWizardData({
                indicatorConfig: [],
                topicConfig: [],
                metadataConfig: [],
                ouConfig: [],
                filterSettingConfig: {
                    fileExtensionsFilter: [],
                    fileSizeFilter: "",
                    fileLastModifiedDateFilter: ""
                },
                scanModeConfig: []
            });

            setCurrentStep(2);
        } else {
            history.push(`/scanning/start-scan/${configId}`)
        }
    }

    const checkIfWizardDataChanged = (newData, oldData) => {
        let hasChanged = false;

        //todo improve logic
        oldData.indicatorConfig.forEach(item => {
            const newDataItem = newData.indicatorConfig.filter(newDataItem => newDataItem.indicatorId === item.indicatorId)[0];
            if (item.threshold !== newDataItem?.threshold || item.weight !== newDataItem?.weight) hasChanged = true;
        });

        oldData.topicConfig.forEach(item => {
            const newDataItem = newData.topicConfig.filter(newDataItem => newDataItem.topicId === item.topicId)[0];
            if (item.threshold !== newDataItem?.threshold || item.weight !== newDataItem?.weight) hasChanged = true;
        });

        oldData.metadataConfig.forEach(item => {
            const newDataItem = newData.metadataConfig.filter(newDataItem => newDataItem.metadataId === item.metadataId)[0];
            if (item.threshold !== newDataItem?.threshold || item.weight !== newDataItem?.weight) hasChanged = true;
        });

        oldData.ouConfig.forEach(item => {
            const newDataItem = newData.ouConfig.filter(newDataItem => newDataItem.orgDepartmentId === item.orgDepartmentId)[0];
            const difference = _.xor(item.directoryPath, newDataItem?.directoryPath);
            if (difference.length) hasChanged = true;
        });

        const oldFileExtensions = oldData.filterSettingConfig.fileExtensionsFilter;

        const oldSelectedFileTypesIds = filterFiles.filter(fileType => {
            return oldFileExtensions.includes(fileType.extensions);
        }).map(fileType => fileType.filterSettingFileId);

        const fileExtensionsFilterDiff = _.xor(newData.filterSettingConfig.fileExtensionsFilter, oldSelectedFileTypesIds);

        if (fileExtensionsFilterDiff.length) hasChanged = true;

        if (newData.filterSettingConfig.fileLastModifiedDateFilter !== oldData.filterSettingConfig.fileLastModifiedDateFilter) hasChanged = true;
        if (newData.filterSettingConfig.fileSizeFilter !== oldData.filterSettingConfig.fileSizeFilter) hasChanged = true;

        const difference = _.xor(newData.scanModeConfig, oldData.scanModeConfig);
        if (difference.length) hasChanged = true;

        setWizardConfigDataChanged(hasChanged);
    }

    return (
        <WizardComponent
            settings={settings}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            wizardData={wizardData}
            submitStepData={submitStepData}
            wizardResponse={wizardResponse}
            sendingWizardData={sendingWizardData}
            sendingRunScan={sendingRunScan}
            runScan={runScan}
            selectConfiguration={selectConfiguration}
            readOnly={readOnly}
            wizardConfigDataChanged={wizardConfigDataChanged}
        />
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default withRouter(connect(mapStateToProps)(Wizard));