import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, Field } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import './EmailSettings.scss';

const SMTP = (props) => {
    const { t } = useTranslation();
    const {
        submitSMTP,
        loading,
        smtpConfig
    } = props;

    const discardChanges = (formik) => {
        _.keys(smtpConfig).map((key) => formik.setFieldValue(key, smtpConfig[key]))
    }

    return (
        <div className="email-settings-content">
            <Formik
                enableReinitialize
                initialValues={{
                    host: smtpConfig.host || "",
                    port: smtpConfig.port || 0,
                    emailFrom: smtpConfig.emailFrom || "",
                    password: "",
                }}
                validationSchema={Yup.object({
                    host: Yup.string().required(t("settings_tab6_tab1_input1_mandatory")),
                    port: Yup.number().required(t("settings_tab6_tab1_input1_mandatory")),
                    emailFrom: Yup.string().email(t("settings_tab6_tab1_input3_invalid")).required(t("settings_tab6_tab1_input1_mandatory")),
                    password: Yup.string().required(t("settings_tab6_tab1_input1_mandatory")),
                })}
                onSubmit={(values) => submitSMTP(values)}>

                {(formikProps) => {
                    return (
                        <div className="form-wrapper">
                            <div className='center-form'>
                                <div className='limit-width'>
                                    <div className="email-input">
                                        <div className="input-content">
                                            <div className="input-title">
                                                <strong>1. </strong>
                                                <span>{t('settings_tab6_tab1_input1')}</span>
                                            </div>
                                            <div className="input-body">
                                                <div className="flex">
                                                    <Field name="host" />
                                                </div>
                                            </div>
                                            <div className="error-message">
                                                <ErrorMessage name="host" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="email-input">
                                        <div className="input-content">
                                            <div className="input-title">
                                                <strong>2. </strong>
                                                <span>{t('settings_tab6_tab1_input2')}</span>
                                            </div>
                                            <div className="input-body">
                                                <div className="flex">
                                                    <Field name="port" type="number" />
                                                </div>
                                            </div>
                                            <div className="error-message">
                                                <ErrorMessage name="port" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="email-input">
                                        <div className="input-content">
                                            <div className="input-title">
                                                <strong>3. </strong>
                                                <span>{t('settings_tab6_tab1_input3')}</span>
                                            </div>
                                            <div className="input-body">
                                                <div className="flex">
                                                    <Field name="emailFrom" />
                                                </div>
                                            </div>
                                            <div className="error-message">
                                                <ErrorMessage name="emailFrom" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="email-input">
                                        <div className="input-content">
                                            <div className="input-title">
                                                <strong>4. </strong>
                                                <span>{t('settings_tab6_tab1_input4')}</span>
                                            </div>
                                            <div className="input-body">
                                                <div className="flex">
                                                    <Field name="password" type="password" placeholder={t('settings_tab6_tab1_input4_placeholder')} />
                                                </div>
                                            </div>
                                            <div className="error-message">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="actions ">
                                <div>{t('settings_tab1_layout_chain_text_7')}:</div>
                                <Button onClick={() => discardChanges(formikProps)}>{t('settings_tab1_button3_title')}</Button>
                                <Button
                                    loading={loading}
                                    onClick={formikProps.handleSubmit}>
                                    {t('settings_tab1_button4_title')}
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </Formik>

        </div>

    )
}
export default SMTP;