import { UPDATE_DASHBOARD, UPDATE_DASHBOARD_LIST_FIELD, UPDATE_DASHBOARD_CHART_FIELD } from "../types/dashboardTypes";

export const updateDashboard = value => ({
    type: UPDATE_DASHBOARD,
    dashboard: value
});

export const updateDashboardListField = (field, value) => {
    return { type: UPDATE_DASHBOARD_LIST_FIELD, field, value };
};

export const updateDashboardChartField = (field, value) => {
    return { type: UPDATE_DASHBOARD_CHART_FIELD, field, value };
};