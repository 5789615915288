import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setMetadataService, getMetadataService, deleteMetadataService } from "services/metadata";
import MetadataComponent from '../components/MetadataComponent';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'

const Metadata = (props) => {

    const [metadataList, setMetadataList] = useState([]);
    const [allMetadataList, setAllMetadataList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deletingMetadataIds, setDeletingMetadataIds] = useState([]);
    const [isFetchingMetadataList, setIsFetchingMetadataList] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getMetadataList();
    }, []);

    const getMetadataList = async () => {
        const response = await getMetadataService();

        const newMetadataList = response.map(metadata => {
            return {
                ...metadata,
                valueType: metadata.valueType || "Relative"
            }
        })

        setMetadataList(newMetadataList);
        setAllMetadataList(newMetadataList);
        setIsFetchingMetadataList(false);
    }

    const discardMetadataListChanges = () => {
        const metadataCopy = _.cloneDeep(metadataList);
        setAllMetadataList(metadataCopy);
    }

    const submitMetadata = async () => {
        setIsSubmitting(true);

        try {
            const metadataToSend = allMetadataList.map((item) => {
                const { metadataId, name, weight, threshold, kpiType, infoText, valueType } = item;

                return {
                    metadataId,
                    name,
                    weight: parseInt(weight),
                    threshold: parseInt(threshold),
                    kpiType,
                    valueType,
                    infoText: infoText || ""
                };
            })

            await setMetadataService(metadataToSend);
            await getMetadataList();

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_metadata_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log("Failed submit metadata:", err);
        }

        setIsSubmitting(false);
    }

    const editMetadata = (index, field, value) => {
        let metadataListCopy = _.cloneDeep(allMetadataList);
        metadataListCopy[index][field] = value;
        setAllMetadataList(metadataListCopy)
    }

    const deleteMetadata = async (metadataId) => {
        if (await confirm({
            title: t('deletekeyword_confirm_title'),
            confirmation: t('deletekeyword_confirm_message'),
        })) {
            setDeletingMetadataIds([...deletingMetadataIds, metadataId]);

            try {
                await deleteMetadataService(metadataId);
                await getMetadataList();

                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_topics_updated')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            } catch (err) {
                console.log("Failed delete metadata:", err);
            }

            const newDeletingMetadataIds = deletingMetadataIds.filter(id => id !== metadataId);
            setDeletingMetadataIds(newDeletingMetadataIds);
        }
    }

    return <MetadataComponent
        isFetchingMetadataList={isFetchingMetadataList}
        deleteMetadata={deleteMetadata}
        deletingMetadataIds={deletingMetadataIds}
        getMetadataList={getMetadataList}
        editMetadata={editMetadata}
        submitMetadata={submitMetadata}
        discardMetadataListChanges={discardMetadataListChanges}
        isSubmitting={isSubmitting}
        allMetadataList={allMetadataList}
    />;
};

export default Metadata;