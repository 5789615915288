import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, Field } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import { BiEditAlt } from 'react-icons/bi';
import { languageCodes } from 'services/helper';
import './EmailSettings.scss';

const EmailText = (props) => {
    const { t } = useTranslation();
    const {
        submitEmailText,
        loading,
        emailTextConfig
    } = props;

    const discardChanges = (formik) => {
        _.keys(emailTextConfig).forEach((language) => {
            const languageContent = emailTextConfig[language];

            _.keys(languageContent).forEach((fieldName) => {
                formik.setFieldValue(`${language}_${fieldName}`, languageContent[fieldName])
            })
        })
    }

    const initialValues = {};
    _.keys(emailTextConfig).forEach((language) => {
        const languageContent = emailTextConfig[language];

        initialValues[`${language}_1`] = languageContent['1'];
        initialValues[`${language}_2`] = languageContent['2'];
        initialValues[`${language}_3`] = languageContent['3'];
    })

    return (
        <div className="email-settings-content">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object({
                })}
                onSubmit={(values) => submitEmailText(values)}>

                {(formikProps) => {
                    return (
                        <div className="form-wrapper">

                            <table className="kpmg-table secondary">
                                <thead>
                                    <tr>
                                        <th>{t('settings_tab6_tab3_table_title1')}</th>
                                        <th>{t('settings_tab6_tab3_table_title2')}</th>
                                        <th>{t('settings_tab6_tab3_table_title3')}</th>
                                        <th>{t('settings_tab6_tab3_table_title4')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {_.keys(emailTextConfig).map((language) => {
                                        const languageContent = emailTextConfig[language];
                                        return (
                                            <tr>
                                                <td>{languageCodes[language]}</td>
                                                <td>
                                                    <div className="email-content-input">
                                                        <Field component="textarea" name={`${language}_1`} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="email-content-input">
                                                        <Field component="textarea" name={`${language}_2`} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="email-content-input">
                                                        <Field component="textarea" name={`${language}_3`} />
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>

                            <div className="actions ">
                                <div>{t('settings_tab1_layout_chain_text_7')}:</div>
                                <Button onClick={() => discardChanges(formikProps)}>{t('settings_tab1_button3_title')}</Button>
                                <Button
                                    loading={loading}
                                    onClick={formikProps.handleSubmit}>
                                    {t('settings_tab1_button4_title')}
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </div >

    )
}
export default EmailText;