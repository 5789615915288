import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import TableSortIcon from 'components/common/TableSortIcon/TableSortIcon';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import ChartPointModal from 'components/modals/ChartPointModal/containers/ChartPointModal';
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import ChangeMultipleFileStatusModal from 'components/modals/ChangeMultipleFileStatusModal/containers/ChangeMultipleFileStatusModal';
import InventoryUserPicker from 'components/modals/InventoryUserPicker/containers/InventoryUserPicker';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import moment from 'moment';
import ReactSlider from 'react-slider';
import MultiSelect from "react-multi-select-component";
import NumberFormat from 'react-number-format';
import './GenerationComponent.scss';

const GenerationComponent = (props) => {
    const {
        settings,
        listData,
        setListData,
        applyLocalFilters,
        localFilters,
        globalFilters,
        changedTableLoading,
        setChangedTableLoading,
        fileTypes,
        changeFilter,
        sortTable,
        selectedRows,
        changeSelectedRow,
        setSelectedRows,
        removeUpdatedRows
    } = props;

    const mainColor = settings?.theme?.mainColor;
    const { t } = useTranslation();

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false);
    const [isChartPointModalOpen, setIsChartPointModalOpen] = useState(false);
    const [isInventoryValidationModalOpen, setIsInventoryValidationModalOpen] = useState(false);
    const [isChangeMultipleFileStatusModalOpen, setIsChangeMultipleFileStatusModalOpen] = useState(false);

    const [selectedPoint, setSelectedPoint] = useState(null);

    const numberOfColumns = 6;

    const openPointModal = (listItem) => {
        setIsChartPointModalOpen(true);
        setSelectedPoint(listItem);
    }

    const headerColumns = [
        {
            columnName: t('analysis_tab1_generation_table_header_1'),
            sortable: false,
        },
        {
            columnName: t('analysis_tab1_generation_table_header_4'),
            columnKey: 'fileName',
            sortable: true,
        },
        {
            columnName: t('analysis_tab1_generation_table_header_5'),
            columnKey: 'extension',
            sortable: true,
        },
        {
            columnName: t('analysis_tab1_generation_table_header_6'),
            columnKey: 'criticality',
            sortable: true,
        },
        {
            columnName: t('analysis_tab1_generation_table_header_7'),
            columnKey: 'complexity',
            sortable: true,
        },
        {
            columnName: t('analysis_tab1_generation_table_header_8'),
            columnKey: 'lastModified',
            sortable: true,
        },
    ]

    return (
        <div className="generation-wrapper">
            <div className="generation-content">
                <div className="generation-table-wrapper">
                    <table className="kpmg-table stripped hoverable">
                        <thead>
                            <tr className="row-header">
                                <th colSpan={numberOfColumns}>
                                    <div className={`section-header-item active`}>
                                        <span style={{ borderColor: mainColor }}>{t('analysis_tab1_generation_title')}</span>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th colSpan={numberOfColumns} className="options-row-cell">
                                    <div className="options-row">
                                        <div className="custom-input">
                                            <div className="custom-input-label" style={{ width: 75 }}>{t('analysis_tab1_generation_complexity')}:</div>
                                            <div className="custom-input-fields">
                                                <input
                                                    type="number"
                                                    className="number-row"
                                                    onChange={e => changeFilter('complexity.min', e.target.value)}
                                                    value={localFilters.complexity.min}
                                                />
                                                <span className="custom-input-separator">-</span>
                                                <input
                                                    type="number"
                                                    className="number-row"
                                                    onChange={e => changeFilter('complexity.max', e.target.value)}
                                                    value={localFilters.complexity.max}
                                                />
                                            </div>
                                        </div>

                                        <div className="custom-input">
                                            <div className="custom-input-label">{t('analysis_tab1_generation_period')}:</div>
                                            <div className="custom-input-fields">
                                                <input
                                                    type="date"
                                                    onChange={e => changeFilter('date.start', e.target.value)}
                                                    value={localFilters.date.start}
                                                />
                                                <span className="custom-input-separator">-</span>
                                                <input
                                                    type="date"
                                                    onChange={e => changeFilter('date.end', e.target.value)}
                                                    value={localFilters.date.end}
                                                />
                                            </div>
                                        </div>

                                        <div className="custom-input column">
                                            <div className="custom-input-label">{t('analysis_tab1_generation_filetypes')}:</div>
                                            <div className="custom-input-fields">
                                                <MultiSelect
                                                    className="custom-select"
                                                    options={fileTypes}
                                                    value={localFilters.extension}
                                                    onChange={values => changeFilter('extension', values)}
                                                    overrideStrings={{
                                                        selectSomeItems: t('analysis_tab1_generation_filetypes_placeholder'),
                                                        allItemsAreSelected: t('multiselect_allItemsAreSelected'),
                                                        selectAll: t('multiselect_selectAll'),
                                                        search: t('multiselect_search'),
                                                        clearSearch: t('multiselect_clearSearch')
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="custom-input column">
                                            <div className="custom-input-label">{t('analysis_tab1_generation_number')}:</div>
                                            <div className="custom-input-fields">
                                                <input
                                                    type="number"
                                                    className="number-row"
                                                    onChange={e => changeFilter('limit', e.target.value)}
                                                    value={localFilters.limit}
                                                />
                                            </div>
                                        </div>

                                        <div className="custom-input" style={{ gridRow: 'span 2' }}>
                                            <div className="send-button">
                                                <Button
                                                    disabled={!selectedRows.length}
                                                    className="light"
                                                    onClick={() => setIsChangeMultipleFileStatusModalOpen(true)}>
                                                    {t('analysis_tab1_generation_send')}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="custom-input">
                                            <div className="custom-input-label" style={{ width: 75 }}>{t('analysis_tab1_generation_criticality')}:</div>
                                            <div className="custom-input-fields">
                                                <input
                                                    type="number"
                                                    className="number-row"
                                                    onChange={e => changeFilter('criticality.min', e.target.value)}
                                                    value={localFilters.criticality.min}
                                                />
                                                <span className="custom-input-separator">-</span>
                                                <input
                                                    type="number"
                                                    className="number-row"
                                                    onChange={e => changeFilter('criticality.max', e.target.value)}
                                                    value={localFilters.criticality.max}
                                                />
                                            </div>
                                        </div>

                                        <div className="custom-input">
                                            <div className="custom-input-label">{t('analysis_tab1_generation_filesize')}:</div>
                                            <div className="custom-input-fields">
                                                <NumberFormat
                                                    className="input-number"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={false}
                                                    value={localFilters.fileSize.min}
                                                    allowNegative={false}
                                                    onValueChange={e => changeFilter('fileSize.min', e.value)}
                                                    allowLeadingZeros={false}
                                                />
                                                <span className="custom-input-separator">-</span>
                                                <NumberFormat
                                                    className="input-number"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={false}
                                                    value={localFilters.fileSize.max}
                                                    allowNegative={false}
                                                    onValueChange={e => changeFilter('fileSize.max', e.value)}
                                                    allowLeadingZeros={false}
                                                />
                                            </div>
                                        </div>


                                        <div className="custom-input column slider">
                                            <div className="custom-input-label">{t('analysis_tab1_generation_scattering')}:</div>

                                            <ReactSlider
                                                className="header-slider"
                                                marks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                                min={0}
                                                max={100}
                                                step={10}
                                                markClassName="header-slider-mark"
                                                thumbClassName="header-slider-thumb"
                                                trackClassName="header-slider-track"
                                                renderThumb={(props, state) => (
                                                    <div {...props} className={`${props.className} key-${props['aria-valuenow']}`}>
                                                        <div className="thumb-indicator"></div>
                                                    </div>
                                                )}
                                                renderMark={(props) => <div {...props}>{props.key}</div>}
                                                onChange={value => changeFilter('scatter', value / 100)}
                                                value={localFilters.scatter * 100}
                                            />
                                        </div>

                                        <div className="custom-input center">
                                            <Button
                                                style={{ width: '100%' }}
                                                className="light very-small"
                                                onClick={() => applyLocalFilters(localFilters, globalFilters)}>
                                                {t('analysis_tab1_generation_title_action')}
                                            </Button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                {
                                    headerColumns.map((headerColumn, i) => (
                                        <th style={{ minWidth: 150 }} key={i}>
                                            <div className="table-head" onClick={() => sortTable(headerColumn.columnKey)}>
                                                <span>{headerColumn.columnName}</span>
                                                {
                                                    headerColumn.sortable &&
                                                    <div className="sort-icon">
                                                        <TableSortIcon
                                                            columnName={headerColumn.columnKey}
                                                            sortColumn={localFilters.orderColumn}
                                                            sortOrder={localFilters.orderDirection}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                changedTableLoading ?
                                    _.times(10, (i) => (
                                        <tr key={i}>
                                            {
                                                _.times(numberOfColumns, (j) => (
                                                    <td key={j} style={{ backgroundColor: "white" }}>
                                                        <Skeleton width={60} height={10} />
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    )) :
                                    listData.length ?
                                        listData.map((listItem, i) => {
                                            const isChecked = selectedRows.filter(row => row.id === listItem.id)[0];

                                            return (
                                                <tr className="cursor-pointer" key={i} onClick={() => openPointModal(listItem)}>
                                                    <td className="number-row" onClick={e => e.stopPropagation()}>
                                                        <input
                                                            type="checkbox"
                                                            value={isChecked ? true : false}
                                                            onChange={(e) => changeSelectedRow(listItem)}
                                                        />
                                                    </td>
                                                    <td>{listItem.fileName || '-'}</td>
                                                    <td>{listItem.extension || '-'}</td>
                                                    <td className='number-row'>{listItem.criticality}</td>
                                                    <td className='number-row'>{listItem.complexity}</td>
                                                    <td>{listItem.lastModified ? moment(listItem.lastModified).format("DD/MM/YYYY") : '-'}</td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan={numberOfColumns}>{t('analysis_tab1_generation_table_empty')}</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                setSelectedItem={setSelectedPoint}
                selectedItem={selectedPoint}
                refreshScreen={() => applyLocalFilters(localFilters, globalFilters)}
            />

            <ChartPointModal
                isOpen={isChartPointModalOpen}
                setIsOpen={setIsChartPointModalOpen}
                setIsFileOverviewModalOpen={setIsFileOverviewModalOpen}
                setIsFileValidationModalOpen={setIsSelectUserModalOpen}
                setIsInventoryValidationModalOpen={setIsInventoryValidationModalOpen}
                selectedPoint={selectedPoint}
                setIsGraphLoading={setChangedTableLoading}
                getChartDataWithFilters={() => applyLocalFilters(localFilters, globalFilters)}
            />

            <SelectUserModal
                isOpen={isSelectUserModalOpen}
                setIsOpen={setIsSelectUserModalOpen}
                selectedItems={selectedRows}
                setIsGraphLoading={setChangedTableLoading}
                getChartDataWithFilters={() => applyLocalFilters(localFilters, globalFilters)}
                title={t('file_validation_modal_title')}
                description={t('file_validation_modal_content')}
            />

            <ChangeMultipleFileStatusModal
                isOpen={isChangeMultipleFileStatusModalOpen}
                setIsOpen={setIsChangeMultipleFileStatusModalOpen}
                selectedRows={selectedRows}
                reloadTab={removeUpdatedRows}
                setSelectedCheckboxes={setSelectedRows}
            />

            {
                isInventoryValidationModalOpen &&
                <InventoryUserPicker
                    isOpen={isInventoryValidationModalOpen}
                    setIsOpen={setIsInventoryValidationModalOpen}
                    selectedItems={selectedRows}
                    reloadTab={() => applyLocalFilters(localFilters, globalFilters)}
                />
            }
        </div>
    )
};

export default GenerationComponent;