import React from "react";
import UserInterfaceComponent from '../components/UserInterfaceComponent';

const UserInterface = (props) => {
    return (
        <UserInterfaceComponent
            {...props}
        />
    )
};

export default UserInterface;