import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import _ from 'lodash';
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";

const PrivateRoute = (props) => {
    const {
        component: Component,
        user,
        permission,
        license,
        licensePermission,
        redirectRoute,
        history,
        ...rest
    } = props;

    const userPermissions = user && user.groupPermissions;
    const permissionMatch = permission ? userPermissions && _.intersection(userPermissions, permission).length : true;

    const licenseExpired = licensePermission ? license?.isExpired : false;
    const licenseInvalid = licensePermission ? license?.isInvalid : false;
    const licensePermissionMatch = licensePermission ? license?.resources?.includes(licensePermission) : true;

    const { t } = useTranslation();

    const routes = [
        {
            permission: ["EUCGUIDANCE"],
            license: "Info-Portal",
            route: "/guidance"
        },
        {
            permission: ["EUCSCAN"],
            license: "Discovery",
            route: "/scanning"
        },
        {
            permission: ['EUCDASHBOARDREAD', 'EUCDASHBOARDWRITE'],
            license: "Analyse",
            route: "/analyzing"
        },
        {
            permission: ["EUCDASHBOARDWRITE"],
            license: "Validation",
            route: "/validation"
        },
        {
            permission: ["EUCINVENTORYMANAGEMENT"],
            license: "Management",
            route: "/management"
        },
        {
            permission: ["EUCADMIN"],
            license: "Settings",
            route: "/settings"
        },
    ]

    if (license?.resources && user?.groupPermissions) {
        if (!(permissionMatch && licensePermissionMatch && !licenseExpired && !licenseInvalid)) {
            if (user.groupPermissions.length) {
                const routeToRedirect = routes.filter(route => {
                    const userPermission = _.intersection(user.groupPermissions, route.permission).length;
                    const licensePermission = license?.resources?.includes(route.license);
                    return userPermission && licensePermission;
                })[0];
                if (routeToRedirect) history.push(routeToRedirect.route);
            }
        }
    }

    return (
        <Route {...rest} render={props => (
            permissionMatch && licensePermissionMatch && !licenseExpired && !licenseInvalid ?
                redirectRoute ?
                    <Redirect to={redirectRoute} /> :
                    <Component {...props} /> :
                <Layout>
                    {
                        licenseInvalid ?
                            <StatusMessage
                                title={t('settings_tab8_license_invalid')}
                                message={t('settings_tab8_license_invalid_text')}
                            /> :
                            licenseExpired ?
                                <StatusMessage
                                    title={t('settings_tab8_license_expired')}
                                    message={t('settings_tab8_license_expired_text')}
                                /> :
                                !licensePermissionMatch ?
                                    <StatusMessage
                                        title={t('settings_tab8_license_resource_disabled')}
                                        message={t('settings_tab8_license_resource_disabled_text')}
                                    /> :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ margin: '40px 0', textAlign: 'center' }}>User {user.samAccountName}, has access to the following EUC group(s) <strong>{userPermissions && userPermissions.join(", ")}</strong>.</div>
                                        <div style={{ textAlign: 'center' }}>The required permission access for this page is <strong>{permission.join(", ")}</strong>.</div>
                                    </div>
                    }
                </Layout>
        )} />
    );
};

const StatusMessage = props => {
    const { title, message } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ margin: '20px 0' }}>
                <strong>{title}</strong>
            </div>
            <div>{message}</div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
    license: store.licenseReducer.license
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));