import React from "react";
import DatePicker from "react-datepicker";
import _ from 'lodash';
import { getIn } from 'formik';
import MultiSelect from "react-multi-select-component";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import "./Input.scss";

const InputComponent = (props) => {
    const getInputType = (props, t) => {
        const {
            type,
            formikProps,
            value = "",
            placeholder,
            name,
            min,
            disabled,
            selectOptions,
            onChange,
            inputOptions
        } = props;

        const inputValue = !_.isNil(getIn(formikProps?.values, name)) ? getIn(formikProps?.values, name) : value;

        switch (type) {
            case 'text':
                return (
                    <input
                        type="text"
                        name={name}
                        placeholder={placeholder}
                        value={inputValue}
                        disabled={disabled}
                        onChange={formikProps ? formikProps.handleChange : null}
                        onBlur={formikProps ? formikProps.handleBlur : null}
                    />
                )

            case 'number':
                return (
                    <input
                        type="number"
                        min={min}
                        name={name}
                        placeholder={placeholder}
                        value={inputValue}
                        disabled={disabled}
                        onBlur={formikProps ? formikProps.handleBlur : null}
                        onChange={(e) => {
                            if (formikProps) {
                                const value = min >= 0 ? e.target.value.replace(/[^\d]/, '') : e.target.value;
                                formikProps.setFieldValue(name, value);
                            }
                        }}
                    />
                )

            case 'date':
                return (
                    <DatePicker
                        name={name}
                        selected={formikProps?.values[name] || null}
                        disabled={disabled}
                        onBlur={formikProps ? formikProps.handleBlur : null}
                        onChange={(value) => {
                            const valueToSet = value === null ? "" : value;
                            formikProps.setFieldValue(name, valueToSet);
                        }}
                    />
                )

            case 'select':
                return (
                    <select
                        value={inputValue}
                        onChange={formikProps ? formikProps.handleChange : null}
                        onBlur={formikProps ? formikProps.handleBlur : null}
                        name={name}>
                        {
                            selectOptions.map((selectOption, i) => (
                                <option key={i} value={selectOption.value} disabled={selectOption.disabled}>{selectOption.label}</option>
                            ))
                        }
                    </select>
                )
            case 'multi-select':
                return (
                    <MultiSelect
                        className="custom-select"
                        overrideStrings={{
                            selectSomeItems: t('multiselect_selectSomeItems'),
                            allItemsAreSelected: t('multiselect_allItemsAreSelected'),
                            selectAll: t('multiselect_selectAll'),
                            search: t('multiselect_search'),
                            clearSearch: t('multiselect_clearSearch')
                        }}
                        {...inputOptions}
                    />
                )

            case 'file':
                return (
                    <input
                        type="file"
                        id={name}
                        name={name}
                        onChange={onChange}
                        onClick={e => (e.target.value = null)}
                        style={{ display: 'none' }}
                    />
                )

            default:
                return (
                    <input
                        type="text"
                        name={name}
                        placeholder={placeholder}
                        value={inputValue}
                        disabled={disabled}
                        onChange={formikProps ? formikProps.handleChange : null}
                        onBlur={formikProps ? formikProps.handleBlur : null}
                    />
                )
        }
    }

    const { formikProps, name } = props;
    const hasError = formikProps && getIn(formikProps.touched, name) && getIn(formikProps.errors, name);
    const { t } = useTranslation();

    return (
        <div className={hasError ? "input-wrapper error" : "input-wrapper"}>
            <div className="input-content">
                {getInputType(props, t)}
            </div>
            <div className="input-error-message">

            </div>
        </div>
    );
};

export default InputComponent;