import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ScanSettingsComponent from '../components/ScanSettingsComponent';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettingsConfigGraph, updateSettingsConfigCrawler } from "redux/actions/settingsActions";
import { updateDashboard } from "redux/actions/dashboardActions";
import { setConfigGraphService, setConfigCrawlerService } from "services/settings";
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import appFilters from 'services/appFilters';

const ScanSettings = (props) => {
    const { settings, dashboard, updateSettingsConfigGraph, updateSettingsConfigCrawler, updateDashboard } = props;
    const { t } = useTranslation()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const updateConfigGraph = async (formData) => {
        const newConfigGraph = {
            ...settings.configGraph,
            complexityAxis: parseInt(formData.complexity),
            criticalityAxis: parseInt(formData.criticality),
            pointLimit: parseInt(formData.pointLimit)
        }

        try {
            await setConfigGraphService(newConfigGraph);
            updateSettingsConfigGraph(newConfigGraph);
            updateDashboard(appFilters);
        } catch (err) {
            console.log(`Error while updating config graph: ${err}`);
        }
    }

    const updateConfigCrawler = async (formData) => {
        const newConfigCrawler = {
            ...settings.configGraph,
            duplicateLimit: parseInt(formData.duplicateLimit),
            maxFileSize: parseInt(formData.maxFileSize) * 1024,
            maxFileNumberExportExcel: parseInt(formData.maxFileNumberExportExcel),
            maxDirectoryNotFoundRetries: parseInt(formData.maxDirectoryNotFoundRetries),
        }

        try {
            await setConfigCrawlerService(newConfigCrawler);
            updateSettingsConfigCrawler(newConfigCrawler)
        } catch (err) {
            console.log(`Error while updating config crawler: ${err}`);
        }
    }

    const updateConfigs = async (formData) => {
        setIsSubmitting(true);
        try {
            await updateConfigGraph(formData.configGraph);
            await updateConfigCrawler(formData.configCrawler);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_scan_settings_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log("Failed updating configurations:", err);
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_scan_settings_error')} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
        finally {
            setIsSubmitting(false);
        }
    }

    return <ScanSettingsComponent
        {...props}
        updateConfigs={updateConfigs}
        configGraph={settings.configGraph}
        configCrawler={settings.configCrawler}
        isSubmitting={isSubmitting}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettingsConfigGraph, updateSettingsConfigCrawler, updateDashboard }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScanSettings);