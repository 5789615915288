import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from 'components/common/Input/Input';
import Button from 'components/common/Button/Button';
import { MdClose } from 'react-icons/md';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import './StepThreeComponent.scss';

const Checkbox = props => {
    const { text, checked, onChange, disabled } = props;

    return (
        <div className="checkbox-wrapper" onClick={() => {
            if (!disabled) {
                onChange(!checked);
            }
        }}>
            <div className="checkbox-square">
                {
                    checked ?
                        <MdClose style={{ fontSize: 15 }} /> :
                        null
                }
            </div>
            <div className="checkbox-label">{text}</div>
        </div>
    )
}

const StepThreeComponentLoading = () => {
    return (
        <div className="step-three-loading">
            {
                _.times(3, (i) => (
                    <div key={i}>
                        <Skeleton width={100} style={{ marginBottom: 20 }} />
                        <Skeleton width={150} count={7} style={{ marginBottom: 10 }} />
                    </div>
                ))
            }
        </div>
    )
}

const StepThreeComponent = props => {
    const {
        scanModes,
        filterFiles,
        handleScanModeChange,
        selectedScanModes,
        handleFileTypeChange,
        selectedFileTypes,
        submitData,
        data,
        isFetching,
        readOnly
    } = props;

    const { t } = useTranslation();

    let fileSizeMinInitial = 0;
    let fileSizeMaxInitial = 100;
    let noSizeLimit = false;

    let dateStartInitial = moment().toDate();
    let dateEndInitial = moment().add(2, 'days').toDate();

    if (data?.filterSettings?.fileSizeFilter) {
        const valuesInMb = data.filterSettings.fileSizeFilter.split(" ");
        fileSizeMinInitial = valuesInMb[0] ? valuesInMb[0].replace(/[^0-9.]/g, "") : 0;
        fileSizeMaxInitial = valuesInMb[1] ? valuesInMb[1].replace(/[^0-9.]/g, "") : 100;

        if (data?.filterSettings?.fileSizeFilter === ">=0") noSizeLimit = true;
    }

    if (data?.filterSettings?.fileLastModifiedDateFilter) {
        const dates = data.filterSettings.fileLastModifiedDateFilter.split("-");
        dateStartInitial = moment(dates[0], 'DD.MM.YYYY').toDate();
        dateEndInitial = moment(dates[1], 'DD.MM.YYYY').toDate();
    }

    const formik = useFormik({
        initialValues: {
            lastChangeDateStart: dateStartInitial,
            lastChangeDateEnd: dateEndInitial,
            fileSizeMin: fileSizeMinInitial,
            fileSizeMax: fileSizeMaxInitial,
            noSizeLimit: noSizeLimit,
        },
        validationSchema: Yup.object().shape({
            lastChangeDateStart: Yup.date().required(),
            lastChangeDateEnd: Yup.date()
                .when("lastChangeDateStart", (st, sc) => {
                    return st && sc.min(st, `This date has to be bigger than ${moment(st).format("DD/MM/YYYY")}`);
                })
                .required(),
            fileSizeMin: Yup.number().min(0).required(),
            fileSizeMax: Yup.number().min(0).required()
                .moreThan(
                    Yup.ref('fileSizeMin'),
                    'This value should be greater than min value'
                ),
            noSizeLimit: Yup.boolean(),
        }),
        // enableReinitialize: true,
        onSubmit: values => {
            submitData(values);
        },
    });

    const quickScan = scanModes.filter(scanMode => scanMode.scanGroup === 'Quick Scan')[0];
    const deepScan = scanModes.filter(scanMode => scanMode.scanGroup === 'Deep Scan')[0];

    const officeApplications = filterFiles.filter(filterFile => filterFile.fileGroup === 'Office Application');
    const script = filterFiles.filter(filterFile => filterFile.fileGroup === 'Script');
    const transfer = filterFiles.filter(filterFile => filterFile.fileGroup === 'Transfer');

    return (
        <div className="step-three-wrapper">
            {
                isFetching ?
                    <StepThreeComponentLoading /> :
                    <form onSubmit={formik.handleSubmit}>
                        <div className="cards">
                            <div className="card-options">
                                <div className="title">3.1) {t('scan_tab1_wizard_step3_layout_chain_text_1')}</div>
                                <div className="content">
                                    <div className="section">
                                        <div className="title-border">
                                            <Checkbox
                                                text={t('scan_tab1_wizard_step3_layout_chain_text_4')}
                                                checked={selectedScanModes.includes(quickScan?.scanModeId)}
                                                onChange={(isChecked) => handleScanModeChange(quickScan?.scanModeId, isChecked)}
                                                disabled={readOnly} />
                                        </div>
                                        <ul>
                                            <li>Analysis of meta data</li>
                                        </ul>
                                    </div>

                                    <div className="section">
                                        <div className="title-border">
                                            <Checkbox
                                                text={t('scan_tab1_wizard_step3_layout_chain_text_6')}
                                                checked={selectedScanModes.includes(deepScan?.scanModeId)}
                                                onChange={(isChecked) => handleScanModeChange(deepScan?.scanModeId, isChecked)}
                                                disabled={readOnly} />

                                        </div>
                                        <ul>
                                            <li>Analysis of technical parameter</li>
                                            <li>Keyword-Search</li>
                                            <li>Duplicate detection</li>
                                        </ul>

                                    </div>

                                </div>
                            </div>

                            <div className="card-options">
                                <div className="title">3.2) {t('scan_tab1_wizard_step3_layout_chain_text_2')}</div>
                                <div className="content">
                                    <div className="section">
                                        <div className="title">{t('scan_tab1_wizard_step3_layout_chain_text_12')}</div>
                                        {
                                            officeApplications.map((officeApp, i) => (
                                                <Checkbox
                                                    key={i}
                                                    text={officeApp.fileName}
                                                    checked={selectedFileTypes.includes(officeApp.filterSettingFileId)}
                                                    onChange={(isChecked) => handleFileTypeChange(officeApp.filterSettingFileId, isChecked)}
                                                    disabled={readOnly} />
                                            ))
                                        }
                                    </div>

                                    <div className="grid grid-2">
                                        <div className="section">
                                            <div className="title">{t('scan_tab1_wizard_step3_layout_chain_text_18')}</div>
                                            {
                                                script.map((scriptItem, i) => (
                                                    <Checkbox
                                                        key={i}
                                                        text={scriptItem.fileName}
                                                        checked={selectedFileTypes.includes(scriptItem.filterSettingFileId)}
                                                        onChange={(isChecked) => handleFileTypeChange(scriptItem.filterSettingFileId, isChecked)}
                                                        disabled={readOnly} />
                                                ))
                                            }
                                        </div>
                                        <div className="section">
                                            <div className="title">{t('scan_tab1_wizard_step3_layout_chain_text_22')}</div>
                                            {
                                                transfer.map((transferItem, i) => (
                                                    <Checkbox
                                                        key={i}
                                                        text={transferItem.fileName}
                                                        checked={selectedFileTypes.includes(transferItem.filterSettingFileId)}
                                                        onChange={(isChecked) => handleFileTypeChange(transferItem.filterSettingFileId, isChecked)}
                                                        disabled={readOnly} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-options">
                                <div className="title">3.3) {t('scan_tab1_wizard_step3_layout_chain_text_26')}</div>
                                <div className="content">
                                    <div className="section">
                                        <div className="input-label">{t('scan_tab1_wizard_step3_layout_chain_text_27')}</div>
                                        <div className="input-row">
                                            <Input
                                                type="date"
                                                name="lastChangeDateStart"
                                                disabled={readOnly}
                                                formikProps={formik}
                                            />
                                            <span>-</span>
                                            <Input
                                                type="date"
                                                name="lastChangeDateEnd"
                                                disabled={readOnly}
                                                formikProps={formik}
                                            />
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="input-label">{t('scan_tab1_wizard_step3_layout_chain_text_28')}</div>
                                        <div className="input-row">
                                            <Input
                                                type="number"
                                                name="fileSizeMin"
                                                disabled={readOnly || formik.values.noSizeLimit}
                                                min={0}
                                                formikProps={formik}
                                            />
                                            <span>-</span>
                                            <Input
                                                type="number"
                                                name="fileSizeMax"
                                                disabled={readOnly || formik.values.noSizeLimit}
                                                min={0}
                                                formikProps={formik}
                                            />
                                        </div>
                                        <div className="input-row">
                                            <Checkbox
                                                text="No size limit"
                                                checked={formik.values.noSizeLimit}
                                                onChange={(isChecked) => formik.setFieldValue('noSizeLimit', isChecked)}
                                                disabled={readOnly} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            !readOnly &&
                            <div className="action-buttons">
                                <Button type="submit" style={{ width: 130 }}>{t('scan_tab1_wizard_step1_button_1')}</Button>
                            </div>
                        }
                    </form>
            }
        </div>
    )
}

export default StepThreeComponent;