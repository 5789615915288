import React from 'react';
import { generatePages } from 'services/helper';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import './TablePagination.scss';

const TablePagination = props => {
    const { pagination, changePageDataCallback, dataCount, isLoading } = props;

    const numberOfPages = Math.ceil(dataCount / pagination.limit);
    const tablePages = generatePages(pagination.currentIndex, numberOfPages);

    const changePageData = (pageIndex, limit) => {
        let newPagination = { ...pagination };

        if (pageIndex) {
            newPagination.currentIndex = pageIndex;
        }

        if (limit) {
            newPagination.limit = limit;
            newPagination.selectValue = limit;
            newPagination.currentIndex = 1;
        }

        changePageDataCallback(newPagination);
    }

    return (
        dataCount > 0 ?
            <div className="table-footer-component">
                {
                    isLoading ?
                        <>
                            <div className="pagination">
                                {
                                    _.times(4, i => (
                                        <Skeleton key={i} width={25} height={25} style={{ marginRight: 5 }} />
                                    ))
                                }
                            </div>
                            <div className="actions">
                                <Skeleton width={80} height={25} />
                            </div>
                        </> :
                        <>
                            <div className="pagination">
                                <div
                                    className={pagination.currentIndex === 1 ? "disabled" : ""}
                                    onClick={() => changePageData(pagination.currentIndex - 1, null)}>
                                    <BsChevronLeft />
                                </div>
                                {
                                    tablePages.map((tablePage, i) => (
                                        <div
                                            className={tablePage === "..." ? "disabled" : (tablePage === pagination.currentIndex ? "active" : "")}
                                            key={i}
                                            onClick={() => changePageData(tablePage, null)}>{tablePage}</div>
                                    ))
                                }
                                <div
                                    className={pagination.currentIndex === numberOfPages ? "disabled" : ""}
                                    onClick={() => changePageData(pagination.currentIndex + 1, null)}>
                                    <BsChevronRight />
                                </div>
                            </div>
                            <div className="actions">
                                <span>Show:</span>
                                <select
                                    name="limitSelect"
                                    id="limitSelect"
                                    value={pagination.selectValue}
                                    onChange={(e) => changePageData(null, parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </>
                }

            </div> :
            null
    )
}

export default TablePagination