export async function getDictionaryService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/dictionary`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setDictionaryService(sentences) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(sentences)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/dictionary`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.text();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}
