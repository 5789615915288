import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import './TableActionButton.scss';

const TableActionButton = props => {
    const { children, onClick, asyncFunction, style, disabled = false, linkTo, toolTip } = props;
    const [isLoading, setIsLoading] = useState(false);

    const onClickFunction = async () => {
        if (asyncFunction) {
            setIsLoading(true);
            await asyncFunction();
            setIsLoading(false);
        } else {
            onClick();
        }
    }
    const toolTipId = Math.random();

    return (
        <div
            data-for={`${toolTipId}.alias`}
            data-tip={toolTip}
            >
            {
                linkTo ?
                    <Link
                        className={`table-action-button ${disabled ? "disabled" : ""}`}
                        style={style}
                        to={isLoading || disabled ? "#" : linkTo}>
                        {children}
                    </Link> :
                    <div
                        className={`table-action-button ${disabled ? "disabled" : ""}`}
                        onClick={isLoading || disabled ? null : onClickFunction}
                        style={style}>
                        {
                            isLoading ?
                                <ClipLoader
                                    size={14}
                                    color={"#525662"}
                                    loading={true}
                                /> :
                                children
                        }
                    </div>
            }
            <ReactTooltip
                id={`${toolTipId}.alias`}
                effect="solid"
                delayShow={0}
                delayHide={0}
                className="question-tooltip"
            />
        </div>
    )
}

export default TableActionButton;