import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettingsConfigGraph, updateSettingsConfigCrawler } from "redux/actions/settingsActions";
import { updateDashboard } from "redux/actions/dashboardActions";
import KalibrierungScanComponent from '../components/KalibrierungScanComponent';

const KalibrierungScan = (props) => {

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    return <KalibrierungScanComponent

    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettingsConfigGraph, updateSettingsConfigCrawler, updateDashboard }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KalibrierungScan);