import React from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { getPointData } from 'services/helper';
import './ChartPointListModalComponent.scss';

const ChartPointListModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        pointsList,
        setIsChartPointModalOpen,
        setSelectedPoint
    } = props;

    const { t } = useTranslation();

    const selectPoint = (point) => {
        const parsedPoint = getPointData(point);

        setSelectedPoint(parsedPoint);
        setIsOpen(false);
        setIsChartPointModalOpen(true);
    }

    const firstPoint = pointsList[0];

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal chart-point-list-modal"
            overlayClassName="kpmg-modal-overlay transparent"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="content-header">
                <div className="filename">{t('point_list_modal_title')}</div>
                <div
                    className="close-tooltip"
                    onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="content-body">
                <div className="info-details">
                    <p>{t('point_modal_criticality')}: {firstPoint?.criticality}, {t('point_modal_complexity')}: {firstPoint?.complexity}</p>
                </div>

                <table className="kpmg-table stripped">
                    <thead>
                        <tr>
                            <th style={{ width: 60 }}>{t('point_list_modal_table_header_1')}</th>
                            <th>{t('point_list_modal_table_header_2')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pointsList?.map((point, i) => (
                                <tr key={i} onClick={() => selectPoint(point)}>
                                    <td className="center">
                                        <img src={point?.statusIcon} alt="status-icon" />
                                    </td>
                                    <td>{point?.fileName}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </Modal>
    )
};

export default ChartPointListModalComponent;