import React, { useState, useEffect } from "react";
import ExpertenmodusComponent from '../components/ExpertenmodusComponent';

const Expertenmodus = (props) => {
    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    return <ExpertenmodusComponent />;
};

export default Expertenmodus;