import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SelectUserModalComponent from '../components/SelectUserModalComponent';
import { setValidationFormRequest } from 'services/excel';
import { getSMTPReceiversService } from 'services/email';
import { sendEmail } from 'services/helper';
import { searchUsersService } from 'services/user';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
const mime = require('mime-types');

const SelectUserModal = (props) => {
    const { settings, setIsOpen, selectedItems, isOpen, getChartDataWithFilters, getCoordinatorNameCallback, keepOpenOnCallback } = props;
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [coordinatorList, setCoordinatorList] = useState(null);
    const [thisCoordinator, setThisCoordinator] = useState(null);

    const [isLoadingComponent, setIsLoadingComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const [useGroups, setUseGroups] = useState(false);

    const [inputSearch, setInputSearch] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        getCoordinatorList();
    }, []);

    useEffect(() => {
        if (isOpen) {
            setSelectedOption(null);
            setThisCoordinator(null);
            getCoordinator();
            setInputSearch("");
            setSelectedUser(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (settings?.coordinatorConfig) {
            setUseGroups(settings.coordinatorConfig.useGroups ? true : false);
        }
    }, [settings?.coordinatorConfig])

    const submitValidation = async () => {
        setIsLoading(true);

        try {
            const formDatasPromises = selectedItems.map(selectedItem => {
                const path = selectedItem.path.replace(`\\${selectedItem.fileName}`, "");

                const formData = {
                    fileId: selectedItem.id,
                    type: mime.lookup(selectedItem.fileName) || "unknown",
                    directory: path,
                    alias: selectedItem.alias,
                    samAccount: selectedUser.samAccountName,
                }

                return setValidationFormRequest(formData);
            })

            await Promise.all(formDatasPromises);

            setIsOpen(false);
            getChartDataWithFilters();

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_file_validation_success')} />, {
                progressClassName: "toastr-progress-bar success"
            });

            await sendEmail("1", selectedUser.samAccountName);
        } catch (err) {
            console.log(`Error in request validation: ${err}`)
        }

        setIsLoading(false);
    }

    const getCoordinatorList = async () => {
        try {
            const coordinators = await getSMTPReceiversService();
            setCoordinatorList(coordinators);
        } catch (err) {
            console.log(`Error while fetching coordinator: ${err}`)
        }
    }

    const getCoordinator = async () => {
        setIsLoadingComponent(true);

        try {
            const coordinator = coordinatorList.filter(coordinator => {
                return coordinator.orgDepartmentId === (useGroups ? selectedItems[0].orgGroupId : selectedItems[0].orgDepartmentId);
            })[0];

            if (coordinator?.samAccountName) {
                const userEntity = await searchUsersService(`search=${coordinator.samAccountName}`);

                setThisCoordinator({
                    ...coordinator,
                    name: userEntity[0].displayName
                });
            }
        } catch (err) {
            console.log(`Error while fetching coordinator: ${err}`);
        }

        setIsLoadingComponent(false);
    }

    return (
        <SelectUserModalComponent
            {...props}
            selectedItems={selectedItems}
            submitValidation={submitValidation}
            getCoordinatorNameCallback={getCoordinatorNameCallback}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            isLoading={isLoading}
            coordinatorList={coordinatorList}
            isLoadingComponent={isLoadingComponent}
            thisCoordinator={thisCoordinator}
            keepOpenOnCallback={keepOpenOnCallback}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user,
});

export default connect(mapStateToProps)(SelectUserModal);