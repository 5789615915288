import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ValidierungenComponent from '../components/ValidierungenComponent';
import { getExcelDataService, changeValidationStatusService } from 'services/excel';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions';
import { sendEmail } from 'services/helper';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const Validierungen = (props) => {
    const { settings } = props;

    const [selectedTab, setSelectedTab] = useState(0);
    const [validationFormOpen, setValidationFormOpen] = useState({
        data: [],
        count: 0
    });
    const [validationFormPending, setValidationFormPending] = useState({
        data: [],
        count: 0
    });
    const [validationFormClosed, setValidationFormClosed] = useState({
        data: [],
        count: 0
    });
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        limit: 100,
        selectValue: 100,
        currentIndex: 1,
    });

    const { t } = useTranslation();

    const isAllowedValidation = settings?.validationConfig?.formAutoClose === 1;

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getValidationFormByStatus('open', pagination);
        if (!isAllowedValidation) getValidationFormByStatus('pendingacceptance', pagination);
        getValidationFormByStatus('closed', pagination);
    }, []);

    const getValidationFormByStatus = async (status, pagination) => {
        setLoading(true);

        try {
            const responseLength = await getExcelDataService({ validationstatus: status }, false, pagination, true);
            const response = await getExcelDataService({ validationstatus: status }, false, pagination);
            const responseSorted = _.orderBy(response, (item) => +new Date(item.createdAt), ['desc']);

            if (status === 'open') setValidationFormOpen({
                data: responseSorted,
                count: responseLength.count
            });

            if (status === 'pendingacceptance') setValidationFormPending({
                data: responseSorted,
                count: responseLength.count
            });

            if (status === 'closed') setValidationFormClosed({
                data: responseSorted,
                count: responseLength.count
            });
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setLoading(false);
    }

    const changeValidationStatus = async (validationId, status, emailType, samAccount) => {
        const formData = { validationId, status }

        try {
            if (await confirm({
                title: t('validation_change_status_confirm_title'),
                confirmation: t('validation_change_status_confirm_message')
            })) {
                await changeValidationStatusService(formData);

                if (emailType && samAccount) {
                    await sendEmailAction(emailType, samAccount);
                }

                getValidationFormByStatus('open');
                getValidationFormByStatus('closed');

                if (!isAllowedValidation) {
                    getValidationFormByStatus('pendingacceptance');
                }
            }
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const sendEmailAction = async (emailType, samAccount) => {
        try {
            await sendEmail(emailType, samAccount);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_mail_sent_success')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_mail_sent_error')} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
    }

    const reloadTabs = (newPagination) => {
        getValidationFormByStatus('open', newPagination);
        getValidationFormByStatus('closed', newPagination);

        if (!isAllowedValidation) {
            getValidationFormByStatus('pendingacceptance', newPagination);
        }
    }

    const changePageData = (newPagination) => {
        setPagination(newPagination);
        reloadTabs(newPagination);
    }

    return <ValidierungenComponent
        {...props}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loading={loading}
        validationFormOpen={validationFormOpen}
        validationFormPending={validationFormPending}
        validationFormClosed={validationFormClosed}
        changeValidationStatus={changeValidationStatus}
        reloadTabs={reloadTabs}
        sendEmailAction={sendEmailAction}
        pagination={pagination}
        changePageData={changePageData}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(Validierungen);