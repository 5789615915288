import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Button.scss";

const ButtonComponent = (props) => {
    const { children, onClick, disabled, type = 'button', loading, className, style, buttonLink } = props;

    return (
        <>
            {
                buttonLink ?
                    <a
                        href={buttonLink}
                        target="blank"
                        rel="noopener noreferrer"
                        className="button-component">
                        {children}
                    </a> :
                    <button
                        className={`button-component ${disabled || loading ? "disabled" : ''} ${className || ''}`}
                        onClick={() => onClick && onClick()}
                        disabled={disabled || loading}
                        type={type}
                        style={{ ...style }}
                    >
                        {
                            loading ?
                                <div className="button-component-loading">
                                    <ClipLoader
                                        size={17}
                                        color={className?.includes("light") ? "#525662" : "#fff"}
                                        loading={true}
                                    />
                                </div> :
                                <div className="button-content">{children}</div>
                        }
                    </button>
            }
        </>
    );
};

export default ButtonComponent;