import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChartPointModalComponent from '../components/ChartPointModalComponent';
import { changeValidationStatusService } from 'services/excel';
import { getOrganizationService } from 'services/settings';
import { getOrganizationName } from 'services/helper';
import { connect } from "react-redux";
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'

const ChartPointModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        setIsFileValidationModalOpen,
        setIsInventoryValidationModalOpen,
        selectedPoint,
        getChartDataWithFilters,
        setIsGraphLoading,
        settings
    } = props;

    const [departmentName, setDepartmentName] = useState("Loading ...");
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedPoint) getDepartmentName(selectedPoint)
    }, [selectedPoint?.id]);

    useEffect(() => {
        if (!isOpen) setDepartmentName("Loading ...");
    }, [isOpen]);

    const getDepartmentName = async (data) => {
        const organizationData = await getOrganizationService();
        const useGroups = settings?.coordinatorConfig?.useGroups ? true : false;
        const name = getOrganizationName(useGroups, organizationData, data);
        setDepartmentName(name);
    }

    const changeValidationStatus = async (newStatus) => {
        if (newStatus === 'request-validation-inventory') {
            setIsInventoryValidationModalOpen(true);
            setIsOpen(false);
        } else {
            if (newStatus === 'request-validation') {
                setIsFileValidationModalOpen(true);
                setIsOpen(false);
            } else {
                let formData = {}

                if (selectedPoint.statusId === 'no-validation') {
                    formData = {
                        fileId: selectedPoint.id,
                        type: selectedPoint.extension,
                        directory: selectedPoint.path.replace(`\\${selectedPoint.fileName}`, ""),
                        status: null,
                        eucResult: null,
                        alias: selectedPoint.alias
                    }
                } else {
                    formData = {
                        validationId: selectedPoint.validationId,
                        status: null,
                        eucResult: null,
                    }

                    if (newStatus === 'no-validation') {
                        formData.status = 'Deprecated';
                    }
                }

                if (newStatus === 'euc') {
                    formData.status = 'Closed';
                    formData.eucResult = 'euc';
                }

                if (newStatus === 'non-euc') {
                    formData.status = 'Closed';
                    formData.eucResult = 'non-euc';
                }

                if (newStatus === 'in-inventory') {
                    formData.status = 'Inventorized';
                }

                try {
                    if (await confirm({
                        title: t('validation_change_status_confirm_title'),
                        confirmation: t('validation_change_status_confirm_message'),
                    })) {
                        setIsOpen(false);
                        setIsGraphLoading(true);
                        await changeValidationStatusService(formData);
                        getChartDataWithFilters();
                    }
                } catch (err) {
                    console.log(`Error while changing validation status: ${err}`)
                }
            }
        }
    }

    return (
        <ChartPointModalComponent
            {...props}
            changeValidationStatus={changeValidationStatus}
            departmentName={departmentName}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user,
    license: store.licenseReducer.license
});

export default connect(mapStateToProps)(ChartPointModal);