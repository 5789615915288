export async function getSMTPCredentialsService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/smtp/credentials`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
};

export async function submitSMTPService({ host, port, emailFrom, password }) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ host, port, emailFrom, password })
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/smtp/credentials`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
};

export async function getSMTPReceiversService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/idvcoordinator`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
};

export async function submitReceiversService(params) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/idvcoordinator`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
};

export async function getEmailOptionsService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/smtp/email-options`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
};

export async function submitEmailTextService(params) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/smtp/email-options`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
};

export async function sendEmailService(params) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/smtp/send`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
};

export async function getCoordinatorsConfigService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configvalidation/coordinator`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
};
