import React, { useState } from "react";
import Button from 'components/common/Button/Button';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { FcFolder, FcFile } from 'react-icons/fc';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import { FiChevronRight } from 'react-icons/fi';
import { RiFolderForbidLine, RiFolderOpenLine } from 'react-icons/ri';
import { VscFolderOpened } from 'react-icons/vsc';

import './FileTreeModalComponent.scss';
import 'rc-tree/assets/index.css';

const mime = require('mime-types');

const FileTreeModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        selectFileCallback,
        settings,
        currentPath,
        currentChildren,
        currentChildrenFiles,
        getDirectories,
        selectFolderInPath,
        selectedTreeIndex,
        setSelectedTreeIndex,
        allowedAccess,
        allowedAccessMessage,
        isFetching,
        isFetchingRoot
    } = props;

    const [isFileSelected, setIsFileSelected] = useState(false);
    const mainColor = settings?.theme?.mainColor;
    const { t } = useTranslation();
    const allFiles = [...currentChildren, ...currentChildrenFiles];

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal file-tree-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{t('filetree_modal_title')}</h3>
                <p style={{ color: mainColor }}>{t('filetree_modal_subtitle')}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <div className="tree-wrapper">
                    <div className="tree-path">
                        {
                            isFetchingRoot ?
                                <div style={{ width: 150 }}>
                                    <Skeleton width="100%" height="15" />
                                </div> :
                                currentPath?.map((folderInfo, i) => {
                                    const previousPath = currentPath[i - 1];
                                    const folderName = previousPath ? folderInfo.fullName.replace(`${previousPath.fullName}\\`, "") : folderInfo.fullName;

                                    return (
                                        <div key={i} className="tree-path-item">
                                            <div className="tree-path-item__name" onClick={() => selectFolderInPath(i)}>{folderName}</div>
                                            {
                                                (currentPath.length - 1) !== i ?
                                                    <div className="tree-path-item__icon"><FiChevronRight /></div> :
                                                    null
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>

                    {
                        isFetching ?
                            <div>
                                {
                                    _.times(3, (i) => (
                                        <div className="tree-row" key={i}>
                                            <div className="tree-row__icon-skeleton">
                                                <Skeleton width="30" height="30" />
                                            </div>
                                            <div className="tree-row__name-skeleton">
                                                <Skeleton width="80%" height="15" />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div> :
                            allowedAccess ?
                                (currentChildren.length || currentChildrenFiles.length) ?
                                    <div>
                                        {
                                            currentChildren?.map((children, i) => {
                                                const lastBreadCrumb = _.last(currentPath);
                                                const folderName = currentPath.length > 1 ? children.fullName.replace(`${lastBreadCrumb.fullName}\\`, "") : children.fullName;

                                                return (
                                                    <div
                                                        key={i}
                                                        className={selectedTreeIndex === i ? "tree-row selected" : "tree-row"}
                                                        onClick={() => {
                                                            setSelectedTreeIndex(i);
                                                            setIsFileSelected(false);
                                                        }}
                                                        onDoubleClick={() => getDirectories(children)}>
                                                        <div className="tree-row__icon"><FcFolder /></div>
                                                        <div className="tree-row__name">{folderName}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            currentChildrenFiles?.map((childrenFile, i) => (
                                                <div
                                                    key={i}
                                                    className={selectedTreeIndex === (i + currentChildren.length) ? "tree-row selected" : "tree-row"}
                                                    onClick={() => {
                                                        setSelectedTreeIndex(i + currentChildren.length);
                                                        setIsFileSelected(true);
                                                    }}
                                                    onDoubleClick={() => {
                                                        selectFileCallback({
                                                            name: allFiles[selectedTreeIndex].name,
                                                            path: allFiles[selectedTreeIndex].fullName,
                                                            type: mime.lookup(allFiles[selectedTreeIndex].name) || "unknown"
                                                        });
                                                        setIsOpen(false);
                                                    }}>
                                                    <div className="tree-row__icon"><FcFile /></div>
                                                    <div className="tree-row__name">{childrenFile.name}</div>
                                                </div>
                                            ))
                                        }
                                    </div> :
                                    <div className="folder-message empty">
                                        <div className="icon"><VscFolderOpened /></div>
                                        <div className="message">{t('filetree_modal_empty')}</div>
                                    </div> :
                                <div className="folder-message forbidden">
                                    <div className="icon"><RiFolderForbidLine /></div>
                                    <div className="message">{t('filetree_modal_forbidden')}</div>
                                </div>
                    }
                </div>

                <div className="action-buttons">
                    <Button disabled={!allFiles[selectedTreeIndex] || !isFileSelected} onClick={() => {
                        selectFileCallback({
                            name: allFiles[selectedTreeIndex].name,
                            path: allFiles[selectedTreeIndex].fullName,
                            type: mime.lookup(allFiles[selectedTreeIndex].name) || ""
                        });
                        setIsOpen(false);
                    }}>{t('filetree_modal_button')}</Button>
                </div>
            </div>
        </Modal>
    )
};

export default FileTreeModalComponent;