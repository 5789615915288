import _ from 'lodash';

export const setObjValue = (propertyPath, value, obj) => {
    let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".")

    if (properties.length > 1) {
        if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== "object") obj[properties[0]] = {}
        return setObjValue(properties.slice(1), value, obj[properties[0]])
    } else {
        obj[properties[0]] = value
        return true;
    }
}

export const getQuestionTypeName = (type, t) => {
    if (type === "text") return t('settings_inventory_questions_question_textfield');
    if (type === "select") return t('settings_inventory_questions_question_dropdown');
    if (type === "checkbox") return t('settings_inventory_questions_question_multiselect');
    if (type === "file") return t('settings_inventory_questions_question_file');
    if (type === "title") return t("settings_inventory_questions_question_section_title");
}

export const addRequirementToQuestion = (sectionData, questionTrigger, optionTrigger, questionToChange) => {
    const questionIndex = sectionData.questions.findIndex(question => question.order === questionToChange);

    return {
        questionIndexToUpdate: questionIndex,
        newRequirement: {
            requiredOrder: questionTrigger,
            behaviour: "show",
            priority: 1,
            trigger: optionTrigger
        }
    }
}

export const removeRequirementFromQuestion = (sectionData, questionTrigger, optionTrigger) => {
    return sectionData.questions.map(question => {
        const requirementIndex = question.requirements.findIndex(requirement => {
            if (optionTrigger) {
                return requirement.requiredOrder === questionTrigger && requirement.trigger === optionTrigger;
            } else {
                return requirement.requiredOrder === questionTrigger;
            }
        });

        if (requirementIndex !== -1) _.pullAt(question.requirements, requirementIndex)

        return question;
    })
}

export const addFinishedToQuestion = (sectionData, questionTrigger, optionTrigger) => {
    const trigger = optionTrigger || questionTrigger;
    const thisQuestionIndex = sectionData.questions.findIndex(question => question.order === questionTrigger);
    const questionFinishedArray = sectionData.questions[thisQuestionIndex].finished || [];

    if (!questionFinishedArray.includes(trigger)) {
        return [...questionFinishedArray, trigger];
    }

    return questionFinishedArray;
}

export const removeFinishedFromQuestion = (sectionData, questionTrigger, optionTrigger) => {
    const thisQuestionIndex = sectionData.questions.findIndex(question => question.order === questionTrigger);

    return sectionData.questions[thisQuestionIndex].finished?.filter(value => {
        if (optionTrigger) {
            return value !== optionTrigger
        } else {
            return value !== questionTrigger
        }
    });
}

export const checkQuestionRequirements = (formikQuestionValues = [], question, currentSection) => {
    if (question?.requirements?.length) {
        let isVisible = false;

        const requirements = question.requirements.map(questionRequirement => {
            const optionTrigger = questionRequirement.trigger;
            const requiredQuestion = `${currentSection}/${questionRequirement.requiredOrder.split(".").join("*")}`
            const questionValues = formikQuestionValues[requiredQuestion];

            // Needs a specific option to be visible
            if (optionTrigger) {
                return questionValues?.includes(optionTrigger);
            } else {
                // Needs a specific the question filled to be visible
                return !questionValues?.includes("§§§") && questionValues !== "";
            }
        })

        if (_.some(requirements, val => val)) isVisible = true;

        return isVisible;
    }

    return question?.behaviour === "show";
}

export const checkIfQuestionIsVisible = (values, question, currentSection) => {
    const isQuestionVisible = question.behaviour === 'show' || checkQuestionRequirements(values, question, currentSection);
    return isQuestionVisible;
}

export const setQuestionAsLast = (question, otherQuestions) => {
    const allQuestionsOrder = otherQuestions
        .filter(question => !question.parentOrder)
        .map(question => parseInt(question.order));

    const highestQuestionOrder = allQuestionsOrder.length ? _.max(allQuestionsOrder) : 0;

    const thisQuestionOrder = highestQuestionOrder + 1;

    const options = question.options.map((option, i) => {
        return {
            ...option,
            order: `${thisQuestionOrder}.${i + 1}`
        }
    })

    return {
        ...question,
        order: `${thisQuestionOrder}`,
        options
    }
}