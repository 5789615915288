import React, { useState, useEffect } from 'react'
import LanguageComponent from '../components/LanguageComponent'
import { getDictionaryService, setDictionaryService } from 'services/dictionary';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import _ from 'lodash';

const Language = (props) => {
    const { t } = useTranslation();
    const [fetchingDictionary, setFetchingDictionary] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [savedDictionary, setSavedDictionary] = useState({});
    const [dictionary, setDictionary] = useState(null);

    useEffect(() => {
        getDictionary();
    }, []);

    const getDictionary = async () => {
        setFetchingDictionary(true);

        try {
            const dictionary = await getDictionaryService();
            setDictionary(JSON.stringify(dictionary, undefined, 4));
            setSavedDictionary(JSON.stringify(dictionary, undefined, 4));
        } catch (err) {
            console.log(`Error while fetching dictionary: ${err}`);
        }

        setFetchingDictionary(false)
    }

    const updateDictionary = async () => {
        setIsLoading(true);

        try {
            const dictionaryParsed = JSON.parse(dictionary);

            await setDictionaryService(dictionaryParsed);

            setSavedDictionary(JSON.stringify(dictionaryParsed, undefined, 4));

            i18n.addResourceBundle('en', 'translations', {
                ...dictionaryParsed.en
            });

            i18n.addResourceBundle('de', 'translations', {
                ...dictionaryParsed.de
            });

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_update_dictionary_success')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while fetching dictionary: ${err}`);
        }

        setIsLoading(false)
    }

    return (
        <LanguageComponent
            dictionary={dictionary}
            setDictionary={setDictionary}
            updateDictionary={updateDictionary}
            isLoading={isLoading}
            savedDictionary={savedDictionary}
            fetchingDictionary={fetchingDictionary}
        />
    )
}
export default Language;