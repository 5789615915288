import customFetch from './fetch';
import _ from 'lodash';

export async function getRiskOfficersService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/riskOfficer`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function updateRiskOfficerService(riskOfficerData) {
    try {
        const body = JSON.stringify(riskOfficerData);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/riskOfficer`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}