import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EucCheckQuestionsComponent from '../components/EucCheckQuestionsComponent';
import { sendExcelDataService } from 'services/excel';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getExcelDataService } from 'services/excel';
import { parseFileOverviewModalMetadata } from 'services/helper';

const EucCheckQuestions = (props) => {
    const { location } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [successStatus, setSuccessStatus] = useState('');
    const [filename, setFilename] = useState('');
    const [alias, setAlias] = useState('');
    const [oldFormData, setOldFormData] = useState(null);
    const [isLoadingOldFormData, setIsLoadingOldFormData] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const urlParams = location?.search?.split("?validationId=");
        const validationId = urlParams[1];
        if (validationId) getValidation(validationId);
    }, [location]);

    const getValidation = async (validationId) => {
        setIsLoadingOldFormData(true);

        try {
            const response = await getExcelDataService({ validationId }, true);
            const thisValidation = response[0];
            const thisValidationParsed = parseFileOverviewModalMetadata(thisValidation);
            setOldFormData(thisValidationParsed);
        } catch (err) {
            console.log(err);
        }

        setIsLoadingOldFormData(false);
    }

    const sendQuestionsData = async (formData) => {
        setIsLoading(true);

        try {
            const response = await sendExcelDataService(formData);
            setFilename(formData.fileName);
            setAlias(formData.alias);
            setSuccessStatus(response.eucResult);
            setFormSent(true);
        } catch (err) {
            console.log(`Error: ${err}`);
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={err} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }

        setIsLoading(false)
    }

    return <EucCheckQuestionsComponent
        {...props}
        successStatus={successStatus}
        filename={filename}
        alias={alias}
        sendQuestionsData={sendQuestionsData}
        formSent={formSent}
        isLoading={isLoading}
        oldFormData={oldFormData}
        isLoadingOldFormData={isLoadingOldFormData}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default withRouter(connect(mapStateToProps)(EucCheckQuestions));