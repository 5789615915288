import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { updateDashboard } from "redux/actions/dashboardActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DashboardComponent from '../components/DashboardComponent';
import { getOrganizationService } from 'services/settings';
import { getScanProgressService } from 'services/scanning';
import _ from 'lodash';
import appFilters from 'services/appFilters';

const Dashboard = (props) => {
    const { settings, dashboard, updateDashboard, history, match: { params: { currentPath } }, user } = props;

    const [selectedTenants, setSelectedTenants] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const [organizationData, setOrganizationData] = useState([]);
    const [isFetchingOrganizations, setIsFetchingOrganizations] = useState(true);

    const [performedScans, setPerformedScans] = useState([]);
    const [selectedScanIndex, setSelectedScanIndex] = useState("none");
    const [isFetchingPerformedScans, setIsFetchingPerfomedScans] = useState(true);

    const [globalFilters, setGlobalFilters] = useState(null);

    const updateGlobalFilters = useRef(null);

    const { t } = useTranslation();

    const userPermissions = user && user.groupPermissions;

    const sidebarItems = [
        {
            itemTitle: t('analysis_tab1_sidebar_title_1'),
            items: [
                {
                    key: 'graphen',
                    label: t('analysis_tab1_sidebar_item_1_1'),
                    permission: true
                },
                {
                    key: 'listenansicht',
                    label: t('analysis_tab1_sidebar_item_1_2'),
                    permission: true
                },
                {
                    key: 'generation',
                    label: t('analysis_tab1_sidebar_item_1_3'),
                    permission: userPermissions.includes("EUCDASHBOARDWRITE")
                },
                // {
                //     key: 'eizelansicht',
                //     label: t('analysis_tab1_sidebar_item_1_3')
                // }
            ]
        },
        // {
        //     itemTitle: t('analysis_tab1_sidebar_title_2'),
        //     items: [
        //         {
        //             key: 'kritikalitat',
        //             label: t('analysis_tab1_sidebar_item_2_1')
        //         },
        //         {
        //             key: 'komplexitat',
        //             label: t('analysis_tab1_sidebar_item_2_2')
        //         },
        //     ]
        // },
        // {
        //     itemTitle: 'Stichproben',
        //     items: ['Generierung', 'Status']
        // },
        {
            itemTitle: t('analysis_tab1_sidebar_title_3'),
            items: [
                {
                    key: 'gudiance',
                    label: t('analysis_tab1_sidebar_item_3_1'),
                    permission: true
                },
                {
                    key: 'support',
                    label: t('analysis_tab1_sidebar_item_3_2'),
                    permission: true
                },
            ]
        },
    ];

    useEffect(() => {
        if (currentPath) {
            const allRoutes = _.flattenDeep(sidebarItems.map(sidebarItem => sidebarItem.items));
            const currentRoute = allRoutes.filter(route => route.key === currentPath)[0];
            const hasPermission = currentRoute.permission;

            if (!hasPermission) {
                history.push("/analyzing/dashboard");
            }
        }
    }, [currentPath]);

    useEffect(() => {
        getOrganization();
        getPerformedScans();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    useEffect(() => {
        if (updateGlobalFilters.current && dashboard.chart && dashboard.list) {
            const { tenantIds, groupIds, departmentIds } = updateGlobalFilters.current;

            setGlobalFilters({
                orgTenantId: _.isEmpty(tenantIds) ? null : tenantIds,
                orgGroupId: _.isEmpty(groupIds) ? null : groupIds,
                orgDepartmentId: _.isEmpty(departmentIds) ? null : departmentIds,
                runId: selectedScanIndex,
            });

            updateGlobalFilters.current = null;
        }
    }, [dashboard])

    const getOrganization = async () => {
        setIsFetchingOrganizations(true);

        try {
            const response = await getOrganizationService();
            let formattedOrganizations = {
                0: {
                    name: 'analysis_tab1_topbar_tenant_default',
                    id: 0,
                    refId: 0,
                    groups: {}
                }
            };

            if (response?.organization.length)
                response.organization.forEach((org) => {
                    formattedOrganizations[org.tenantId] = {
                        name: org.name,
                        id: org.tenantId,
                        refId: org.tenantId,
                        groups: {}
                    };

                    org.groups.forEach((group) => {
                        formattedOrganizations[org.tenantId].groups[`${org.tenantId}-${group.groupId}`] = {
                            name: group.name,
                            refId: `${org.tenantId}-${group.groupId}`,
                            id: group.groupId,
                            departments: {}
                        }

                        group.departments.forEach((dept) => {
                            formattedOrganizations[org.tenantId].groups[`${org.tenantId}-${group.groupId}`].departments[`${org.tenantId}-${group.groupId}-${dept.departmentId}`] = {
                                name: dept.name,
                                refId: `${org.tenantId}-${group.groupId}-${dept.departmentId}`,
                                id: dept.departmentId
                            }
                        })
                    })
                });

            selectAllByDefault(formattedOrganizations);

            setOrganizationData(formattedOrganizations);
        } catch (err) {
            console.log(err);
        }

        setIsFetchingOrganizations(false);
    }

    const getPerformedScans = async () => {
        setIsFetchingPerfomedScans(true);

        try {
            const response = await getScanProgressService();
            setPerformedScans(response);
        } catch (err) {
            console.log("Error while fetching scan modes", err);
        }

        setIsFetchingPerfomedScans(false);
    }

    const applyGlobalFilters = (currentTenants = null, currentGroups = null, currentDepartments = null) => {
        let tenantIds = currentTenants || [];
        let groupIds = currentGroups || [];
        let departmentIds = currentDepartments || [];

        const availableGroups = _.flattenDeep(selectedTenants.map((selectedTenant) => {
            const tenant = organizationData[selectedTenant.value];
            return _.values(tenant.groups).map((group) => ({
                label: group.name,
                value: group.refId
            }))
        }))

        const availableDepartments = _.flattenDeep(selectedGroups.map((selectedGroup) => {
            const path = selectedGroup.value?.split('-');
            const group = organizationData[path[0]].groups[`${path[0]}-${path[1]}`];
            return _.values(group.departments).map((dept) => ({
                label: dept.name,
                value: dept.refId
            }))
        }))

        const validSelectedGroups = selectedGroups.filter(a => availableGroups.some(b => a.value === b.value))
        const validSelectedDepartments = selectedDepartments.filter(a => availableDepartments.some(b => a.value === b.value))

        selectedTenants.forEach((selectedTenant) => {
            const tenantId = parseInt(selectedTenant.value);
            tenantIds.push(tenantId);
        })
        validSelectedGroups.forEach((selectedGroup) => {
            const path = selectedGroup.value.split('-');
            const tenantId = parseInt(path[0]);
            const groupId = parseInt(path[1]);
            groupIds.push(groupId);
            const index = tenantIds.indexOf(tenantId);
            if (index >= 0) tenantIds.splice(index, 1);
        })
        validSelectedDepartments.forEach((selectedDepartment) => {
            const path = selectedDepartment.value.split('-');
            const groupId = parseInt(path[1]);
            const dptId = parseInt(path[2]);
            departmentIds.push(dptId);
            const index = groupIds.indexOf(groupId);
            if (index >= 0) groupIds.splice(index, 1);
        })

        updateDashboard(appFilters)

        updateGlobalFilters.current = {
            tenantIds, groupIds, departmentIds
        };
    }

    const getAvailableTenants = (organizationData) => {
        return _.values(organizationData).map((tenant) => ({
            label: t(tenant.name),
            value: tenant.refId
        }))
    }

    const getAvailableGroups = (selectedTenants, organizationData) => {
        return _.flattenDeep(selectedTenants.map((selectedTenant) => {
            const tenant = organizationData[selectedTenant.value];

            return _.values(tenant?.groups).map((group) => ({
                label: t(group.name),
                value: group.refId
            }))
        }))
    }

    const getAvailableDepartments = (selectedGroups, organizationData) => {
        return _.flattenDeep(selectedGroups.map((selectedGroup) => {
            const path = selectedGroup.value?.split('-');
            const group = organizationData[path[0]]?.groups[`${path[0]}-${path[1]}`];

            return _.values(group?.departments).map((dept) => ({
                label: t(dept.name),
                value: dept.refId
            }))
        }))
    }

    const getAvailableScans = (departments) => {
        const departmentsStructure = departments.map(department => department.value);

        const filteredScans = performedScans?.filter(scan => {
            const scanStructure = scan?.departments?.map(department => {
                return `${department.orgTenantId}-${department.orgGroupId}-${department.orgDepartmentId}`;
            });

            const isScanIncluded = _.difference(scanStructure, departmentsStructure).length === 0;
            return isScanIncluded;
        });

        return filteredScans;
    }

    const selectAllByDefault = (organizationData) => {
        const availableTenants = getAvailableTenants(organizationData);
        const availableGroups = getAvailableGroups(availableTenants, organizationData);
        const availableDepartments = getAvailableDepartments(availableGroups, organizationData);

        setSelectedTenants(availableTenants);
        setSelectedGroups(availableGroups);
        setSelectedDepartments(availableDepartments);
    }

    return <DashboardComponent
        {...props}
        settings={settings}
        currentPath={currentPath}
        history={history}
        organizationData={organizationData}
        performedScans={performedScans}
        selectedTenants={selectedTenants}
        setSelectedTenants={setSelectedTenants}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        selectedDepartments={selectedDepartments}
        setSelectedDepartments={setSelectedDepartments}
        selectedScanIndex={selectedScanIndex}
        setSelectedScanIndex={setSelectedScanIndex}
        applyGlobalFilters={applyGlobalFilters}
        globalFilters={globalFilters}
        isFetchingOrganizations={isFetchingOrganizations}
        getAvailableTenants={getAvailableTenants}
        getAvailableGroups={getAvailableGroups}
        getAvailableDepartments={getAvailableDepartments}
        getAvailableScans={getAvailableScans}
        isFetchingPerformedScans={isFetchingPerformedScans}
        sidebarItems={sidebarItems}
    />;
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard,
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateDashboard }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));