import React, { useState } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";
import { AiOutlineSearch, AiOutlineStop } from 'react-icons/ai'
import { FiStopCircle } from 'react-icons/fi'
import WizardModal from 'components/Scanning/WizardModal/containers/WizardModal';
import ClipLoader from "react-spinners/ClipLoader";

import './UbersichtScansComponent.scss';
//ScanProgressService

const UbersichtScansComponentLoading = () => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
            </div>

            {
                _.times(5, (j) => (
                    <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                    </div>
                ))
            }
        </div>
    )
}

const UbersichtScansComponent = (props) => {
    const { progressList, isFetching, openWizardModal, isModalOpen, setIsModalOpen, currentConfigId, stopScan, loadingStopScan, abortScan } = props;
    const { t } = useTranslation();

    function numberFormat(n) {
        let [n2, n3] = [n, 0];
        while (n2 >= 1e3) {
            n2 /= 1e3;
            n3++;
        }
        return n2.toFixed(n3 > 0 ? 1 : 0) + ['', 'k', 'M', 'G'][n3];
    }

    const renderStopButton = (progress) => {
        if (progress.taskState === "GeneratorRunning" || progress.taskState === "ProcessorRunning") {
            return (
                <div className="stop-button" onClick={() => stopScan(progress.runId)}>
                    <FiStopCircle style={{ fontSize: 16 }} />
                    <span>{t('status_scan_table_stop_button')}</span>
                </div>
            )
        }
        return null;
    }

    const renderAbortButton = (progress) => {
        if (progress.runStatus === "Done" || progress.runStatus === "Failure") return null;
        else
            return (
                <div className="stop-button" onClick={() => abortScan(progress.runId)}>
                    <AiOutlineStop style={{ fontSize: 15 }} />
                    <span>{t('status_scan_table_abort_button')}</span>
                </div>
            )
    }

    return (
        <Layout>
            <div className="ubersicht-wrapper">
                <div className="ubersicht-content">
                    {
                        isFetching ?
                            <UbersichtScansComponentLoading /> :
                            progressList.length ?
                                <div className="table-wrapper">
                                    <table className="kpmg-table stripped">
                                        <thead>
                                            <tr>
                                                <th>{t('scan_tab3_table_header_1')}</th>
                                                <th>{t('status_scan_table_scan_type')}</th>
                                                <th>{t('status_scan_table_scan_description')}</th>
                                                <th>{t('scan_tab3_table_header_2')}</th>
                                                <th>{t('scan_tab3_table_header_3')}</th>
                                                <th>{t('scan_tab3_table_header_4')}</th>
                                                <th>{t('scan_tab3_table_header_5')}</th>
                                                <th>{t('scan_tab3_table_header_6')}</th>
                                                <th>{t('status_scan_table_action')}</th>
                                                <th>{t('status_scan_table_configuration')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="empty">
                                                <td colSpan="10"></td>
                                            </tr>
                                            {
                                                progressList.map((progress, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className='number-row' style={{ width: 40 }}>{progress.runId}</td>
                                                            <td>{progress?.scanModes?.[0]?.scanGroup}</td>
                                                            <td>{progress?.departments?.[0]?.department}</td>
                                                            <td>{progress.runStatus}</td>
                                                            <td>{progress.hasTask ? progress.taskState : "NotStarted"}</td>
                                                            <td>{progress.startDate}</td>
                                                            <td>{progress.endDate}</td>
                                                            <td className='number-row' style={{ minWidth: 80 }} >{numberFormat(progress.processed)}/{numberFormat(progress.toProcess)}</td>
                                                            <td style={{ width: 110, textAlign: 'center' }}>
                                                                {
                                                                    loadingStopScan.includes(progress.runId) ?
                                                                        <ClipLoader
                                                                            size={17}
                                                                            color={"#525662"}
                                                                            loading={true}
                                                                        /> :
                                                                        <>
                                                                            {renderStopButton(progress)}
                                                                            {renderAbortButton(progress)}
                                                                        </>
                                                                }

                                                            </td>

                                                            <td style={{ width: 100 }}>
                                                                <div className="details-button" onClick={() => openWizardModal(progress.configRunId)}>
                                                                    <AiOutlineSearch style={{ fontSize: 16 }} />
                                                                    <span>{t('scan_tab2_table_button')}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div> :
                                <div>
                                    <p>{t('scan_tab3_table_empty')}</p>
                                </div>
                    }
                </div>
            </div>
            <WizardModal
                id={currentConfigId}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </Layout>
    )
};

export default UbersichtScansComponent;