import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateDashboard } from "redux/actions/dashboardActions";
import GenerationComponent from '../components/GenerationComponent';
import { getFilterFilesService } from 'services/scanning';
import { getSampleDataService } from 'services/analysis';
import moment from 'moment';
import _ from 'lodash';
import appFilters from 'services/appFilters';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Generation = (props) => {
    const { settings, globalFilters, toggleChartCallback, updateDashboard, dashboard } = props;
    const [changedTableLoading, setChangedTableLoading] = useState(false);
    const [fileTypes, setFileTypes] = useState([]);
    const [initialListData, setInitialListData] = useState([]);
    const [listData, setListData] = useState([]);
    const [localFilters, setLocalFilters] = useState(appFilters.sample.filters);
    const [selectedRows, setSelectedRows] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        getFilterFiles();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    useEffect(() => {
        if (dashboard?.sample?.data.length) {
            setListData(dashboard.sample.data);
        } else {
            if (globalFilters) {
                setChangedTableLoading(true);
                getListData(globalFilters, localFilters);
            }
        }
    }, [globalFilters])

    useEffect(() => {
        setLocalFilters(dashboard?.sample?.filters);
    }, [dashboard?.sample?.filters])

    const getFilterFiles = async () => {
        try {
            const response = await getFilterFilesService();
            const responseParsed = response.map(fileType => {
                return {
                    label: fileType.fileName,
                    value: fileType.extensions
                }
            })
            setFileTypes(responseParsed);
        } catch (err) {
            console.log("Error while fetching filter files", err);
        }
    }

    const initializeDateFilter = (listData) => {
        const listDates = listData.map(item => moment(item.lastModified).valueOf());
        const minDate = _.min(listDates);
        const maxDate = _.max(listDates);

        return {
            date: {
                start: moment(minDate).subtract(4, 'years').format("YYYY-MM-DD"),
                end: moment(maxDate).format("YYYY-MM-DD"),
            }
        }
    }

    const getListData = async (globalFilters, thisLocalFilters) => {
        try {
            toast(<ToastrContent type="warning" title={t('toastr_title_warning')} message={t('toastr_generation_warning')} />, {
                progressClassName: "toastr-progress-bar warning"
            });

            setSelectedRows([]);

            let initializedLocalFilters = _.cloneDeep(localFilters);
            const newListData = await getSampleDataService(globalFilters, thisLocalFilters);

            const newListDataParsed = newListData.map(data => {
                return {
                    ...data,
                    id: data.fileId,
                    path: data.fullName,
                }
            });

            setListData(newListDataParsed);
            setInitialListData(newListDataParsed);

            // if (!thisLocalFilters) {
            //     const initializedDateFilter = initializeDateFilter(newListData);

            //     initializedLocalFilters = {
            //         ...initializedLocalFilters,
            //         ...initializedDateFilter
            //     }
            // }

            updateDashboard({
                ...dashboard,
                sample: {
                    ...dashboard.sample,
                    data: newListDataParsed,
                    filters: thisLocalFilters || initializedLocalFilters,
                }
            });
        } catch (err) {
            console.log("Error fetching graph data", err);
        }

        setChangedTableLoading(false);
    }

    const changeFilter = (key, value, runFetch = false) => {
        let newLocalFilters = _.cloneDeep(localFilters);

        if (Array.isArray(key)) {
            key.forEach((thisKey, i) => {
                _.set(newLocalFilters, thisKey, value[i]);
            });
        } else {
            _.set(newLocalFilters, key, value);
        }

        if (runFetch) applyLocalFilters(newLocalFilters, globalFilters);

        setLocalFilters(newLocalFilters);

        return newLocalFilters;
    }

    const applyLocalFilters = (newLocalFilters, globalFilters) => {
        setChangedTableLoading(true);
        getListData(globalFilters, newLocalFilters);
    }

    const removeUpdatedRows = rows => {
        const rowsIds = rows.map(row => row.fileId);

        const newList = listData.filter(listRow => {
            return !rowsIds.includes(listRow.id)
        });

        setListData(newList);
        setInitialListData(newList);

        updateDashboard({
            ...dashboard,
            sample: {
                ...dashboard.sample,
                data: newList
            }
        })

    }

    const sortTable = (column) => {
        let sortOrder = 'asc';

        if (localFilters.orderColumn === column) {
            if (localFilters.orderDirection === 'asc') sortOrder = 'desc';
            if (localFilters.orderDirection === 'desc') {
                sortOrder = null;
                column = null;
            };
        }

        const newLocalFilters = changeFilter(["orderColumn", "orderDirection"], [column, sortOrder]);
        const newListData = _.orderBy(initialListData, newLocalFilters.orderColumn, newLocalFilters.orderDirection);
        setListData(newListData);

        updateDashboard({
            ...dashboard,
            sample: {
                ...dashboard.sample,
                data: newListData,
                filters: newLocalFilters,
            }
        })
    }

    const changeSelectedRow = newRow => {
        let newSelectedRows = _.cloneDeep(selectedRows);
        const includesId = newSelectedRows.filter(row => row.id === newRow.id)[0];

        if (includesId) {
            newSelectedRows = selectedRows.filter(row => row.id !== newRow.id);
        } else {
            newSelectedRows.push(newRow);
        }

        setSelectedRows(newSelectedRows);
    }

    return <GenerationComponent
        settings={settings}
        listData={listData}
        setListData={setListData}
        setLocalFilters={setLocalFilters}
        localFilters={localFilters}
        globalFilters={globalFilters}
        changedTableLoading={changedTableLoading}
        setChangedTableLoading={setChangedTableLoading}
        toggleChartCallback={toggleChartCallback}
        applyLocalFilters={applyLocalFilters}
        fileTypes={fileTypes}
        changeFilter={changeFilter}
        sortTable={sortTable}
        changeSelectedRow={changeSelectedRow}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        removeUpdatedRows={removeUpdatedRows}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateDashboard }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Generation);