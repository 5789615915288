import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getIndicatorsService, setIndicatorsService } from "services/settings";
import IndicatorsComponent from '../components/IndicatorsComponent';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const Indicators = (props) => {
    const [isFetchingIndicators, setIsFetchingIndicators] = useState(true);
    const [indicators, setIndicators] = useState([]);
    const [allIndicators, setAllIndicators] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getIndicators();
    }, []);

    const getIndicators = async () => {
        try {
            const response = await getIndicatorsService();
            setIndicators(response);

            const technicalFilter = response?.filter((indicator) => indicator.type === "ExcelTechnichal");
            const accessFilter = response?.filter((indicator) => indicator.type === "AccessTechnichal");

            const allIndicators = [...accessFilter, ...technicalFilter];
            const indicatorsInitialized = allIndicators.map(indicator => {
                return {
                    ...indicator,
                    valueType: indicator.valueType || "Relative"
                }
            })

            setAllIndicators(indicatorsInitialized);
        } catch (e) {
            alert('Something went wrong: ' + e.message)
        }

        setIsFetchingIndicators(false);
    }

    const editIndicator = (indicatorId, field, value) => {
        let indicatorsCopy = _.cloneDeep(allIndicators);

        const indicatorIndex = indicatorsCopy.findIndex(indicator => indicator.indicatorId === indicatorId);
        indicatorsCopy[indicatorIndex][field] = value;

        setAllIndicators([...indicatorsCopy])
    }

    const discardIndicatorsChanges = () => {
        let indicatorsCopy = _.cloneDeep(indicators);
        setAllIndicators([...indicatorsCopy]);
    }

    const submitIndicators = async () => {
        setIsSubmitting(true);

        try {
            const indicatorsToSend = allIndicators.map((item) => {
                const { indicatorId, name, type, weight, threshold, kpiType, infoText, valueType } = item;
                return {
                    indicatorId,
                    name,
                    type,
                    weight: parseInt(weight),
                    threshold: parseInt(threshold),
                    kpiType,
                    valueType,
                    infoText: infoText || ""
                };
            });

            await setIndicatorsService(indicatorsToSend);
            await getIndicators();

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_indicators_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log("Failed submit indicators:", err);
        }

        setIsSubmitting(false);
    }

    return <IndicatorsComponent
        indicators={indicators}
        allIndicators={allIndicators}
        editIndicator={editIndicator}
        discardIndicatorsChanges={discardIndicatorsChanges}
        submitIndicators={submitIndicators}
        isFetchingIndicators={isFetchingIndicators}
        isSubmitting={isSubmitting}
    />;
};

export default Indicators;