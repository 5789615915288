import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setTopicsService, getTopicsService, deleteTopicService, setTopicKeywordsService, getTopicKeywordsService } from "services/topic";
import KeywordsComponent from '../components/KeywordsComponent';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions';
import readXlsxFile from 'read-excel-file';
import { exportToXlsx } from "services/helper";
import moment from "moment";

const Keywords = (props) => {
    const [topics, setTopics] = useState([]);
    const [allTopics, setAllTopics] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deletingTopicIds, setDeletingTopicIds] = useState([]);
    const [isFetchingTopics, setIsFetchingTopics] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getTopics();
    }, []);

    const getTopics = async () => {
        const response = await getTopicsService();
        const topicsInitialized = response.map(topic => {
            return {
                ...topic,
                valueType: topic.valueType || "Relative"
            }
        })
        setTopics(topicsInitialized);
        setAllTopics(topicsInitialized);
        setIsFetchingTopics(false);
        return topicsInitialized;
    }

    const discardTopicsChanges = () => {
        let topicsCopy = _.cloneDeep(topics);
        setAllTopics([...topicsCopy]);
    }

    const submitTopics = async () => {
        setIsSubmitting(true);

        try {
            await formatAndSubmitTopics(allTopics);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_keywords_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log("Failed submit topics:", err);
        }

        setIsSubmitting(false);
    }

    const formatAndSubmitTopics = async (topics) => {
        const indicatorsToSend = topics.map((item) => {
            const { topicId, name, weight, threshold, kpiType, infoText, valueType } = item;
            return {
                topicId,
                name,
                weight: parseInt(weight),
                threshold: parseInt(threshold),
                kpiType,
                valueType,
                infoText: infoText || ""
            };
        })

        await setTopicsService(indicatorsToSend);
        return await getTopics();
    }

    const editTopic = (index, field, value) => {
        let topicsCopy = _.cloneDeep(allTopics);
        topicsCopy[index][field] = value;
        setAllTopics([...topicsCopy])
    }

    const deleteTopic = async (topicId) => {
        if (await confirm({
            title: t('deletekeyword_confirm_title'),
            confirmation: t('deletekeyword_confirm_message'),
        })) {
            setDeletingTopicIds([...deletingTopicIds, topicId]);

            try {
                await deleteTopicService(topicId);
                await getTopics();

                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_topics_updated')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            } catch (err) {
                console.log("Failed delete topic:", err);
            }

            const newDeletingTopicIds = deletingTopicIds.filter(id => id !== topicId);
            setDeletingTopicIds(newDeletingTopicIds);
        }
    }

    const uploadExcel = async (e) => {
        try {
            setIsSubmitting(true);
            const file = e.target.files[0];
            const format = _.last(file.name?.split('.'));
            if (format !== "xlsx") throw new Error(t('settings_tab4_error_xlsx'));
            let newTopics = [];
            let newKeywords = [];

            //read excel topics
            const indicatorRows = await readXlsxFile(file, { sheet: 1 })
            if (indicatorRows.length) {
                indicatorRows.shift();
                newTopics = indicatorRows.map((row, i) => {
                    const topicAlreadyExists = _.find(allTopics, (t) => t.name === row[0]);
                    if (topicAlreadyExists) throw new Error(`${t('settings_tab4_error_xlsx_duplicate')}: "${row[0] || t('settings_tab4_error_xlsx_empty')}" ${t('settings_tab4_error_xlsx_line1')} ${i + 2}`);
                    if (row[5] !== "Relative" && row[5] !== "Absolute") throw new Error(`${t('settings_tab4_error_xlsx_type')}: ${row[5]}`);
                    return {
                        name: row[0],
                        infoText: {
                            en: row[1],
                            de: row[2]
                        },
                        weight: row[4],
                        threshold: row[3],
                        kpiType: row[6] === "Complexity" ? "Complexity" : row[6] === "Criticality" ? "Criticality" : "NonAssigned",
                        valueType: row[5],
                    }
                });
            }
            const topics = [...allTopics, ...newTopics];

            //read excel keywords
            const keywordRows = await readXlsxFile(file, { sheet: 2 });
            if (keywordRows.length) {
                keywordRows.shift();
                newKeywords = keywordRows.map((row) => {
                    return {
                        name: row[1],
                        topicName: row[0]
                    }
                });
                newKeywords.forEach((kword, i) => {
                    const topicBelonged = _.find(topics, (t) => t.name === kword.topicName);
                    if (!topicBelonged) throw new Error(`${t('settings_tab4_error_xlsx_not_exists')}: "${kword.topicName || t('settings_tab4_error_xlsx_empty')}}" ${t('settings_tab4_error_xlsx_line2')} ${i + 2}`);
                });
            }

            const updatedTopics = await formatAndSubmitTopics(topics);
            const promises = newKeywords.map((kword, i) => {
                const topicBelonged = _.find(updatedTopics, (t) => t.name === kword.topicName); //Caution: using the name to search may lead to issues 
                return setTopicKeywordsService([{ topicId: topicBelonged.topicId, name: kword.name }]);
            });
            await Promise.all(promises);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_excel_uploaded')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        }
        catch (e) {
            console.log('error', e);
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={e?.message || JSON.stringify(e)} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
        finally {
            setIsSubmitting(false);
        }
    }

    const exportKeywords = async () => {
        let xlsxToExport = {};
        let indicatorColumns = [], keywordColumns = [];

        const promises = allTopics.map(async (topic) => {
            indicatorColumns.push({
                'Indicator': topic.name,
                'Infotext in English': topic.infoText?.en,
                'Infotext in German': topic.infoText?.de,
                'Threshold': topic.threshold,
                'Weight': topic.weight,
                'Indicator Type': topic.valueType,
                'KPI': topic.kpiType
            });

            const keywords = await getTopicKeywordsService(topic.topicId);
            keywords.forEach((k) => keywordColumns.push({
                'Indicator': topic.name,
                'Keyword': k.name
            }))
            return true;
        });

        await Promise.all(promises);

        xlsxToExport["Indicator"] = indicatorColumns;
        xlsxToExport["Keywords"] = keywordColumns;

        const csvName = `Export EUCP - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}`;
        exportToXlsx(xlsxToExport, csvName);
    }

    const exportTemplate = async () => {
        let xlsxToExport = {};

        const indicatorColumns = [
            {
                'Indicator': "example1",
                'Infotext in English': "This an example 1",
                'Infotext in German': "Das ist ein Beispiel 1",
                'Threshold': 10,
                'Weight': 25,
                'Indicator Type': "Relative",
                'KPI': "Complexity"
            },
            {
                'Indicator': "example2",
                'Infotext in English': "This an example 2",
                'Infotext in German': "Das ist ein Beispiel 2",
                'Threshold': 4,
                'Weight': 14,
                'Indicator Type': "Absolute",
                'KPI': "Criticality"
            },
        ];

        const keywordColumns = [
            {
                'Indicator': "example1",
                'Keyword': "Key1"
            },
            {
                'Indicator': "example1",
                'Keyword': "Key2"
            },
            {
                'Indicator': "example2",
                'Keyword': "KeyB"
            },
        ];

        xlsxToExport["Indicator"] = indicatorColumns;
        xlsxToExport["Keywords"] = keywordColumns;
        const csvName = `Export EUCP - Template`;
        exportToXlsx(xlsxToExport, csvName);

    }

    return <KeywordsComponent
        isFetchingTopics={isFetchingTopics}
        deleteTopic={deleteTopic}
        deletingTopicIds={deletingTopicIds}
        getTopics={getTopics}
        editTopic={editTopic}
        submitTopics={submitTopics}
        discardTopicsChanges={discardTopicsChanges}
        isSubmitting={isSubmitting}
        allTopics={allTopics}
        uploadExcel={uploadExcel}
        exportTemplate={exportTemplate}
        exportKeywords={exportKeywords}
    />;
};

export default Keywords;