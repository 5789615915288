import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from 'components/common/Button/Button';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './AddKeywordModal.scss';

const AddKeywordModal = (props) => {
    const { isOpen, setIsOpen, addNewKeywordCallback, settings, loading, isMetadata, metadataFields } = props;
    const [keyword, setKeyword] = useState("");
    const [field, setField] = useState("");
    const [rule, setRule] = useState("IsLike");
    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    useEffect(() => {
        setKeyword("");
        setField("");
        setRule("IsLike");
        formik.resetForm();
    }, [isOpen]);

    let initialValues = {
        keyword
    }

    let validationSchema = {
        keyword: Yup.string().required(t('formik_required_field')),
    }

    if (isMetadata) {
        initialValues.field = field;
        initialValues.rule = rule;

        validationSchema.field = Yup.string().required(t('formik_required_field'));
        validationSchema.rule = Yup.string().required(t('formik_required_field'));
        validationSchema.keyword = Yup.string()
            .when('rule', {
                is: (rule) => {
                    if (rule) {
                        return rule === "IsLike";
                    }
                },
                then: Yup.string().required(t('formik_required_field'))
            });
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape(validationSchema),
        enableReinitialize: true,
        onSubmit: values => {
            addNewKeywordCallback(values);
        },
    });

    const { errors, touched } = formik;

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal add-keyword-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{isMetadata ? t('addkeyword_modal_title_metadata') : t('addkeyword_modal_title')}</h3>
                <p style={{ color: mainColor }}>{isMetadata ? t('addkeyword_modal_subtitle_metadata') : t('addkeyword_modal_subtitle')}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    {
                        isMetadata &&
                        <div className="input-wrapper" style={{ marginBottom: 20 }}>
                            <label htmlFor="keyword">{isMetadata ? t('addkeyword_modal_input_field_label_metadata') : t('addkeyword_modal_input_field_label')}</label>
                            <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.field}
                                name="field"
                                id="field">
                                {metadataFields?.map((field) => {
                                    return <option value={field.field}>{field.field}</option>
                                })}
                            </select>
                            {
                                errors.field && touched.field &&
                                <div className="error-message">
                                    {errors.field}
                                </div>
                            }
                        </div>
                    }
                    {
                        isMetadata &&
                        <div className="input-wrapper" style={{ marginBottom: 20 }}>
                            <label htmlFor="keyword">{isMetadata ? t('addkeyword_modal_input_rule_label_metadata') : t('addkeyword_modal_input_rule_label')}</label>

                            <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.rule}
                                name="rule"
                                id="rule">
                                <option value="IsLike">{t("addkeyword_modal_input_rule_option_1")}</option>
                                <option value="IsNotNull">{t("addkeyword_modal_input_rule_option_2")}</option>
                            </select>

                            {
                                errors.rule && touched.rule &&
                                <div className="error-message">
                                    {errors.rule}
                                </div>
                            }
                        </div>
                    }
                    {
                        ((isMetadata && formik.values.rule === "IsLike") || !isMetadata) &&
                        <div className="input-wrapper" style={{ marginBottom: 20 }}>
                            <label htmlFor="keyword">{isMetadata ? t('addkeyword_modal_input_label_metadata') : t('addkeyword_modal_input_label')}</label>
                            <input
                                className={errors.keyword && touched.keyword ? 'error' : ''}
                                id="keyword"
                                name="keyword"
                                type="text"
                                placeholder={isMetadata ? t('addkeyword_modal_input_placeholder_metadata') : t('addkeyword_modal_input_placeholder')}
                                value={formik.values.keyword || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur} />
                            {
                                errors.keyword && touched.keyword &&
                                <div className="error-message">
                                    {errors.keyword}
                                </div>
                            }
                        </div>
                    }
                    <div className="action-buttons">
                        <Button onClick={() => formik.resetForm()}>{isMetadata ? t('addkeyword_modal_reset_field_metadata') : t('addkeyword_modal_reset_field')}</Button>
                        <Button type="submit" loading={loading} style={{ width: 100 }}>{isMetadata ? t('addkeyword_modal_create_metadata') : t('addkeyword_modal_create')}</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(AddKeywordModal);