import React from "react";
import Layout from 'layouts/layout';
import Wizard from './Wizard/containers/Wizard';
import './StartScanComponent.scss';

const StartScanComponent = props => {
    const { id, allConfigurations } = props;

    return (
        <Layout>
            <div className="start-scan-wrapper">
                <div className="start-scan-content">
                    <Wizard id={id} allConfigurations={allConfigurations} />
                </div>
            </div>
        </Layout>
    )
};

export default StartScanComponent;