import React from "react";
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";

const QuestionsManagementSkeleton = () => {
    return (
        <div>
            <div style={{ marginBottom: 20, width: '100%' }}>
                <Skeleton width={250} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                <div style={{ width: "100%" }}><Skeleton width="80%" /></div>

                <div style={{ width: 80 }}>
                    <Skeleton width={'100%'} />
                </div>
            </div>

            {
                _.times(5, (i) => (
                    <div key={i} style={{ marginBottom: 40 }}>
                        {
                            _.times(3, (j) => (
                                <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                                    <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                                    <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                                    <div style={{ width: "100%" }}><Skeleton width="80%" /></div>
                                    <div style={{ width: "100%" }}><Skeleton width="80%" /></div>

                                    <div style={{ display: 'flex', justifyContent: 'center', width: 80 }}>
                                        <Skeleton width={20} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50 }}>
                <div>
                    <Skeleton width={165} height={30} />
                </div>
                <div style={{ marginLeft: 20 }}>
                    <Skeleton width={165} height={30} />
                </div>
            </div>
        </div>
    )
}

export default QuestionsManagementSkeleton