import React, { useState } from "react";
import NavTabs from 'components/common/NavTabs/NavTabs';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import TableActionButton from 'components/common/TableActionButton/TableActionButton';
import TablePagination from 'components/common/TablePagination/TablePagination';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import { ImSearch } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import { GiAlarmClock } from 'react-icons/gi';
import { GrUndo, GrCheckmark } from 'react-icons/gr';
import moment from 'moment';
import './ValidierungenComponent.scss';

const ValidierungenComponent = (props) => {
    const {
        loading,
        validationFormOpen,
        validationFormPending,
        validationFormClosed,
        changeValidationStatus,
        selectedTab,
        setSelectedTab,
        settings,
        reloadTabs,
        sendEmailAction,
        pagination,
        changePageData
    } = props;

    const { t } = useTranslation();

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const generateItemMetadata = data => {
        return {
            id: data.fileId,
            validationId: data.validationId,
            fileName: data.fileName,
            path: data.fullName,
            complexity: data.complexity,
            criticality: data.criticality,
            department: data.department,
            createdVia: data.origin,
            user: data.displayName,
            createdAt: data.createdAt,
            lastModified: null,
            answers: data.answers,
            alias: data.alias,
            status: data.status
        }
    }

    const openFileOverviewModal = (row) => {
        const item = generateItemMetadata(row)
        setSelectedItem(item);
        setIsFileOverviewModalOpen(true);
    }

    const getResultValue = result => {
        if (result === 'euc') return t('screenValidierung_table_value_euc');
        if (result === 'non-euc') return t('screenValidierung_table_value_non_euc');

        return '-';
    }

    const isAllowedAlias = settings?.validationConfig?.allowAlias === 1;
    const isAllowedValidation = settings?.validationConfig?.formAutoClose === 1;

    const tabs = isAllowedValidation ?
        [t('screenValidierung_tab_1'), t('screenValidierung_tab_3')] :
        [t('screenValidierung_tab_1'), t('screenValidierung_tab_2'), t('screenValidierung_tab_3')]

    return (
        <Layout>
            <div className="validierungen">
                <div className="validierungen-content">
                    <NavTabs
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabs={tabs}
                    >
                        {
                            selectedTab === 0 &&
                            <div>
                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            validationFormOpen.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_7')}</th>
                                                            <th>{t('screenValidierung_table_column_3')}</th>
                                                            <th>{t('screenValidierung_table_column_8')}</th>
                                                            {/* <th>{t('screenValidierung_table_column_5')}</th> */}
                                                            {/* <th>{t('screenValidierung_table_column_6')}</th> */}
                                                            <th>{t('screenValidierung_table_column_9')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            validationFormOpen.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{thisRow.displayName || "-"}</td>
                                                                    <td>{thisRow.department || "-"}</td>
                                                                    <td>{t('screenValidierung_table_value_pending')}</td>
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.complexity) ? thisRow.complexity : "-"}</td> */}
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.criticality) ? thisRow.criticality : "-"}</td> */}
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </TableActionButton>

                                                                            <TableActionButton asyncFunction={() => sendEmailAction("2", thisRow.samAccount)}>
                                                                                <GiAlarmClock />
                                                                            </TableActionButton>

                                                                            <TableActionButton onClick={() => changeValidationStatus(thisRow.validationId, "Deprecated")}>
                                                                                <MdClose />
                                                                            </TableActionButton>
                                                                            {/* <div className="table-btn arrow" onClick={() => null}>
                                                                            <BiEditAlt />
                                                                        </div> */}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>

                                <TablePagination
                                    pagination={pagination}
                                    dataCount={validationFormOpen.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }

                        {
                            selectedTab === (isAllowedValidation ? null : 1) &&
                            <div>
                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            validationFormPending.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_7')}</th>
                                                            <th>{t('screenValidierung_table_column_3')}</th>
                                                            <th>{t('screenValidierung_table_column_8')}</th>
                                                            {/* <th>{t('screenValidierung_table_column_5')}</th> */}
                                                            {/* <th>{t('screenValidierung_table_column_6')}</th> */}
                                                            <th>{t('screenValidierung_table_column_9')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            validationFormPending.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{thisRow.displayName || "-"}</td>
                                                                    <td>{thisRow.department || "-"}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{getResultValue(thisRow.eucResult)}</td>
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.complexity) ? thisRow.complexity : "-"}</td> */}
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.criticality) ? thisRow.criticality : "-"}</td> */}
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </TableActionButton>

                                                                            <TableActionButton asyncFunction={() => changeValidationStatus(thisRow.validationId, "Open", "1", thisRow.samAccount)}>
                                                                                <GrUndo />
                                                                            </TableActionButton>

                                                                            <TableActionButton style={{ fontSize: 14 }} asyncFunction={() => changeValidationStatus(thisRow.validationId, "Closed", "3", thisRow.samAccount)}>
                                                                                <GrCheckmark />
                                                                            </TableActionButton>

                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => changeValidationStatus(thisRow.validationId, "Deprecated")}>
                                                                                <MdClose />
                                                                            </TableActionButton>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>
                                <TablePagination
                                    pagination={pagination}
                                    dataCount={validationFormPending.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }

                        {
                            selectedTab === (isAllowedValidation ? 1 : 2) &&
                            <div>
                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            validationFormClosed.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_7')}</th>
                                                            <th>{t('screenValidierung_table_column_3')}</th>
                                                            <th>{t('screenValidierung_table_column_8')}</th>
                                                            {/* <th>{t('screenValidierung_table_column_5')}</th> */}
                                                            {/* <th>{t('screenValidierung_table_column_6')}</th> */}
                                                            <th>{t('screenValidierung_table_column_9')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            validationFormClosed.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{thisRow.displayName || "-"}</td>
                                                                    <td>{thisRow.department || "-"}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{getResultValue(thisRow.eucResult)}</td>
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.complexity) ? thisRow.complexity : "-"}</td> */}
                                                                    {/* <td className='number-row'>{!_.isNil(thisRow.criticality) ? thisRow.criticality : "-"}</td> */}
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </TableActionButton>

                                                                            <TableActionButton asyncFunction={() => changeValidationStatus(thisRow.validationId, "Open", "1", thisRow.samAccount)}>
                                                                                <GrUndo />
                                                                            </TableActionButton>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>
                                <TablePagination
                                    pagination={pagination}
                                    dataCount={validationFormClosed.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }
                    </NavTabs>
                </div>
            </div>

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                refreshScreen={reloadTabs}
                hideSnapshotsPagination={true}
            />
        </Layout>
    )
};

export default ValidierungenComponent;