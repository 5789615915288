import React from "react";
import NumberFormat from 'react-number-format';

const Number = (props) => {
    const { value } = props;

    return (
        <NumberFormat
            thousandSeparator={"."}
            decimalSeparator={false}
            value={value}
            displayType="text"
        />
    );
};

export default Number;