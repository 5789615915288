import React, { useState, useEffect } from "react";
import Button from "components/common/Button/Button";
import { useTranslation } from "react-i18next";
import './FileSelect.scss';

const mime = require('mime-types');

const FileSelect = (props) => {
    const {
        onChange,
        inputText,
        allowedExtensions,
        buttonText,
        id,
        disabled,
        multiple = false,
        downloadFiles
    } = props;

    const [filename, setFilename] = useState(inputText);
    const { t } = useTranslation();

    useEffect(() => {
        setFilename(inputText);
    }, [])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const buttonClick = () => {
        if (downloadFiles) {
            downloadFiles.forEach(link => {
                const file_path = `${process.env.REACT_APP_MAIN_ENDPOINT}/file/download?key=${link}`;

                var a = document.createElement('A');
                a.href = file_path;
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
        } else {
            document.getElementById(id).click()
        };
    }

    return (
        <div className={disabled ? "file-select-component disabled" : "file-select-component"}>
            <div className="file-select-component__content">
                <div className="filename">{filename}</div>
                <Button onClick={buttonClick} disabled={downloadFiles ? false : disabled}>
                    {buttonText}
                </Button>
            </div>

            <input
                type="file"
                id={id}
                onChange={async e => {
                    const files = e.target.files;

                    const filesObjectPromises = Array.from(files).map(async file => {
                        let fileObject = {
                            name: file.name,
                            type: mime.lookup(file.name) || "unknown",
                            file: file
                        }

                        if (fileObject?.type?.includes('image')) {
                            const base64Image = await toBase64(file);
                            fileObject.base64 = base64Image;
                        }

                        return fileObject
                    });

                    const filesObject = await Promise.all(filesObjectPromises);

                    setFilename(files.length > 1 ? `${files.length} files` : filesObject[0]?.name);
                    onChange(files.length > 1 ? filesObject : filesObject[0]);
                }}
                multiple={multiple}
                style={{ display: "none" }}
                accept={allowedExtensions || "*"}
            />
        </div>
    )
};

export default FileSelect;