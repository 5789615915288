import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import './ManageKeywordListModalComponent.scss';

const ManageKeywordListModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        submitKeywordList,
        isLoading,
        isMetadata
    } = props;

    const { t } = useTranslation();

    useEffect(() => {
        formik.resetForm();
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            name: '',
            infoText: {
                en: '',
                de: ''
            },
            weight: '',
            threshold: '',
            kpiType: '',
            valueType: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('formik_required_field')),
            infoText: Yup.object().shape({
                en: Yup.string().required(t('formik_required_field')),
                de: Yup.string().required(t('formik_required_field'))
            }),
            weight: Yup.number().required(t('formik_required_field')),
            threshold: Yup.number().required(t('formik_required_field')),
            kpiType: Yup.string().required(t('formik_required_field')),
            valueType: Yup.string().required(t('formik_required_field')),
        }),
        enableReinitialize: true,
        onSubmit: values => {
            submitKeywordList(values)
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal manage-topic-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="content-header">
                <div className="content-header__title">
                    <div>{isMetadata ? t('addtopic_modal_title_metadata') : t('addtopic_modal_title')}</div>
                </div>

                <div className="close-tooltip" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="content-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="name">{isMetadata ? t('addtopic_modal_name_label_metadata') : t('addtopic_modal_name_label')}</label>
                        <Input
                            name="name"
                            placeholder={isMetadata ? t('addtopic_modal_name_placeholder_metadata') : t('addtopic_modal_name_placeholder')}
                            formikProps={formik}
                        />
                    </div>

                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="infoText.en">{isMetadata ? t('addtopic_modal_infotext_en_label_metadata') : t('addtopic_modal_infotext_en_label')}</label>
                        <Input
                            name="infoText.en"
                            placeholder={isMetadata ? t('addtopic_modal_infotext_en_placeholder_metadata') : t('addtopic_modal_infotext_en_placeholder')}
                            formikProps={formik}
                        />
                    </div>

                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="infoText.de">{isMetadata ? t('addtopic_modal_infotext_de_label_metadata') : t('addtopic_modal_infotext_de_label')}</label>
                        <Input
                            name="infoText.de"
                            placeholder={isMetadata ? t('addtopic_modal_infotext_en_placeholder_metadata') : t('addtopic_modal_infotext_en_placeholder')}
                            formikProps={formik}
                        />
                    </div>

                    <div className="input-flex-2">
                        <div className="input-wrapper" style={{ marginBottom: 20 }}>
                            <label htmlFor="weight">{isMetadata ? t('addtopic_modal_weight_label_metadata') : t('addtopic_modal_weight_label')}</label>
                            <Input
                                type="number"
                                name="weight"
                                placeholder={isMetadata ? t('addtopic_modal_weight_placeholder_metadata') : t('addtopic_modal_weight_placeholder')}
                                formikProps={formik}
                            />
                        </div>

                        <div className="input-wrapper" style={{ marginBottom: 20 }}>
                            <label htmlFor="threshold">{isMetadata ? t('addtopic_modal_threshold_label_metadata') : t('addtopic_modal_threshold_label')}</label>
                            <Input
                                type="number"
                                name="threshold"
                                placeholder={isMetadata ? t('addtopic_modal_threshold_placeholder_metadata') : t('addtopic_modal_threshold_placeholder')}
                                formikProps={formik}
                            />
                        </div>
                    </div>

                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="threshold">{isMetadata ? t('addtopic_modal_kpitype_label_metadata') : t('addtopic_modal_kpitype_label')}</label>
                        <Input
                            type="select"
                            name="kpiType"
                            formikProps={formik}
                            selectOptions={[
                                {
                                    label: isMetadata ? t('addtopic_modal_kpitype_placeholder_metadata') : t('addtopic_modal_kpitype_placeholder'),
                                    value: '',
                                    disabled: true
                                },
                                {
                                    label: isMetadata ? t('addtopic_modal_kpitype_option_1_metadata') : t('addtopic_modal_kpitype_option_1'),
                                    value: 'Complexity',
                                },
                                {
                                    label: isMetadata ? t('addtopic_modal_kpitype_option_2_metadata') : t('addtopic_modal_kpitype_option_2'),
                                    value: 'Criticality',
                                },
                                {
                                    label: isMetadata ? t('addtopic_modal_kpitype_option_3_metadata') : t('addtopic_modal_kpitype_option_3'),
                                    value: 'NonAssigned',
                                },
                            ]}
                        />
                    </div>

                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="threshold">{isMetadata ? t('addtopic_modal_filetype_label_metadata') : t('addtopic_modal_filetype_label')}</label>
                        <Input
                            type="select"
                            name="valueType"
                            formikProps={formik}
                            selectOptions={[
                                {
                                    label: isMetadata ? t('addtopic_modal_filetype_placeholder_metadata') : t('addtopic_modal_filetype_placeholder'),
                                    value: '',
                                    disabled: true
                                },
                                {
                                    label: isMetadata ? t('addtopic_modal_filetype_option_1_metadata') : t('addtopic_modal_filetype_option_1'),
                                    value: 'Relative',
                                },
                                {
                                    label: isMetadata ? t('addtopic_modal_filetype_option_2_metadata') : t('addtopic_modal_filetype_option_2'),
                                    value: 'Absolute',
                                },
                            ]}
                        />
                    </div>

                    <div className="action-buttons">
                        <Button type="submit" loading={isLoading} style={{ width: 100 }}>{t('addtopic_modal_create')}</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
};

export default ManageKeywordListModalComponent