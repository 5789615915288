import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTopicKeywordsService, setTopicKeywordsService, deleteTopicKeywordService } from "services/topic";
import { getMetadataKeywordsService, setMetadataKeywordsService, deleteMetadataKeywordService, getMetadataFieldsOptionsService } from "services/metadata";
import EucKeywordsModalComponent from '../components/EucKeywordsModalComponent';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'

const EucKeywordsModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        keywordListItem,
        keywordListItemId,
        readOnly,
        settings,
        isMetadata
    } = props;

    const [fetchingKeywords, setFetchingKeywords] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [newKeywords, setNewKeywords] = useState([]);
    const [metadataFields, setMetadataFields] = useState([]);

    const [addKeywordModalOpen, setAddKeywordModalOpen] = useState(false);
    const [addKeywordLoading, setAddKeywordLoading] = useState(false);

    const [deletingKeywordIds, setDeletingKeywordIds] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen && keywordListItemId) {
            setFetchingKeywords(true);
            getKeywords();
        } else {
            setKeywords([]);
        }
    }, [keywordListItemId, isOpen])

    const getKeywords = async () => {
        if (!keywordListItem || !keywordListItemId) return;
        try {
            let response;
            if (isMetadata) {
                response = await getMetadataKeywordsService(keywordListItemId);
                const fields = await getMetadataFieldsOptionsService();
                setMetadataFields(fields);
            }
            else {
                response = await getTopicKeywordsService(keywordListItemId)
            }
            setKeywords(response);
        } catch (err) {
            console.log("Error keywords fetching:", err);
        }

        setFetchingKeywords(false);
    }

    const addKeyword = async (values) => {
        setAddKeywordLoading(true);

        try {
            const { keyword, field = null, rule } = values;

            if (isMetadata) {
                await setMetadataKeywordsService([{
                    metadataId: keywordListItemId,
                    name: rule === "IsLike" ? keyword : "true",
                    field,
                    rule
                }]);
            } else {
                await setTopicKeywordsService([{ topicId: keywordListItemId, name: keyword }]);
            }

            await getKeywords();
            setAddKeywordModalOpen(false);
        } catch (err) {
            console.log("Error keywords set:", err);
        }

        setAddKeywordLoading(false);
    }

    const deleteKeyword = async (keywordId) => {
        if (await confirm({
            title: t('deletekeyword_confirm_title'),
            confirmation: t('deletekeyword_confirm_message')
        })) {
            setDeletingKeywordIds([...deletingKeywordIds, keywordId]);

            try {
                isMetadata ? await deleteMetadataKeywordService(keywordId) : await deleteTopicKeywordService(keywordId);
                await getKeywords();
            } catch (err) {
                console.log("Error keyword delete:", err);
            }

            let newDeletingKeywordIds = deletingKeywordIds.filter(id => id !== keywordId);
            setDeletingKeywordIds(newDeletingKeywordIds);
        }
    }

    return <EucKeywordsModalComponent
        settings={settings}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        keywords={keywords}
        fetchingKeywords={fetchingKeywords}
        setNewKeywords={setNewKeywords}
        newKeywords={newKeywords}
        addKeyword={addKeyword}
        deleteKeyword={deleteKeyword}
        addKeywordModalOpen={addKeywordModalOpen}
        setAddKeywordModalOpen={setAddKeywordModalOpen}
        addKeywordLoading={addKeywordLoading}
        deletingKeywordIds={deletingKeywordIds}
        readOnly={readOnly}
        isMetadata={isMetadata}
        metadataFields={metadataFields}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(EucKeywordsModal);