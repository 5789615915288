import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getDictionaryService } from './services/dictionary';

const savedSettings = JSON.parse(localStorage.getItem("kpmg-settings"));

const loadI18n = async () => {
	const dictionaryWords = await getDictionaryService();

	i18n.addResourceBundle('en', 'translations', {
		...dictionaryWords.en
	});

	i18n.addResourceBundle('de', 'translations', {
		...dictionaryWords.de
	});

	i18n.changeLanguage((savedSettings && savedSettings.language) || "de");
	i18n.on('languageChanged', () => {
		var langEvent;
		if (typeof (Event) === 'function') {
			langEvent = new Event('i18next.languageChanged');
		} else {
			langEvent = document.createEvent('Event');
			langEvent.initEvent('i18next.languageChanged', true, true);
		}
		window.dispatchEvent(langEvent);
	})
}

i18n
	.use(initReactI18next)
	.init({
		resources: {},
		lng: (savedSettings && savedSettings.language) || "de",
		fallbackLng: "deen",
		debug: false,

		ns: ["translations"],
		defaultNS: "translations",

		keySeparator: ".",

		interpolation: {
			escapeValue: false,
		}
	});

loadI18n();

export default i18n;
