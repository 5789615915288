import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Button from "components/common/Button/Button";
import { confirmable } from 'react-confirm';
import Modal from 'react-modal';
import './ConfirmModal.scss';

const ConfirmModal = ({ show, proceed, confirmation, title, isLoading, options }) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={show}
            className="kpmg-modal confirm-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => proceed(false)}
            ariaHideApp={false}>
            <div className="confirm-modal-content">
                <div className="title">{title}</div>
                <div className="content">{confirmation}</div>
                <div className="actions">
                    <Button type="button" onClick={() => proceed(false)}>{t('confirm_modal_cancel')}</Button>
                    <Button type="button" loading={isLoading} onClick={() => proceed(true)}>{t('confirm_modal_ok')}</Button>
                </div>
            </div>
        </Modal>
    )
}

ConfirmModal.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.object
}

export default confirmable(ConfirmModal);