import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from 'components/common/Input/Input';
import Button from 'components/common/Button/Button';
import InfoComponent from 'components/common/InfoComponent/InfoComponent';
import EucKeywordsModal from 'components/modals/EucKeywordsModal/containers/EucKeywordsModal';
import Skeleton from "react-loading-skeleton";
import { BiSearchAlt } from 'react-icons/bi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import './StepFiveComponent.scss';

const StepFiveComponentLoading = () => {
    return (
        <div>
            <div style={{ marginBottom: 20, width: '100%' }}>
                <Skeleton width={250} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
            </div>

            {
                _.times(15, (j) => (
                    <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                    </div>
                ))
            }
        </div>
    )
}

const StepFiveComponent = props => {
    const { settings, changeStep, defaultIndicators, isFetching, submitStepData, data, readOnly } = props;

    const [keywordsModalOpen, setKeywordsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    let indicators = !_.isEmpty(data) ? data : defaultIndicators;
    let formattedIndicators = [];

    const { t } = useTranslation();

    let initialValues = {}
    let validationSchema = {}

    indicators.forEach(rowData => {
        let identifier = '';
        let currentRowData = null;
        let defaultRowData = null;

        if (rowData.indicatorId) {
            currentRowData = rowData;
            defaultRowData = defaultIndicators.filter(thisIndicator => thisIndicator.indicatorId === rowData.indicatorId)[0];
            identifier = `${rowData.indicatorId}-indicator`;
        }

        if (rowData.topicId) {
            currentRowData = rowData;
            defaultRowData = defaultIndicators.filter(thisTopic => thisTopic.topicId === rowData.topicId)[0];
            identifier = `${rowData.topicId}-topic`;
        }

        if (rowData.metadataId) {
            currentRowData = rowData;
            defaultRowData = defaultIndicators.filter(thisMetadata => thisMetadata.metadataId === rowData.indicatorId)[0];
            identifier = `${rowData.metadataId}-metadata`;
        }

        const newIndicator = {
            weight: currentRowData ? currentRowData.weight : defaultRowData.weight,
            threshold: currentRowData ? currentRowData.threshold : defaultRowData.threshold,
            kpiType: currentRowData ? currentRowData.kpiType : defaultRowData.kpiType,
            valueType: currentRowData ? currentRowData.valueType : defaultRowData.valueType,
        }

        initialValues[identifier] = currentRowData;
        formattedIndicators.push({
            ...rowData,
            ...newIndicator,
            type: currentRowData ? currentRowData.type : defaultRowData.type,
            name: currentRowData ? currentRowData.name : defaultRowData.name,
        });

        validationSchema[identifier] = Yup.object().shape({
            weight: Yup.number().required(),
            threshold: Yup.number().required(),
            kpiType: Yup.string().required(),
            valueType: Yup.string().required(),
        });
    })

    const defaultIndicatorsGroupedByType = _.groupBy(defaultIndicators.filter((i) => i.type), 'type');
    const itemsGroupedByType = _.groupBy(formattedIndicators.filter((i) => i.type), 'type');
    const itemKeys = _.keys(itemsGroupedByType);


    validationSchema = Yup.object().shape({
        ...validationSchema
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            submitStepData(values, 5);
            changeStep(6);
        },
    });

    const mapKeyToTranslation = key => {
        if (key === 'MetaData') return t('scan_tab1_wizard_step5_tip_chain_text_1')
        if (key === 'Technichal') return t('scan_tab1_wizard_step4_tip_chain_text_2');
        if (key === 'Links') return t('scan_tab1_wizard_step4_tip_chain_text_1');
        if (key === 'topic-item') return t('scan_tab1_wizard_step5_tip_chain_text_2');
        if (key === 'metadata-item') return t('scan_tab1_wizard_step5_tip_chain_text_3');

        return key;
    }

    const openKeywordModal = (item) => {
        setSelectedItem(item);
        setKeywordsModalOpen(true);
    }

    return (
        <div className="step-five-wrapper">

            {
                isFetching ?
                    <StepFiveComponentLoading /> :
                    <form onSubmit={formik.handleSubmit}>
                        <div className="table-wrapper">
                            <table className="kpmg-table stripped">
                                <thead>
                                    <tr>
                                        <th style={{ width: 150 }}>{t('scan_tab1_wizard_step5_layout_chain_text_1')}</th>
                                        <th style={{ width: 220 }}>{t('scan_tab1_wizard_step5_layout_chain_text_2')}</th>
                                        <th>Keywords</th>
                                        <th>{t('scan_tab1_wizard_step5_layout_chain_text_3')}</th>
                                        <th>{t('scan_tab1_wizard_step5_layout_chain_text_4')}</th>
                                        <th>{t('scan_tab1_wizard_step5_layout_chain_text_5')}</th>
                                        <th>{t('scan_tab1_wizard_step5_layout_chain_text_6')}</th>
                                        <th>{t('scan_tab1_wizard_step5_layout_chain_text_7')}</th>
                                        <th>{t('scan_tab1_wizard_step4_layout_chain_text_10')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="empty">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {
                                        itemKeys.map((itemKey, i) => {
                                            const itemsData = itemsGroupedByType[itemKey];
                                            const defaultData = defaultIndicatorsGroupedByType[itemKey];

                                            return itemsData.map((itemData, j) => {
                                                let rowSpan = null;
                                                const defaultItem = defaultData.filter((value) => {
                                                    return value.indicatorId === itemData.indicatorId ||
                                                        value.topicId === itemData.topicId ||
                                                        value.metadataId === itemData.metadataId
                                                })[0];

                                                if (j === 0) {
                                                    rowSpan = {
                                                        rowCount: itemsData.length,
                                                        label: mapKeyToTranslation(itemKey),
                                                        class: `${i % 2 ? 'primary-dark' : 'primary-medium'} with-h-padding`
                                                    };

                                                    if (itemsData.length !== 1) {
                                                        rowSpan.class = `${rowSpan.class} with-v-padding`
                                                    }
                                                }

                                                let fieldName = `${itemData.indicatorId}-indicator`;
                                                if (itemData.topicId) fieldName = `${itemData.topicId}-topic`;
                                                if (itemData.metadataId) fieldName = `${itemData.metadataId}-metadata`;

                                                return (
                                                    <tr key={j}>
                                                        {
                                                            rowSpan &&
                                                            <td rowSpan={rowSpan.rowCount} className={rowSpan.class} style={{ whiteSpace: 'nowrap' }}>
                                                                {rowSpan.label}
                                                            </td>
                                                        }
                                                        <td style={{ textAlign: 'left', minWidth: 200 }}>{itemData.name}</td>
                                                        <td>
                                                            {
                                                                fieldName.includes("topic") || fieldName.includes("metadata") ?
                                                                    <div
                                                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                        className='cursor-pointer' onClick={() => openKeywordModal(itemData)}
                                                                    >
                                                                        <BiSearchAlt
                                                                            style={{ color: "#525662", justifyContent: 'center', fontSize: 21 }}
                                                                        />
                                                                    </div> :
                                                                    "-"
                                                            }
                                                        </td>
                                                        <td>
                                                            <InfoComponent
                                                                tooltipValue={settings.language === 'de' ? itemData.infoText?.de : itemData.infoText?.en}
                                                                style={{ marginLeft: 0, color: "#525662", justifyContent: 'center' }} />
                                                        </td>
                                                        <td className="input-cell dark-bg">
                                                            <Input
                                                                type="number"
                                                                name={`${fieldName}.threshold`}
                                                                formikProps={formik}
                                                                disabled={readOnly}
                                                            />
                                                        </td>
                                                        <td className='number-row'>{defaultItem.threshold}</td>
                                                        <td className="input-cell dark-bg">
                                                            <Input
                                                                type="number"
                                                                name={`${fieldName}.weight`}
                                                                formikProps={formik}
                                                                disabled={readOnly}
                                                            />
                                                        </td>
                                                        <td className='number-row'>{defaultItem.weight}</td>
                                                        <td className='number-row'>{itemData.valueType || "-"}</td>
                                                    </tr>
                                                )
                                            });
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="action-buttons">
                            <div className="section-tip">
                                <div className="title">{t('scan_tab1_wizard_step5_layout_chain_text_8')}:</div>
                                <div className="text">{t('scan_tab1_wizard_step5_layout_chain_text_9')}:</div>
                            </div>

                            <div className="confirm">
                                {
                                    !readOnly &&
                                    <Button
                                        type="submit"
                                        style={{ width: 130 }}>
                                        {t('scan_tab1_wizard_step1_button_1')}
                                    </Button>
                                }
                            </div>
                        </div>
                    </form>
            }

            <EucKeywordsModal
                isOpen={keywordsModalOpen}
                setIsOpen={setKeywordsModalOpen}
                keywordListItem={selectedItem}
                keywordListItemId={!_.isNil(selectedItem.metadataId) ? selectedItem.metadataId : selectedItem.topicId}
                isMetadata={!_.isNil(selectedItem.metadataId) ? true : false}
                readOnly={true}
            />
        </div>
    )
}

export default StepFiveComponent;