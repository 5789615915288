import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "../redux/actions/settingsActions";
import { updateUser } from "../redux/actions/userActions";
import { updateDashboard } from "../redux/actions/dashboardActions";
import { updateLicense } from "../redux/actions/licenseActions";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userMeService } from 'services/user';
import { getLicenseKeyService } from 'services/settings';
import Loading from 'components/common/Loading/Loading';
import { adjustColorBrightness, parseLicenseData } from 'services/helper';
import { getAllConfigurations } from 'services/configurations';
import _ from 'lodash';
import './layout.scss';

const Layout = props => {
    const {
        children,
        match: { path },
        settings,
        user,
        updateSettings,
        updateUser,
        updateDashboard,
        dashboard,
        previewTheme,
        className,
        updateLicense,
        license,
        contentCardStyles
    } = props;

    const { i18n, t } = useTranslation();
    const [navbarHoveredIndex, setNavbarHoveredIndex] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [licenseFetching, setLicenseFetching] = useState(false);

    useEffect(() => {
        initApplication();
    }, []);

    const initApplication = async () => {
        if (_.isEmpty(license)) {
            await getLicenseData();
        }

        if (_.isEmpty(user)) {
            await getUserData();
        }
    }

    const getUserData = async () => {
        setIsLoading(true);

        try {
            const userData = await userMeService();
            await getConfigurations();
            updateUser(userData);
        } catch (err) {
            setError(`Error ${err.status}: ${err.statusText}`);
        }

        setIsLoading(false);
    }

    const getLicenseData = async () => {
        setLicenseFetching(true);

        try {
            const licenseJwt = await getLicenseKeyService();
            const licenseParsed = await parseLicenseData(licenseJwt || { keyText: "" });
            updateLicense(licenseParsed);
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setLicenseFetching(false);
    }

    const getConfigurations = async () => {
        try {
            const { settingsData, dashboardData } = await getAllConfigurations(settings, dashboard);
            updateDashboard(dashboardData);
            updateSettings(settingsData);
        } catch (err) {
            console.log(`Error while fetching configurations: ${err}`)
        }
    }

    const changeI18nLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const toggleLanguage = (selectedLanguage) => {
        changeI18nLanguage(selectedLanguage);

        updateSettings({
            ...settings,
            language: selectedLanguage,
        });
    }

    const sidebarItems = [
        {
            icon: require('assets/icons/Guidance.png').default,
            title: t('menuHamburguer_button1_title'),
            description: t('menuHamburguer_button1_subtitle'),
            link: '/guidance',
            licenseAvailable: license?.resources?.includes("Info-Portal"),
            tabs: [
                {
                    name: t('screenEucCheck_layout_chain_text_1'),
                    link: '/euc-check'
                },
                {
                    name: t('screenMeineValidierungen_layout_chain_text_0'),
                    link: '/meine-validierungen'
                },
                {
                    name: t('screenAnsprechartner_layout_chain_text_0'),
                    link: '/ansprechartner'
                },
                {
                    name: t('screenEucRegularien_layout_chain_text_0'),
                    link: '/euc-regularien'
                }
            ]
        },
        {
            icon: require('assets/icons/Scanning.png').default,
            pageTitle: t('menuHamburguer_button2_title'),
            title: t('menuHamburguer_button2_title'),
            description: t('menuHamburguer_button2_subtitle'),
            link: '/scanning',
            licenseAvailable: license?.resources?.includes("Discovery") && !license?.isExpired && !license?.isInvalid,
            tabs: [
                {
                    name: t('scan_tab1'),
                    link: '/start-scan'
                },
                {
                    name: t('scan_tab2'),
                    link: '/konfigurationen'
                },
                {
                    name: t('scan_tab3'),
                    link: '/ubersicht-scans'
                }
            ]
        },
        {
            icon: require('assets/icons/Analyze.png').default,
            title: t('menuHamburguer_button3_title'),
            pageTitle: t('menuHamburguer_button3_title'),
            description: t('menuHamburguer_button3_subtitle'),
            link: '/analyzing',
            licenseAvailable: license?.resources?.includes("Analyse") && !license?.isExpired && !license?.isInvalid,
            tabs: [
                {
                    name: "Dashboard",
                    link: '/dashboard'
                },
                // {
                //     name: "Expertenmodus",
                //     link: '/expertenmodus'
                // },
            ]
        },
        {
            icon: require('assets/icons/Validate.png').default,
            title: t('menuHamburguer_button6_title'),
            pageTitle: t('menuHamburguer_button6_title'),
            description: t('menuHamburguer_button6_subtitle'),
            link: '/validation',
            licenseAvailable: license?.resources?.includes("Validation") && !license?.isExpired && !license?.isInvalid,
            tabs: [
                {
                    name: t('validation_tab_1'),
                    link: "/validierungen"
                },
                // {
                //     name: t('validation_tab_2'),
                //     link: "/inventarisierungen"
                // },
            ]
        },
        // {
        //     icon: require('assets/icons/Manage.png').default,
        //     title: t('menuHamburguer_button4_title'),
        //     pageTitle: t('screenInventory_title'),
        //     description: t('menuHamburguer_button4_subtitle'),
        //     link: '/management',
        //     licenseAvailable: license?.resources?.includes("Management") && !license?.isExpired && !license?.isInvalid,
        //     tabs: []
        // },
        {
            icon: require('assets/icons/Settings.png').default,
            title: t('menuHamburguer_button5_title'),
            pageTitle: t('menuHamburguer_button5_title'),
            description: t('menuHamburguer_button5_subtitle'),
            link: '/settings',
            licenseAvailable: true,
            tabs: [
                {
                    name: t('settings_tab1'),
                    link: '/user-interface'
                },
                {
                    name: t('settings_tab2'),
                    link: '/berechtigungen'
                },
                {
                    name: t('settings_tab3'),
                    link: '/org-einheiten'
                },
                {
                    name: t('settings_tab4'),
                    link: '/kalibrierung-scan'
                },
                {
                    name: t('settings_tab10'),
                    link: '/questionnaires'
                },
                {
                    name: t('settings_tab6'),
                    link: '/email-settings'
                },
                {
                    name: t('settings_tab8'),
                    link: '/license-key'
                }
            ]
        },
    ];

    // workaround for IE
    window.addEventListener("resize", () => {
        let header = document.getElementById('header');
        header.style.width = '100%'
    });

    const selectedMenuItemIndex = sidebarItems.findIndex(sidebarItem => path.includes(sidebarItem.link));
    const selectedMenuItem = sidebarItems[selectedMenuItemIndex];

    const mainColor = previewTheme?.mainColor || settings?.theme?.mainColor;
    const secondaryColor = previewTheme?.secondaryColor || settings?.theme?.secondaryColor;
    const backgroundFile = previewTheme?.backgroundFile || settings?.theme?.backgroundFile;
    const logoFile = previewTheme?.logoFile || settings?.theme?.logoFile;

    const onlyGuidancePermission = user?.groupPermissions?.length === 1 && user.groupPermissions[0] === "EUCGUIDANCE";

    return (
        <div className="main-layout" style={{ backgroundImage: `url(${backgroundFile})` }}>
            {
                !onlyGuidancePermission && user?.groupPermissions &&
                <div className="main-layout-sidebar">
                    <div className="arrow-right" style={{ top: 215 + (selectedMenuItemIndex * 70) }}></div>
                    <div className="sidebar-wrapper">

                        <div className="sidebar-title">
                            <img src={require('assets/images/euc_small.png').default} className="logo-small" alt="EUC" />
                            <img src={require('assets/images/euc_big.png').default} className="logo-big" alt="EUC" />
                        </div>

                        <div className="sidebar-items">
                            {
                                _.map(sidebarItems, (sidebarItem, i) => (
                                    <Link to={sidebarItem.link} key={i} className={sidebarItem.licenseAvailable ? "sidebar-item" : "sidebar-item disabled"}>
                                        <div className="sidebar-item-icon">
                                            <img src={sidebarItem.icon} alt="" />
                                        </div>
                                        <div className="sidebar-item-content">
                                            <div className="sidebar-item-title">{sidebarItem.title}</div>
                                            <div className="sidebar-item-description">{sidebarItem.description}</div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>

                        <div className="sidebar-footer">
                            <div className="sidebar-toggle-language">
                                <div className={settings.language === 'de' ? 'language active' : 'language'} onClick={() => toggleLanguage('de')}>DE</div>
                                <div className="separator">|</div>
                                <div className={settings.language === 'en' ? 'language active' : 'language'} onClick={() => toggleLanguage('en')}>EN</div>
                            </div>
                            <div className="sidebar-powered-by">
                                <div className="powered-by">{t('menuHamburguer_footer')}</div>
                                <div className="kpmg-logo">
                                    <img src={require('assets/images/kpmg_icon.png').default} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className={`main-layout-wrapper ${className || ""}`}>
                <div className="main-layout-section">
                    <div className="section-wrapper">
                        {
                            onlyGuidancePermission ?
                                <div className="language-bar">
                                    <div className={settings.language === 'de' ? 'language active' : 'language'} onClick={() => toggleLanguage('de')}>Deutsch</div>
                                    <div className="separator">|</div>
                                    <div className={settings.language === 'en' ? 'language active' : 'language'} onClick={() => toggleLanguage('en')}>English</div>
                                </div> :
                                null
                        }

                        <header id='header' className="header">
                            <div className="header-title">
                                {
                                    selectedMenuItem.pageTitle ?
                                        <h1>{selectedMenuItem.pageTitle}</h1> :
                                        null
                                }
                                <img src={logoFile} alt="logo" />
                            </div>

                            <nav className="navbar">
                                {
                                    selectedMenuItem.tabs.map((tab, i) => (
                                        <Link
                                            key={i}
                                            to={`${selectedMenuItem.link}${tab.link}`}
                                            onMouseEnter={() => setNavbarHoveredIndex(i)}
                                            onMouseLeave={() => setNavbarHoveredIndex(false)}
                                            className={`navbar-item ${path.includes(`${selectedMenuItem.link}${tab.link}`) ? "active" : ""}`}>
                                            <div className="text" style={{ borderBottomColor: navbarHoveredIndex === i || path.includes(`${selectedMenuItem.link}${tab.link}`) ? secondaryColor : 'white' }}>{tab.name}</div>
                                        </Link>
                                    ))
                                }
                            </nav>

                            <div className="yellow-bar" style={{ background: `linear-gradient(to bottom, ${adjustColorBrightness(secondaryColor, 0.3)} 0, ${secondaryColor} 30%, ${adjustColorBrightness(secondaryColor, -0.1)} 100%)` }}></div>
                        </header>

                        <div className="content">
                            {
                                isLoading || licenseFetching ?
                                    <Loading /> :
                                    error ?
                                        <div className="error">
                                            <p>{error}</p>
                                            <button onClick={() => window.location.reload()}>{t('screenEucCheck_check_chain_text_3')}</button>
                                        </div> :
                                        path.includes("guidance") || path.includes("management") ?
                                            <div className="content-card" style={{ borderTopColor: secondaryColor, ...contentCardStyles }}>
                                                {children}
                                            </div> :
                                            children
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user,
    dashboard: store.dashboardReducer.dashboard,
    license: store.licenseReducer.license,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettings, updateUser, updateDashboard, updateLicense }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));