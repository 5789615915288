import React from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';

const KeywordsComponent = (props) => {
    const {
        updateConfigs,
        configGraph,
        configCrawler,
        isSubmitting
    } = props;

    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            configGraph: {
                complexity: configGraph?.complexityAxis,
                criticality: configGraph?.criticalityAxis,
                pointLimit: configGraph?.pointLimit,
            },
            configCrawler: {
                duplicateLimit: configCrawler?.duplicateLimit,
                maxFileSize: (configCrawler?.maxFileSize || 0) / 1024,
                maxFileNumberExportExcel: configCrawler?.maxFileNumberExportExcel || 0,
                maxDirectoryNotFoundRetries: configCrawler?.maxDirectoryNotFoundRetries || 0,
            }
        },
        validationSchema: Yup.object().shape({
            configGraph: Yup.object().shape({
                complexity: Yup.number().min(1).max(100).required(),
                criticality: Yup.number().min(1).max(100).required(),
                pointLimit: Yup.number().min(0).required()
            }),
            configCrawler: Yup.object().shape({
                duplicateLimit: Yup.number().min(0).required(),
                maxFileSize: Yup.number().min(0).required(),
                maxFileNumberExportExcel: Yup.number().min(0).required(),
                maxDirectoryNotFoundRetries: Yup.number().min(0).required(),
            }),
        }),
        enableReinitialize: true
    });

    const submitConfigs = async () => {
        if (!_.isEmpty(formik.errors)) {
            throw new Error("Formik errors")
        } else {
            await updateConfigs(formik.values);
        }
    }

    const resetConfigs = () => {
        formik.resetForm();
    }

    return (
        <div className="scan-config-component">
            <div className="graph-axis">
                <p>{t('settings_tab4_graph_axis_text')}</p>
                <div className="fields">
                    <div className="field">
                        <label>{t('settings_tab4_layout_chain_text_9_a')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configGraph.complexity"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label>{t('settings_tab4_layout_chain_text_10_a')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configGraph.criticality"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label>{t('settings_tab4_graph_point_limit')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configGraph.pointLimit"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                </div>

                <p style={{ margin: "20px 0" }}>{t('settings_tab4_max_file_description')}</p>

                <div className="fields">
                    <div className="field">
                        <label>{t('settings_tab4_max_file_size')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configCrawler.maxFileSize"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                </div>

                <p style={{ margin: "20px 0" }}>{t('settings_tab4_max_file_size_excel_descr')}</p>

                <div className="fields">
                    <div className="field">
                        <label>{t('settings_tab4_max_file_size_excel')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configCrawler.maxFileNumberExportExcel"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                </div>

                <p style={{ margin: "20px 0" }}>{t('settings_tab4_dups_description')}</p>

                <div className="fields">
                    <div className="field">
                        <label>{t('settings_tab4_dups')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configCrawler.duplicateLimit"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                </div>

                <p style={{ margin: "20px 0" }}>{t('settings_tab4_max_directories_description')}</p>

                <div className="fields">
                    <div className="field">
                        <label>{t('settings_tab4_max_directories')}</label>
                        <div>
                            <Input
                                type="number"
                                name="configCrawler.maxDirectoryNotFoundRetries"
                                formikProps={formik}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions">
                <div>{t('settings_tab4_button_layout_chain_text_a')}:</div>
                <Button onClick={() => resetConfigs()}>
                    {t('settings_tab4_button1_title_a')}
                </Button>
                <Button loading={isSubmitting} onClick={submitConfigs}>
                    {t('settings_tab4_button2_title_a')}
                </Button>
            </div>
        </div>
    )
};

export default KeywordsComponent;