import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import Button from 'components/common/Button/Button';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import DownloadCsvModal from 'components/modals/DownloadCsvModal/containers/DownloadCsvModal';
import './DuplicateModalComponent.scss';

const DuplicateModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        isFetching,
        estimRow,
        dups,
        openPointModal
    } = props;

    const [downloadCsvModalOpen, setDownloadCsvModalOpen] = useState(false);
    const { t } = useTranslation();

    const fileIds = dups.map(dup => dup.fileOtherId);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                className="kpmg-modal user-list-modal"
                overlayClassName="kpmg-modal-overlay"
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}>
                <div className="content-header">
                    <div>{t('duplicate_modal_title')}</div>
                    <div className="close-tooltip" onClick={() => setIsOpen(false)}>
                        <FaTimes />
                    </div>
                </div>

                <div className="content-body">
                    <p>{t('duplicate_description')}</p>

                    {
                        dups.length && dups.length > 0 ?
                            <p>{dups[0].file}</p> :
                            <p></p>
                    }

                    {
                        !isFetching && dups.length &&
                        <div className="download-csv">
                            <Button onClick={() => setDownloadCsvModalOpen(true)}>{t('duplicate_modal_download_csv')}</Button>
                        </div>
                    }

                    <div className="table-wrapper">
                        {
                            isFetching ?
                                <TableSkeletonLoading
                                    columns={2}
                                    rows={estimRow}
                                /> :
                                dups.length ?
                                    <table className="kpmg-table stripped">
                                        <thead>
                                            <tr>
                                                <th>{t('duplicate_table_header_1')}</th>
                                                <th>{t('duplicate_table_header_2')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dups.map((dups, i) => (
                                                    <tr key={i} onClick={() => openPointModal(dups.fileValues)}>
                                                        <td>{dups.fileOther || "-"}</td>
                                                        <td>{`${Math.round(dups.score * 100)}%` || "-"}</td>
                                                    </tr>

                                                ))
                                            }
                                        </tbody>
                                    </table> :
                                    <p>{t('duplicate_table_empty')}</p>
                        }
                    </div>
                </div>
            </Modal>

            <DownloadCsvModal
                isOpen={downloadCsvModalOpen}
                setIsOpen={setDownloadCsvModalOpen}
                fileIds={fileIds}
                hideFirstQuestion={true}
            />
        </div>
    )
};

export default DuplicateModalComponent;