import React, { useState, useEffect } from "react";
import NavTabs from 'components/common/NavTabs/NavTabs';
import Button from 'components/common/Button/Button';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { GrCheckmark } from 'react-icons/gr';
import { FcFile } from 'react-icons/fc';
import Skeleton from "react-loading-skeleton";
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading'
import _ from 'lodash';
import { formatThousandsAndDecimals } from 'services/helper';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { IoWarningOutline } from 'react-icons/io5';
import ReactTooltip from "react-tooltip";
import DownloadCsvModal from 'components/modals/DownloadCsvModal/containers/DownloadCsvModal';

import './FileOverviewModalComponent.scss';

const FileOverviewModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        selectedItem,
        settings,
        selectedTab,
        setSelectedTab,
        changeCurrentPage,
        departmentName,

        loadingSnapshots,
        fileDetails,
        fileSnapshots,
        currentSnapshotIndex,

        loadingValidations,
        fileValidations,
        fileAnswers,
        currentValidationIndex,

        loadingDuplicates,
        fileDuplicates,
        downloadDuplicatesCsv,

        updateAlias,
        isEditingAlias,
        setIsEditingAlias,

        inventoryVersions,
        loadingInventoryVersions,
        currentInventoryIndex,

        noTabsPermissions
    } = props;

    const [aliasValue, setAliasValue] = useState("");

    const [criticalityValues, setCriticalityValues] = useState([]);
    const [complexityValues, setComplexityValues] = useState([]);
    const [runId, setRunId] = useState(null);

    const [modalTabs, setModalTabs] = useState([]);

    const [downloadCsvModalOpen, setDownloadCsvModalOpen] = useState(false);


    useEffect(() => {
        const criticalityItems = fileDetails.filter(item => item.discriminator === 'CriticalityIntValue');
        const complexityItems = fileDetails.filter(item => item.discriminator === 'ComplexityIntValue');
        setRunId(fileDetails?.[0]?.wsRunId);

        setCriticalityValues(criticalityItems);
        setComplexityValues(complexityItems);
    }, [fileDetails]);

    useEffect(() => {
        setAliasValue(selectedItem?.alias || "");
    }, [selectedItem])

    useEffect(() => {
        let tabs = [];

        if (loadingSnapshots || loadingValidations || loadingDuplicates || loadingInventoryVersions) {
            tabs = [t('fileoverview_modal_tab_4'), t('fileoverview_modal_tab_2'), t('fileoverview_modal_tab_1'), t('fileoverview_modal_tab_3')];
        } else {
            tabs = [];
            tabs.push(inventoryVersions.length ? t('fileoverview_modal_tab_4') : null);
            tabs.push(fileAnswers.length ? t('fileoverview_modal_tab_2') : null);
            tabs.push(!noTabsPermissions && fileSnapshots.length ? t('fileoverview_modal_tab_1') : null);
            tabs.push(!noTabsPermissions && fileDuplicates.length ? t('fileoverview_modal_tab_3') : null);

            const newSelectedTab = tabs.findIndex(tab => ![null].includes(tab));
            setSelectedTab(newSelectedTab);
        }

        setModalTabs(tabs);
    }, [loadingSnapshots, loadingValidations, loadingDuplicates, loadingInventoryVersions]);

    const mainColor = settings?.theme?.mainColor;
    const { t } = useTranslation();
    const isAllowedAlias = settings?.validationConfig?.allowAlias === 1;

    const currentFileInfo = fileSnapshots[currentSnapshotIndex] || selectedItem;
    const currentFileValidation = fileValidations[currentValidationIndex] || selectedItem;
    const currentFileInventory = inventoryVersions[currentInventoryIndex];

    const isLoading = loadingSnapshots || loadingValidations || loadingDuplicates || loadingInventoryVersions;
    const allTabsEmpty = !modalTabs.some(e => e !== null);

    const duplicatesFileIds = fileDuplicates?.map(duplicate => duplicate.fileOtherId);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                className="kpmg-modal file-overview-modal"
                overlayClassName="kpmg-modal-overlay"
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}>
                <div className="modal-header">
                    <div className="file-overview-info">
                        <h3>{selectedItem?.fileName}</h3>
                        <p style={{ color: mainColor }}>{selectedItem?.path}</p>

                        <hr />

                        <div className="file-overview-details">
                            <table>
                                <tbody>
                                    <tr>
                                        <td> <div className="file-detail">{t('fileoverview_modal_file_status')}: {selectedItem?.status ? t(selectedItem.status) : '-'}</div></td>
                                        <td><div className="file-detail">{t('fileoverview_modal_file_captured_by')}: {selectedItem?.createdVia || '-'}</div></td>
                                        <td><div className="file-detail">{t('fileoverview_modal_file_created_at')}: {selectedItem?.createdAt ? moment(selectedItem.createdAt).format("DD.MM.YYYY") : '-'}</div></td>
                                    </tr>
                                    <tr>
                                        <td> <div className="file-detail">{t('fileoverview_modal_file_department')}: {departmentName || '-'}</div></td>

                                        <td><div className="file-detail">{t('fileoverview_modal_file_employee')}: {selectedItem?.user || '-'}</div></td>
                                        <td><div className="file-detail">{t('fileoverview_modal_file_last_modified')}: {selectedItem?.lastModified ? moment(selectedItem.lastModified).format("DD.MM.YYYY") : '-'}</div></td>
                                    </tr>

                                    <tr>
                                        <td>
                                            {
                                                isAllowedAlias &&
                                                <div className="file-detail">
                                                    {
                                                        isEditingAlias ?
                                                            <div className="file-detail-alias">
                                                                <label htmlFor="">Alias:</label>
                                                                <input
                                                                    type="text"
                                                                    value={aliasValue}
                                                                    onChange={e => setAliasValue(e.target.value)}
                                                                />
                                                                <div className="action-button" onClick={() => updateAlias(aliasValue)}>
                                                                    <GrCheckmark />
                                                                </div>
                                                                <div className="action-button" onClick={() => setIsEditingAlias(false)}>
                                                                    <MdClose />
                                                                </div>
                                                            </div> :
                                                            <div className="file-detail-alias">
                                                                <div>Alias: {selectedItem?.alias || '-'}</div>
                                                                {
                                                                    selectedItem?.validationId &&
                                                                    <div className="action-button" onClick={() => setIsEditingAlias(true)}>
                                                                        <BiEditAlt />
                                                                    </div>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="close-icon" onClick={() => setIsOpen(false)}>
                        <FaTimes />
                    </div>
                </div>

                <div id="file-overview-body" className="modal-body">
                    <div className="tabs-wrapper">
                        {
                            isLoading ?
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <Skeleton width={130} height={25} style={{ marginRight: 10 }} />
                                        <Skeleton width={130} height={25} style={{ marginRight: 10 }} />
                                        <Skeleton width={130} height={25} />
                                    </div>
                                    <div style={{ width: '100%', marginTop: 30 }}>
                                        <Skeleton width={'100%'} height={10} style={{ marginBottom: 5 }} />
                                        <Skeleton width={'100%'} height={10} style={{ marginBottom: 5 }} />
                                        <Skeleton width={'90%'} height={10} />
                                    </div>
                                </div> :
                                allTabsEmpty ?
                                    <div>
                                        {t('fileoverview_modal_no_tabs')}
                                    </div> :
                                    <NavTabs
                                        selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab}
                                        tabs={modalTabs}
                                    >
                                        {
                                            selectedTab === 0 &&
                                            <>
                                                {
                                                    inventoryVersions.length ?
                                                        <div className="questions-tab">
                                                            <div className="tab-info">
                                                                <div className="left-items">
                                                                    {`From ${currentFileInventory?.createdAt ? moment(currentFileInventory.createdAt).format("DD.MM.YYYY") : '-'} submitted by ${currentFileInventory?.businessOfficer || "-"}, reviewed by ${currentFileInventory?.riskOfficer || "-"}`}
                                                                </div>

                                                                <div className="right-items">
                                                                    {
                                                                        currentFileInventory?.version &&
                                                                        <div className="version">
                                                                            v.{currentFileInventory?.version}
                                                                        </div>
                                                                    }

                                                                    <PaginationComponent
                                                                        selectedTab={0}
                                                                        currentIndex={currentInventoryIndex}
                                                                        currentItems={inventoryVersions}
                                                                        changeCurrentPage={changeCurrentPage}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {
                                                                currentFileInventory.sections.map((sectionData, i) => {
                                                                    const sectionQuestionsAndAnswers = sectionData.answers;
                                                                    const sectionTitles = [t('settings_inventory_questions_section_1'), t('settings_inventory_questions_section_2')];
                                                                    const isEucInfoSection = sectionData.sectionName === 'EUC Information';

                                                                    const showSectionData = sectionData.answers?.length || isEucInfoSection;
                                                                    let questionStartIndex = isAllowedAlias ? 2 : 1;
                                                                    let showMetadataQuestions = true;

                                                                    if (!isEucInfoSection) {
                                                                        questionStartIndex -= 2;
                                                                        showMetadataQuestions = false;
                                                                    }

                                                                    let questionIndex = questionStartIndex;

                                                                    return (
                                                                        showSectionData ?
                                                                            <div className="questions-wrapper" key={i}>
                                                                                <div className="section-title">{isEucInfoSection ? sectionTitles[0] : sectionTitles[1]}</div>
                                                                                {
                                                                                    showMetadataQuestions ?
                                                                                        <div className="question">
                                                                                            <div className="question-title">1. {t('screenEucCheck_question_file_chain_text2')}</div>
                                                                                            <div className="question-answer">{selectedItem?.path}</div>
                                                                                        </div> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    (isAllowedAlias && showMetadataQuestions) ?
                                                                                        <div className="question">
                                                                                            <div className="question-title">2. {t('screenEucCheck_question_alias_text')}</div>
                                                                                            <div
                                                                                                className={`question-answer ${currentFileInventory?.oldAlias ? "different" : ""}`}
                                                                                                data-for={`${i}.alias`}
                                                                                                data-tip={`${t('fileoverview_modal_inventory_previous_alias')}: ${currentFileInventory?.oldAlias}`}>
                                                                                                {currentFileInventory?.alias || "-"}

                                                                                                {
                                                                                                    currentFileInventory?.oldAlias &&
                                                                                                    <ReactTooltip
                                                                                                        id={`${i}.alias`}
                                                                                                        effect="solid"
                                                                                                        delayShow={0}
                                                                                                        delayHide={0}
                                                                                                        className="question-tooltip"
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                        </div> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    sectionQuestionsAndAnswers.map((inventoryAnswer, j) => {
                                                                                        if (inventoryAnswer.questionType !== "title") questionIndex++;

                                                                                        return (
                                                                                            <div className="question" key={j}>
                                                                                                {
                                                                                                    inventoryAnswer.questionType === 'title' ?
                                                                                                        <div className="section-title-content">
                                                                                                            <FaAngleDoubleRight className="section-title-icon" />
                                                                                                            <div className="section-title-text">{inventoryAnswer.title}</div>
                                                                                                        </div> :
                                                                                                        <>
                                                                                                            <div className="question-title">
                                                                                                                {questionIndex}. {inventoryAnswer.title}
                                                                                                            </div>
                                                                                                            {
                                                                                                                inventoryAnswer.questionType === 'file' ?
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            inventoryAnswer?.answer?.map((filePath, k) => {
                                                                                                                                const filename = _.last(filePath.split("\\"));
                                                                                                                                return (
                                                                                                                                    filename ?
                                                                                                                                        <a
                                                                                                                                            href={`${process.env.REACT_APP_MAIN_ENDPOINT}/file/download?key=${filePath}`}
                                                                                                                                            target="blank"
                                                                                                                                            rel="noopener noreferrer"
                                                                                                                                            className="file-row"
                                                                                                                                            key={k}>
                                                                                                                                            <FcFile style={{ fontSize: 18 }} />
                                                                                                                                            <span>{filename}</span>
                                                                                                                                        </a> :
                                                                                                                                        "-"
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div> :
                                                                                                                    null
                                                                                                            }

                                                                                                            {
                                                                                                                inventoryAnswer.questionType !== 'file' ?
                                                                                                                    <div
                                                                                                                        className={`question-answer ${inventoryAnswer?.oldAnswer ? "different" : ""}`}
                                                                                                                        data-for={`${i}.${j}`}
                                                                                                                        data-tip={inventoryAnswer?.oldAnswer?.length ? `${t('fileoverview_modal_inventory_previous_answer')}: ${inventoryAnswer.oldAnswer.join(", ")}` : t('screenEucCheck_question_new_question')}>
                                                                                                                        {inventoryAnswer.answer.join("\n")}
                                                                                                                        {
                                                                                                                            inventoryAnswer?.oldAnswer &&
                                                                                                                            <ReactTooltip
                                                                                                                                id={`${i}.${j}`}
                                                                                                                                effect="solid"
                                                                                                                                delayShow={0}
                                                                                                                                delayHide={0}
                                                                                                                                className="question-tooltip"
                                                                                                                            />
                                                                                                                        }
                                                                                                                    </div> :
                                                                                                                    null
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div> :
                                                                            null
                                                                    )
                                                                })
                                                            }
                                                        </div> :
                                                        <p className="empty-tab">{t('fileoverview_modal_tab_2_empty')}</p>
                                                }
                                            </>
                                        }

                                        {
                                            selectedTab === 1 &&
                                            <>
                                                {
                                                    fileValidations.length ?
                                                        <div className="questions-tab">
                                                            <div className="tab-info">
                                                                <div className="left-items">
                                                                    <div className="created-at">
                                                                        {t('fileoverview_modal_tab_2_created_by')}:&nbsp;
                                                                        {currentFileValidation?.displayName || "-"}
                                                                        &nbsp;{t('fileoverview_modal_tab_2_on')}&nbsp;
                                                                        {currentFileValidation?.createdAt ? moment(currentFileValidation.createdAt).format("DD.MM.YYYY") : '-'}
                                                                    </div>
                                                                </div>

                                                                <div className="right-items">
                                                                    <PaginationComponent
                                                                        selectedTab={1}
                                                                        currentIndex={currentValidationIndex}
                                                                        currentItems={fileValidations}
                                                                        changeCurrentPage={changeCurrentPage}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="question">
                                                                <div className="question-title">1. {t('screenEucCheck_question_file_chain_text2')}</div>
                                                                <div className="question-answer">{selectedItem?.path}</div>
                                                            </div>

                                                            {
                                                                isAllowedAlias &&
                                                                <div className="question">
                                                                    <div className="question-title">2. {t('screenEucCheck_question_alias_text')}</div>
                                                                    <div className="question-answer">{currentFileValidation?.alias || "-"}</div>
                                                                </div>
                                                            }

                                                            {
                                                                fileAnswers.map((answer, i) => (
                                                                    <div className="question" key={i}>
                                                                        <div className="question-title">
                                                                            {i + (isAllowedAlias ? 3 : 2)}. {t(answer.question)}
                                                                        </div>
                                                                        <div className="question-answer">{t(answer.value)}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div> :
                                                        <p className="empty-tab">{t('fileoverview_modal_tab_2_empty')}</p>
                                                }
                                            </>
                                        }

                                        {
                                            selectedTab === 2 &&
                                            <div className="scan-ergebnis-tab">
                                                <div className="tab-info">
                                                    <div className="left-items">
                                                        <div className="created-at">
                                                            Scan results from {currentFileInfo?.scanDate ? moment(currentFileInfo.scanDate).format("DD.MM.YYYY") : '-'} {runId && `(RunId: ${runId})`}
                                                        </div>
                                                    </div>
                                                    <div className="right-items">
                                                        <PaginationComponent
                                                            selectedTab={2}
                                                            currentIndex={currentSnapshotIndex}
                                                            currentItems={fileSnapshots}
                                                            changeCurrentPage={changeCurrentPage}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="values-overview">
                                                    <div className="main-value-wrapper">
                                                        <div className="main-value">
                                                            <div>{t('fileoverview_modal_tab_1_text_1')}</div>
                                                            <div>{_.isNil(currentFileInfo?.complexity) ? "-" : currentFileInfo?.complexity}</div>
                                                        </div>
                                                    </div>

                                                    <div className="main-value-wrapper">
                                                        <div className="main-value">
                                                            <div>{t('fileoverview_modal_tab_1_text_2')}</div>
                                                            <div>{_.isNil(currentFileInfo?.criticality) ? "-" : currentFileInfo?.criticality}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tables-title">
                                                    <div>{t('fileoverview_modal_tab_1_details')}:</div>
                                                </div>

                                                <div className="tables">
                                                    <div className="complexity-table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{t('fileoverview_modal_tab_1_header_1')}</th>
                                                                    <th style={{ width: 90 }}>{t('fileoverview_modal_scan_value')}</th>
                                                                    <th style={{ width: 120 }}>{t('fileoverview_modal_scan_threshold')}</th>
                                                                    <th style={{ width: 120 }} >{t('fileoverview_modal_tab_1_header_3')}*</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loadingSnapshots ?
                                                                        _.times(10, (i) => (
                                                                            <tr style={{ backgroundColor: "#fff" }} key={i}>
                                                                                <td colSpan={4}>
                                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                        <Skeleton width={150} key={i} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )) :
                                                                        complexityValues.length ?
                                                                            complexityValues.map((indicator, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{indicator.indicator}</td>
                                                                                    <td width={30}>{formatThousandsAndDecimals(indicator.value)}</td>
                                                                                    <td width={30}>{formatThousandsAndDecimals(indicator.threshold)}</td>
                                                                                    {/* todo se é absoluto, nao fazer a conta */}
                                                                                    <td width={30}>
                                                                                        {
                                                                                            indicator.valueType === "Absolute" ?
                                                                                                indicator.weight :
                                                                                                formatThousandsAndDecimals((indicator.weight / currentFileInfo?.complexityDenom) * 100, true)
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )) :
                                                                            <tr className="empty">
                                                                                <td colSpan={4}>{t('fileoverview_modal_tab_1_empty')}</td>
                                                                            </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="criticality-table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{t('fileoverview_modal_tab_1_header_4')}</th>
                                                                    <th style={{ width: 90 }}>{t('fileoverview_modal_scan_value')}</th>
                                                                    <th style={{ width: 120 }}>{t('fileoverview_modal_tab_1_header_2')}</th>
                                                                    <th style={{ width: 120 }}>{t('fileoverview_modal_tab_1_header_3')}*</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loadingSnapshots ?
                                                                        _.times(10, (i) => (
                                                                            <tr style={{ backgroundColor: "#fff" }} key={i}>
                                                                                <td colSpan={4}>
                                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                        <Skeleton width={150} key={i} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )) :
                                                                        criticalityValues.length ?
                                                                            criticalityValues.map((indicator, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{indicator.indicator}</td>
                                                                                    <td width={30}>{formatThousandsAndDecimals(indicator.value)}</td>
                                                                                    <td width={30}>{formatThousandsAndDecimals(indicator.threshold)}</td>
                                                                                    <td width={30}>
                                                                                        {
                                                                                            indicator.valueType === "Absolute" ?
                                                                                                indicator.weight :
                                                                                                formatThousandsAndDecimals((indicator.weight / currentFileInfo?.criticalityDenom) * 100, true)
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )) :
                                                                            <tr className="empty">
                                                                                <td colSpan={4}>{t('fileoverview_modal_tab_1_empty')}</td>
                                                                            </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="tables-info">{t('fileoverview_modal_tab_1_info')}</div>
                                                {currentFileInfo?.lastError &&
                                                    <div className="tables-error">
                                                        <IoWarningOutline />
                                                        {t('fileoverview_modal_results_error_label')} {currentFileInfo?.lastError}
                                                    </div>}
                                            </div>
                                        }

                                        {
                                            selectedTab === 3 &&
                                            <div className="duplicates-tab">
                                                {
                                                    loadingDuplicates ?
                                                        <TableSkeletonLoading
                                                            columns={2}
                                                            rows={3}
                                                        /> :
                                                        fileDuplicates.length ?
                                                            <>
                                                                <div className="download-csv">
                                                                    <Button onClick={() => setDownloadCsvModalOpen(true)}>{t('fileoverview_modal_download_csv')}</Button>
                                                                </div>

                                                                <table className="kpmg-table stripped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{t('duplicate_table_header_1')}</th>
                                                                            <th>{t('duplicate_table_header_2')}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            fileDuplicates.map((dups, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{dups.fileOther || "-"}</td>
                                                                                    <td>{`${Math.round(dups.score * 100)}%` || "-"}</td>
                                                                                </tr>

                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </> :
                                                            <p>{t('duplicate_table_empty')}</p>
                                                }
                                            </div>
                                        }
                                    </NavTabs>
                        }
                    </div>
                </div>
            </Modal>

            <DownloadCsvModal
                isOpen={downloadCsvModalOpen}
                setIsOpen={setDownloadCsvModalOpen}
                fileIds={duplicatesFileIds}
                hideFirstQuestion={true}
            />
        </div>
    )
};

const PaginationComponent = (props) => {
    const { selectedTab, currentIndex, currentItems, changeCurrentPage } = props;

    return (
        currentItems.length > 1 ?
            <div className="pagination">
                <div
                    className={currentIndex === 0 ? "disabled" : ""}
                    onClick={() => changeCurrentPage('previous', currentIndex, currentItems, selectedTab)}>
                    <BsChevronLeft />
                </div>
                <div
                    className={currentIndex >= (currentItems.length - 1) ? "disabled" : ""}
                    onClick={() => changeCurrentPage('next', currentIndex, currentItems, selectedTab)}>
                    <BsChevronRight />
                </div>
            </div> :
            null
    )
}

export default FileOverviewModalComponent;