import React, { useState } from "react";
import Layout from 'layouts/layout';
import NavTabs from 'components/common/NavTabs/NavTabs';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import ValidationDownloadModal from 'components/modals/ValidationDownloadModal/containers/ValidationDownloadModal';
import TablePagination from 'components/common/TablePagination/TablePagination';
import { useTranslation } from "react-i18next";
import { ImSearch } from 'react-icons/im';
import { IoIosArrowForward } from 'react-icons/io';
import { GrUndo } from 'react-icons/gr';
import { parseFileOverviewModalMetadata } from 'services/helper';
import moment from 'moment';
import TableActionButton from 'components/common/TableActionButton/TableActionButton';
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import './MeineValidierungenComponent.scss';

const MeineValidierungenComponent = (props) => {
    const {
        validationFormOpen,
        currentValidationFormOpen,
        validationFormClosed,
        currentValidationFormClosed,
        loading,
        settings,
        reloadTabs,
        pagination,
        changePageData,
        selectedTab,
        changeTab,
        user,
        rejectInventoryForm,
        forwardToUser,
        isSearchUserModalOpen,
        setIsSearchUserModalOpen
    } = props;
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

    const isAllowedAlias = settings?.validationConfig?.allowAlias === 1;

    const openFileOverviewModal = (row) => {
        const item = parseFileOverviewModalMetadata(row)
        setSelectedItem(item);
        setIsFileOverviewModalOpen(true);
    }

    const getResultValue = result => {
        if (result === 'euc') return t('screenValidierung_table_value_euc');
        if (result === 'non-euc') return t('screenValidierung_table_value_non_euc');

        return '-';
    }

    const isInventoryRole = user?.groupPermissions?.includes("EUCINVENTORY");

    return (
        <Layout>
            <div className="meine-validierungen">
                <div className="meine-validierungen-content">
                    <h2>{t('screenMeineValidierungen_title_text')}</h2>
                    <p>{t('screenMeineValidierungen_content_text')}</p>

                    <NavTabs
                        selectedTab={selectedTab}
                        setSelectedTab={(tab) => changeTab(tab)}
                        tabs={[t('screenMeineValidierungen_tab_1'), t('screenMeineValidierungen_tab_2')]}
                    >
                        {
                            selectedTab === 0 &&
                            <div>
                                {
                                    loading ?
                                        <TableSkeletonLoading
                                            columns={5}
                                            rows={5}
                                        /> :
                                        validationFormOpen.length ?
                                            <div>
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenMeineValidierungen_table_column_2')}</th>
                                                            <th>{t('screenMeineValidierungen_table_column_4')}</th>
                                                            <th>Type</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenMeineValidierungen_table_column_8')}</th>
                                                            }
                                                            <th>{t('screenMeineValidierungen_table_column_7')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentValidationFormOpen.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    <td>{thisRow.inventoryId ? "Inventory" : "EUC"}</td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </TableActionButton>

                                                                            <TableActionButton onClick={() => {
                                                                                setSelectedItem(thisRow);
                                                                                setIsSearchUserModalOpen(true)
                                                                            }}>
                                                                                <img
                                                                                    className="send-user-icon"
                                                                                    src={require("assets/icons/custom/send-user.png").default}
                                                                                    alt="send-user"
                                                                                />
                                                                            </TableActionButton>

                                                                            {
                                                                                thisRow.inventoryId ?
                                                                                    <>
                                                                                        <TableActionButton linkTo={`/guidance/inventory-check-questions?inventoryId=${thisRow.inventoryId}`}>
                                                                                            <IoIosArrowForward />
                                                                                        </TableActionButton>
                                                                                        {
                                                                                            isInventoryRole && (thisRow.status === "Open" || thisRow.status === "Started") && thisRow.orderNo > 1 &&
                                                                                            <TableActionButton onClick={() => rejectInventoryForm(thisRow.inventoryId)}>
                                                                                                <GrUndo />
                                                                                            </TableActionButton>
                                                                                        }
                                                                                    </> :
                                                                                    <TableActionButton linkTo={`/guidance/euc-check-questions?validationId=${thisRow.validationId}`}>
                                                                                        <IoIosArrowForward />
                                                                                    </TableActionButton>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <TablePagination
                                                    pagination={pagination}
                                                    dataCount={validationFormOpen.length}
                                                    changePageDataCallback={pagination => changePageData(pagination, null, 0)}
                                                />
                                            </div> :
                                            <p>{t('screenMeineValidierungen_tab_1_empty')}</p>
                                }
                            </div>
                        }

                        {
                            selectedTab === 1 &&
                            <div>
                                {
                                    loading ?
                                        <TableSkeletonLoading
                                            columns={5}
                                            rows={5}
                                        /> :
                                        currentValidationFormClosed.length ?
                                            <div>
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenMeineValidierungen_table_column_2')}</th>
                                                            <th>{t('screenMeineValidierungen_table_column_4')}</th>
                                                            <th>Type</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenMeineValidierungen_table_column_8')}</th>
                                                            }
                                                            <th>{t('screenMeineValidierungen_table_column_9')}</th>
                                                            <th>{t('screenMeineValidierungen_table_column_7')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentValidationFormClosed.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    <td>{thisRow.inventoryId ? "Inventory" : "EUC"}</td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        <strong>
                                                                            {
                                                                                thisRow.inventoryId ?
                                                                                    t(thisRow.inventoryStatus) :
                                                                                    getResultValue(thisRow.eucResult)
                                                                            }
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <div className="table-btn magnifier" onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <TablePagination
                                                    pagination={pagination}
                                                    dataCount={validationFormClosed.length}
                                                    changePageDataCallback={pagination => changePageData(pagination, null, 1)}
                                                />
                                            </div> :
                                            <p>{t('screenMeineValidierungen_tab_1_empty')}</p>
                                }
                            </div>
                        }
                    </NavTabs>
                </div>
            </div>

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                selectedItemType={selectedItem?.inventoryId ? "inventory" : "validation"}
                refreshScreen={reloadTabs}
                hideSnapshotsPagination={true}
            />

            <ValidationDownloadModal
                isOpen={isDownloadModalOpen}
                setIsOpen={setIsDownloadModalOpen}
                selectedItem={{}}
            />

            <SelectUserModal
                isOpen={isSearchUserModalOpen}
                setIsOpen={setIsSearchUserModalOpen}
                selectedItems={[selectedItem]}
                getCoordinatorNameCallback={(user) => forwardToUser(user, selectedItem.inventoryId ? selectedItem.sectionId : selectedItem.validationId, selectedItem.inventoryId ? "inventory" : "validation")}
                hideYourselfOption
                title={t('forwarding_modal_title')}
                description={t('forwarding_modal_description')}
            />
        </Layout>
    )
};

export default MeineValidierungenComponent;