import React, { useState, useEffect } from "react";
import ModalLayout from 'layouts/modal-layout/modal-layout';
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import UserListModal from 'components/modals/UserListModal/containers/UserListModal';
import { useTranslation } from "react-i18next";
import SendButton from 'components/common/SendButton/SendButton';

const InventoryUserPickerComponent = (props) => {
    const {
        isOpen,
        selectedItems,
        submitRequest,
        isModalOpen,
        setIsModalOpen,
        businessOfficerModalOpen,
        setBusinessOfficerModalOpen,
        riskOfficerModalOpen,
        setRiskOfficerModalOpen,
        closeModal,
        isLoading
    } = props;

    const [businessOfficerName, setBusinessOfficerName] = useState("");
    const [riskOfficerName, setRiskOfficerName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setIsModalOpen(true);
        }
    }, [isOpen]);

    const changeToStepTwo = () => {
        setIsModalOpen(false);
        setBusinessOfficerModalOpen(true);
    }

    const getBusinessOfficerName = businessOfficer => {
        setBusinessOfficerName(businessOfficer);

        setBusinessOfficerModalOpen(false);
        setRiskOfficerModalOpen(true);
        setSearchTerm("group=EUCINVENTORY");
    }

    const getRiskOfficerName = riskOfficer => {
        setRiskOfficerName(riskOfficer);
        submitRequest({ riskOfficer: riskOfficer.samAccountName, businessOfficer: businessOfficerName.samAccountName });
    }

    return (
        <div>
            <ModalLayout
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                style={{ width: 500, height: 230 }}
                modalTitle={t('inventory_user_picker_step_1_title')}
                modalDescription={t('inventory_user_picker_step_1_description')}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}>
                    <SendButton
                        onClick={() => changeToStepTwo()}
                    />
                </div>
            </ModalLayout>

            <SelectUserModal
                isOpen={businessOfficerModalOpen}
                setIsOpen={closeModal}
                selectedItems={selectedItems}
                getCoordinatorNameCallback={getBusinessOfficerName}
                keepOpenOnCallback={true}
                title={t("inventory_user_picker_step_2_title")}
                description={t("inventory_user_picker_step_2_description")}
            />

            <UserListModal
                isOpen={riskOfficerModalOpen}
                setIsOpen={closeModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectUserCallback={getRiskOfficerName}
                buttonLoading={isLoading}
                title={t("inventory_user_picker_step_3_title")}
                description={t("inventory_user_picker_step_3_description")}
            />
        </div>
    )
};

export default InventoryUserPickerComponent;