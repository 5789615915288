import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from 'formik';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import InfoComponent from 'components/common/InfoComponent/InfoComponent';
import QuestionnaireSkeleton from 'components/common/QuestionnaireSkeleton/QuestionnaireSkeleton';
import FileTreeModal from 'components/modals/FileTreeModal/containers/FileTreeModal';
import './QuestionnaireComponent.scss';

const checkQuestionRequirements = (formikQuestionValues, questionRequirements) => {
    if (questionRequirements.length) {
        let isVisible = false;

        const requirements = questionRequirements.map(questionRequirement => {
            return formikQuestionValues[questionRequirement.requiredId] === questionRequirement.trigger;
        })

        if (requirements.includes(true)) isVisible = true;

        return isVisible;
    }

    return false;
}

const checkIfQuestionIsVisible = (values, question) => {
    const isQuestionVisible = question.behaviour === 'show' || checkQuestionRequirements(values, question.requirements);
    return isQuestionVisible;
}

const questionsFactory = (question, questionKey, t, formikProps, isAllowedAlias) => {
    const { values, errors, touched, setFieldValue, setFieldTouched } = formikProps;

    const hasError = errors[question.questionId] && errors[question.questionId];
    const hasTouched = touched[question.questionId] && touched[question.questionId];

    const handleQuestionChange = (questionId, value, otherQuestionValues) => {
        setFieldValue(questionId, value);

        const otherQuestionIds = _.keys(otherQuestionValues)
            .filter(questionKey => questionKey !== "fileSelect")
            .map(questionKey => parseInt(questionKey));

        otherQuestionIds.forEach(otherQuestionId => {
            if (otherQuestionId > questionId && otherQuestionValues[otherQuestionId] !== '§§§') {
                setFieldValue(otherQuestionId, "§§§");
            }
        })
    }

    const getQuestionIndex = (values, questionId) => {
        /*
            This function retrieves a human readable index.

            All questions are used in the questionsFactory function because of validation (formik) purposes.
            So if the factory is created in a way that the user should answer question 1, then go to question 3, 
            we still should show (step 1 and step 2, not step 1 and step 3).
         */

        let questionValues = _.cloneDeep(values);

        delete questionValues.fileSelect;
        delete questionValues.alias;

        const questionIdKeys = _.keys(questionValues);

        questionIdKeys.forEach(questionKey => {
            if (questionValues[questionKey] === '§§§') delete questionValues[questionKey];
        });

        const questionVisibleIdKeys = _.keys(questionValues);

        return questionVisibleIdKeys.indexOf(questionId.toString()) + (isAllowedAlias ? 3 : 2);
    }

    switch (question.type) {
        case 'select':
            const optionsOrdered = _.orderBy(question.options, ['order'], 'asc');
            const isQuestionVisible = checkIfQuestionIsVisible(values, question);

            if (isQuestionVisible && values[question.questionId] === '§§§') setFieldValue(question.questionId, '');

            const questionIndex = getQuestionIndex(values, question.questionId);

            return (
                isQuestionVisible &&
                <div className="question" key={questionKey}>
                    <div className="question-content">
                        <label htmlFor={questionKey}>
                            <div className="question-title">
                                <strong>{questionIndex}. </strong>
                                {t(`${question.text}`)}
                            </div>
                        </label>

                        <div className="question-body">
                            <div className="flex">
                                <select
                                    onChange={(e) => handleQuestionChange(question.questionId, e.target.value, values)}
                                    onBlur={setFieldTouched}
                                    name={question.questionId}
                                    value={values[question.questionId]}
                                    htmlFor={questionKey}>
                                    <option value="" defaultValue>--- {t('screenEucCheck_dropbox_chain_text_1')} ---</option>
                                    {
                                        optionsOrdered.map((questionOption, i) => (
                                            <option key={i} value={questionOption.value}>{t(questionOption.value)}</option>
                                        ))
                                    }
                                </select>

                                <InfoComponent tooltipValue={t(question.infoText)} />
                            </div>

                            <div className="error-message">
                                <ErrorMessage name={question.questionId} />
                            </div>
                        </div>
                    </div>
                </div>
            )

        default: return null;
    }
}

/*
    The Formik is initialized with all questions retrieved from back-end;
    We are using the symbol "§§§" to represent the questions not visible, because all questions are set as required by default.
    So, taking into account that the library does not implement dynamic validations, this approach is used to solve that problem.
*/

const QuestionnaireComponent = (props) => {
    const { sendQuestionsData, questions, oldFormData, isLoading, isSubmitting, isAllowedAlias, isAllowedFileExplorer, isLoadingOldFormData } = props;
    const [isFileTreeModalOpen, setIsFileTreeModalOpen] = useState(false);
    const [yupValidationSchema, setYupValidationSchema] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        let yupValidationSchemaTemp = {
            fileSelect: Yup.string().required(t('screenEucCheck_check_chain_text_1')),
            directory: Yup.object().shape({
                path: Yup.string().required(t('screenEucCheck_check_chain_text_1'))
            }),
            alias: Yup.string(),
        }

        questions.forEach(question => {
            yupValidationSchemaTemp[question.questionId] = Yup.string().required(t('screenEucCheck_check_chain_text_2'));
        })

        yupValidationSchemaTemp = Yup.object().shape({
            ...yupValidationSchemaTemp
        });

        setYupValidationSchema(yupValidationSchemaTemp);
    }, [questions])

    let formikInitialState = {
        fileSelect: JSON.stringify({ name: oldFormData?.fileName, type: oldFormData?.type }) || "",
        directory: {
            path: oldFormData?.path || ""
        },
        alias: oldFormData?.alias || "",
    }

    questions.forEach(question => {
        const oldAnswers = oldFormData?.answers;
        const questionOldAnswer = oldAnswers?.filter(oldQuestion => oldQuestion.questionId === question.questionId)[0];
        formikInitialState[question.questionId] = questionOldAnswer?.value || '§§§';
    });

    return (
        <div className="questionnaire-component">
            {
                isLoading || isLoadingOldFormData ?
                    <QuestionnaireSkeleton isAllowedAlias={isAllowedAlias} /> :
                    <Formik
                        enableReinitialize
                        initialValues={formikInitialState}
                        validationSchema={yupValidationSchema}
                        onSubmit={(values) => sendQuestionsData(values)}>
                        {(formikProps) => {
                            const values = formikProps.values;
                            const defaultInputsFilled = values.directory.path;

                            return (
                                <Form>
                                    <div className="question">
                                        <div className="question-content">
                                            <label htmlFor="fileSelect">
                                                <div className="question-title">
                                                    <strong>1. </strong>
                                                    <span>{t('screenEucCheck_question_file_chain_text2')}</span>
                                                </div>
                                            </label>

                                            <div className="question-body">
                                                <div className="flex">
                                                    <Field
                                                        name="directory.path"
                                                        onChange={e => {
                                                            formikProps.setFieldValue('directory.path', e.target.value);
                                                        }}
                                                        placeholder={isAllowedFileExplorer ? t('screenEucCheck_question_file_chain_text3') : ""}
                                                        disabled={oldFormData}
                                                        readOnly={isAllowedFileExplorer ? true : false}
                                                    />

                                                    {
                                                        isAllowedFileExplorer &&
                                                        <Button disabled={oldFormData} onClick={() => oldFormData ? null : setIsFileTreeModalOpen(true)}>{t('screenEucCheck_button_chain_text_2')}</Button>
                                                    }
                                                </div>

                                                <div className="error-message">
                                                    <ErrorMessage name="directory.path" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isAllowedAlias &&
                                        <div className="question">
                                            <div className="question-content">
                                                <label htmlFor="fileSelect">
                                                    <div className="question-title">
                                                        <strong>2. </strong>
                                                        <span>{t('screenEucCheck_question_alias_text')}</span>
                                                    </div>
                                                </label>

                                                <div className="question-body">
                                                    <div className="flex">
                                                        <Field name="alias" />

                                                        <InfoComponent tooltipValue={t('screenEucCheck_info_alias_text')} />
                                                    </div>

                                                    <div className="error-message">
                                                        <ErrorMessage name="alias" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        defaultInputsFilled &&
                                        questions.map((question, i) => {
                                            return questionsFactory(question, i, t, formikProps, isAllowedAlias)
                                        })
                                    }

                                    {
                                        defaultInputsFilled && _.isEmpty(formikProps.errors) &&
                                        <div className="form-actions">
                                            <Button style={{ minWidth: 150 }} type="submit" className="small light" loading={isSubmitting}>{t('screenEucCheck_button_chain_text_1')}</Button>
                                        </div>
                                    }

                                    <FileTreeModal
                                        isOpen={isFileTreeModalOpen}
                                        setIsOpen={setIsFileTreeModalOpen}
                                        selectFileCallback={(fileData) => {
                                            formikProps.setFieldValue('directory.path', fileData.path);
                                            formikProps.setFieldValue('fileSelect', JSON.stringify(fileData));
                                            formikProps.validateField("directory.path");
                                        }}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
            }
        </div>
    )
};

export default QuestionnaireComponent;