import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from 'react-icons/fa';
import './EucCheckSuccess.scss';

const EucCheckSuccess = props => {
    const { clickAction, filename, alias, bottomText, statusText } = props;
    const { t } = useTranslation();

    return (
        <div className="euc-check-success">
            <div className="success-card">
                <div className="success-check">
                    <FaCheck />
                </div>
                <div className="success-text">
                    <div className="title">{t('screenEucCheck_layout_chain_text_5')}</div>
                    <div className="message">{filename}</div>
                    <div className="alias">{alias}</div>
                    <div className="status">{statusText}</div>
                </div>
            </div>
            <div className="bottom-message">
                {bottomText}
            </div>

            {
                clickAction &&
                <button onClick={clickAction}>{t('screenEucCheck_layout_chain_text_8')}</button>
            }

        </div>
    )
}

export default EucCheckSuccess;