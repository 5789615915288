import React, { useState, useEffect } from "react";
import Layout from 'layouts/layout';
import NavTabs from 'components/common/NavTabs/NavTabs';
import EucCheckManagement from 'components/Settings/EucCheckManagement/containers/EucCheckManagement'
import InventoryQuestionsManagement from 'components/Settings/InventoryQuestionsManagement/containers/InventoryQuestionsManagement'
import { useTranslation } from "react-i18next";
import './QuestionnairesComponent.scss';

const QuestionnairesComponent = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation();

    const tabs = [t('settings_tab5')];

    return (
        <Layout>
            <div>
                <NavTabs
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabs={tabs}
                >
                    {
                        selectedTab === 0 &&
                        <EucCheckManagement />
                    }

                    {/* {
                        selectedTab === 1 &&
                        <InventoryQuestionsManagement />
                    } */}
                </NavTabs>
            </div>
        </Layout>
    )
};

export default QuestionnairesComponent;