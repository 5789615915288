import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import 'ace-builds/src-noconflict/ext-searchbox';

import './LanguageComponent.scss';

const jsonlint = require("jsonlint-mod");

const LanguageComponent = (props) => {
    const {
        dictionary,
        savedDictionary,
        setDictionary,
        updateDictionary,
        isLoading,
        fetchingDictionary
    } = props;

    const [error, setError] = useState("");
    const { t } = useTranslation();

    const changeDictionary = (newDictionary) => {
        try {
            setDictionary(newDictionary);
            jsonlint.parse(newDictionary);
            setError("");
        } catch (err) {
            setError(err.message);
        }
    }

    return (
        <div className="language-component">
            <div className="language-content">
                {
                    error &&
                    <div className="error-message">{error}</div>
                }
                <div className="json-wrapper">
                    {
                        fetchingDictionary ?
                            _.times(10, (i) => (
                                <Skeleton key={i} width={'100%'} height={15} style={{ marginBottom: 15 }} />
                            )) :
                            dictionary &&
                            <AceEditor
                                mode="json"
                                theme="github"
                                value={dictionary}
                                width={"100%"}
                                height={"100%"}
                                showPrintMargin={false}
                                onChange={(value) => changeDictionary(value)}
                                editorProps={{ $blockScrolling: true }}
                                setOptions={{ useWorker: false }}
                            />
                    }
                </div>

                <div className="actions">
                    <div>{t('settings_tab4_button_layout_chain_text_a')}:</div>

                    <Button onClick={() => {
                        setDictionary(savedDictionary);
                        setError("");
                    }}>
                        {t('settings_tab4_button1_title_a')}
                    </Button>

                    <Button loading={isLoading} disabled={error} onClick={updateDictionary}>
                        {t('settings_tab4_button2_title_a')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default LanguageComponent;