import { UPDATE_DASHBOARD, UPDATE_DASHBOARD_CHART_FIELD, UPDATE_DASHBOARD_LIST_FIELD } from "../types/dashboardTypes";
import appFilters from 'services/appFilters';

const initialState = {
    dashboard: appFilters
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard
            };

        case UPDATE_DASHBOARD_CHART_FIELD:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    chart: {
                        ...state.dashboard.chart,
                        [action.field]: action.value
                    }
                }
            };

        case UPDATE_DASHBOARD_LIST_FIELD:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    list: {
                        ...state.dashboard.list,
                        [action.field]: action.value
                    }
                }
            };
        default:
            return state;
    }
};