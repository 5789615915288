import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import './AddBlacklistModalComponent.scss';

const AddBlacklistModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        submitKeywordList,
        isLoading,
    } = props;

    const { t } = useTranslation();

    useEffect(() => {
        formik.resetForm();
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('formik_required_field')),
        }),
        enableReinitialize: true,
        onSubmit: values => {
            submitKeywordList(values)
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal add-blacklist-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="content-header">
                <div className="content-header__title">
                    <div>{t('addblacklist_modal_title')}</div>
                </div>

                <div className="close-tooltip" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="content-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                        <label htmlFor="name">{ t('addblacklist_modal_name_label')}</label>
                        <Input
                            name="name"
                            placeholder={ t('addblacklist_modal_name_placeholder')}
                            formikProps={formik}
                        />
                    </div>

                    <div className="action-buttons">
                        <Button type="submit" loading={isLoading} style={{ width: 100 }}>{t('addtopic_modal_create')}</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
};

export default AddBlacklistModalComponent