import React from "react";
import { connect } from "react-redux";
import WizardModalComponent from '../components/WizardModalComponent';

const WizardModal = (props) => {
    const { isOpen, setIsOpen, id, settings } = props;

    return (
        <WizardModalComponent
            id={id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            settings={settings}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(WizardModal);