import React, { useState, useEffect, useRef } from "react";
import UbersichtScansComponent from '../components/UbersichtScansComponent';
import { abortScanService, getScanProgressService, stopScanService } from 'services/scanning';
import _ from 'lodash';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'

const UbersichtScans = (props) => {
    const [progressList, setProgressList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentConfigId, setCurrentConfigId] = useState(null);
    const [loadingStopScan, setLoadingStopScan] = useState([]);
    const loadingStopScanRef = useRef([]);

    const { t } = useTranslation();

    useEffect(() => {
        getScanProgresses();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);
    const getScanProgresses = async () => {

        try {
            const response = await getScanProgressService(); //TODO remove map
            const responseSorted = _.orderBy(response, (item) => item.RunId, ['desc']);
            setProgressList(responseSorted);
        } catch (err) {
            console.log("Error while fetching my configurations", err);
        }

        setIsFetching(false);
    }

    const openWizardModal = configId => {
        setCurrentConfigId(configId);
        setIsModalOpen(true);
    }

    const stopScan = async (runId) => {
        try {
            if (await confirm({
                title: t('confirm_stop_scan_title'),
                confirmation: t('confirm_stop_scan_message'),
            })) {

                loadingStopScanRef.current = [...loadingStopScanRef.current, runId];
                setLoadingStopScan(loadingStopScanRef.current);
                const res = await stopScanService(runId);
                await getScanProgresses();
                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_stop_scan_success')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            }
        }
        catch (e) {
            console.log('error stopScan', e);
        }
        finally {
            loadingStopScanRef.current = [...loadingStopScanRef.current.filter((x) => x !== runId)];
            setLoadingStopScan(loadingStopScanRef.current);
        }
    }

    const abortScan = async (runId) => {
        try {
            if (await confirm({
                title: t('confirm_stop_scan_title'),
                confirmation: t('confirm_stop_scan_message'),
            })) {

                loadingStopScanRef.current = [...loadingStopScanRef.current, runId];
                setLoadingStopScan(loadingStopScanRef.current);
                const res = await abortScanService(runId);
                await getScanProgresses();
                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_abort_scan_success')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            }
        }
        catch (e) {
            console.log('error stopScan', e);
        }
        finally {
            loadingStopScanRef.current = [...loadingStopScanRef.current.filter((x) => x !== runId)];
            setLoadingStopScan(loadingStopScanRef.current);
        }
    }

    return <UbersichtScansComponent
        isFetching={isFetching}
        progressList={progressList}
        openWizardModal={openWizardModal}
        currentConfigId={currentConfigId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        stopScan={stopScan}
        abortScan={abortScan}
        loadingStopScan={loadingStopScan}
    />;
};

export default UbersichtScans;