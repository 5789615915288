import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GraphenComponent from '../components/GraphenComponent';
import { updateDashboardChartField } from "redux/actions/dashboardActions";
import { getGraphDataService } from 'services/analysis';
import { getTopicsService } from 'services/topic';
import { parseFileSnapshot, groupByMulti } from 'services/helper';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import _ from 'lodash';

const Graphen = (props) => {
    const { settings, globalFilters, toggleChartCallback, updateDashboardChartField, dashboard } = props;
    const [isGraphLoading, setIsGraphLoading] = useState(false);
    const [scatterChartData, setScatterChartData] = useState([]);
    const [chartDuplicateData, setChartDuplicateData] = useState({});

    const [axisVisible, setAxisVisible] = useState("true");
    const [topics, setTopics] = useState([]);
    const [criticalityValue, setCriticalityValue] = useState(50);
    const [complexityValue, setComplexityValue] = useState(50);
    const [isLocalFiltersActive, setIsLocalFiltersActive] = useState(false)
    const [chartDataCount, setChartDataCount] = useState(0);
    const [allDataCount, setAllDataCount] = useState(0);
    const [loadingAllDataCount, setLoadingAllDataCount] = useState(0);
    const [localFilters, setLocalFilters] = useState({
        status: [],
        complexity: {
            min: 0,
            max: 100
        },
        criticality: {
            min: 0,
            max: 100
        },
        duplicates: {
            showOnlyDuplicates: false
        },
        topicId: [],
        validationType: [],
        nameSearch: ''
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getTopics();
    }, []);

    useEffect(() => {
        if (dashboard?.chart?.chartData) {
            setScatterChartData(dashboard?.chart?.chartData);
            setChartDuplicateData(dashboard?.chart?.duplicates);
            setAllDataCount(dashboard?.chart?.allDataCount);
            setChartDataCount(dashboard?.chart?.chartDataCount);
        }
    }, [])

    useEffect(() => {
        if ((globalFilters?.orgTenantId || globalFilters?.orgGroupId || globalFilters?.orgDepartmentId) && !dashboard?.chart?.chartData) {
            getScatterChartData(globalFilters);
        }
    }, [globalFilters])

    useEffect(() => {
        setLocalFilters(dashboard?.chart?.filters);
    }, [dashboard.chart.filters])

    useEffect(() => {
        setCriticalityValue(settings.configGraph.criticalityAxis);
        setComplexityValue(settings.configGraph.complexityAxis);
    }, [settings.configGraph])

    const getScatterChartData = async (globalFilters, thisLocalFilters = null) => {
        setIsGraphLoading(true);

        const pointLimit = settings?.configGraph?.pointLimit || 100;

        try {
            getGraphDataLength(globalFilters, thisLocalFilters);
            getAllDataLength(globalFilters);

            const graphData = await getGraphDataService(globalFilters, thisLocalFilters, { limit: pointLimit });

            const graphDataParsed = graphData.map(point => {
                return {
                    ...parseFileSnapshot(point)
                }
            });

            const { duplicateIds, duplicatesGroupedObject } = getChartDuplicates(graphDataParsed);

            const graphDataWithoutOverlapping = graphDataParsed.filter(point => {
                return !duplicateIds.includes(point.id);
            });

            const singleDuplicate = _.keys(duplicatesGroupedObject).map(duplicateKey => {
                const arrayGroup = duplicatesGroupedObject[duplicateKey];
                const firstElement = arrayGroup[0];

                return {
                    ...firstElement,
                    statusId: 'duplicate',
                    numberOfDuplicates: arrayGroup.length
                }
            })

            const chartAllData = [...graphDataWithoutOverlapping, ...singleDuplicate];
            const dataGroupedByStatus = _.groupBy(chartAllData, 'statusId');
            setScatterChartData(dataGroupedByStatus);

            updateDashboardChartField("chartData", dataGroupedByStatus);
            updateDashboardChartField("chartDataCount", graphData?.length);
            updateDashboardChartField("duplicates", duplicatesGroupedObject);
            updateDashboardChartField("filters", thisLocalFilters || localFilters);
        } catch (err) {
            console.log("Error fetching graph data", err);
        }

        setIsGraphLoading(false);
    }

    const getGraphDataLength = async (globalFilters, thisLocalFilters) => {
        const pointLimit = settings?.configGraph?.pointLimit || 100;
        const graphDataLength = await getGraphDataService(globalFilters, thisLocalFilters, { limit: pointLimit }, true);
        setChartDataCount(graphDataLength?.count);

        if (graphDataLength?.count > pointLimit) {
            toast(<ToastrContent type="warning" title={t('toastr_title_warning')} message={t('toastr_graphen_warning')} />, {
                progressClassName: "toastr-progress-bar warning"
            });
        }
    }

    const getAllDataLength = async (globalFilters) => {
        setLoadingAllDataCount(true);

        const globalFiltersDataLength = await getGraphDataService(globalFilters, null, null, true);
        setAllDataCount(globalFiltersDataLength?.count);
        updateDashboardChartField("allDataCount", globalFiltersDataLength?.count)

        setLoadingAllDataCount(false);
    }

    const getChartDuplicates = (graphData) => {
        const graphDataOverlapping = graphData.filter((point, i) => {
            return graphData.some((otherPoint, j) => {
                const sameCriticality = point.criticality === otherPoint.criticality;
                const sameComplexity = point.complexity === otherPoint.complexity;

                if (i !== j && sameCriticality && sameComplexity) {
                    return otherPoint
                }

                return null;
            })
        });

        const duplicateIds = graphDataOverlapping.map(point => point.id);

        const duplicatesGroupedArray = groupByMulti(graphDataOverlapping, (item) => {
            return [item.x, item.y];
        });

        let duplicatesGroupedObject = {};

        duplicatesGroupedArray.forEach(arrayGroup => {
            const firstItem = arrayGroup[0];
            duplicatesGroupedObject[`${firstItem.complexity}-${firstItem.criticality}`] = arrayGroup;
        });

        setChartDuplicateData(duplicatesGroupedObject);

        return { duplicatesGroupedObject, duplicateIds };
    }

    const getTopics = async () => {
        try {
            const topics = await getTopicsService();
            const topicsParsed = topics.map(topic => {
                return {
                    label: topic.name,
                    value: topic.topicId
                }
            })
            setTopics(topicsParsed);
        } catch (err) {
            console.log(`Error while fetching topics: ${err}`);
        }
    }

    const getChartDataWithFilters = (thisLocalFilters = null) => {
        let localFilterCopy = _.cloneDeep(thisLocalFilters || localFilters);

        const thereAreLocalFiltersActive = !_.isEmpty(localFilterCopy.status) || !_.isEmpty(localFilterCopy.topicId) || !_.isEmpty(localFilterCopy.validationType) || localFilterCopy.nameSearch || localFilterCopy?.complexity.min !== 0 || localFilterCopy?.complexity.max !== 100 || localFilterCopy?.criticality.min !== 0 || localFilterCopy?.criticality.max !== 100;
        setIsLocalFiltersActive(thereAreLocalFiltersActive);

        getScatterChartData(globalFilters, localFilterCopy);
    }

    return <GraphenComponent
        settings={settings}
        scatterChartData={scatterChartData}
        criticalityValue={criticalityValue}
        setCriticalityValue={setCriticalityValue}
        complexityValue={complexityValue}
        setComplexityValue={setComplexityValue}
        axisVisible={axisVisible}
        setAxisVisible={setAxisVisible}
        globalFilters={globalFilters}
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        getChartDataWithFilters={getChartDataWithFilters}
        isGraphLoading={isGraphLoading}
        setIsGraphLoading={setIsGraphLoading}
        isLocalFiltersActive={isLocalFiltersActive}
        topics={topics}
        toggleChartCallback={toggleChartCallback}
        configGraph={settings.configGraph}
        chartDuplicateData={chartDuplicateData}
        chartDataCount={chartDataCount}
        allDataCount={allDataCount}
        loadingAllDataCount={loadingAllDataCount}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    dashboard: store.dashboardReducer.dashboard
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateDashboardChartField }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Graphen);