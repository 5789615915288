import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DuplicateModalComponent from '../components/DuplicateModalComponent';
import { getDuplicates, getFileValues } from 'services/analysis';
import { parseFileSnapshot } from 'services/helper';
import { useTranslation } from "react-i18next";
import _ from 'lodash';

const DuplicateModal = (props) => {
    const { setIsOpen, itemId, isOpen, estimRow, openPointModal } = props;
    const [isFetching, setIsFetching] = useState(false);
    const [dups, setDups] = useState([]);
    const [oldId, setOldId] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen && (!oldId || oldId != itemId)) {
            setDups([]);
        }
    }, [isOpen]);


    useEffect(() => {
        if (itemId) {
            setOldId(itemId);
            getDupsI(itemId);
        }
    }, [itemId]);

    const getDupsI = async (itemId) => {
        setIsFetching(true);

        try {
            const dups = await getDuplicates(itemId);
            const dupsSorted = _.orderBy(dups, (item) => item.score, ['desc']);
            const fileDupsPromises = dupsSorted.map(file => {
                return getFileValues(file.fileOtherId);
            });

            const fileDupsValues = await Promise.all(fileDupsPromises);
            const dupsWithValues = dupsSorted.map(dup => {
                const dupValues = fileDupsValues.filter(file => file.id === dup.fileOtherId)[0];

                return {
                    ...dup,
                    fileValues: parseFileSnapshot(dupValues),
                    fileValuesNoParsing: dupValues
                }
            });

            setDups(dupsWithValues);
        } catch (err) {
            console.log(`Error while fetching search users request: ${err}`)
        }

        setIsFetching(false);
    }

    return (
        <DuplicateModalComponent
            {...props}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isFetching={isFetching}
            estimRow={estimRow}
            dups={dups}
            openPointModal={openPointModal}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(DuplicateModal);