import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import moment from 'moment';
import { fileStatusList, formatFileSize } from 'services/helper';
import './ChartPointModalComponent.scss';

const ChartPointModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        settings,
        user,
        selectedPoint,
        setIsFileOverviewModalOpen,
        changeValidationStatus,
        license,
        departmentName
    } = props;
    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;
    const statusIcon = fileStatusList.filter(status => status.id === selectedPoint?.statusId)[0];
    const hasPermissionToEdit = user?.groupPermissions?.includes("EUCDASHBOARDWRITE");
    // const hasManagementPermission = user?.groupPermissions?.includes("EUCINVENTORY") || user?.groupPermissions?.includes("EUCINVENTORYMANAGEMENT");
    const licenseHasManagement = license?.resources?.includes("Management");
    const licenseHasInfoPortal = license?.resources?.includes("Info-Portal");

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal chart-point-modal"
            overlayClassName="kpmg-modal-overlay transparent"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="tooltip-header">
                {
                    statusIcon?.icon &&
                    <div className="circle">
                        <img src={statusIcon?.icon} alt="status-icon" />
                    </div>
                }
                <div className="filename">{selectedPoint?.fileName}</div>
                <div
                    className="close-tooltip"
                    onClick={() => setIsOpen(false)}
                    style={{ color: mainColor }}>
                    <FaTimes />
                </div>
            </div>

            <div className="tooltip-content">
                <div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_created_at')}:</div>
                        <div>{selectedPoint?.createdAt ? moment(selectedPoint.createdAt).format("DD.MM.YYYY") : '-'}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_captured_by')}:</div>
                        <div>{selectedPoint?.createdVia || "-"}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_complexity')}:</div>
                        <div>{selectedPoint?.complexity?.toFixed(0)}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_criticality')}:</div>
                        <div>{selectedPoint?.criticality?.toFixed(0)}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_filesize')}:</div>
                        <div>{selectedPoint?.fileSize ? `${formatFileSize(selectedPoint?.fileSize)} KB` : "-"}</div>
                    </div>
                    {
                        hasPermissionToEdit &&
                        <div className="field-content column">
                            <div className="field-title">{t('point_modal_marking')}:</div>
                            <select
                                onChange={e => changeValidationStatus(e.target.value)}
                                name="selectFileStatus"
                                id="selectFileStatus"
                                style={{ color: mainColor }}
                                value={selectedPoint?.statusId}
                            >
                                <option value="no-validation">{t('analysis_tab1_graphen_legend_dropdown_3')}</option>
                                {licenseHasInfoPortal && <option value="request-validation">{t('analysis_tab1_graphen_legend_dropdown_5')}</option>}
                                <option value="non-euc">{t('analysis_tab1_graphen_legend_dropdown_1')}</option>
                                <option value="euc">{t('analysis_tab1_graphen_legend_dropdown_2')}</option>
                                {/* <option value="in-inventory">{t('point_modal_euc_inventory')}</option> */}

                                {/* {
                                    licenseHasManagement &&
                                    <option value="request-validation-inventory">{t('analysis_tab1_graphen_legend_dropdown_8')}</option>
                                } */}
                            </select>
                        </div>
                    }
                </div>

                <div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_last_modified')}:</div>
                        <div>{selectedPoint?.lastModified ? moment(selectedPoint.lastModified).format("DD.MM.YYYY") : '-'}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_date')}:</div>
                        <div>{selectedPoint?.date ? moment(selectedPoint.date).format("DD.MM.YYYY") : '-'}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_department')}:</div>
                        <div>{departmentName || "-"}</div>
                    </div>
                    <div className="field-content">
                        <div className="field-title">{t('point_modal_employee')}:</div>
                        <div>{selectedPoint?.user || "-"}</div>
                    </div>
                    <div className="field-content column">
                        <div className="field-title">{t('point_modal_details')}:</div>
                        <div className="selector" onClick={() => {
                            setIsOpen(false);
                            setIsFileOverviewModalOpen(true)
                        }}>
                            <div style={{ color: mainColor }}>{t('point_modal_open_view')}</div>
                            <IoMdArrowDropright style={{ fontSize: 22, color: mainColor }} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default ChartPointModalComponent;