import React from "react";
import Button from '../Button/Button';
import "./AddItem.scss";

const AddItem = (props) => {
    const { text, style, onClick, disabled } = props;

    return (
        <div className="add-item-component" style={{ ...style }}>
            <Button type="button" disabled={disabled} onClick={onClick}>+</Button>
            {
                text &&
                <span>{text}</span>
            }
        </div>
    );
};

export default AddItem;