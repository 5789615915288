import React, { useState } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import MultiSelect from "react-multi-select-component";
import { IoMdArrowDropright } from 'react-icons/io';
import GuidancePDF from 'assets/pdfs/UserGuide.pdf';

// Components
import Graphen from './Graphen/containers/Graphen';
import Listenansicht from './Listenansicht/containers/Listenansicht';
import Generation from './Generation/containers/Generation';

import './DashboardComponent.scss';

const DashboardComponent = (props) => {
    const {
        currentPath,
        history,
        organizationData,
        performedScans,
        selectedTenants,
        setSelectedTenants,
        selectedGroups,
        setSelectedGroups,
        selectedDepartments,
        setSelectedDepartments,
        selectedScanIndex,
        setSelectedScanIndex,
        applyGlobalFilters,
        globalFilters,
        isFetchingOrganizations,
        getAvailableTenants,
        getAvailableGroups,
        getAvailableDepartments,
        isFetchingPerformedScans,
        sidebarItems,
        getAvailableScans
    } = props;

    const { t } = useTranslation();
    const [chartFullScreen, setChartFullScreen] = useState(false);

    const redirectToRoute = item => {
        const itemPath = item.toLowerCase();
        history.push(`/analyzing/dashboard/${itemPath}`);
    }

    const changeScanIndex = (scanIndex) => {
        setSelectedScanIndex(scanIndex);
    }

    const getSelectValue = number => isNaN(number) ? "none" : parseInt(number);

    const availableTenants = getAvailableTenants(organizationData);
    const availableGroups = getAvailableGroups(selectedTenants, organizationData);
    const availableDepartments = getAvailableDepartments(selectedGroups, organizationData);
    const availableScans = getAvailableScans(selectedDepartments);

    return (
        <Layout className="dashboard-page">
            <div className={chartFullScreen ? "dashboard-wrapper full-screen" : "dashboard-wrapper"}>
                <div className="dashboard-content">
                    <div className="chart-topbar">
                        <div className="title">{t('analysis_tab1_topbar_title')}:</div>
                        <div className="content">
                            <div className="content-selects">
                                <div className="input-row">
                                    <div className="input-wrapper">
                                        <div className="input-title" style={{ minWidth: 90 }}>{t('analysis_tab1_topbar_tenant')}:</div>
                                        <div className="input-field">
                                            <MultiSelect
                                                className={isFetchingOrganizations ? "custom-select loading" : "custom-select"}
                                                options={availableTenants}
                                                value={selectedTenants}
                                                onChange={setSelectedTenants}
                                                overrideStrings={{
                                                    selectSomeItems: t('analysis_tab1_topbar_tenant_placeholder'),
                                                    allItemsAreSelected: t('multiselect_allItemsAreSelected'),
                                                    selectAll: t('multiselect_selectAll'),
                                                    search: t('multiselect_search'),
                                                    clearSearch: t('multiselect_clearSearch')
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <div className="input-title">{t('analysis_tab1_topbar_group')}:</div>
                                        <div className="input-field">
                                            <MultiSelect
                                                className={isFetchingOrganizations ? "custom-select loading" : "custom-select"}
                                                options={availableGroups}
                                                value={selectedGroups.filter(a => availableGroups.some(b => a.value === b.value))}
                                                onChange={setSelectedGroups}
                                                overrideStrings={{
                                                    selectSomeItems: t('analysis_tab1_topbar_group_placeholder'),
                                                    allItemsAreSelected: t('multiselect_allItemsAreSelected'),
                                                    selectAll: t('multiselect_selectAll'),
                                                    search: t('multiselect_search'),
                                                    clearSearch: t('multiselect_clearSearch')
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <div className="input-title">{t('analysis_tab1_topbar_department')}:</div>
                                        <div className="input-field">
                                            <MultiSelect
                                                className={isFetchingOrganizations ? "custom-select loading" : "custom-select"}
                                                options={availableDepartments}
                                                value={selectedDepartments.filter(a => availableDepartments.some(b => a.value === b.value))}
                                                onChange={setSelectedDepartments}
                                                overrideStrings={{
                                                    selectSomeItems: t('analysis_tab1_topbar_department_placeholder'),
                                                    allItemsAreSelected: t('multiselect_allItemsAreSelected'),
                                                    selectAll: t('multiselect_selectAll'),
                                                    search: t('multiselect_search'),
                                                    clearSearch: t('multiselect_clearSearch')
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="input-wrapper">
                                        <div className="input-title" style={{ minWidth: 90 }}>{t('analysis_tab1_topbar_scan')}:</div>
                                        <div className="input-field" style={{ maxWidth: '100%' }}>
                                            <select
                                                name="scanSelect"
                                                id="scanSelect"
                                                onChange={e => changeScanIndex(getSelectValue(e.target.value))}
                                                value={selectedScanIndex}
                                                disabled={isFetchingPerformedScans}
                                            >
                                                <option value="none">{t('analysis_tab1_topbar_scan_placeholder')}</option>
                                                {
                                                    availableScans.map((scan, i) => (
                                                        <option key={i} value={scan.runId}>{scan.startDate} - RunId {scan.runId} </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="apply-filter">
                                <Button className="light small" onClick={() => applyGlobalFilters()}>{t('analysis_tab1_graphen_filters_apply')}</Button>
                            </div>
                        </div>
                    </div>

                    <div className="chart-sidebar">
                        {
                            sidebarItems.map((sidebarItem, i) => (
                                <div className="item-wrapper" key={i}>
                                    <div className="item-title">{sidebarItem.itemTitle}</div>
                                    <div className="item-list">
                                        {
                                            sidebarItem.items.map((item, i) => {
                                                const route = item.key;

                                                if (route.toLowerCase() === 'support') return (
                                                    <a href="mailto:de-eucp-support@kpmg.com"
                                                        className={route.toLowerCase() === currentPath ? "link item active" : "link item"}
                                                        key={i}>
                                                        <div className="icon">
                                                            <IoMdArrowDropright style={{ fontSize: 18 }} />
                                                        </div>
                                                        <span>{item.label}</span>
                                                    </a>
                                                )

                                                if (route.toLowerCase() === 'gudiance') return (
                                                    <a target="_blank" rel="noopener noreferrer" href={GuidancePDF}
                                                        className={route.toLowerCase() === currentPath ? "link item active" : "link item"}
                                                        key={i}>
                                                        <div className="icon">
                                                            <IoMdArrowDropright style={{ fontSize: 18 }} />
                                                        </div>
                                                        <span>{item.label}</span>
                                                    </a>
                                                )

                                                return (
                                                    item.permission ?
                                                        <div
                                                            onClick={() => redirectToRoute(route)}
                                                            className={route.toLowerCase() === currentPath ? "item active" : "item"}
                                                            key={i}>
                                                            <div className="icon">
                                                                <IoMdArrowDropright style={{ fontSize: 18 }} />
                                                            </div>
                                                            <span>{item.label}</span>
                                                        </div> :
                                                        null
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="chart">
                        {
                            currentPath === 'graphen' &&
                            <Graphen
                                globalFilters={globalFilters}
                                toggleChartCallback={() => setChartFullScreen(!chartFullScreen)}
                            />
                        }
                        {
                            currentPath === 'listenansicht' &&
                            <Listenansicht
                                globalFilters={globalFilters}
                                toggleChartCallback={() => setChartFullScreen(!chartFullScreen)}
                            />
                        }
                        {
                            currentPath === 'generation' &&
                            <Generation
                                globalFilters={globalFilters}
                            />
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default DashboardComponent;