import moment from 'moment';

export default {
    chart: {
        chartData: null,
        chartDataCount: 0,
        duplicates: null,
        allDataCount: 0,
        filters: {
            status: [],
            complexity: {
                min: 0,
                max: 100
            },
            criticality: {
                min: 0,
                max: 100
            },
            duplicates: {
                showOnlyDuplicates: false
            },
            topicId: [],
            validationType: [],
            nameSearch: ''
        }
    },
    list: {
        data: [],
        dataCount: 0,
        allDataCount: 0,
        pagination: {
            limit: 100,
            selectValue: 100,
            currentIndex: 1
        },
        filters: {
            status: [],
            complexity: {
                min: 0,
                max: 100
            },
            criticality: {
                min: 0,
                max: 100
            },
            duplicates: {
                showOnlyDuplicates: false
            },
            order: null,
            reverse: null
        }
    },
    sample: {
        data: [],
        totalCount: null,
        filters: {
            complexity: {
                min: 0,
                max: 100
            },
            criticality: {
                min: 0,
                max: 100
            },
            fileSize: {
                min: 0,
                max: 10
            },
            date: {
                start: moment().subtract(4, 'years').format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            },
            scatter: 0,
            extension: [
                {
                    label: 'MS Excel',
                    value: '*.xlsx *.xls *.xlsb *.xlsm'
                }
            ],
            limit: 50,
            orderColumn: null,
            orderDirection: null
        }
    }
}