import { combineReducers } from 'redux';
import { settingsReducer } from "./settingsReducer";
import { userReducer } from "./userReducer";
import { dashboardReducer } from "./dashboardReducer";
import { licenseReducer } from "./licenseReducer";

export const reducers = combineReducers({
    settingsReducer,
    userReducer,
    dashboardReducer,
    licenseReducer
});
