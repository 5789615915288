import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuestionnaireComponent from '../components/QuestionnaireComponent';
import { connect } from "react-redux";
import { getExcelQuestionsService } from 'services/excel';
import { getDirectoriesService } from 'services/scanning';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const Questionnaire = (props) => {
    const { oldFormData, submitForm, settings, isSubmitting, isLoadingOldFormData } = props;

    const [questions, setQuestions] = useState([]);
    const [isAllowedAlias, setIsAllowedAlias] = useState(null);
    const [isAllowedFileExplorer, setIsAllowedFileExplorer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        getExcelQuestions();
    }, []);

    useEffect(() => {
        setIsAllowedAlias(settings?.validationConfig?.allowAlias === 1);
        setIsAllowedFileExplorer(settings?.validationConfig?.useFolderImpersonation === 1);
    }, [settings]);

    const getExcelQuestions = async () => {
        setIsLoading(true);

        try {
            const response = await getExcelQuestionsService();
            setQuestions(response);
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setIsLoading(false);
    }

    const sendQuestionsData = async (formValues) => {
        const fileData = JSON.parse(formValues.fileSelect);
        const pathReplacingSlashes = formValues.directory.path.split("/").join("\\");
        const fileNameFromPath = _.last(pathReplacingSlashes.split("\\"));
        const allAnswers = _.omit(formValues, ['fileSelect', 'directory', 'alias'])

        const answers = Object.keys(allAnswers).map(formValueKey => {
            return {
                questionId: parseInt(formValueKey),
                value: formValues[formValueKey],
            }
        })

        const answersFilled = answers.filter(answer => answer.value !== "§§§");
        const directoryWithoutFilename = pathReplacingSlashes.replace(`\\${fileNameFromPath}`, "");

        const formData = {
            directory: directoryWithoutFilename,
            alias: formValues.alias,
            fileName: fileNameFromPath,
            type: fileData.type,
            answers: answersFilled
        }

        try {
            submitForm(formData);
        } catch (err) {
            throw err;
        }
    }

    return <QuestionnaireComponent
        isLoadingOldFormData={isLoadingOldFormData}
        oldFormData={oldFormData}
        questions={questions}
        sendQuestionsData={sendQuestionsData}
        isLoading={isLoading}
        isAllowedAlias={isAllowedAlias}
        isAllowedFileExplorer={isAllowedFileExplorer}
        isSubmitting={isSubmitting}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default connect(mapStateToProps)(Questionnaire);