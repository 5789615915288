import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import Modal from 'react-modal';
import AddItem from 'components/common/AddItem/AddItem';
import AddKeywordModal from 'components/modals/AddKeywordModal/AddKeywordModal';
import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from "react-loading-skeleton";
import { FaTimes } from 'react-icons/fa';
import _ from 'lodash';
import './EucKeywordsModalComponent.scss';

const EucKeywordsModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        keywords,
        settings,
        newKeywords,
        addKeyword,
        addKeywordModalOpen,
        setAddKeywordModalOpen,
        addKeywordLoading,
        deleteKeyword,
        deletingKeywordIds,
        fetchingKeywords,
        readOnly,
        isMetadata,
        metadataFields
    } = props;
    const [listSearch, setListSearch] = useState('');

    const { t } = useTranslation();

    const allKeywords = [...keywords, ...newKeywords];

    const filteredKeywords = listSearch ? allKeywords.filter((key) => {
        const nameLowercased = key?.name?.toLowerCase() || "";
        const fieldLowercased = key?.field?.toLowerCase() || "";
        const term = listSearch.toLowerCase();

        return nameLowercased.includes(term) || fieldLowercased.includes(term);
    }) : allKeywords;

    let arrayChunkSize = 19;
    if (filteredKeywords.length > (19 * 3)) arrayChunkSize = Math.ceil(filteredKeywords.length / 3);
    const keywordsChunks = _.chunk(filteredKeywords, arrayChunkSize);

    const mainColor = settings?.theme?.mainColor;

    return (
        <>
            <Modal
                isOpen={isOpen}
                className="kpmg-modal"
                overlayClassName="kpmg-modal-overlay euc-keywords-modal"
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}>
                <div className="modal-header">
                    <h3>{isMetadata ? t('settings_tab4_popup_keywords_layout_chain_text1_metadata') : t('settings_tab4_popup_keywords_layout_chain_text1')}</h3>
                    <p style={{ color: mainColor }}>{isMetadata ? t('settings_tab4_popup_keywords_layout_chain_text2_metadata') : t('settings_tab4_popup_keywords_layout_chain_text2')}</p>

                    <div className="close-icon" onClick={() => setIsOpen(false)}>
                        <FaTimes />
                    </div>
                </div>

                <div className="modal-body">
                    <div className="search-tab">
                        <input type="text" placeholder={isMetadata ? t('settings_tab4_popup_keywords_layout_chain_text3_metadata') : t('settings_tab4_popup_keywords_layout_chain_text3')} onChange={(e) => setListSearch(e.target.value)} />
                    </div>

                    <div className="lists-section">
                        <div className="secondary-list">
                            {
                                fetchingKeywords ?
                                    <div>
                                        <Skeleton width={150} height={10} count={5} style={{ marginBottom: 10 }} />
                                    </div> :
                                    filteredKeywords.length ?
                                        keywordsChunks.map((chunk, chunkNumber) => (
                                            <div className="list" key={chunkNumber}>
                                                {
                                                    chunkNumber === 0 &&
                                                    <div className="header">
                                                        <span>{isMetadata ? t('settings_tab4_popup_keywords_layout_column_metadata') : t('settings_tab4_popup_keywords_layout_column')}</span>
                                                    </div>
                                                }
                                                {
                                                    chunk.map((keyword, i) => (
                                                        <div className="list-item" key={i}>
                                                            {
                                                                keyword.field ?
                                                                    <div
                                                                        className="name"
                                                                        title={`${keyword.field}: ${keyword.rule === 'IsLike' ? keyword.name : "not null"}`}>
                                                                        {keyword.field}
                                                                        {`: `}
                                                                        {keyword.rule === 'IsLike' ? keyword.name : "not null"}
                                                                    </div> :
                                                                    <div className="name" title={keyword.name}>
                                                                        {keyword.name}
                                                                    </div>
                                                            }

                                                            {
                                                                deletingKeywordIds.includes(keyword.topicKeywordId) ?
                                                                    <div className="loading">
                                                                        <ClipLoader
                                                                            size={15}
                                                                            color={"#525662"}
                                                                            loading={true}
                                                                        />
                                                                    </div> :
                                                                    !readOnly ?
                                                                        <div className="delete" onClick={() => deleteKeyword(isMetadata ? keyword.metadataKeywordId : keyword.topicKeywordId)}>
                                                                            <FaTimes />
                                                                        </div> :
                                                                        null

                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )) :
                                        <div className="empty-keywords">{isMetadata ? t('settings_tab4_popup_keywords_layout_chain_text4_metadata') : t('settings_tab4_popup_keywords_layout_chain_text4')}</div>
                            }

                        </div>
                    </div>

                    {
                        !readOnly ?
                            <div className="bottom-options">
                                <AddItem
                                    text={isMetadata ? t('settings_tab4_popup_keywords_button1_metadata') : t('settings_tab4_popup_keywords_button1')}
                                    onClick={() => setAddKeywordModalOpen(true)} />

                                <Button onClick={() => setIsOpen(false)} style={{ width: 165 }}>{isMetadata ? t('settings_tab4_popup_keywords_button2_metadata') : t('settings_tab4_popup_keywords_button2')}</Button>
                            </div> :
                            null
                    }
                </div>
            </Modal>

            <AddKeywordModal
                isOpen={addKeywordModalOpen}
                setIsOpen={setAddKeywordModalOpen}
                loading={addKeywordLoading}
                isMetadata={isMetadata}
                addNewKeywordCallback={addKeyword}
                metadataFields={metadataFields}
            />
        </>
    )
};

export default EucKeywordsModalComponent;
