import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { adjustColorBrightness } from 'services/helper';

import StepOne from 'components/Scanning/StartScan/components/StepOne/containers/StepOne';
import StepTwo from 'components/Scanning/StartScan/components/StepTwo/containers/StepTwo';
import StepThree from 'components/Scanning/StartScan/components/StepThree/containers/StepThree';
import StepFour from 'components/Scanning/StartScan/components/StepFour/containers/StepFour';
import StepFive from 'components/Scanning/StartScan/components/StepFive/containers/StepFive';
import StepSix from 'components/Scanning/StartScan/components/StepSix/StepSix';

import './WizardComponent.scss';

const WizardComponent = (props) => {
    const {
        settings: { theme: { mainColor, secondaryColor } },
        currentStep,
        setCurrentStep,
        wizardData,
        submitStepData,
        wizardResponse,
        sendingWizardData,
        sendingRunScan,
        runScan,
        selectConfiguration,
        readOnly,
        wizardConfigDataChanged
    } = props;

    const { t } = useTranslation();

    const steps = [
        {
            name: t('scan_tab1_wizard_step1_subtitle'),
            icon: require("assets/icons/wizard/step1.png").default,
            iconWidth: 25,
            iconHeight: 25,
            hidden: readOnly
        },
        {
            name: t('scan_tab1_wizard_step2_subtitle'),
            icon: require("assets/icons/wizard/step2.png").default,
            iconWidth: 25,
            iconHeight: 25
        },
        {
            name: t('scan_tab1_wizard_step3_subtitle'),
            icon: require("assets/icons/wizard/step3.png").default,
            iconWidth: 30,
            iconHeight: 30
        },
        {
            name: t('scan_tab1_wizard_step4_subtitle'),
            icon: require("assets/icons/wizard/step4.png").default,
            iconWidth: 40,
        },
        {
            name: t('scan_tab1_wizard_step5_subtitle'),
            icon: require("assets/icons/wizard/step5.png").default,
            iconWidth: 25,
        },
        {
            name: t('scan_tab1_wizard_step6_subtitle'),
            icon: require("assets/icons/wizard/step6.png").default,
            iconWidth: 25,
            iconHeight: 25,
            hidden: readOnly
        },
    ]

    return (
        <div className="wizard-wrapper">
            <div className="wizard-steps">
                <svg xmlns="http://www.w3.org/2000/svg" width="150" className="arrow" version="1.1">
                    <defs>
                        <linearGradient id="grad" x2="0%" y2="100%">
                            <stop offset="0" stopColor={adjustColorBrightness(secondaryColor, 0.2)} />
                            <stop offset="0.5" stopColor={adjustColorBrightness(secondaryColor, 0)} />
                            <stop offset="1" stopColor={adjustColorBrightness(secondaryColor, -0.2)} />
                        </linearGradient>
                    </defs>
                    <polygon points="0,0 120,0, 150,45, 120,89 0,89" fill="url(#grad)" />
                </svg>

                <div className="wizard-text">
                    <div className="title">{t('scan_tab1_wizard_step1_layout_chain_text_1')}</div>
                    <div className="subtitle">{readOnly ? t('scan_tab1_wizard_step1_layout_chain_text_2_readonly') : t('scan_tab1_wizard_step1_layout_chain_text_2')}</div>
                </div>

                {
                    steps.map((step, i) => (
                        !step.hidden &&
                        <div
                            className={readOnly || i + 1 <= currentStep ? "wizard-step hoverable" : "wizard-step"}
                            key={i}
                            onClick={() => readOnly ? setCurrentStep(i + 1) : i + 1 <= currentStep && setCurrentStep(i + 1)}>
                            <img src={step.icon} alt={`step-icon-${i + 1}`} width={step.iconWidth} height={step.iconHeight} />
                            <div className="step-name">{t(`scan_tab1_wizard_step${readOnly ? i : i + 1}_title`)}</div>
                            <div className="step-description" style={{ borderColor: i + 1 === currentStep ? mainColor : "transparent" }}>{step.name}</div>
                        </div>
                    ))
                }
            </div>

            <div className="wizard-content">
                {
                    currentStep === 1 &&
                    <StepOne selectConfiguration={selectConfiguration} />
                }
                {
                    currentStep === 2 &&
                    <StepTwo
                        changeStep={setCurrentStep}
                        data={wizardData.ouConfig}
                        submitStepData={submitStepData}
                        readOnly={readOnly}
                    />
                }
                {
                    currentStep === 3 &&
                    <StepThree
                        changeStep={setCurrentStep}
                        data={{ filterSettings: wizardData.filterSettingConfig, scanSettings: wizardData.scanModeConfig }}
                        submitStepData={submitStepData}
                        readOnly={readOnly}
                    />
                }
                {
                    currentStep === 4 &&
                    <StepFour
                        changeStep={setCurrentStep}
                        data={[...wizardData.topicConfig.filter((i) => i.kpiType === "Complexity"), ...wizardData.indicatorConfig.filter((i) => i.kpiType === "Complexity"), ...wizardData.metadataConfig.filter((i) => i.kpiType === "Complexity")]}
                        submitStepData={submitStepData}
                        readOnly={readOnly}
                    />
                }
                {
                    currentStep === 5 &&
                    <StepFive
                        changeStep={setCurrentStep}
                        data={[...wizardData.topicConfig.filter((i) => i.kpiType === "Criticality"), ...wizardData.indicatorConfig.filter((i) => i.kpiType === "Criticality"), ...wizardData.metadataConfig.filter((i) => i.kpiType === "Criticality")]}
                        submitStepData={submitStepData}
                        readOnly={readOnly}
                    />
                }
                {
                    currentStep === 6 &&
                    <StepSix
                        submitStepData={submitStepData}
                        runScan={runScan}
                        wizardResponse={wizardResponse}
                        sendingWizardData={sendingWizardData}
                        sendingRunScan={sendingRunScan}
                        wizardConfigDataChanged={wizardConfigDataChanged}
                    />
                }
            </div>
        </div>
    )
};

export default WizardComponent;