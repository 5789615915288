import { UPDATE_LICENSE } from "../types/licenseTypes";

const initialState = {
    license: {}
};

export const licenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LICENSE:
            return {
                ...state,
                license: action.license
            };

        default:
            return state;
    }
};