import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import './StepSix.scss';

const StepSix = props => {
    const { wizardResponse, submitStepData, sendingWizardData, sendingRunScan, runScan, wizardConfigDataChanged, match: { params: { id } } } = props;
    const [configName, setConfigName] = useState('');
    const { t } = useTranslation();

    let mainSectionVisibile = true;
    if (!wizardConfigDataChanged && id) mainSectionVisibile = false;

    const submitScan = () => {

        if (wizardConfigDataChanged) {
            if (wizardResponse) {
                runScan();
            } else {
                submitStepData({ name: null }, 6);
            }
        } else {
            if (wizardResponse || id) {
                runScan();
            } else {
                submitStepData({ name: null }, 6);
            }
        }

    }

    return (
        <div className="step-six-wrapper">
            <div className="section-title">{t('scan_tab1_wizard_step6_layout_chain_text_1')}</div>

            <div className="sections-wrapper">
                {
                    mainSectionVisibile ?
                        <div className="section-content section-config-name">
                            <p>{t('scan_tab1_wizard_step6_layout_chain_text_2')}</p>
                            <label htmlFor="name">{t('scan_tab1_wizard_step6_layout_chain_text_3')}</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                onChange={e => setConfigName(e.target.value)} />

                            <div className="button-wrapper">
                                <Button
                                    disabled={wizardResponse || !configName}
                                    loading={sendingWizardData}
                                    style={{ width: 230 }}
                                    onClick={() => submitStepData({ name: configName }, 6)}>
                                    {
                                        wizardResponse ?
                                            t('scan_tab1_wizard_step6_button_1_done') :
                                            t('scan_tab1_wizard_step6_button_1')
                                    }
                                </Button>
                            </div>
                        </div> :
                        null
                }

                <div className="section-content">
                    <p>{t('scan_tab1_wizard_step6_layout_chain_text_4')}</p>

                    <div className="button-wrapper">
                        <Button
                            disabled={sendingWizardData}
                            loading={sendingRunScan}
                            style={{ paddingRight: 20, paddingLeft: 20, width: 310 }}
                            onClick={() => submitScan()}>
                            {t('scan_tab1_wizard_step6_button_2')}
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default withRouter(StepSix);