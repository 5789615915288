import React, { useState, useEffect } from "react";
import NavTabs from 'components/common/NavTabs/NavTabs';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment'
import Button from 'components/common/Button/Button';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import './ValidationDownloadModalComponent.scss';
import FileSelect from "components/common/FileSelect/FileSelect";

const ValidationDownloadModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        selectedItem,
        settings,
        history
    } = props;

    const { t } = useTranslation();
    const mainColor = settings?.theme?.mainColor;

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal validation-download-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <div className="validation-download-info">
                    <h3>{selectedItem?.fileName}</h3>
                    <p style={{ color: mainColor }}>{selectedItem?.path}</p>

                    <hr />

                    <div className="file-overview-details">
                        <div className="file-overview-details__column">
                            <div className="file-detail">{t('fileoverview_modal_file_status')}: {selectedItem?.status ? t(selectedItem.status) : '-'}</div>
                            <div className="file-detail">{t('fileoverview_modal_file_department')}: {selectedItem?.department || '-'}</div>
                            {/* {
                                isAllowedAlias &&
                                <div className="file-detail">Alias: {selectedItem?.alias || '-'}</div>
                            } */}
                        </div>

                        <div className="file-overview-details__column">
                            <div className="file-detail">{t('fileoverview_modal_file_captured_by')}: {selectedItem?.createdVia || '-'}</div>
                            <div className="file-detail">{t('fileoverview_modal_file_employee')}: {selectedItem?.user || '-'}</div>
                        </div>

                        <div className="file-overview-details__column">
                            <div className="file-detail">{t('fileoverview_modal_file_created_at')}: {selectedItem?.createdAt ? moment(selectedItem.createdAt).format("DD.MM.YYYY") : '-'}</div>
                            <div className="file-detail">{t('fileoverview_modal_file_last_modified')}: {selectedItem?.lastModified ? moment(selectedItem.lastModified).format("DD.MM.YYYY") : '-'}</div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="file-icon">
                    <div className="status-disabled"></div>
                </div> */}

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">

                <div className="details-tab">

                    <div className="flex">
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title1')}</div>
                            <div className="question-answer"> 'BS Review CalucBation' </div>
                        </div>
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title2')}</div>
                            <div className="question-answer"> v1 </div>
                        </div>
                    </div>

                    <div className="question">
                        <div className="question-title">{t('validationdownloadmodal_input_title3')}</div>
                        <div className="question-answer big"> Zweck der Anwendung ist es, eine Profitabilitätsanaylse auf Basis der erbrachten Stunden je Business Service gegenüber den simulierten Preisentwicklungen zu erstelen.</div>
                    </div>

                    <div className="flex">
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title4')}</div>
                            <div className="question-answer"> MS Excel </div>
                        </div>
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title5')}</div>
                            <div className="question-answer"> Ja </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title6')}</div>
                            <div className="question-answer"> B. Kielstein </div>
                        </div>
                        <div className="question">
                            <div className="question-title">{t('validationdownloadmodal_input_title7')}</div>
                            <div className="question-answer">G. Herst </div>
                        </div>
                    </div>

                    <div className="files-attached">
                        <div className="file">
                            <img src={require('assets/icons/pdf.png').default} />
                                Technical Architecture 20200110.pdf
                            </div>
                        <div className="file">
                            <img src={require('assets/icons/pdf.png').default} />
                                EUC Description v2 20200109.pdf
                            </div>
                    </div>

                    <div className="question">
                        <FileSelect
                            id="file-input"
                            inputText={t('screenEucCheck_question_file_chain_text3')}
                            buttonText={t('screenEucCheck_button_chain_text_2')}
                            onChange={fileObject => { }}
                        />
                    </div>

                    <hr />

                    <div className="question">
                        <div className="question-title">{t('validationdownloadmodal_input_title8')}</div>
                        <div className="question-answer big"> RKB2 - Die Anwendung wird wiederholt in einem geschäftsprozess verwendet und berechnet u.a. dispositive Daten.</div>
                    </div>

                    <div className="field question">
                        <div className="question-title">{t('validationdownloadmodal_input_title10')}</div>
                        <select
                            name="select-configuration"
                            id="select-configuration"
                            onChange={e => { }}
                        >
                            <option value="empty">{t('filetree_modal_button')}</option>
                            {[...Array(9)].map((item, index) => (
                                <option value={index} key={index}>{index}</option>
                            ))}
                        </select>
                    </div>


                    <div className="question">
                        <div className="question-title">{t('validationdownloadmodal_input_title9')}</div>
                        <div className="question-answer big">
                            1. Datei ist auf einem passwort-geschützen Verzeichnis zu verwaren <br />
                            2. Regelmäßige, monatliche Back-Ups sind zu erstellen
                        </div>
                    </div>

                    {/* <div className="question">
                        <div className="question-title">{t('validationdownloadmodal_input_title11')}</div>
                        <textarea className='question-answer big' rows="3" />
                    </div> */}

                    <div className="actions">
                        <Button className="light small confirm-button" onClick={() => { }}>
                            {t('validationdownloadmodal_input_button1')}
                        </Button>
                        <Button className="light small confirm-button" onClick={() => history.push('/management/inventory')}>
                            {t('validationdownloadmodal_input_button2')}
                        </Button>
                    </div>

                </div>
            </div>
        </Modal>
    )
};

export default ValidationDownloadModalComponent;