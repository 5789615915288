import React, { useState, useEffect } from "react";
import KonfigurationenComponent from '../components/KonfigurationenComponent';
import { myConfigurationsService } from 'services/scanning';
import _ from 'lodash';

const Konfigurationen = (props) => {
    const [configurationList, setConfigurationList] = useState([]);
    const [isFetching, setIsFetching] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentConfigId, setCurrentConfigId] = useState(null);

    useEffect(() => {
        getMyConfigurations();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    const getMyConfigurations = async () => {
        setIsFetching(true);

        try {
            const response = await myConfigurationsService();
            const responseSorted = _.orderBy(response, (item) => +new Date(item.createdAt), ['desc']);
            setConfigurationList(responseSorted);
        } catch (err) {
            console.log("Error while fetching my configurations", err);
        }

        setIsFetching(false);
    }

    const openWizardModal = configId => {
        setCurrentConfigId(configId);
        setIsModalOpen(true);
    }

    return <KonfigurationenComponent
        isFetching={isFetching}
        configurationList={configurationList}
        openWizardModal={openWizardModal}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentConfigId={currentConfigId}
    />;
};

export default Konfigurationen;