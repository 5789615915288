import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import Button from 'components/common/Button/Button';
import { BiEditAlt } from 'react-icons/bi';
import Modal from 'react-modal';
import { ImSearch } from 'react-icons/im';
import UserListModal from 'components/modals/UserListModal/containers/UserListModal';
import './EmailSettings.scss';

const Receivers = (props) => {
    const { t } = useTranslation();
    const {
        submitReceivers,
        loading,
        receiversConfig,
        receiversShowGroup
    } = props;

    const [showModal, setShowModal] = useState(null);
    const [unsavedSettings, setUnsavedSettings] = useState([]);
    const [usersModalOpen, setUsersModalOpen] = useState(false);
    const [inputSearch, setInputSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setUnsavedSettings(receiversConfig);
    }, [receiversConfig])

    const discardChanges = () => {
        setUnsavedSettings(receiversConfig);
    }

    const editReceiver = (newData) => {
        const settingsCopy = _.cloneDeep(unsavedSettings);
        const index = _.findIndex(settingsCopy, (item) => _.isEqual(item, showModal));
        settingsCopy[index] = newData;
        setUnsavedSettings(settingsCopy);
        setShowModal(null);
    }

    const selectUser = user => {
        setInputSearch(user.samAccountName);
        editReceiver({
            ...showModal,
            samAccountName: user.samAccountName,
            displayName: user.displayName
        })
        setShowModal(null);
        setUsersModalOpen(false);
        setInputSearch("");
    }

    return (
        <div className="email-settings-content">

            <div className="form-wrapper">

                <table className="kpmg-table secondary">
                    <thead>
                        <tr>
                            <th>{receiversShowGroup ? t('settings_tab6_tab2_table_title3') : t('settings_tab6_tab2_table_title1')}</th>
                            <th>{t('settings_tab6_tab2_table_title2')}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            unsavedSettings.map((item, i) => (
                                <tr key={i}>
                                    <td>{receiversShowGroup ? item.groupName : item.departmentName}</td>
                                    <td>{item.displayName}</td>

                                    <td>
                                        <div className="action-buttons">
                                            <div className="table-btn arrow" onClick={() => setShowModal(item)}>
                                                <BiEditAlt />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="actions ">
                    <div>{t('settings_tab1_layout_chain_text_7')}:</div>
                    <Button onClick={() => discardChanges()}>{t('settings_tab1_button3_title')}</Button>
                    <Button
                        loading={loading}
                        onClick={() => submitReceivers(unsavedSettings)}>
                        {t('settings_tab1_button4_title')}
                    </Button>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                className="kpmg-modal receivers-modal"
                overlayClassName="kpmg-modal-overlay"
                onRequestClose={() => setShowModal(null)}
                ariaHideApp={false}>
                <div className="receivers-modal-content">
                    <div className="title">{t('settings_tab6_tab2_modal_title')}</div>

                    <div className="receivers-input">
                        {/* <div className="input-title">
                            <span>{t('settings_tab6_tab2_modal_input_title')}</span>
                        </div> */}
                        <div className="input-row">
                            <input
                                className="input-text"
                                type="text"
                                placeholder={t('file_validation_modal_option_4_placeholder')}
                                value={inputSearch}
                                onChange={e => setInputSearch(e.target.value)}
                            />
                            <Button className="small light" onClick={() => { setUsersModalOpen(true); setSearchTerm(`search=${inputSearch}`); }} >
                                <div className="flex center">
                                    <ImSearch />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div className="actions">
                        <Button type="button" onClick={() => setShowModal(null)}>{t('confirm_modal_cancel')}</Button>
                        {/* <Button type="button" onClick={() => editReceiver({ ...showModal, samAccountName: inputSearch })}>{t('confirm_modal_ok')}</Button> */}
                    </div>
                </div>
            </Modal>
            <UserListModal
                isOpen={usersModalOpen}
                setIsOpen={setUsersModalOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectUserCallback={selectUser}
            />
        </div >

    )
}
export default Receivers;