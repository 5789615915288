import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import FileTreeScanModal from 'components/modals/FileTreeScanModal/containers/FileTreeScanModal';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiFolderFill } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';
import AddItem from 'components/common/AddItem/AddItem';
import Skeleton from "react-loading-skeleton";
import './StepTwoComponent.scss';

const StepTwoComponent = props => {
    const {
        isFetchingOrganizations,
        submitData,
        organizationData,
        filesTreeData,
        setTreeTenantId,
        setTreeGroupId,
        setTreeDepartmentId,
        setTreeNewFile,
        deleteTreeFile,
        addTreeItem,
        readOnly
    } = props;

    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentOrganizationIndex, setCurrentOrganizationIndex] = useState(null);

    const hasTreeDataNotEmpty = filesTreeData.filter(treeData => treeData?.tenantId && treeData?.groupId && treeData?.departmentId);

    return (
        <div className="step-two-wrapper">
            {
                isFetchingOrganizations ?
                    <div className="organization-wrapper-loading">
                        <Skeleton width={150} height={15} />
                        <div className="items-wrapper">
                            <Skeleton width={160} height={95} />
                            <Skeleton width={160} height={95} />
                            <Skeleton width={160} height={95} />
                        </div>

                        <div className="button-wrapper">
                            <Skeleton width={145} height={30} />
                        </div>
                    </div> :
                    <div className="organization-wrapper">
                        <div className="section-title">{t('scan_tab1_wizard_step2_layout_chain_text_1')}</div>
                        <div className="section-title">
                            {
                                hasTreeDataNotEmpty.length ?
                                    t('scan_tab1_wizard_step2_layout_chain_text_2') : ""
                            }
                        </div>

                        {
                            !filesTreeData.includes(undefined) ?
                                filesTreeData.map((fileTreeData, i) => {
                                    const thisTenant = organizationData.organization?.filter(organization => organization.tenantId === fileTreeData.tenantId)[0];
                                    const tenantGroups = thisTenant?.groups;

                                    const thisTenantGroup = thisTenant?.groups.filter(group => group.groupId === fileTreeData.groupId)[0];
                                    const tenantGroupDepartments = thisTenantGroup?.departments;

                                    const allItemsSet = fileTreeData.tenantId && fileTreeData.groupId && fileTreeData.departmentId;

                                    const noGroupsAvailable = fileTreeData.tenantId && tenantGroups?.length;
                                    const noDepartmentsAvailable = fileTreeData.groupId && tenantGroupDepartments?.length;

                                    return (
                                        <React.Fragment key={i}>
                                            <div className="organization-list">
                                                <div className="item-cards">
                                                    {
                                                        i === 0 && fileTreeData.tenantId ?
                                                            <div className="item-card" style={{ gridColumn: 1 }}>
                                                                <div className="icon">
                                                                    <img
                                                                        src={require('assets/icons/wizard/database.png').default}
                                                                        alt="database-icon"
                                                                        width="70" />
                                                                </div>
                                                                <div className="text">{t('scan_tab1_wizard_step2_layout_chain_tenant')}:</div>
                                                                <div className="input">
                                                                    <select
                                                                        name="tenantName"
                                                                        id="tenantName"
                                                                        defaultValue={fileTreeData.tenantId}
                                                                        disabled={readOnly}
                                                                        onChange={e => setTreeTenantId(i, e.target.value)}>
                                                                        {
                                                                            organizationData.organization?.map((tenant, i) => (
                                                                                <option value={tenant.tenantId} key={i}>{tenant.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> :
                                                            i === 0 && !fileTreeData.tenantId ?
                                                                <div className="add-item-button disabled">
                                                                    <img
                                                                        src={require('assets/icons/wizard/organization.png').default}
                                                                        alt="organization-icon"
                                                                        width="45" />
                                                                    <div className="empty">{t('scan_tab1_wizard_step2_empty_tenant')}</div>
                                                                </div> :
                                                                null
                                                    }

                                                    {
                                                        fileTreeData.groupId ?
                                                            <div className="item-card" style={{ gridColumn: 2 }}>
                                                                {
                                                                    !readOnly &&
                                                                    <div className="remove-item" onClick={() => setTreeGroupId(i, "")}>
                                                                        <MdClose />
                                                                    </div>
                                                                }
                                                                <div className="icon">
                                                                    <img
                                                                        src={require('assets/icons/wizard/organization.png').default}
                                                                        alt="organization-icon"
                                                                        width="45" />
                                                                </div>
                                                                <div className="text">{t('scan_tab1_wizard_step2_layout_chain_group')}:</div>
                                                                <div className="input">
                                                                    <select
                                                                        name="groupName"
                                                                        id="groupName"
                                                                        defaultValue={fileTreeData.groupId}
                                                                        disabled={readOnly}
                                                                        onChange={e => setTreeGroupId(i, e.target.value)}>
                                                                        {
                                                                            tenantGroups.map((group, i) => (
                                                                                <option value={group.groupId} key={i}>{group.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> :
                                                            noGroupsAvailable ?
                                                                <div className="add-item-button" onClick={() => setTreeGroupId(i)}>
                                                                    <AiOutlinePlus style={{ marginRight: 10 }} />
                                                                    <img
                                                                        src={require('assets/icons/wizard/organization.png').default}
                                                                        alt="organization-icon"
                                                                        width="45" />
                                                                </div> :
                                                                <div className="add-item-button disabled">
                                                                    <img
                                                                        src={require('assets/icons/wizard/organization.png').default}
                                                                        alt="organization-icon"
                                                                        width="45" />
                                                                    <div className="empty">{t('scan_tab1_wizard_step2_empty_group')}</div>
                                                                </div>
                                                    }

                                                    {
                                                        fileTreeData.departmentId ?
                                                            <div className="item-card" style={{ gridColumn: 3 }}>
                                                                {
                                                                    !readOnly &&
                                                                    <div className="remove-item" onClick={() => setTreeDepartmentId(i, "")}>
                                                                        <MdClose />
                                                                    </div>
                                                                }
                                                                <div className="icon">
                                                                    <img src={require('assets/icons/wizard/users.png').default} alt="users-icon" width="50" />
                                                                </div>
                                                                <div className="text">{t('scan_tab1_wizard_step2_layout_chain_zone')}:</div>
                                                                <div className="input">
                                                                    <select
                                                                        name="departmentName"
                                                                        id="departmentName"
                                                                        disabled={readOnly}
                                                                        defaultValue={fileTreeData.departmentId}
                                                                        onChange={e => setTreeDepartmentId(i, e.target.value)}>
                                                                        {
                                                                            tenantGroupDepartments.map((department, i) => (
                                                                                <option value={department.departmentId} key={i}>{department.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> :
                                                            noDepartmentsAvailable && noGroupsAvailable ?
                                                                <div className="add-item-button" onClick={() => setTreeDepartmentId(i)}>
                                                                    <AiOutlinePlus style={{ marginRight: 10 }} />
                                                                    <img src={require('assets/icons/wizard/users.png').default} alt="users-icon" width="50" />
                                                                </div> :
                                                                <div className="add-item-button disabled">
                                                                    <img src={require('assets/icons/wizard/users.png').default} alt="users-icon" width="50" />
                                                                    <div className="empty">{t('scan_tab1_wizard_step2_empty_department')}</div>
                                                                </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="file-list" style={{ backgroundColor: hasTreeDataNotEmpty.length ? "#f0f0f2" : "#fff" }}>
                                                <div className="file-items">
                                                    {
                                                        allItemsSet ?
                                                            fileTreeData.files.map((file, j) => (
                                                                <div className="file-item" key={j}>
                                                                    <div className="file-icon">
                                                                        <RiFolderFill />
                                                                    </div>
                                                                    <div className="file-content">
                                                                        <div className="file-title">{file.name}</div>
                                                                        <div className="file-path">{file.smallPath}</div>
                                                                    </div>
                                                                    {
                                                                        !readOnly &&
                                                                        <div className="file-remove">
                                                                            <div className="icon" onClick={() => deleteTreeFile(i, j)}>
                                                                                <MdClose />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )) :
                                                            null
                                                    }

                                                    {
                                                        allItemsSet && !readOnly ?
                                                            <AddItem
                                                                style={{ marginLeft: 10, marginTop: 10, fontWeight: 600 }}
                                                                text={t('scan_tab1_wizard_step2_button_1')}
                                                                onClick={() => {
                                                                    setCurrentOrganizationIndex(i);
                                                                    setIsModalOpen(true);
                                                                }} /> :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }) :
                                <p className="items-not-found">{t('scan_tab1_wizard_step2_not_found')}</p>
                        }

                        <div className="section-buttons">
                            {
                                hasTreeDataNotEmpty.length && !readOnly ?
                                    <>
                                        <div
                                            className="add-item-button"
                                            style={{ gridColumn: 2 }}
                                            onClick={() => addTreeItem()}>
                                            <AiOutlinePlus style={{ marginRight: 10 }} />
                                            <img src={require('assets/icons/wizard/organization.png').default} alt="database-icon" width="45" />
                                        </div>

                                        <div
                                            className="add-item-button"
                                            style={{ gridColumn: 3 }}
                                            onClick={() => addTreeItem(true)}>
                                            <AiOutlinePlus style={{ marginRight: 10 }} />
                                            <img src={require('assets/icons/wizard/users.png').default} alt="database-icon" width="50" />
                                        </div>
                                    </> :
                                    null
                            }
                        </div>

                        {
                            !readOnly && !filesTreeData.includes(undefined) &&
                            <div className="confirm">
                                <Button onClick={() => submitData()} style={{ width: 130 }}>{t('scan_tab1_wizard_step1_button_1')}</Button>
                            </div>
                        }
                    </div>
            }

            <FileTreeScanModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                selectFileCallback={(fileData) => setTreeNewFile(currentOrganizationIndex, fileData)} />
        </div>
    )
}

export default StepTwoComponent;