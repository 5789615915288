import customFetch from './fetch';
import _ from 'lodash';

export async function getExcelDataService(filters, userOnly = false, pagination = null, isCount = false) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    let filtersString = `${userOnly ? '/me' : ''}?`;

    if (filters) {
        _.keys(filters).forEach(filterKey => {
            if (Array.isArray(filters[filterKey])) {
                filters[filterKey].forEach(filterValue => {
                    filtersString += `${filterKey}=${filterValue}&`
                })
            } else {
                filtersString += `${filterKey}=${filters[filterKey]}&`
            }
        })
    }

    if (pagination) {
        filtersString += `limit=${pagination.limit}&`;

        if (pagination.currentIndex) {
            filtersString += `skip=${pagination.limit * (pagination.currentIndex - 1)}`;
        }
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform${isCount ? "/count" : ""}${filtersString}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function sendExcelDataService(formData) {
    try {
        let url = `${process.env.REACT_APP_MAIN_ENDPOINT}/validationform`;

        const body = JSON.stringify(formData);
        const response = await customFetch(url, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getExcelQuestionsService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/questions`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setExcelQuestionsService(questionsData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(questionsData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/questions`, requestOptions);
        if (!response.ok) throw await response.text();
        const responseJSON = await response.text();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setValidationFormRequest(formData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(formData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/request`, requestOptions);
        if (!response.ok) throw await response.text();
        const responseJSON = await response.text();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function changeValidationStatusService(formData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(formData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/status`, requestOptions);
        if (!response.ok) throw await response.text();
        const responseJSON = await response.text();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getValidationsByIdService(filters) {
    try {
        const body = JSON.stringify(filters);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/export`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function forwardValidationService(userSamAccount, validationId) {
    try {
        const body = JSON.stringify({ username: userSamAccount });
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/validationform/${validationId}/forward`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}