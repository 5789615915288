import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import DownloadCsvModalComponent from '../components/DownloadCsvModalComponent';
import moment from 'moment';
import { getValidationsByIdService } from 'services/excel';
import { getInventoriesByIdService, getInventorySectionQuestionsService } from 'services/inventory';
import { getGraphDataCsvService } from 'services/analysis';
import { exportScanByFileId } from 'services/scanning';
import { exportToCsv, exportToXlsx, getFileStatus, fileStatusList, getOrganizationName } from 'services/helper';
import { getOrganizationService } from 'services/settings';
import _ from 'lodash';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const DownloadCsvModal = (props) => {
    const {
        globalFilters = {},
        localFilters = {},
        settings,
        settings: { language },
        fileIds = []
    } = props;

    const [isDownloading, setIsDownloading] = useState(false);
    const { t } = useTranslation();

    const downloadCsv = async (fileType, rowsType, columnsToExport) => {
        if (!columnsToExport.length) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_empty_csv_download_columns')} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        } else {
            setIsDownloading(true);

            try {
                const filesList = await getFilesList(rowsType);

                if (fileType === "csv") {
                    if (columnsToExport.includes("metadata")) {
                        const filesMetadata = await getMetadata(filesList);
                        const csvName = `Export EUCP - Metadata - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}.csv`;
                        exportToCsv(filesMetadata, csvName);
                    }

                    if (columnsToExport.includes("inventory")) {
                        const filesLatestAnswers = await getLastInventoryAnswers(filesList);
                        const csvName = `Export EUCP - Inventory - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}.csv`;
                        exportToCsv(filesLatestAnswers, csvName);
                    }

                    if (columnsToExport.includes("last-answers")) {
                        const filesLatestAnswers = await getLastAnswers(filesList);
                        const csvName = `Export EUCP - EUC Validations - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}.csv`;
                        exportToCsv(filesLatestAnswers, csvName);
                    }

                    if (columnsToExport.includes("last-scans")) {
                        const { complexityScans, criticalityScans } = await getLastScans(filesList);
                        let csvName = `Export EUCP - Last Scans - complexity - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}.csv`;
                        exportToCsv(complexityScans, csvName);
                        csvName = `Export EUCP - Last Scans - criticality - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}.csv`;
                        exportToCsv(criticalityScans, csvName);
                    }
                }

                if (fileType === "excel") {
                    let xlsxToExport = {};

                    if (columnsToExport.includes("metadata")) {
                        const filesMetadata = await getMetadata(filesList);
                        xlsxToExport["metadata"] = filesMetadata;
                    }

                    if (columnsToExport.includes("inventory")) {
                        const filesLatestAnswers = await getLastInventoryAnswers(filesList);
                        xlsxToExport["inventory"] = filesLatestAnswers;
                    }

                    if (columnsToExport.includes("last-answers")) {
                        const filesLatestAnswers = await getLastAnswers(filesList);
                        xlsxToExport["EUC Validations"] = filesLatestAnswers;
                    }

                    if (columnsToExport.includes("last-scans")) {
                        const { complexityScans, criticalityScans } = await getLastScans(filesList);
                        xlsxToExport["complexity scans"] = complexityScans;
                        xlsxToExport["criticality scans"] = criticalityScans;
                    }

                    const csvName = `Export EUCP - Data - ${moment(new Date()).format("YYYY-MM-DD HH-mm")}`;

                    exportToXlsx(xlsxToExport, csvName);
                }
            } catch (err) {
                console.log(`Error: ${err}`);
                toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_error_excel_download')} />, {
                    progressClassName: "toastr-progress-bar danger"
                });
            }

            setIsDownloading(false);
        }
    }

    const getFilesList = async (rowsType) => {
        let paginationOptions = {
            limit: settings?.configCrawler?.maxFileNumberExportExcel,
            currentIndex: 0
        };

        if (fileIds.length) {
            localFilters.exportFileId = fileIds;
        }

        if (rowsType === "validation") localFilters.status = ["non-euc", "euc"];
        if (rowsType === "inventory") localFilters.status = ["in-inventory"];
        const data = await getGraphDataCsvService(globalFilters, localFilters, paginationOptions);
        return data;
    }

    const getMetadata = async rowsList => {
        const columnList = [
            {
                columnName: 'Id',
                columnKey: 'id'
            },
            {
                columnName: 'Name',
                columnKey: 'name'
            },
            {
                columnName: 'Path',
                columnKey: 'fullName'
            },
            {
                columnName: 'Entered on',
                columnKey: 'createdAt'
            },
            {
                columnName: 'Entered via',
                columnKey: 'createdVia'
            },
            {
                columnName: 'Complexity',
                columnKey: 'complexity'
            },
            {
                columnName: 'Criticality',
                columnKey: 'criticality'
            },
            {
                columnName: 'File size',
                columnKey: 'fileSize'
            },
            {
                columnName: 'Last modified',
                columnKey: 'lastModified'
            },
            {
                columnName: 'Department (AD)',
                columnKey: 'department'
            },
            {
                columnName: 'User (AD)',
                columnKey: 'lastModifiedByMeta'
            },
            {
                columnName: 'Status',
                columnKey: 'status'
            }
        ];

        const organizationData = await getOrganizationService();
        const useGroups = settings?.coordinatorConfig?.useGroups ? true : false;

        const rowsParsed = rowsList.map(data => {
            const fileStatus = getFileStatus(data.validationStatus, data.validationResult);
            const fileStatusLabel = fileStatusList.filter(status => status.id === fileStatus.id)[0];

            let columnData = {};

            columnList.forEach(column => {
                columnData[column.columnName] = _.isNil(data[column.columnKey]) ? "-" : data[column.columnKey];

                if (column.columnKey === "status") columnData[column.columnName] = t(fileStatusLabel.label);
                if (column.columnKey === "fileSize") columnData[column.columnName] = data.fileSize ? `${data.fileSize} bytes` : "0 bytes";
                if (column.columnKey === "fullName") columnData[column.columnName] = data.fullName.replace(`\\${data?.name}`, "");
                if (["lastModified", "createdAt"].includes(column.columnKey)) {
                    columnData[column.columnName] = data[column.columnKey] ? moment(data[column.columnKey]).format("DD/MM/YYYY HH:mm") : "-"
                };
                if (column.columnKey === "department") {
                    const name = getOrganizationName(useGroups, organizationData, data);
                    columnData[column.columnName] = name || "-";
                }
            });

            return columnData;
        });

        return rowsParsed;
    }

    const getLastAnswers = async rowsList => {
        const fileIds = rowsList.map(row => row.id);
        const questionsAndAnswersData = await getValidationsByIdService({ exportFileId: fileIds });

        const allQuestionTitles = questionsAndAnswersData.map(row => {
            return row.answers.map(questionData => questionData.question);
        });

        const allQuestionTitlesUnique = _.uniq(_.flattenDeep(allQuestionTitles));

        const validationsWithAnswers = questionsAndAnswersData.filter(validation => validation.answers.length);

        const rowsParsed = validationsWithAnswers.map(validationData => {
            const fileData = rowsList.filter(file => file.id === validationData.fileId)[0];
            let questionsData = {};

            allQuestionTitlesUnique.forEach(questionTitleUnique => {
                const questionTitle = t(questionTitleUnique);

                if (validationData) {
                    const allQuestionsData = validationData.answers;
                    const thisQuestionData = allQuestionsData.filter(questionData => questionData.question === questionTitleUnique)[0];
                    const questionAnswer = thisQuestionData?.value ? t(thisQuestionData?.value) : '-';
                    if (!questionsData[questionTitle] || questionsData[questionTitle] === "-") questionsData[questionTitle] = questionAnswer;
                } else {
                    questionsData[questionTitle] = "-";
                }
            })

            return {
                "Id": validationData.fileId,
                "Name": fileData.name,
                "Path": fileData.fullName.replace(`\\${fileData?.name}`, ""),
                ...questionsData
            }
        })

        return rowsParsed;
    }

    const getLastInventoryAnswers = async rowsList => {
        const fileIds = rowsList.map(row => row.id);
        const inventoriesWithQuestionsData = await getInventoriesByIdService({ exportFileId: fileIds });
        const inventoriesWithAnsweredSections = inventoriesWithQuestionsData.filter(inventory => {
            return inventory.sections.some(section => section.answer.length)
        })

        const uniqueConfigSectionIds = _.uniq(
            inventoriesWithAnsweredSections
                .map(inventory => inventory.sections).flat(1)
                .map(inventorySection => inventorySection.configSectionId)
        );

        const configSectionDataPromises = uniqueConfigSectionIds.map(configSection => {
            return getInventorySectionQuestionsService(configSection);
        });

        const configSectionData = await Promise.all(configSectionDataPromises);

        // A map with (configSectionId-questionId) for each existing question in all inventories
        const questionsMappingIds = _.uniq(
            inventoriesWithAnsweredSections.map(question => question.sections)
                .flat(1)
                .map(section => {
                    return section.answer.map(questionData => {
                        const isSectionTitle = questionData.answer.includes("§§§");
                        return isSectionTitle ? "section-title" : `${section.configSectionId}-${questionData.questionOrder}`;
                    })
                })
                .flat(1)
                .filter(questionId => questionId !== "section-title")
        );

        const questionsMappingIdsSorted = _.orderBy(questionsMappingIds, null, "asc");

        const rowsParsed = inventoriesWithAnsweredSections.map(inventoryData => {
            const row = rowsList.filter(row => inventoryData.fileId === row.id)[0];
            const inventorySections = inventoryData?.sections;
            const inventorySectionsObject = inventorySections ? _(inventorySections)
                .mapKeys((value, key) => value.configSectionId)
                .mapValues(value => value.answer)
                .value() : null;

            let questionsData = {};

            questionsMappingIdsSorted.forEach(questionMapId => {
                const ids = questionMapId.split("-");
                const configSectionId = parseInt(ids[0]);
                const questionOrder = ids[1];

                const configSectionInstance = configSectionData.filter(configSection => parseInt(configSection.sectionId) === configSectionId)[0];
                const configSectionQuestion = configSectionInstance.questions.filter(question => question.order === questionOrder)[0];
                const questionName = `(Config ${configSectionId}) - ${configSectionQuestion?.text[language]}`

                if (inventorySectionsObject && inventorySectionsObject[configSectionId]) {
                    const thisQuestion = inventorySectionsObject[configSectionId].filter(question => question.questionOrder === questionOrder)[0];

                    if (thisQuestion) {
                        if (configSectionQuestion.type === "checkbox" || configSectionQuestion.type === "select") {
                            const questionOptionsData = configSectionQuestion?.options?.filter(option => {
                                return thisQuestion?.answer?.includes(option.order);
                            });

                            const questionOptionsAnswer = questionOptionsData.map(option => {
                                return option.value[language];
                            }).join(", ");

                            questionsData[questionName] = questionOptionsAnswer || "-";
                        } else {
                            questionsData[questionName] = thisQuestion?.answer?.join(", ") || "-";
                        }
                    } else {
                        questionsData[questionName] = "-";
                    }
                } else {
                    questionsData[questionName] = "-";
                }
            })

            return {
                "Id": row.id,
                "Name": row.name,
                "Path": row.fullName.replace(`\\${row?.name}`, ""),
                ...questionsData
            }
        });

        return rowsParsed;
    }

    const getLastScans = async rowsList => {
        const fileIds = rowsList.map(row => row.id);
        const lastScans = await exportScanByFileId(fileIds);
        const complexityIndicators = new Set();
        const criticalityIndicators = new Set();

        lastScans.forEach(scan => {
            scan.scanDetails?.length && scan.scanDetails.forEach((details) => {
                if (details.discriminator === "ComplexityIntValue") complexityIndicators.add(details.indicator)
                else if (details.discriminator === "CriticalityIntValue") criticalityIndicators.add(details.indicator)
            });
        });

        let complexityScans = [];
        let criticalityScans = [];

        lastScans.forEach(lastScan => {
            const scanDetails = _.keyBy(lastScan?.scanDetails, 'indicator');
            const commonColumns = {
                id: lastScan?.file?.id,
                Name: lastScan?.file?.name,
                Path: lastScan?.file?.fullName.replace(`\\${lastScan?.file?.name}`, ""),
            };
            const complexityScanParsed = { ...commonColumns, Complexity: lastScan?.complexity };
            const criticallyScanParsed = { ...commonColumns, Criticality: lastScan?.criticality };

            for (let indicator of complexityIndicators) {
                complexityScanParsed[`${indicator} (file results)`] = scanDetails[indicator]?.value || "-";
                complexityScanParsed[`${indicator} (weight)`] = scanDetails[indicator]?.weight || "-";
            }
            for (let indicator of criticalityIndicators) {
                criticallyScanParsed[`${indicator} (file results)`] = scanDetails[indicator]?.value || "-";
                criticallyScanParsed[`${indicator} (weight)`] = scanDetails[indicator]?.weight || "-";
            }

            complexityScans.push(complexityScanParsed);
            criticalityScans.push(criticallyScanParsed);
        });

        return { complexityScans, criticalityScans };
    }

    return (
        <DownloadCsvModalComponent
            {...props}
            isDownloading={isDownloading}
            downloadCsv={downloadCsv}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    license: store.licenseReducer.license
});

export default connect(mapStateToProps)(DownloadCsvModal);