import customFetch from './fetch';
import _ from 'lodash';
import moment from 'moment';

export async function getGraphDataService(globalFilters, localFilters = null, pagination, isCount) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    let filtersString = '';

    if (globalFilters) {
        const globalFiltersKeys = _.keys(globalFilters);

        globalFiltersKeys.forEach((filterKey) => {
            if (!_.isNil(globalFilters[filterKey]) && globalFilters[filterKey] !== "none") {
                if (Array.isArray(globalFilters[filterKey])) {
                    globalFilters[filterKey].forEach((filter) => {
                        filtersString += `${filterKey}=${filter}&`;
                    })
                }
                else filtersString += `${filterKey}=${globalFilters[filterKey]}&`;
            }
        });

        if (globalFilters?.orgTenantId?.includes(0)) filtersString += 'includenotscanned=true&';
    }

    if (localFilters) {
        const localFiltersKeys = _.keys(localFilters);

        localFiltersKeys.forEach((filterKey) => {
            if (filterKey === 'status') {
                localFilters[filterKey].forEach(thisStatus => {
                    if (thisStatus === 'non-euc') filtersString += `validationstatus=closed&formresult=non-euc&`
                    if (thisStatus === 'euc') filtersString += `validationstatus=closed&formresult=euc&`
                    if (thisStatus === 'no-validation') filtersString += `validationstatus=novalidation&`
                    if (thisStatus === 'in-validation') filtersString += `validationstatus=open&`
                    if (thisStatus === 'validated') filtersString += `validationstatus=PendingAcceptance&`
                    if (thisStatus === 'in-inventory') filtersString += `validationstatus=inventorized&inventorystatus=Open&inventorystatus=PendingAcceptance&inventorystatus=Requested&inventorystatus=Closed&inventorystatus=Finalized&`
                    if (thisStatus === 'in-inventory-process') filtersString += `validationstatus=inventorized&inventorystatus=Open&inventorystatus=PendingAcceptance&inventorystatus=Requested&`
                    if (thisStatus === 'in-inventory-closed') filtersString += `validationstatus=inventorized&inventorystatus=Closed&`
                });
            }
            else if (filterKey === 'hasError') {
                localFilters[filterKey].forEach(thisOption => {
                    if (thisOption === '0') filtersString += `hasError=false&`
                    if (thisOption === '1') filtersString += `hasError=true&`
                });
            }
            else {
                if (typeof localFilters[filterKey] === 'object') {
                    if (filterKey === 'complexity') {
                        filtersString += `mincomp=${localFilters.complexity.min}&`;
                        filtersString += `maxcomp=${localFilters.complexity.max}&`;
                    }

                    if (filterKey === 'criticality') {
                        filtersString += `mincrit=${localFilters.criticality.min}&`;
                        filtersString += `maxcrit=${localFilters.criticality.max}&`;
                    }

                    if (filterKey === "duplicates") {
                        if (localFilters.duplicates.showOnlyDuplicates) filtersString += `minDup=1&`;
                        if (localFilters.duplicates.list) {
                            localFilters.duplicates.list.forEach(duplicate => {
                                filtersString += `fileSnapshotId=${duplicate}&`
                            })
                        }
                    }

                    if (filterKey === 'topicId') {
                        const topicIdNames = localFilters[filterKey].map(topic => topic.value);

                        topicIdNames.forEach(topicId => {
                            filtersString += `topicId=${topicId}&`;
                        })
                    }

                    if (filterKey === 'validationType') {
                        const validationTypeNames = localFilters[filterKey].map(validationType => validationType.value);

                        validationTypeNames.forEach(validationType => {
                            filtersString += `validationType=${validationType}&`;
                        })
                    }
                } else {
                    if (!_.isNil(localFilters[filterKey]) && localFilters[filterKey] !== "none" && localFilters[filterKey] !== "") {
                        filtersString += `${filterKey}=${localFilters[filterKey]}&`;
                    }
                }
            }
        });
    }

    if (pagination) {
        filtersString += `limit=${pagination.limit}&`;

        if (pagination.currentIndex) {
            filtersString += `skip=${pagination.limit * (pagination.currentIndex - 1)}`;
        }
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/files${isCount ? "/count" : ""}?${filtersString}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFileSnapshotsService(id) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/snapshots/${id}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFileSnapshotValuesService(fileId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/snapshots/${fileId}/values`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFileValidationsService(fileId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/validations/${fileId}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFileValidationAnswersService(validationId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/validations/${validationId}/answers`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getDuplicates(fsId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/duplicates/${fsId}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getFileValues(fileId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/files/${fileId}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getSampleDataService(globalFilters, localFilters = null) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    let filtersString = '';

    if (globalFilters) {
        const globalFiltersKeys = _.keys(globalFilters);

        globalFiltersKeys.forEach((filterKey) => {
            if (!_.isNil(globalFilters[filterKey]) && globalFilters[filterKey] !== "none") {
                if (Array.isArray(globalFilters[filterKey])) {
                    globalFilters[filterKey].forEach((filter) => {
                        filtersString += `${filterKey}=${filter}&`;
                    })
                }
                else filtersString += `${filterKey}=${globalFilters[filterKey]}&`;
            }
        });

        if (globalFilters?.orgTenantId?.includes(0)) filtersString += 'includenotscanned=true&';
    }

    if (localFilters) {
        const localFiltersKeys = _.keys(localFilters);
        const filterWithConstraints = ['extension', 'complexity', 'criticality', 'fileSize', 'date'];

        localFiltersKeys.forEach((filterKey) => {
            if (filterWithConstraints.includes(filterKey)) {
                if (filterKey === 'extension') {
                    localFilters[filterKey].forEach(extensionObject => {
                        const extensionsList = extensionObject.value.replace(/\*/g, '').split(" ");
                        extensionsList.forEach(extension => filtersString += `extension=${extension}&`);
                    });
                }

                if (filterKey === 'complexity') {
                    filtersString += `mincomp=${localFilters.complexity.min}&`;
                    filtersString += `maxcomp=${localFilters.complexity.max}&`;
                }

                if (filterKey === 'criticality') {
                    filtersString += `mincrit=${localFilters.criticality.min}&`;
                    filtersString += `maxcrit=${localFilters.criticality.max}&`;
                }

                if (filterKey === 'fileSize') {
                    filtersString += `minsize=${localFilters.fileSize.min * 1024 * 1024}&`;
                    filtersString += `maxsize=${localFilters.fileSize.max * 1024 * 1024}&`;
                }

                if (filterKey === 'date') {
                    filtersString += `startdate=${localFilters.date.start}T00:00:00.000Z&`;
                    filtersString += `enddate=${localFilters.date.end}T23:59:59.000Z&`;
                }
            } else {
                if (!_.isNil(localFilters[filterKey]) && localFilters[filterKey] !== "none" && localFilters[filterKey] !== "") {
                    filtersString += `${filterKey}=${localFilters[filterKey]}&`;
                }
            }
        });
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/sample?${filtersString}`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function getGraphDataCsvService(globalFilters, localFilters = null, pagination, isCount) {
    let filters = {
        validationstatus: [],
        formresult: [],
        inventorystatus: []
    };

    if (globalFilters && !_.isEmpty(globalFilters)) {
        const globalFiltersKeys = _.keys(globalFilters);

        globalFiltersKeys.forEach((filterKey) => {
            if (!_.isNil(globalFilters[filterKey]) && globalFilters[filterKey] !== "none") {
                filters[filterKey] = globalFilters[filterKey];
            }
        });

        if (globalFilters?.orgTenantId?.includes(0)) {
            filters.includenotscanned = true;
        }
    }

    if (localFilters && !_.isEmpty(localFilters)) {
        const localFiltersKeys = _.keys(localFilters);

        localFiltersKeys.forEach((filterKey) => {
            if (filterKey === 'status') {
                localFilters[filterKey].forEach(thisStatus => {
                    if (thisStatus === 'non-euc') {
                        filters.validationstatus.push("closed")
                        filters.formresult.push("non-euc")
                    }

                    if (thisStatus === 'euc') {
                        filters.validationstatus.push("closed")
                        filters.formresult.push("euc")
                    }

                    if (thisStatus === 'no-validation') {
                        filters.validationstatus.push("novalidation")
                    }

                    if (thisStatus === 'in-validation') {
                        filters.validationstatus.push("open")
                    }

                    if (thisStatus === 'validated') {
                        filters.validationstatus.push("PendingAcceptance")
                    }

                    if (thisStatus === 'in-inventory') {
                        filters.validationstatus.push("inventorized")
                        filters.inventorystatus = [...filters.inventorystatus, "Open", "PendingAcceptance", "Requested", "Closed", "Finalized"]
                    }

                    if (thisStatus === 'in-inventory-process') {
                        filters.validationstatus.push("inventorized")
                        filters.inventorystatus = [...filters.inventorystatus, "Open", "PendingAcceptance", "Requested"]
                    }

                    if (thisStatus === 'in-inventory-closed') {
                        filters.validationstatus.push("inventorized")
                        filters.inventorystatus.push("Closed")
                    }
                });
            }
            else if (filterKey === "fileId") {
                filters[filterKey] = parseInt(localFilters[filterKey]);
            }
            else if (filterKey === 'hasError') {
                localFilters[filterKey].forEach(thisOption => {
                    if (thisOption === '0') filters[filterKey] = false
                    if (thisOption === '1') filters[filterKey] = true
                });
            }
            else {
                if (typeof localFilters[filterKey] === 'object') {
                    if (filterKey === 'complexity') {
                        filters.mincomp = localFilters.complexity.min;
                        filters.maxcomp = localFilters.complexity.max;
                    }

                    if (filterKey === 'criticality') {
                        filters.mincrit = localFilters.criticality.min;
                        filters.maxcrit = localFilters.criticality.max;
                    }

                    if (filterKey === "duplicates") {
                        if (localFilters.duplicates.showOnlyDuplicates) filters.minDup = 1;

                        if (localFilters.duplicates.list) {
                            filters.fileSnapshotId = localFilters.duplicates.list;
                        }
                    }

                    if (filterKey === 'topicId') {
                        const topicIdNames = localFilters[filterKey].map(topic => topic.value);
                        if (topicIdNames.length) filters.topicId = topicIdNames;
                    }

                    if (filterKey === 'validationType') {
                        const validationTypeNames = localFilters[filterKey].map(validationType => validationType.value);
                        if (validationTypeNames.length) filters.validationType = validationTypeNames;
                    }

                    if (filterKey === 'exportFileId') {
                        filters.exportFileId = localFilters[filterKey];
                    }
                } else {
                    if (!_.isNil(localFilters[filterKey]) && localFilters[filterKey] !== "none" && localFilters[filterKey] !== "") {
                        filters[filterKey] = localFilters[filterKey];
                    }
                }
            }
        });
    }

    if (pagination) {
        filters.limit = pagination.limit;

        if (pagination.currentIndex) {
            filters.skip = pagination.limit * (pagination.currentIndex - 1);
        }
    }

    try {
        const body = JSON.stringify(filters);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/scanresult/files/export`, "POST", body);
        return response;
    } catch (err) {
        throw err;
    }
}