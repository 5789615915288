import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ManageKeywordListModalComponent from '../components/ManageKeywordListModalComponent';
import { setTopicsService } from 'services/topic';
import { setMetadataService } from 'services/metadata';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const ManageKeywordListModal = (props) => {
    const { setIsOpen, getKeywordList, isMetadata } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const submitKeywordList = async values => {
        setIsLoading(true);

        values.threshold = parseInt(values.threshold);
        values.weight = parseInt(values.weight);

        try {
            if (isMetadata) {
                await setMetadataService([values])
            } else {
                await setTopicsService([values]);
            }

            await getKeywordList();
            setIsOpen(false);
            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_topic_created')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while creating keywordlist: ${err}`);
        }

        setIsLoading(false);
    }

    return (
        <ManageKeywordListModalComponent
            {...props}
            submitKeywordList={submitKeywordList}
            isLoading={isLoading}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(ManageKeywordListModal);