import customFetch from './fetch';

export async function getInterfaceConfigService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configlayout`, requestOptions);

        if (!response.ok) throw response;

        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
            const responseJSON = await response.json();
            return responseJSON;
        } else {
            const responseText = await response.text();
            return responseText;
        }
    } catch (err) {
        throw err;
    }
}

export async function updateInterfaceConfigService(interfaceConfig) {
    const { configLayoutId, mainColor, secondaryColor, backgroundFile, logoFile } = interfaceConfig;

    let configOptions = {
        highlightDarkBackground: mainColor,
        highlightLightBackground: secondaryColor,
        backgroundFile,
        logoFile
    }

    if (configLayoutId) configOptions.configLayoutId = configLayoutId;

    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(configOptions)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configlayout`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function getOrganizationService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/organization`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setOrganizationService(organizationData) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(organizationData)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/organization`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function getIndicatorsService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/indicator`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setIndicatorsService(indicators) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(indicators)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/indicator`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function getConfigValidationService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configvalidation`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setConfigValidationService(topics) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(topics)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configvalidation`, requestOptions);
        if (!response.ok) throw response;
        const responseText = await response.text();
        return responseText;
    } catch (err) {
        throw err;
    }
}

export async function getConfigGraphService() {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configgraph`, requestOptions);
        if (!response.ok) throw response;
        const responseJSON = await response.json();
        return responseJSON;
    } catch (err) {
        throw err;
    }
}

export async function setConfigGraphService(data) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configgraph`, requestOptions);
        if (!response.ok) throw response;
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getConfigCrawlerService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configcrawler`, "GET")
        return response;
    } catch (err) {
        throw err;
    }

}

export async function setConfigCrawlerService(data) {
    try {
        const body = JSON.stringify(data);
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/configcrawler`, "POST", body)
        return response;
    } catch (err) {
        throw err;
    }
}

export async function getLicenseKeyService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/licensekey`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function checkLicenseKeyService() {
    try {
        const response = await customFetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/licensekey/verify`, "GET")
        return response;
    } catch (err) {
        throw err;
    }
}

export async function updateLicenseKeyService(data) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json"
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data)
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_ENDPOINT}/licensekey`, requestOptions);
        if (!response.ok) throw response;
        return response;
    } catch (err) {
        throw err;
    }
}