import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ChartPointListModalComponent from '../components/ChartPointListModalComponent';
import { connect } from "react-redux";

const ChartPointListModal = (props) => {
    const { pointsList, setPointsList, isOpen, setIsChartPointModalOpen, setSelectedPoint } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOpen) {
            setPointsList([]);
        }
    }, [isOpen]);

    return (
        <ChartPointListModalComponent
            {...props}
            pointsList={pointsList}
            setIsChartPointModalOpen={setIsChartPointModalOpen}
            setSelectedPoint={setSelectedPoint}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(ChartPointListModal);