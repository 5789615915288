import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "redux/actions/settingsActions";
import EucCheckManagementComponent from '../components/EucCheckManagementComponent';
import { getExcelQuestionsService, setExcelQuestionsService } from 'services/excel';
import { setConfigValidationService } from 'services/settings';
import { getDictionaryService, setDictionaryService } from 'services/dictionary';
import i18n from "i18next";
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const EucCheckManagement = (props) => {
    const { settings, updateSettings } = props;

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [requestSending, setRequestSending] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [allowedAlias, setAllowedAlias] = useState(null);
    const [allowedAutomaticValidations, setAllowedAutomaticValidations] = useState(null);
    const [allowedFileExplorer, setAllowedFileExplorer] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        getExcelQuestions();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    useEffect(() => {
        setAllowedAlias(settings?.validationConfig?.allowAlias === 1);
        setAllowedAutomaticValidations(settings?.validationConfig?.formAutoClose === 1);
        setAllowedFileExplorer(settings?.validationConfig?.useFolderImpersonation === 1);
    }, [settings]);

    const setConfigValidation = async () => {
        const newValidationConfig = {
            ...settings.validationConfig,
            allowAlias: allowedAlias ? 1 : 0,
            formAutoClose: allowedAutomaticValidations ? 1 : 0,
            useFolderImpersonation: allowedFileExplorer ? 1 : 0,
        };

        try {
            await setConfigValidationService(newValidationConfig);
            updateSettings({
                ...settings,
                validationConfig: newValidationConfig
            })
        } catch (err) {
            setError(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const getExcelQuestions = async () => {
        try {
            const response = await getExcelQuestionsService();
            setQuestions(response);
        } catch (err) {
            setError(`Error ${err.status}: ${err.statusText}`);
        }

        setIsLoading(false);
    }

    const submitQuestions = async (questionValues) => {
        setRequestSending(true);

        let questionValuesWithoutRef = _.cloneDeep(questionValues);

        try {
            await updateDictionarySentences(questionValuesWithoutRef.dictionary);

            delete questionValuesWithoutRef.dictionary;
            questionValuesWithoutRef = _.omitBy(questionValuesWithoutRef, _.isNull);

            const questionKeys = _.keys(questionValuesWithoutRef);
            let questionValuesParsed = questionKeys.map((questionKey, i) => {
                let questionObject = questionValuesWithoutRef[questionKey];
                questionObject.order = i + 1;
                questionObject.behaviour = i === 0 ? "show" : "hide";

                // Delete data that are used only by GET operations
                delete questionObject.oldFields;

                return questionObject;
            });

            questionValuesParsed.forEach((question, i) => {
                let eucValues = [];

                question.options = question.options.filter(option => option !== null);

                question.options.forEach((option, j) => {
                    option.order = j + 1;

                    const questionThatRequiresThisOption = parseInt(option.requiredByQuestionOrder);
                    const valuesToNotApplyRequirement = [0, 100, 101];

                    if (!valuesToNotApplyRequirement.includes(questionThatRequiresThisOption)) {
                        const questionIndex = questionValuesParsed.findIndex(question => question.order === questionThatRequiresThisOption);

                        if (questionIndex !== -1) {
                            questionValuesParsed[questionIndex].requirements.push({
                                requiredOrder: question.order,
                                behaviour: "show",
                                priority: 1,
                                trigger: option.order
                            })
                        }
                    }

                    if (questionThatRequiresThisOption === 101) {
                        eucValues.push(option.order);
                    }

                    delete option.oldFields;
                    delete option.requiredByQuestionOrder;
                });

                if (eucValues.length) question.valueEuc = eucValues;
            })

            await setExcelQuestionsService(questionValuesParsed);
            await setConfigValidation();
            await reloadDictionary();
            await getExcelQuestions();

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_euc_check_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(err);
        }

        setRequestSending(false);
    }

    const updateDictionarySentences = async (sentenceValues) => {
        const newDictionarySentences = {
            "en": {
                "screenEucCheck_layout_chain_text_9": sentenceValues.isEuc.en,
                "screenEucCheck_layout_chain_text_10": sentenceValues.notEuc.en,
                "screenEucCheck_layout_chain_text_7": sentenceValues.bottomMessageEuc.en,
                "screenEucCheck_layout_chain_text_13": sentenceValues.bottomMessageNotEuc.en
            },
            "de": {
                "screenEucCheck_layout_chain_text_9": sentenceValues.isEuc.de,
                "screenEucCheck_layout_chain_text_10": sentenceValues.notEuc.de,
                "screenEucCheck_layout_chain_text_7": sentenceValues.bottomMessageEuc.de,
                "screenEucCheck_layout_chain_text_13": sentenceValues.bottomMessageNotEuc.de
            }
        }

        try {
            const response = await setDictionaryService(newDictionarySentences);
            return response;
        } catch (err) {
            console.log(JSON.stringify(err));
        }
    }

    const reloadDictionary = async () => {
        const dictionaryWords = await getDictionaryService();

        i18n.addResourceBundle('en', 'translations', {
            ...dictionaryWords.en
        });

        i18n.addResourceBundle('de', 'translations', {
            ...dictionaryWords.de
        });
    }

    return <EucCheckManagementComponent
        error={error}
        isLoading={isLoading}
        questions={questions}
        submitQuestions={submitQuestions}
        requestSending={requestSending}
        allowedAlias={allowedAlias}
        setAllowedAlias={setAllowedAlias}
        allowedAutomaticValidations={allowedAutomaticValidations}
        setAllowedAutomaticValidations={setAllowedAutomaticValidations}
        allowedFileExplorer={allowedFileExplorer}
        setAllowedFileExplorer={setAllowedFileExplorer}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EucCheckManagement);