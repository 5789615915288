import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EucCheckComponent from '../components/EucCheckComponent';

const EucCheck = (props) => {
    const { settings: { theme }, previewTheme } = props;

    return <EucCheckComponent
        {...props}
        previewTheme={previewTheme}
        theme={theme}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default withRouter(connect(mapStateToProps)(EucCheck));
