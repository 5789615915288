import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddQuestionTypeModal from 'components/modals/AddQuestionTypeModal/AddQuestionTypeModal';
import AddItem from 'components/common/AddItem/AddItem';
import QuestionsManagementSkeleton from 'components/common/QuestionsManagementSkeleton/QuestionsManagementSkeleton';
import Button from 'components/common/Button/Button';
import { AiOutlineClose } from 'react-icons/ai';
import { setObjValue, removeRequirementFromQuestion, removeFinishedFromQuestion, addFinishedToQuestion, addRequirementToQuestion, getQuestionTypeName } from 'services/inventoryQuestionsHelper';
import { countChar, isOrderHigher, changeOrder } from 'services/helper';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import _ from 'lodash';
import './InventoryQuestionsManagementComponent.scss';

const InventoryQuestionsManagementComponent = (props) => {
    const {
        isLoading,
        questions,
        submitQuestions,
        requestSending,
        allowedAutomaticValidations,
        setAllowedAutomaticValidations,
    } = props;

    const [allQuestions, setAllQuestions] = useState([]);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [currentParentOrderIndex, setCurrentParentOrderIndex] = useState(null);
    const [questionTypeModalOpen, setQuestionTypeModalOpen] = useState(false);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (questions.length) setAllQuestions(questions);
    }, [questions])

    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    const handleQuestionTypeModal = (sectionIndex, parentOrderIndex) => {
        setCurrentSectionIndex(sectionIndex);
        setCurrentParentOrderIndex(parentOrderIndex);
        setQuestionTypeModalOpen(true);
    }

    const addNewQuestion = (question) => {
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);

        let sectionData = _.cloneDeep(allQuestions[currentSectionIndex]);

        // if (allQuestions[currentSectionIndex].sectionName === 'EUC Information') {
        //     const productRequirementIndex = sectionData.questions.findIndex(question => question.identifier === "protection-requirement");
        //     const newProductRequirementOrder = `${parseInt(question.order) + 1}`;

        //     sectionData.questions[productRequirementIndex].order = newProductRequirementOrder;
        //     sectionData.questions[productRequirementIndex].options = sectionData.questions[productRequirementIndex].options.map((option, i) => {
        //         return {
        //             ...option,
        //             order: `${newProductRequirementOrder}.${i + 1}`
        //         }
        //     });

        //     sectionData.questions[productRequirementIndex].finished = sectionData.questions[productRequirementIndex].finished.map(value => {
        //         return value.replace(question.order, newProductRequirementOrder);
        //     });
        // }

        sectionData.questions.push(question);
        allQuestionsToUpdate[currentSectionIndex] = sectionData;

        setAllQuestions(allQuestionsToUpdate)
    }

    const removeQuestion = (sectionIndex, questionOrder) => {
        const getQuestionOrderToRemove = (questions, questionOrder) => {
            const questionsOrder = questions.filter(question => question.parentOrder === questionOrder);

            if (questionsOrder.length) {
                questionsOrder.forEach(question => {
                    questionsToRemove.push(question.order)
                    getQuestionOrderToRemove(questions, question.order);
                })
            }
        }

        let questionsToRemove = [questionOrder];
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);
        let sectionData = _.cloneDeep(allQuestions[sectionIndex]);

        getQuestionOrderToRemove(sectionData.questions, questionOrder);

        questionsToRemove.forEach(questionToRemove => {
            sectionData.questions = removeRequirementFromQuestion(sectionData, questionToRemove, null);
        })

        sectionData.questions = sectionData.questions.filter((question) => {
            return !questionsToRemove.includes(question.order);
        })

        sectionData.questions.forEach((question, i) => {
            const questionToRemoveLevel = countChar(questionOrder, ".");
            const thisQuestionLevel = countChar(question.order, ".");

            let valueToRemove = "1.0.0";
            if (questionToRemoveLevel === 1) valueToRemove = "0.1.0"
            if (questionToRemoveLevel === 2) valueToRemove = "0.0.1"

            if (isOrderHigher(questionOrder, question.order) === 1 && (questionToRemoveLevel <= thisQuestionLevel)) {
                const oldOrder = question.order;
                const newOrder = changeOrder(oldOrder, valueToRemove, "sub");

                // Update current question order
                sectionData.questions[i].order = newOrder;

                // Update current question parentOrder value
                if (!newOrder.includes(question.parentOrder) && !_.isNull(question.parentOrder)) {
                    const newParentOrder = changeOrder(question.parentOrder, valueToRemove, "sub");
                    sectionData.questions[i].parentOrder = newParentOrder;
                }

                if (question.options.length) {
                    question.options.forEach((option, j) => {
                        const oldOptionOrder = option.order;
                        const newOptionOrder = `${newOrder}.${j + 1}`;

                        // Update current question finished values
                        if (question?.finished?.includes(oldOptionOrder)) {
                            sectionData.questions[i].finished = sectionData.questions[i].finished.map(value => {
                                return value === oldOptionOrder ? value.replace(oldOptionOrder, newOptionOrder) : value;
                            });
                        }

                        // Update current question options order
                        sectionData.questions[i].options[j].order = newOptionOrder;

                        // Update other questions requirements (dropdown and multiple choice)
                        sectionData.questions.forEach((thisQuestion, thisQuestionIndex) => {
                            const requirementIndex = thisQuestion.requirements.findIndex(requirement => {
                                return requirement.requiredOrder === oldOrder && requirement.trigger === oldOptionOrder;
                            });

                            if (requirementIndex !== -1) {
                                sectionData.questions[thisQuestionIndex].requirements[requirementIndex].requiredOrder = newOrder;
                                sectionData.questions[thisQuestionIndex].requirements[requirementIndex].trigger = newOptionOrder;
                            }
                        })
                    });
                } else {
                    // Update other questions requirements (other types)
                    sectionData.questions.forEach((thisQuestion, thisQuestionIndex) => {
                        const requirementIndex = thisQuestion.requirements.findIndex(requirement => {
                            return requirement.requiredOrder === oldOrder;
                        });

                        if (requirementIndex !== -1) {
                            sectionData.questions[thisQuestionIndex].requirements[requirementIndex].requiredOrder = newOrder;
                        }
                    })
                }
            }
        })

        allQuestionsToUpdate[sectionIndex] = sectionData;
        setAllQuestions(allQuestionsToUpdate);
    }

    const addQuestionOption = (sectionIndex, questionOrder) => {
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);

        let sectionData = _.cloneDeep(allQuestions[sectionIndex]);
        const questionIndex = sectionData.questions.findIndex(question => question.order === questionOrder);

        const questionOptions = sectionData.questions[questionIndex].options;
        const questionOptionsOrder = questionOptions.map(option => {
            const optionOrder = questionOrder ? option.order.replace(`${questionOrder}.`, "") : option.order;
            const optionOrderNumber = parseInt(optionOrder);
            return optionOrderNumber;
        });

        const optionNextOrder = questionOptionsOrder.length ? _.max(questionOptionsOrder) + 1 : 1;
        const optionOrder = questionOrder ? `${questionOrder}.${optionNextOrder}` : optionNextOrder;

        sectionData.questions[questionIndex].options.push({
            value: {
                "en": "",
                "de": ""
            },
            order: optionOrder
        })

        allQuestionsToUpdate[sectionIndex] = sectionData;
        setAllQuestions(allQuestionsToUpdate);
    }

    const removeQuestionOption = (sectionIndex, questionOrder, optionOrder) => {
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);

        let sectionData = _.cloneDeep(allQuestions[sectionIndex]);
        const questionIndex = sectionData.questions.findIndex(question => question.order === questionOrder);
        sectionData.questions[questionIndex].options = sectionData.questions[questionIndex].options.filter((option) => {
            return optionOrder !== option.order;
        })

        sectionData.questions = removeRequirementFromQuestion(sectionData, questionOrder, optionOrder);
        sectionData.questions[questionIndex].finished = removeFinishedFromQuestion(sectionData, questionOrder, optionOrder);

        allQuestionsToUpdate[sectionIndex] = sectionData;
        setAllQuestions(allQuestionsToUpdate);
    }

    const handleRequirementsChange = (sectionIndex, questionToChange, questionTrigger, optionTrigger) => {
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);

        let sectionData = _.cloneDeep(allQuestions[sectionIndex]);
        const thisQuestionIndex = sectionData.questions.findIndex(question => question.order === questionTrigger);

        // Remove this requirement from previous ponting question
        sectionData.questions = removeRequirementFromQuestion(sectionData, questionTrigger, optionTrigger);

        // Remove the existing finished status from current question
        sectionData.questions[thisQuestionIndex].finished = removeFinishedFromQuestion(sectionData, questionTrigger, optionTrigger)

        if (questionToChange === "finished") {
            // If finished, add this status to current question
            sectionData.questions[thisQuestionIndex].finished = addFinishedToQuestion(sectionData, questionTrigger, optionTrigger);
        } else {
            if (questionToChange !== "") {
                // Otherwise add this requirement to the other question
                const { questionIndexToUpdate, newRequirement } = addRequirementToQuestion(sectionData, questionTrigger, optionTrigger, questionToChange);
                sectionData.questions[questionIndexToUpdate].requirements.push(newRequirement)
            }
        }

        allQuestionsToUpdate[sectionIndex] = sectionData;
        setAllQuestions(allQuestionsToUpdate);
    }

    const getQuestionRequirementMatch = (sectionIndex, questionOrder, optionOrder) => {
        const questions = allQuestions[sectionIndex].questions;

        const selectedOption = questions.filter(thisQuestion => {
            const hasRequirements = thisQuestion.requirements.filter(requirement => {
                const questionSameOrder = requirement.requiredOrder === questionOrder;
                const optionSameOrder = requirement.trigger === optionOrder;

                return questionSameOrder && optionSameOrder;
            });

            return hasRequirements.length;
        });

        if (selectedOption.length) return selectedOption[0].order;

        const currentQuestion = questions.filter(thisQuestion => thisQuestion.order === questionOrder)[0];
        if (currentQuestion.finished?.includes(optionOrder)) return "finished";

        return "";
    }

    const sortQuestion = (sortDirection, questionOrder, sectionIndex) => {
        let allQuestionsToUpdate = _.cloneDeep(allQuestions);
        const sectionQuestions = allQuestions[sectionIndex].questions;
        let newSectionQuestions = _.cloneDeep(sectionQuestions);

        const selectedQuestion = sectionQuestions.filter(question => question.order === questionOrder)[0];

        const questionsSameParent = sectionQuestions.filter(question => question.parentOrder === selectedQuestion.parentOrder);
        const questionIndex = questionsSameParent.findIndex(question => question.order === questionOrder);

        const swapQuestionsValues = (questionIndex, otherQuestionIndex, questionsGroup, sectionQuestions, newSectionQuestions) => {
            const currentQuestion = questionsGroup[questionIndex];
            const currentQuestionParent = currentQuestion.parentOrder;

            const otherQuestion = questionsGroup[otherQuestionIndex];
            const otherQuestionParent = otherQuestion?.parentOrder;

            if (currentQuestionParent === otherQuestionParent) {
                const currentQuestionIndex = sectionQuestions.findIndex(question => question.order === currentQuestion.order);
                const otherQuestionIndex = sectionQuestions.findIndex(question => question.order === otherQuestion.order);

                newSectionQuestions[currentQuestionIndex].options.map(option => {
                    option.order = option.order.replace(newSectionQuestions[currentQuestionIndex].order, otherQuestion.order);
                    return option;
                })
                newSectionQuestions[currentQuestionIndex].order = otherQuestion.order;
                newSectionQuestions[currentQuestionIndex].requirements = [];
                newSectionQuestions[currentQuestionIndex].finished = [];

                newSectionQuestions[otherQuestionIndex].options.map(option => {
                    option.order = option.order.replace(newSectionQuestions[otherQuestionIndex].order, selectedQuestion.order);
                    return option;
                })
                newSectionQuestions[otherQuestionIndex].order = selectedQuestion.order;
                newSectionQuestions[otherQuestionIndex].requirements = [];
                newSectionQuestions[otherQuestionIndex].finished = [];
            }

            return newSectionQuestions;
        }

        if (sortDirection === 'up') {
            newSectionQuestions = swapQuestionsValues(questionIndex, questionIndex - 1, questionsSameParent, sectionQuestions, newSectionQuestions);
        }

        if (sortDirection === 'down') {
            newSectionQuestions = swapQuestionsValues(questionIndex, questionIndex + 1, questionsSameParent, sectionQuestions, newSectionQuestions);
        }

        allQuestionsToUpdate[sectionIndex].questions = newSectionQuestions;

        allQuestionsToUpdate = allQuestionsToUpdate.map(section => {
            const questionsSorted = section.questions.sort((x, y) => isOrderHigher(y.order, x.order));
            section.questions = questionsSorted;
            return section;
        });

        setAllQuestions(allQuestionsToUpdate);
    }

    const renderQuestions = (questions, sectionIndex) => {
        if (!questions?.length) return null;

        return questions.map((question, i) => {
            const infoTextRowClass = question?.options?.length % 2 ? "primary-dark" : "primary-medium";
            let questionOrder = i + 1;
            let questionHierarchyLevel = 1;

            if (countChar(question.order, ".") === 1) {
                questionHierarchyLevel = 2;
                questionOrder = `${question.parentOrder}.${i + 1}`
            }

            if (countChar(question.order, ".") === 2) {
                questionHierarchyLevel = 3;
                questionOrder = `${question.parentOrder}.${i + 1}`
            }

            const sectionQuestions = allQuestions[sectionIndex].questions;
            const childQuestions = sectionQuestions.filter(thisQuestion => thisQuestion.parentOrder === question.order);

            const optionsAvailable = sectionQuestions.filter(sectionQuestion => {
                const thisQuestionLevel = countChar(question.order, ".") + 1;
                const sectionQuestionLevel = countChar(sectionQuestion.order, ".") + 1;

                const sectionQuestionOrder = sectionQuestion.order;
                const currentQuestionOrder = question.order;

                const sectionOrderFirstLevel = parseFloat(sectionQuestionOrder.split(".")[0]);
                const currentQuestionOrderFirstLevel = parseFloat(currentQuestionOrder.split(".")[0]);

                const isRootLevelAndBigger = sectionQuestionLevel === 1 && (sectionOrderFirstLevel > currentQuestionOrderFirstLevel);
                const isSameLevel = sectionQuestionLevel === thisQuestionLevel;
                const isOneLevelNested = sectionQuestionLevel === thisQuestionLevel + 1;
                const isSameOrder = questionOrder === sectionQuestionOrder;
                const isValueBigger = parseFloat(sectionQuestionOrder) > parseFloat(questionOrder);
                return (isSameLevel || isOneLevelNested || isRootLevelAndBigger) && !isSameOrder && isValueBigger;
            });

            const optionsAvailableOrder = optionsAvailable.map(sectionQuestion => sectionQuestion.order);

            const selectedQuestion = getQuestionRequirementMatch(sectionIndex, question.order, null);
            const isTitleType = question.type === 'title';
            // const isBusinessSection = allQuestions[sectionIndex].sectionName === 'EUC Information';

            const showArrowUp = i !== 0;
            const showArrowDown = (i + 1) !== questions.length;

            return (
                <div className={`question-wrapper level-${questionHierarchyLevel}`} key={i}>
                    <div className="question">
                        <div className="question-type">
                            {getQuestionTypeName(question.type, t)}
                        </div>
                        <table className="kpmg-table">
                            <tbody>
                                <tr className={isTitleType ? "title-row" : ""}>
                                    <td className="primary-dark" id={i} style={{ width: 70 }}>{questionOrder}</td>
                                    <td className="primary-dark" style={{ width: 130 }}>
                                        <strong>{question.parentOrder ? t('settings_inventory_questions_text_5') : (isTitleType ? t('settings_inventory_questions_text_9') : t('settings_inventory_questions_text_4'))}</strong>
                                    </td>
                                    <td className="input-cell">
                                        <input type="text"
                                            value={question.text.de}
                                            onChange={(e) => changeQuestionValue(e.target.value, "text.de", question.order, null, sectionIndex)}
                                            required
                                        />
                                    </td>
                                    <td className="input-cell">
                                        <input type="text"
                                            value={question.text.en}
                                            onChange={(e) => changeQuestionValue(e.target.value, "text.en", question.order, null, sectionIndex)}
                                            required
                                        />
                                    </td>
                                    <td style={{ width: 110 }}>
                                        {
                                            question.type === "text" ?
                                                <select
                                                    onChange={(e) => changeQuestionValue(e.target.value, "fieldSize", question.order, null, sectionIndex)}
                                                    value={question.fieldSize || ""}
                                                    required>
                                                    <option value="1">{t('settings_inventory_questions_question_textfield_type_1')}</option>
                                                    <option value="3">{t('settings_inventory_questions_question_textfield_type_2')}</option>
                                                    <option value="8">{t('settings_inventory_questions_question_textfield_type_3')}</option>
                                                </select> :
                                                null
                                        }
                                    </td>
                                    <td className="input-cell" style={{ width: 110 }}>
                                        {
                                            question.type === "text" || question.type === "file" ?
                                                <select
                                                    onChange={e => handleRequirementsChange(sectionIndex, e.target.value, question.order, null)}
                                                    value={selectedQuestion}
                                                    required>
                                                    <option value="">-- Select an option --</option>
                                                    {
                                                        optionsAvailableOrder.map((optionAvailable, i) => (
                                                            <option value={optionAvailable} key={i}>
                                                                {t('settings_inventory_questions_text_7')}: {t('settings_inventory_questions_text_8')} {optionAvailable}
                                                            </option>
                                                        ))
                                                    }
                                                    <option value={1}>Finish</option>
                                                </select> :
                                                null
                                        }
                                    </td>
                                    <td style={{ width: 30 }}>
                                        {
                                            !questionCanBeChanged(question) &&
                                            <div className="remove-row" onClick={() => removeQuestion(sectionIndex, question.order)}>
                                                <AiOutlineClose />
                                            </div>
                                        }
                                    </td>
                                </tr>
                                {
                                    question?.options.map((option, j) => {
                                        const rowClass = j % 2 ? "primary-dark" : "primary-medium";

                                        const selectedOption = getQuestionRequirementMatch(sectionIndex, question.order, option.order)

                                        return (
                                            <tr key={j}>
                                                <td className={rowClass}>{questionOrder}.{j + 1}</td>
                                                <td className={rowClass}>{t('settings_inventory_questions_text_6')} {j + 1}</td>
                                                <td className="input-cell">
                                                    <input type="text"
                                                        value={option.value.de}
                                                        onChange={(e) => changeQuestionValue(e.target.value, "value.de", question.order, option.order, sectionIndex)}
                                                        required
                                                    />
                                                </td>
                                                <td className="input-cell">
                                                    <input type="text"
                                                        value={option.value.en}
                                                        onChange={(e) => changeQuestionValue(e.target.value, "value.en", question.order, option.order, sectionIndex)}
                                                        required
                                                    />
                                                </td>
                                                <td></td>
                                                <td className="input-cell">
                                                    <select
                                                        onChange={e => handleRequirementsChange(sectionIndex, e.target.value, question.order, option.order)}
                                                        value={selectedOption}
                                                        required
                                                    >
                                                        <option value="">-- Select an option --</option>
                                                        {
                                                            optionsAvailableOrder.map((optionAvailable, i) => (
                                                                <option value={optionAvailable} key={i}>
                                                                    {t('settings_inventory_questions_text_7')}: {t('settings_inventory_questions_text_8')} {optionAvailable}
                                                                </option>
                                                            ))
                                                        }
                                                        <option value="finished">Finish</option>
                                                    </select>
                                                </td>
                                                <td style={{ width: 30 }}>
                                                    {
                                                        !questionCanBeChanged(question) &&
                                                        <div className="remove-row" onClick={() => removeQuestionOption(sectionIndex, question.order, option.order)}>
                                                            <AiOutlineClose />
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr className={isTitleType ? "title-row" : ""}>
                                    <td className={infoTextRowClass}>{questionOrder}.{question?.options?.length + 1}</td>
                                    <td className={infoTextRowClass}>Info text</td>
                                    <td className="input-cell">
                                        <input type="text"
                                            value={question.infoText?.de}
                                            onChange={(e) => changeQuestionValue(e.target.value, "infoText.de", question.order, null, sectionIndex)}
                                            required
                                        />
                                    </td>
                                    <td className="input-cell">
                                        <input type="text"
                                            value={question.infoText?.en}
                                            onChange={(e) => changeQuestionValue(e.target.value, "infoText.en", question.order, null, sectionIndex)}
                                            required
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="question-options">
                            <div className="sort-arrows">
                                {
                                    showArrowUp &&
                                    <div className="sort-arrow up" onClick={() => sortQuestion('up', question.order, sectionIndex)}>
                                        <FiArrowUp style={{ fontSize: 18 }} />
                                    </div>
                                }

                                {
                                    showArrowDown &&
                                    <div className="sort-arrow down" onClick={() => sortQuestion('down', question.order, sectionIndex)}>
                                        <FiArrowDown style={{ fontSize: 18 }} />
                                    </div>
                                }
                            </div>

                            <label htmlFor={`${sectionIndex}-${questionOrder}-behavior`} className="question-option">
                                <input
                                    type="checkbox"
                                    id={`${sectionIndex}-${questionOrder}-behavior`}
                                    checked={question?.behaviour === "show"}
                                    onChange={e => {
                                        const newValue = question?.behaviour === "show" ? "hide" : "show";
                                        changeQuestionValue(newValue, "behaviour", question.order, null, sectionIndex);
                                    }}
                                />
                                <span>{t('settings_inventory_questions_options_visible')}</span>
                            </label>

                            {
                                question.type !== 'title' &&
                                <label htmlFor={`${sectionIndex}-${questionOrder}-required`} className="question-option">
                                    <input
                                        type="checkbox"
                                        id={`${sectionIndex}-${questionOrder}-required`}
                                        checked={question?.required}
                                        onChange={e => {
                                            const newValue = !question?.required;
                                            changeQuestionValue(newValue, "required", question.order, null, sectionIndex);
                                        }}
                                    />
                                    <span>{t('settings_inventory_questions_options_required')}</span>
                                </label>
                            }
                        </div>

                        {
                            ["select", "checkbox"].includes(question.type) && !questionCanBeChanged(question) ?
                                <div style={{ width: 190, textAlign: 'left', marginLeft: 'auto', marginTop: 10 }}>
                                    <AddItem
                                        text={t('settings_inventory_questions_text_1')}
                                        onClick={() => addQuestionOption(sectionIndex, question.order)}
                                    />
                                </div> :
                                null
                        }

                        {
                            questionHierarchyLevel !== 3 && ["select", "checkbox"].includes(question.type) && !questionCanBeChanged(question) ?
                                <div style={{ width: 190, textAlign: 'left', marginLeft: 'auto', marginTop: 10 }}>
                                    <AddItem
                                        text={t('settings_inventory_questions_text_3')}
                                        onClick={() => handleQuestionTypeModal(sectionIndex, question.order)}
                                    />
                                </div> :
                                null
                        }
                    </div>

                    {renderQuestions(childQuestions, sectionIndex)}
                </div>
            )
        })
    }

    const changeQuestionValue = (value, field, questionOrder, optionOrder, sectionIndex) => {
        let allSectionsData = _.cloneDeep(allQuestions);
        let sectionData = allQuestions[sectionIndex];
        let sectionQuestions = sectionData.questions;

        const questionToChangeIndex = sectionQuestions.findIndex(question => question.order === questionOrder);

        if (optionOrder) {
            const optionToChangeIndex = sectionQuestions[questionToChangeIndex].options.findIndex(option => option.order === optionOrder);
            setObjValue(field, value, sectionQuestions[questionToChangeIndex].options[optionToChangeIndex])
        } else {
            setObjValue(field, value, sectionQuestions[questionToChangeIndex])
        }

        sectionData.questions = sectionQuestions;
        allSectionsData[sectionIndex] = sectionData;
        setAllQuestions(allSectionsData)
    }

    const questionCanBeChanged = question => {
        return ["protection-requirement", "technology"].includes(question?.identifier);
    }

    const parentQuestionsBusiness = allQuestions?.length ? allQuestions[0].questions.filter(question => !question.parentOrder) : [];
    const questionsBusiness = _.orderBy(parentQuestionsBusiness, (question) => parseFloat(question.order), 'asc');

    const parentQuestionsRisk = allQuestions?.length ? allQuestions[1].questions.filter(question => !question.parentOrder) : [];
    const questionsRisk = _.orderBy(parentQuestionsRisk, (question) => parseFloat(question.order), 'asc');

    return (
        <div className="inventory-questions">
            <div className="inventory-questions-wrapper">
                {
                    isLoading || !translationsLoaded ?
                        <QuestionsManagementSkeleton /> :
                        <div className="inventory-questions-content">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                submitQuestions(allQuestions);
                            }}>
                                <div className="euc-compliance-officer-section">
                                    <div className="title">{t('settings_inventory_questions_section_1')}</div>

                                    {renderQuestions(questionsBusiness, 0)}

                                    <div style={{ width: 190, textAlign: 'left', marginLeft: 'auto', marginTop: 10 }}>
                                        <AddItem
                                            text={t('settings_inventory_questions_text_2')}
                                            onClick={() => handleQuestionTypeModal(0, null)}
                                        />
                                    </div>
                                </div>

                                <hr />

                                <div className="risk-officer-section">
                                    <div className="title">{t('settings_inventory_questions_section_2')}</div>

                                    {renderQuestions(questionsRisk, 1)}

                                    <div style={{ width: 190, textAlign: 'left', marginLeft: 'auto' }}>
                                        <AddItem
                                            text={t('settings_inventory_questions_text_2')}
                                            onClick={() => handleQuestionTypeModal(1, null)}
                                        />
                                    </div>
                                </div>

                                <div className="settings-option">
                                    <input
                                        type="checkbox"
                                        id="allowAutomaticValidations"
                                        name="allowAutomaticValidations"
                                        value={allowedAutomaticValidations}
                                        onChange={() => setAllowedAutomaticValidations(!allowedAutomaticValidations)}
                                    />
                                    <label htmlFor="allowAutomaticValidations">{t('settings_tab5_allow_automatic_validations_inventory')}</label>
                                </div>

                                <div className="actions flex center">
                                    <div>{t('settings_inventory_questions_actions')}:</div>
                                    <Button loading={requestSending} type="submit">{t('settings_inventory_questions_button_apply')}</Button>
                                </div>
                            </form>
                        </div>
                }
            </div>

            <AddQuestionTypeModal
                isOpen={questionTypeModalOpen}
                setIsOpen={setQuestionTypeModalOpen}
                addNewQuestionCallback={addNewQuestion}
                sectionQuestions={allQuestions?.length ? allQuestions[currentSectionIndex].questions : []}
                sectionName={allQuestions?.length ? allQuestions[currentSectionIndex].sectionName : ""}
                currentParentOrderIndex={currentParentOrderIndex}
            />
        </div>
    )
};

export default InventoryQuestionsManagementComponent;