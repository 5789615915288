import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ValidationDownloadModalComponent from '../components/ValidationDownloadModalComponent';
import _ from 'lodash';
import { withRouter } from "react-router-dom";

const ValidationDownloadModal = (props) => {

    const selectedItem = {
        fileName: 'BS Overview Q3 v1 240519.xls',
        path: `U:\\Operations\...\Financial Services\Finance\FY2020\Reviews\(1)\BS Reviews\ `,
        status: 'Inventarisiert (08.05.20)',
        department: 'Finance',
        createdVia: 'Scan',
        user: 'Max Muller',
        createdAt: 1603209745429,
        lastModified: 1606309948429,
    }


    return (
        <ValidationDownloadModalComponent
            {...props}
            selectedItem={selectedItem}
        />
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default withRouter(connect(mapStateToProps)(ValidationDownloadModal));