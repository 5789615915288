import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getBlacklistService, deleteBlacklistService } from "services/blacklist";
import BlacklistComponent from '../components/BlacklistComponent';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'

const Blacklist = (props) => {

    const [blacklist, setBlacklistList] = useState([]);
    const [allBlacklistList, setAllBlacklistList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deletingBlacklistIds, setDeletingBlacklistIds] = useState([]);
    const [isFetchingBlacklistList, setIsFetchingBlacklistList] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getBlacklistList();
    }, []);

    const getBlacklistList = async () => {
        const response = await getBlacklistService();
        const dataToShow = response?.filter((file) => file.isDeleted === 0)
        setBlacklistList(dataToShow);
        setAllBlacklistList(dataToShow);
        setIsFetchingBlacklistList(false);
    }

    const discardBlacklistListChanges = () => {
        const metadataCopy = _.cloneDeep(blacklist);
        setAllBlacklistList(metadataCopy);
    }

    const deleteBlacklist = async (blacklistId) => {
        if (await confirm({
            title: t('delete_blacklist_confirm_title'),
            confirmation: t('delete_blacklist_confirm_message'),
        })) {
            setDeletingBlacklistIds([...deletingBlacklistIds, blacklistId]);

            try {
                await deleteBlacklistService({ blacklistId });
                await getBlacklistList();

                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_kalibrierung_blacklist_deleted')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            } catch (err) {
                console.log("Failed delete metadata:", err);
            }

            const newDeletingBlacklistIds = deletingBlacklistIds.filter(id => id !== blacklistId);
            setDeletingBlacklistIds(newDeletingBlacklistIds);
        }
    }

    return <BlacklistComponent
        isFetchingBlacklistList={isFetchingBlacklistList}
        deleteBlacklist={deleteBlacklist}
        deletingBlacklistIds={deletingBlacklistIds}
        getBlacklistList={getBlacklistList}
        discardBlacklistListChanges={discardBlacklistListChanges}
        isSubmitting={isSubmitting}
        allBlacklistList={allBlacklistList}
    />;
};

export default Blacklist;