import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import ClipLoader from "react-spinners/ClipLoader";
import { BiTrash } from 'react-icons/bi';
import AddItem from 'components/common/AddItem/AddItem';
import AddBlacklistModal from "components/modals/AddBlacklistModal/containers/AddBlacklistModal";

const BlacklistComponent = (props) => {
    const {
        isFetchingBlacklistList,
        deleteBlacklist,
        deletingBlacklistIds,
        getBlacklistList,
        allBlacklistList
    } = props;

    const [topicModalOpen, setTopicModalOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    return (
        <>
            {
                isFetchingBlacklistList || !translationsLoaded ?
                    <TableSkeletonLoading
                        rows={15}
                        columns={8}
                        style={{ marginTop: 20 }}
                    /> :
                    <>
                        <table className="kpmg-table stripped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_file_name')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap', maxWidth: 15 }}></span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                </tr>
                                {
                                    allBlacklistList.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ textAlign: 'left', wordBreak: 'unset' }}>{item.fullName}</td>
                                                <td>
                                                    <div className="table-action" onClick={() => !deletingBlacklistIds.includes(item.metadataId) && deleteBlacklist(item.blacklistId)}>
                                                        {
                                                            deletingBlacklistIds.includes(item.metadataId) ?
                                                                <ClipLoader
                                                                    size={14}
                                                                    color={"#525662"}
                                                                    loading={true}
                                                                /> :
                                                                <BiTrash />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="empty">
                                    <td >
                                        <AddItem text={t('settings_tab4_add_blacklist')} onClick={() => setTopicModalOpen(true)} />
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                    </>
            }

            <AddBlacklistModal
                isOpen={topicModalOpen}
                setIsOpen={setTopicModalOpen}
                getKeywordList={getBlacklistList}
            />

        </>
    )
};

export default BlacklistComponent;