import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FileOverviewModalComponent from '../components/FileOverviewModalComponent';
import { getFileSnapshotsService, getFileSnapshotValuesService, getFileValidationsService, getFileValidationAnswersService, getDuplicates } from 'services/analysis';
import { changeValidationStatusService } from 'services/excel';
import { getInventoryVersionsService, getInventorySectionQuestionsService } from 'services/inventory';
import { getGraphDataService } from 'services/analysis';
import { getFileStatus, fileStatusList, getOrganizationName, isOrderHigher, exportToCsv } from 'services/helper';
import { getOrganizationService } from 'services/settings';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import _ from 'lodash';

const FileOverviewModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        selectedItem,
        setSelectedItem,
        settings,
        user,
        refreshScreen,
        settings: { language },
        selectedItemType
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const [departmentName, setDepartmentName] = useState("Loading ...");

    const [loadingSnapshots, setLoadingSnapshots] = useState(false);
    const [fileSnapshots, setFileSnapshots] = useState([]);
    const [fileDetails, setFileDetails] = useState([]);
    const [currentSnapshotIndex, setCurrentSnapshotIndex] = useState(0);

    const [loadingValidations, setLoadingValidations] = useState(false);
    const [fileValidations, setFileValidations] = useState([]);
    const [fileAnswers, setFileAnswers] = useState([]);
    const [currentValidationIndex, setCurrentValidationIndex] = useState(0);

    const [loadingDuplicates, setLoadingDuplicates] = useState(false);
    const [fileDuplicates, setFileDuplicates] = useState([]);

    const [isEditingAlias, setIsEditingAlias] = useState(false);

    const [inventoryVersions, setInventoryVersions] = useState([]);
    const [loadingInventoryVersions, setLoadingInventoryVersions] = useState(false);
    const [currentInventoryIndex, setCurrentInventoryIndex] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        setFileSnapshots([]);
        setFileDetails([]);

        setFileValidations([]);
        setFileAnswers([]);

        setFileDuplicates([]);

        setInventoryVersions([]);

        if (selectedItem?.id) {
            getFileListDetails(selectedItem.id);
            getDepartmentName(selectedItem);

            if (selectedItemType === "inventory") {
                getFileInventoryVersions(null, [{ ...selectedItem, versions: [selectedItem] }]);
            } else {
                getFileInventoryVersions(selectedItem.id);
            }

            if (selectedItemType === "validation") {
                getFileValidations(null, [selectedItem]);
            } else {
                getFileValidations(selectedItem.id);
            }
        }

        if (selectedItem?.fileSnapshotId) {
            getFileDuplicates(selectedItem.fileSnapshotId);
        }

    }, [selectedItem?.id, selectedItem?.answers])

    useEffect(() => {
        if (isOpen) {
            setCurrentSnapshotIndex(0);
            setCurrentValidationIndex(0);
            setCurrentInventoryIndex(0);
            setIsEditingAlias(false);
        } else {
            setSelectedItem(null);
            setDepartmentName("Loading ...");
        }
    }, [isOpen])

    const getFileListDetails = async (id) => {
        setLoadingSnapshots(true);

        try {
            const currentFileSnapshots = await getFileSnapshotsService(id);
            const snapShotsReversed = _.reverse([...currentFileSnapshots]);
            setFileSnapshots(snapShotsReversed);

            const firstSnapshot = _.first(snapShotsReversed);

            if (firstSnapshot) {
                const details = await getFileSnapshotValuesService(firstSnapshot.id);
                setFileDetails(details);
            }
        } catch (err) {
            console.log("Error while fetching file snapshots", err);
        }

        setLoadingSnapshots(false);
    }

    const changeCurrentPage = async (direction, currentIndex, items, tabName) => {
        let itemToShow = null;
        let newIndex = null;

        if (direction === 'next') {
            newIndex = (currentIndex + 1 > items.length) ? items.length - 1 : currentIndex + 1;
            itemToShow = items[newIndex];
        } else {
            newIndex = (currentIndex - 1 < 0) ? 0 : currentIndex - 1;
            itemToShow = items[newIndex];
        }

        if (itemToShow) {
            if (tabName === 0) {
                setCurrentInventoryIndex(newIndex);
            }

            if (tabName === 1) {
                const details = await getFileValidationAnswersService(itemToShow.validationId);
                setFileAnswers(details);
                setCurrentValidationIndex(newIndex);
            }

            if (tabName === 2) {
                const details = await getFileSnapshotValuesService(itemToShow.id);
                setFileDetails(details);
                setCurrentSnapshotIndex(newIndex);
            }
        }
    }

    const getFileValidations = async (fileId, validations = []) => {
        setLoadingValidations(true);

        try {
            if (validations.length) {
                setFileValidations([selectedItem]);
                setFileAnswers(selectedItem.answers || []);
            } else {
                const currentFileValidations = await getFileValidationsService(fileId);
                const validationsReversed = _.reverse([...currentFileValidations]);
                setFileValidations(validationsReversed);

                const firstValidation = _.first(validationsReversed);

                if (firstValidation) {
                    const answers = await getFileValidationAnswersService(firstValidation.validationId);
                    setFileAnswers(answers);
                }
            }
        } catch (err) {
            console.log("Error while fetching file snapshots", err);
        }

        setLoadingValidations(false);
    }

    const getFileDuplicates = async (fileId) => {
        setLoadingDuplicates(true);

        try {
            const dups = await getDuplicates(fileId);
            const dupsSorted = _.orderBy(dups, (item) => item.score, ['desc']);
            setFileDuplicates(dupsSorted);
        } catch (err) {
            console.log("Error while fetching file duplicates", err);
        }

        setLoadingDuplicates(false);
    }

    const getFileInventoryVersions = async (fileId, inventories = []) => {
        setLoadingInventoryVersions(true);

        try {
            const inventoryVersions = inventories.length ? inventories : await getInventoryVersionsService(fileId);

            const versionDataPromises = inventoryVersions.map(async inventory => {
                const allVersionsPromises = inventory?.versions?.map(async thisSectionVersion => {
                    const sectionsDataPromises = thisSectionVersion.sections.map(async section => {
                        const sectionData = await getInventorySectionQuestionsService(section.configSectionId);
                        const answers = section.answer;
                        const questions = sectionData.questions;

                        const questionsWithAnswers = answers?.map(questionAnswer => {
                            const thisQuestion = questions.filter(question => question.order === questionAnswer.questionOrder)[0];
                            const isQuestionSelect = thisQuestion.type === 'select' || thisQuestion.type === 'checkbox';
                            let answer = questionAnswer.answer;

                            if (isQuestionSelect) {
                                answer = questionAnswer.answer.map(option => {
                                    const questionValue = thisQuestion.options.filter(questionOption => questionOption.order === option)[0];
                                    return questionValue?.value[language] || "";
                                })
                            }

                            return {
                                title: thisQuestion.text[language],
                                questionType: thisQuestion.type,
                                answer
                            }
                        })

                        return {
                            sectionName: sectionData.sectionName,
                            order: sectionData.order,
                            answers: questionsWithAnswers || []
                        }
                    });

                    const sectionsData = await Promise.all(sectionsDataPromises);

                    return {
                        version: !_.isNil(inventory.inventoryVersion) && !_.isNil(thisSectionVersion.sectionVersion) ? `${inventory.inventoryVersion}.${thisSectionVersion.sectionVersion}` : null,
                        sections: sectionsData,
                        alias: inventory.alias,
                        businessOfficer: thisSectionVersion?.sections[0] ? thisSectionVersion.sections[0].userName : '-',
                        riskOfficer: thisSectionVersion?.sections[1] ? thisSectionVersion.sections[1].userName : '-',
                        creator: inventory.creator,
                        createdAt: inventory.createdAt
                    }
                })

                const allVersions = await Promise.all(allVersionsPromises);

                return allVersions;
            });

            const versionData = await Promise.all(versionDataPromises);
            const versionDataFlat = _.flattenDeep(versionData);
            const versionDataSorted = versionDataFlat.sort((a, b) => isOrderHigher(a.version, b.version));

            const versionWithAnswersComparison = versionDataSorted.map((version, i) => {
                const currentVersion = version;
                const previousVersion = versionDataSorted[i + 1];

                if (previousVersion) {
                    const aliasEqual = _.isEqual(currentVersion.alias, previousVersion.alias);
                    if (!aliasEqual) version.oldAlias = previousVersion.alias;

                    currentVersion.sections.forEach((currentVersionSection, i) => {
                        const previousVersionSection = previousVersion?.sections[i];

                        const currentVersionSectionQuestions = currentVersionSection.answers;
                        const previousVersionSectionQuestions = previousVersionSection?.answers;

                        currentVersionSectionQuestions.forEach((question, j) => {
                            const previousVersionQuestion = previousVersionSectionQuestions?.filter(previousQuestion => {
                                return previousQuestion.title === question.title;
                            })[0];

                            if (previousVersionQuestion) {
                                const answersEqual = _.isEqual(previousVersionQuestion.answer, question.answer);
                                if (!answersEqual) {
                                    version.sections[i].answers[j].oldAnswer = previousVersionQuestion.answer;
                                }
                            }
                            else {
                                version.sections[i].answers[j].oldAnswer = []
                            }
                        })
                    });
                }

                return version;
            });

            setInventoryVersions(versionWithAnswersComparison);
        } catch (err) {
            console.log(err)
        }

        setLoadingInventoryVersions(false)
    }

    const updateAlias = async newAlias => {
        const formData = {
            validationId: selectedItem.validationId,
            alias: newAlias
        }

        try {
            await changeValidationStatusService(formData);
            setSelectedItem({
                ...selectedItem,
                alias: newAlias
            });
            setIsEditingAlias(false);

            await refreshScreen();
        } catch (err) {
            console.log(`Error while changing alias: ${err}`);
        }
    }

    const downloadDuplicatesCsv = async columnList => {
        const duplicatesList = fileDuplicates.map(duplicate => duplicate.fileOtherId);
        const duplicatesDetails = await getGraphDataService(null, { duplicates: { list: duplicatesList } }, null);

        const duplicatedParsed = duplicatesDetails.map(data => {
            const fileStatus = getFileStatus(data.validationStatus, data.validationResult);
            const fileStatusLabel = fileStatusList.filter(status => status.id === fileStatus.id)[0];

            let columnData = {};

            columnList.forEach(column => {
                columnData[column.columnName] = _.isNil(data[column.columnKey]) ? "-" : data[column.columnKey];

                if (column.columnKey === "status") columnData[column.columnName] = t(fileStatusLabel.label);
                if (column.columnKey === "fileSize") columnData[column.columnName] = data.fileSize ? `${data.fileSize} bytes` : 0;
                if (column.columnKey === "fullName") columnData[column.columnName] = data.fullName.replace(`\\${data?.name}`, "");
                if (column.columnKey === "lastModified") columnData[column.columnName] = data.lastModified ? moment(data.lastModified).format("DD/MM/YYYY HH:mm") : "-";
            });

            return columnData;
        });

        const csvDuplicatesExportName = `duplicates-${moment(new Date()).format("YYYY-MM-DD HH[h]mm[m]")}.csv`

        exportToCsv(duplicatedParsed, csvDuplicatesExportName);
    }

    const getDepartmentName = async (data) => {
        try {
            const organizationData = await getOrganizationService();
            const useGroups = settings?.coordinatorConfig?.useGroups ? true : false;
            const name = getOrganizationName(useGroups, organizationData, data);
            setDepartmentName(name);
        } catch (err) {
            setDepartmentName("-");
        }
    }

    const scanViewActivated = settings?.validationConfig?.unpriviledgedScanView === 0;
    const onlyGuidancePermission = user?.groupPermissions?.length === 1 && user.groupPermissions[0] === "EUCGUIDANCE";
    const noTabsPermissions = !scanViewActivated && onlyGuidancePermission;

    return (
        <FileOverviewModalComponent
            {...props}

            isOpen={isOpen}
            setIsOpen={setIsOpen}
            settings={settings}
            selectedItem={selectedItem}
            departmentName={departmentName}

            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}

            changeCurrentPage={changeCurrentPage}

            // Snapshots details
            loadingSnapshots={loadingSnapshots}
            fileDetails={fileDetails}
            fileSnapshots={fileSnapshots}
            currentSnapshotIndex={currentSnapshotIndex}

            // Answers details
            loadingValidations={loadingValidations}
            fileValidations={fileValidations}
            fileAnswers={fileAnswers}
            currentValidationIndex={currentValidationIndex}

            // Duplicates details
            loadingDuplicates={loadingDuplicates}
            fileDuplicates={fileDuplicates}
            downloadDuplicatesCsv={downloadDuplicatesCsv}

            updateAlias={updateAlias}
            isEditingAlias={isEditingAlias}
            setIsEditingAlias={setIsEditingAlias}

            noTabsPermissions={noTabsPermissions}

            // Inventory details
            inventoryVersions={inventoryVersions}
            loadingInventoryVersions={loadingInventoryVersions}
            currentInventoryIndex={currentInventoryIndex}
        />
    )
};


const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user
});

export default connect(mapStateToProps)(FileOverviewModal);