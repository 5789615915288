import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StepThreeComponent from '../components/StepThreeComponent';
import { getFilterFilesService, getScanModesService } from 'services/scanning';
import moment from 'moment';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const StepThree = props => {
    const { changeStep, data, submitStepData, readOnly } = props;

    const [isFetching, setIsFetching] = useState(false);

    const [scanModes, setScanModes] = useState([]);
    const [filterFiles, setFilterFiles] = useState([]);

    const [selectedScanModes, setSelectedScanModes] = useState(data.scanSettings);
    const [selectedFileTypes, setSelectedFileTypes] = useState(data.filterSettings.fileExtensionsFilter);

    const { t } = useTranslation();

    useEffect(() => {
        getStepThreeData();
    }, []);

    const getFilterFiles = async () => {
        try {
            const response = await getFilterFilesService();
            setFilterFiles(response);

            const currentFileExtensions = data.filterSettings.fileExtensionsFilter;

            // Used to fill checkbox with get operation (POST send Ids and GET retrieves strings)
            if (typeof currentFileExtensions === 'string') {
                const selectedFileTypesIds = response.filter(fileType => {
                    return currentFileExtensions.includes(fileType.extensions);
                }).map(fileType => fileType.filterSettingFileId);

                setSelectedFileTypes(selectedFileTypesIds)
            }
        } catch (err) {
            console.log("Error while fetching filter files", err);
        }
    }

    const getScanModes = async () => {
        try {
            const response = await getScanModesService();
            setScanModes(response);
        } catch (err) {
            console.log("Error while fetching scan modes", err);
        }
    }

    const getStepThreeData = async () => {
        setIsFetching(true);
        await Promise.all([getFilterFiles(), getScanModes()]);
        setIsFetching(false);
    }

    const handleScanModeChange = (scanModeId, isChecked) => {
        let newSelectedScanModes = [];

        if (isChecked) {
            newSelectedScanModes = [scanModeId];
        }
        else {
            newSelectedScanModes = [];
        }

        setSelectedScanModes([...newSelectedScanModes]);
    }

    const handleFileTypeChange = (fileTypeId, isChecked) => {
        let newSelectedFileTypes = _.cloneDeep(selectedFileTypes);

        if (isChecked) {
            newSelectedFileTypes.push(fileTypeId);
        } else {
            _.pull(newSelectedFileTypes, fileTypeId);
        }

        setSelectedFileTypes(newSelectedFileTypes);
    }

    const submitData = (formikData) => {
        if (!selectedFileTypes.length || !selectedScanModes.length) {
            if (!selectedFileTypes.length && !selectedScanModes.length) {
                toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_wizard_step3_error_message_2')} />, {
                    progressClassName: "toastr-progress-bar danger"
                });
            } else {
                if (!selectedFileTypes.length) {
                    toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_wizard_step3_error_message_2')} />, {
                        progressClassName: "toastr-progress-bar danger"
                    });
                }

                if (!selectedScanModes.length) {
                    toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_wizard_step3_error_message_1')} />, {
                        progressClassName: "toastr-progress-bar danger"
                    });
                }
            }
        } else {
            const { fileSizeMin, fileSizeMax, lastChangeDateStart, lastChangeDateEnd, noSizeLimit } = formikData;
            const startDateParsed = moment(lastChangeDateStart).format('DD.MM.YYYY');
            const endDateParsed = moment(lastChangeDateEnd).format('DD.MM.YYYY');

            const stepData = {
                scanModeConfig: selectedScanModes,
                filterSettingConfig: {
                    fileExtensionsFilter: selectedFileTypes,
                    fileSizeFilter: noSizeLimit ? ">=0" : `>=${fileSizeMin}MB <=${fileSizeMax}MB`,
                    fileLastModifiedDateFilter: `${startDateParsed}-${endDateParsed}`
                },
                filterFiles
            };

            submitStepData(stepData, 3);
            changeStep(4);
        }
    }

    return (
        <StepThreeComponent
            data={data}
            scanModes={scanModes}
            filterFiles={filterFiles}
            handleScanModeChange={handleScanModeChange}
            selectedScanModes={selectedScanModes}
            handleFileTypeChange={handleFileTypeChange}
            selectedFileTypes={selectedFileTypes}
            submitData={submitData}
            isFetching={isFetching}
            readOnly={readOnly}
        />
    )
}

export default StepThree;