import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StartScanComponent from '../components/StartScanComponent';
import { myConfigurationsService } from 'services/scanning';

const StartScan = (props) => {
    const { match: { params: { id } } } = props;
    const [allConfigurations, setAllConfigurations] = useState([]);

    useEffect(() => {
        myConfigurations();

        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }
    }, []);

    const myConfigurations = async () => {
        try {
            const response = await myConfigurationsService();
            setAllConfigurations(response);
        } catch (err) {
            console.log("Error GET configurations service:", err);
        }
    }

    return <StartScanComponent
        id={id}
        allConfigurations={allConfigurations}
    />;
};

export default withRouter(StartScan);