import { UPDATE_SETTINGS, UPDATE_SETTINGS_CONFIG_GRAPH, UPDATE_SETTINGS_CONFIG_CRAWLER } from "../types/settingsTypes";

export const updateSettings = value => ({
    type: UPDATE_SETTINGS,
    settings: value
});

export const updateSettingsConfigGraph = value => ({
    type: UPDATE_SETTINGS_CONFIG_GRAPH,
    configGraph: value
});

export const updateSettingsConfigCrawler = value => ({
    type: UPDATE_SETTINGS_CONFIG_CRAWLER,
    configCrawler: value
});