import React, { useState, useEffect } from "react";
import StepOneComponent from '../components/StepOneComponent';
import { myConfigurationsService } from 'services/scanning';

const StepOne = props => {
    const { selectConfiguration } = props;

    const [isFetching, setIsFetching] = useState([]);
    const [configurationList, setConfigurationList] = useState([]);

    useEffect(() => {
        getMyConfigurations();
    }, []);

    const getMyConfigurations = async () => {
        setIsFetching(true);

        try {
            const response = await myConfigurationsService();
            setConfigurationList(response);
        } catch (err) {
            console.log("Error while fetching my configurations", err);
        }

        setIsFetching(false);
    }

    return (
        <StepOneComponent
            isFetching={isFetching}
            configurationList={configurationList}
            selectConfiguration={selectConfiguration}
        />
    )
}

export default StepOne;