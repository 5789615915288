import React from "react";
import { useTranslation } from "react-i18next";

const LoadingComponent = (props) => {
    const { t } = useTranslation();

    return (
        <div className="loading-component">
            {t('screenMain_layout_chain_text_2')} ...
        </div>
    )
};

export default LoadingComponent;