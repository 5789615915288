import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from 'components/common/Button/Button';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import _ from 'lodash';
import './AddQuestionTypeModal.scss';

const getQuestionByType = (questionType, sectionQuestions, currentParentOrderIndex, sectionName) => {
    const parentQuestions = sectionQuestions.filter(question => {
        return question.parentOrder === currentParentOrderIndex
    });

    const questionsOrder = parentQuestions.map(question => {
        const questionOrder = currentParentOrderIndex ? question.order.replace(`${currentParentOrderIndex}.`, "") : question.order;
        const questionOrderNumber = parseInt(questionOrder);
        return questionOrderNumber;
    })

    const questionNextOrder = questionsOrder.length ? _.max(questionsOrder) + 1 : 1;

    // let questionNextOrder = 1;

    // if (sectionName === 'EUC Information') {
    //     questionNextOrder = questionsOrder.length ? _.max(questionsOrder) : 1;
    // } else {
    //     questionNextOrder = questionsOrder.length ? _.max(questionsOrder) + 1 : 1;
    // }

    const questionOrder = currentParentOrderIndex ? `${currentParentOrderIndex}.${questionNextOrder}` : `${questionNextOrder}`;

    let questionInterface = {
        text: {
            "en": "",
            "de": ""
        },
        infoText: {
            "en": "",
            "de": ""
        },
        order: questionOrder,
        parentOrder: currentParentOrderIndex,
        behaviour: questionOrder === "1" ? "show" : "hide",
        type: questionType,
        requirements: [],
        options: [],
        fieldSize: null,
        finished: [],
        required: true,
        identifier: null
    };

    if (['checkbox', 'select'].includes(questionType)) {
        questionInterface.options = [
            {
                value: {
                    "en": "",
                    "de": ""
                },
                order: `${questionOrder}.1`
            },
            {
                value: {
                    "en": "",
                    "de": ""
                },
                order: `${questionOrder}.2`
            },
        ]
    }

    return questionInterface;
}

const AddQuestionTypeModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        addNewQuestionCallback,
        settings,
        sectionQuestions,
        currentParentOrderIndex,
        sectionName
    } = props;
    const [questionType, setQuestionType] = useState("text");
    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    const addNewQuestion = () => {
        const newQuestion = getQuestionByType(questionType, sectionQuestions, currentParentOrderIndex, sectionName);
        addNewQuestionCallback(newQuestion);
        setIsOpen(false);
        setQuestionType("text");
    }

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal add-question-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{t('add_question_type_modal_title')}</h3>
                <p style={{ color: mainColor }}>{t('add_question_type_modal_subtitle')}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <div className="input-wrapper" style={{ marginBottom: 20 }}>
                    <label htmlFor="keyword">{t('add_question_type_modal_input_label')}</label>
                    <select value={questionType} onChange={e => setQuestionType(e.target.value)}>
                        <option value="text">{t('add_question_type_modal_input_type_1')}</option>
                        <option value="select">{t('add_question_type_modal_input_type_2')}</option>
                        <option value="checkbox">{t('add_question_type_modal_input_type_3')}</option>
                        <option value="file">{t('add_question_type_modal_input_type_4')}</option>
                        <option value="title">Section title</option>
                    </select>
                </div>

                <div className="action-buttons">
                    <Button onClick={() => addNewQuestion()} style={{ width: 100 }}>{t('add_question_type_modal_create')}</Button>
                </div>
            </div>
        </Modal>
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(AddQuestionTypeModal);