import React, { useState } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from 'react-icons/ai'
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";
import WizardModal from 'components/Scanning/WizardModal/containers/WizardModal';
import moment from 'moment';
import './KonfigurationenComponent.scss';

const KonfigurationenComponentLoading = () => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
            </div>

            {
                _.times(5, (j) => (
                    <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                    </div>
                ))
            }
        </div>
    )
}

const KonfigurationenComponent = (props) => {
    const { configurationList, openWizardModal, isFetching, isModalOpen, setIsModalOpen, currentConfigId } = props;
    const { t } = useTranslation();

    const getConfigurationType = types => {
        const colonyIndex = types.indexOf("Colony Scan");
        const deepIndex = types.indexOf("Deep Scan");
        const quickIndex = types.indexOf("Quick Scan");

        if (colonyIndex !== -1) return types[colonyIndex];
        if (deepIndex !== -1) return types[deepIndex];
        if (quickIndex !== -1) return types[quickIndex];

        return "-";
    }

    return (
        <Layout>
            <div className="konfigurationen-wrapper">
                <div className="konfigurationen-content">
                    {
                        isFetching ?
                            <KonfigurationenComponentLoading /> :
                            configurationList.length ?
                                <div className="table-wrapper">
                                    <table className="kpmg-table stripped">
                                        <thead>
                                            <tr>
                                                <th>{t('scan_tab2_table_header_1')}</th>
                                                <th>{t('scan_tab2_table_header_2')}</th>
                                                <th>{t('scan_tab2_table_header_3')}</th>
                                                <th>{t('scan_tab2_table_header_4')}</th>
                                                <th>{t('scan_tab2_table_header_5')}</th>
                                                <th>{t('scan_tab2_table_header_6')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="empty">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {
                                                configurationList.map((configuration, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ textAlign: 'left', minWidth: 200 }}>{configuration.configName}</td>
                                                            <td>{getConfigurationType(configuration.scanModes)}</td>
                                                            <td>{configuration.lastRun === '0001-01-01T00:00:00' ? '-' : moment(configuration.lastRun).format("DD/MM/YYYY")}</td>
                                                            <td>{moment(configuration.createdAt).format("DD/MM/YYYY")}</td>
                                                            <td>{configuration.userName}</td>
                                                            <td>
                                                                <div className="details-button" onClick={() => openWizardModal(configuration.configRunId)}>
                                                                    <AiOutlineSearch style={{ fontSize: 16 }} />
                                                                    <span>{t('scan_tab2_table_button')}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div> :
                                <div>
                                    <p>{t('scan_tab2_table_empty')}</p>
                                </div>
                    }
                </div>
            </div>

            <WizardModal
                id={currentConfigId}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </Layout>
    )
};

export default KonfigurationenComponent;